import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { store, persistor } from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { injectStore } from 'services/token.service';
import App from './App';

injectStore(store)

createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
);
