import { useAuthState } from 'store/modules/auth';

const usePermissions = () => {
  const {
    loggedUser: { avaliable_permissions },
  } = useAuthState();

  const userIsPermittedTo = (checks, module) => {
    const permissions = avaliable_permissions?.[module] ?? [];
    if (module in avaliable_permissions) {
      if (typeof checks === 'string') {
        return permissions.includes(checks);
      }

      return checks.some((check) => permissions.includes(check));
    }

    return false;
  };

  return { userIsPermittedTo };
};

export default usePermissions;
