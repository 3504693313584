/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Vouchers({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="48" height="48" rx="24" fill={color || "white"}/>
        <g clipPath="url(#clip0_347_6583)">
        <path d="M23.8332 25.4514V26.3086C22.5105 26.3103 21.2385 25.7988 20.2852 24.8817V29.8382H34.4295C35.1396 29.8382 35.7154 29.2625 35.7154 28.5524V24.2662H20.8806C21.6746 25.0278 22.7328 25.4526 23.8332 25.4514ZM32.7151 27.2665H33.5723V28.1238H32.7151V27.2665ZM31.0006 27.2665H31.8578V28.1238H31.0006V27.2665ZM29.2861 27.2665H30.1433V28.1238H29.2861V27.2665ZM27.5716 27.2665H28.4289V28.1238H27.5716V27.2665Z" fill="#FF8264"/>
        <path d="M17.401 20.4087C16.9347 20.4135 16.5367 20.7462 16.4492 21.2042C16.3615 21.6621 16.6091 22.1181 17.0404 22.2945L19.1486 23.1411L18.3041 21.0215C18.1578 20.651 17.7995 20.4078 17.401 20.4087Z" fill="#FF8264"/>
        <path d="M31.319 33.5838C31.5917 33.7144 31.905 33.7309 32.1896 33.6297C32.4745 33.5286 32.707 33.318 32.8359 33.0447L33.9547 30.6955H25.3008L31.319 33.5838Z" fill="black"/>
        <path d="M14.2852 19.1228V23.409H17.5109L16.721 23.0917C15.9431 22.7836 15.4722 21.9881 15.5764 21.1579C15.6807 20.3277 16.3332 19.6732 17.163 19.5667C17.9931 19.4602 18.7898 19.9288 19.1002 20.7056L19.4286 21.5298V17.8369H15.571C14.8609 17.8369 14.2852 18.4127 14.2852 19.1228Z" fill="#FF8264"/>
        <path d="M18.6811 14.5198C18.4086 14.3892 18.0953 14.3729 17.8106 14.4739C17.5258 14.5752 17.2933 14.7858 17.1642 15.0591L16.25 16.9797H23.8069L18.6811 14.5198Z" fill="black"/>
        <path d="M15.9996 25.4514C17.1 25.4526 18.1582 25.0278 18.9524 24.2662H14.2852V28.5524C14.2852 29.2625 14.8609 29.8382 15.571 29.8382H19.4286V24.9905C18.4887 25.84 17.2664 26.3099 15.9996 26.3086V25.4514Z" fill="#FF8264"/>
        <path d="M13.4275 24.9619V22.9109L13.111 23.5762C12.8833 24.0555 13.0141 24.6287 13.4275 24.9619Z" fill="#FF8264"/>
        <path d="M36.5723 23.1419V25.1925L36.8885 24.5276C37.1164 24.0481 36.9856 23.4751 36.5723 23.1419Z" fill="#FF8264"/>
        <path d="M34.4295 17.8369H20.2852V21.8291L20.7326 20.7056C21.0451 19.9334 21.8395 19.4694 22.6656 19.5763C23.4918 19.6835 24.1417 20.3346 24.2471 21.161C24.3524 21.9873 23.887 22.7807 23.1141 23.0917L22.3219 23.409H35.7154V19.1228C35.7154 18.4127 35.1396 17.8369 34.4295 17.8369ZM28.4289 20.8373H27.5716V19.1228H28.4289V20.8373ZM30.1433 20.8373H29.2861V19.1228H30.1433V20.8373ZM31.8578 20.8373H31.0006V19.1228H31.8578V20.8373ZM33.5723 20.8373H32.7151V19.1228H33.5723V20.8373Z" fill="#FF8264"/>
        <path d="M23.4054 21.3871C23.4086 20.9193 23.0779 20.5156 22.6185 20.4264C22.1591 20.3373 21.7014 20.5882 21.5294 21.0233L20.6855 23.1428L22.7935 22.2945C23.1643 22.1463 23.4069 21.7866 23.4054 21.3871Z" fill="#FF8264"/>
        </g>
        <defs>
        <clipPath id="clip0_347_6583">
        <rect width="24" height="24" fill="white" transform="translate(13 12)"/>
        </clipPath>
        </defs>
        </svg>
    )
}

Vouchers.propTypes = {
    color: PropTypes.string
}