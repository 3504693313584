import React, { useCallback, useEffect, useState } from 'react';
import Close from 'assets/icons/CloseIcon';
import Input from 'components/Input/Input';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'components/DropDown/DropDown';
import { DiscountType, frequncyOptions } from 'constants/mocks/settings';
import CustomButton from 'components/Button/CustomButton';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import NotificationModal from 'components/Modal/NotificationModal';
import useFormatDate from 'hooks/useFormatDate';
import DefaultDatePicker from 'components/Input/DefaultDatePicker';
import { useSettingsState } from 'store/modules/settings';
import useSettings from 'hooks/fetch/useSettings';

const CreateDiscount = () => {
  const { loading, selectedDiscount } = useSettingsState();
  const navigate = useNavigate();
  const route = useLocation();
  const [type, setType] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const { id } = useParams();
  const { getFirstDayOfMonth, getLastDayOfMonth } = useFormatDate();
  const { creatediscount, editdiscount } = useSettings();
  //Local State
  const [formData, setFormData] = useState({
    discount_value: '',
    discount_type: '',
    frequency: '',
    threshold: '',
    start_date: '',
    end_date: '',
    product_category: id,
    status: 'active',
  });

  const [showModal, setShowModal] = useState(false);

  //handler function
  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData],
  );
  const startDate = () => {
    return route.pathname.includes('edit-discount') && selectedDiscount
    ? new Date(selectedDiscount.start_date).toISOString().split('T')[0]
    : formData.start_date
    // return route.pathname.includes('edit-discount') && selectedDiscount
    //   ? selectedDiscount.start_date
    //   : null;
  };
  const endDate = () => {
    // return route.pathname.includes('edit-discount') && selectedDiscount
    //   ? selectedDiscount.end_date
    //   : null;
    return route.pathname.includes('edit-discount') && selectedDiscount
    ? new Date(selectedDiscount.end_date).toISOString().split('T')[0]
    : formData.end_date
  };
  useEffect(() => {
    if (route.pathname.includes('edit-discount') && selectedDiscount) {
      setFormData({
        discount_value: selectedDiscount.discount_value ?? '',
        discount_type: selectedDiscount.discount_type ?? '',
        frequency: selectedDiscount.frequency ?? '',
        threshold: selectedDiscount.threshold ?? '',
        start_date: selectedDiscount.start_date ?? '',
        end_date: selectedDiscount.end_date ?? '',
        product_category: selectedDiscount.product_category ?? '',
      });

      setType({
        id: selectedDiscount.discount_type,
        value: selectedDiscount.discount_type,
        label: selectedDiscount.discount_type,
      });
      setFrequency({
        id: selectedDiscount.frequency,
        value: selectedDiscount.frequency,
        label: selectedDiscount.frequency.replace('_', ' '),
      });
    }
  }, [selectedDiscount]);

  const handleClearForm = () => {
    setFormData({
      discount_value: '',
      discount_type: '',
      frequency: '',
      threshold: '',
      start_date: '',
      end_date: '',
    });
    setType(null);
    setFrequency(null);
  };

  const makeRequest = (e) => {
    e.preventDefault();
    route.pathname.includes('edit-discount') && selectedDiscount
      ? editdiscount(selectedDiscount?.discount_id, formData, handleShowModal)
      : creatediscount(formData, handleShowModal);
  };
  return (
    <DashboardLayout
      pageHeader="Discount Management"
      variant="mb-0"
      hasCardTitle={false}
      placeholder="Search names or roles"
      hasPageFilter={false}
      hasFilter={false}
    >
      <div className="grid grid-cols-12">
        <div className="border border-gray-150 p-6 col-span-12 lg:col-span-6 xl:col=span-4 rounded-lg">
          <div className="flex items-center justify-between">
            <p className="text-black-200 font-dmsans_b text-16 leading-6 capitalize">{id}</p>
            <div className="cursor-pointer" data-testid="close" onClick={() => navigate(-1)}>
              <Close color="#3A434B" />
            </div>
          </div>
          <hr className="mt-4 mb-6 h-px border-0 bg-light-smoke" />
          <form className="mt-10" onSubmit={(e) => makeRequest(e)}>
            <div className="grid grid-cols-12 gap-6">
              <div className="mb-6 col-span-12 md:col-span-6">
                <Input
                  label="Discount Value"
                  type="number"
                  placeholder="3%"
                  inputVariant='h-11'
                  id="discount_value"
                  name="discount_value"
                  readonly={route.pathname.includes('edit-discount')}
                  value={formData.discount_value}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6 col-span-12 md:col-span-6">
                <Dropdown
                  variant=" w-full"
                  placeholder=""
                  innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                  value={type}
                  type="input"
                  optionContainerVariant="w-full top-[72px]"
                  inputLabel="Discount Type"
                  inputStyles="border border-gray-600 text-14 rounded-lg font-dmsans_r"
                  inputPlaceholder="Select discount type"
                  inputName="discount_type"
                  inputId="discount_type"
                  options={DiscountType}
                  onChange={(data) => {
                    setType(data);
                    setFormData({ ...formData, discount_type: data.value });
                  }}
                  disabled={route.pathname.includes('edit-discount')}
                />
              </div>
              {/* <div className="mb-6 col-span-12 md:col-span-6">
                <DefaultDatePicker
                  isDisabled={route.pathname.includes('edit-discount')}
                  defaultValue={startDate()}
                  onHandleDateChange={(date) => setFormData({ ...formData, start_date: date })}
                  showLabel={true}
                  label="Start Date"
                  dateVariant=" text-14 border-0 w-full py-16 outline-0 font-dmsans_r font-normal rounded-lg focus:border bg-green_light"
                  selectedDate={new Date(formData.start_date)}
                />
              </div> */}
              <div className="mb-6 col-span-12 md:col-span-6">
                <label className="text-grey mb-2 block text-14">
                  Start Date
                </label>
                <input
                  type='date'
                  defaultValue={startDate()}
                  disabled={route.pathname.includes('edit-discount')}
                  onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
                  className='p-14 text-14 w-full outline-0 font-dmsans_r font-normal
                  rounded-lg focus:border-purple focus:border bg-green_light'
                />
              </div>
              <div className="mb-6 col-span-12 md:col-span-6">
                <label className="text-grey mb-2 block text-14">
                  End Date
                </label>
                <input
                  type='date'
                  defaultValue={endDate()}
                  disabled={route.pathname.includes('edit-discount')}
                  onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}
                  className='p-14 text-14 w-full outline-0 font-dmsans_r font-normal
                  rounded-lg focus:border-purple focus:border bg-green_light'
                />
              </div>
              {/* <div className="mb-6 col-span-12 md:col-span-6">
                <DefaultDatePicker
                  defaultValue={endDate()}
                  isDisabled={route.pathname.includes('edit-discount')}
                  onHandleDateChange={(date) => setFormData({ ...formData, end_date: date })}
                  showLabel={true}
                  label="End Date"
                  dateVariant=" text-14 border-0 w-full py-16 outline-0 font-dmsans_r font-normal rounded-lg focus:border bg-green_light"
                  selectedDate={new Date(formData.end_date)}
                />
              </div> */}
              <div className="mb-6 col-span-12 md:col-span-6">
                <Input
                  label="Threshold (Amount)"
                  type="text"
                  placeholder="100"
                  inputVariant='h-11'
                  id="threshold"
                  name="threshold"
                  value={formData.threshold}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6 col-span-12 md:col-span-6">
                <Dropdown
                  variant=" w-full"
                  placeholder=""
                  innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                  value={frequency}
                  type="input"
                  inputLabel="Frequency"
                  inputStyles="border border-gray-600 text-14 rounded-lg font-dmsans_r"
                  optionContainerVariant="h-28 w-full scroll-styled top-[72px]"
                  inputPlaceholder="Select frequency"
                  inputName="frequency"
                  inputId="frequency"
                  options={frequncyOptions}
                  onChange={(data) => {
                    setFrequency(data);
                    setFormData({ ...formData, frequency: data.value });
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end items-center space-x-6">
              <p
                className="text-purple font-dmsans_r cursor-pointer"
                data-testid="test-cancel"
                onClick={handleClearForm}
              >
                Cancel
              </p>
              <CustomButton
                name="Save"
                disabled={
                  !formData.discount_type ||
                  !formData.discount_value ||
                  !formData.threshold ||
                  !formData.product_category ||
                  !formData.frequency ||
                  !formData.start_date ||
                  !formData.end_date
                }
                loading={loading}
                variant="border border-purple bg-purple text-white rounded font-dmsans_r"
              />
            </div>
          </form>
        </div>
      </div>
      {showModal && (
        <NotificationModal
          onClick={() => navigate(-1)}
          closeModal={handleShowModal}
          title="Your Discount has been Saved!"
          containerVariant="min-w-[400px]"
          buttonName="Done"
          customWidth="w-4/5 mx-auto"
        />
      )}
    </DashboardLayout>
  );
};

export default CreateDiscount;
