/* eslint-disable max-len */
import React from 'react';

export const Airtime2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#23BD86" />
      <g clipPath="url(#clip0_1724_33960)">
        <path
          d="M18.5454 28.5457V31.3866C18.5454 31.8386 18.725 32.2722 19.0447 32.5919C19.3643 32.9115 19.7979 33.0911 20.25 33.0911H28.2045C28.6566 33.0911 29.0901 32.9115 29.4098 32.5919C29.7295 32.2722 29.909 31.8386 29.909 31.3866V28.5457H18.5454ZM24.2272 31.9547C24.0025 31.9547 23.7828 31.8881 23.5959 31.7632C23.409 31.6384 23.2634 31.4609 23.1774 31.2533C23.0914 31.0456 23.0689 30.8171 23.1127 30.5967C23.1565 30.3763 23.2648 30.1738 23.4237 30.0149C23.5826 29.8559 23.7851 29.7477 24.0055 29.7039C24.226 29.66 24.4545 29.6825 24.6621 29.7685C24.8697 29.8545 25.0472 30.0002 25.1721 30.1871C25.2969 30.3739 25.3636 30.5936 25.3636 30.8184C25.3636 31.1198 25.2439 31.4088 25.0308 31.6219C24.8176 31.835 24.5286 31.9547 24.2272 31.9547Z"
          fill="#E9E6FD"
        />
        <path
          d="M18.5454 28.5455V16.6137C18.5454 16.1617 18.725 15.7281 19.0447 15.4084C19.3643 15.0888 19.7979 14.9092 20.25 14.9092H28.2045C28.6566 14.9092 29.0901 15.0888 29.4098 15.4084C29.7295 15.7281 29.909 16.1617 29.909 16.6137V28.5455H18.5454Z"
          fill="white"
        />
      </g>
      <path
        d="M35.4 4.25462C35.7314 3.68067 35.536 2.94386 34.9462 2.64158C31.5824 0.917603 27.8584 0.0124634 24.0786 0.000121597C23.4158 -0.00204235 22.904 0.562871 22.935 1.22489C22.9659 1.88691 23.528 2.39499 24.1907 2.40084C27.5113 2.43013 30.781 3.22484 33.7446 4.723C34.3361 5.02199 35.0686 4.82857 35.4 4.25462Z"
        fill="#23BD86"
      />
      <defs>
        <clipPath id="clip0_1724_33960">
          <rect width="20" height="20" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};
