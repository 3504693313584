export const PaymentHeader = [
  {
    name: 'Customer',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Categories',
    variant: 'pl-4',
  },
  {
    name: 'Amount Paid',
    variant: 'pl-4',
  },
  {
    name: 'Payment  Method/ API',
    variant: 'pl-4',
  },
  {
    name: 'Date Created',
    variant: 'pl-4',
  },
];
export const GuestPaymentHeader = [
  {
    name: 'Customer',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Categories',
    variant: 'pl-4',
  },
  {
    name: 'Amount Paid',
    variant: 'pl-4',
  },
  {
    name: 'Payment',
    variant: 'pl-4',
  },
  {
    name: 'Status',
    variant: 'pl-4',
  },
  {
    name: 'Date Created',
    variant: 'pl-4',
  },
  {
    name: '',
  },
];
export const paymentFilterList = [
  { id: 8, value: '', label: 'All Categories' },
  { id: 1, value: 'airtime-purchase', label: 'Airtime' },
  { id: 2, value: 'data-purchase', label: 'Data' },
  { id: 3, value: 'electricity-purchase', label: 'Electricity' },
  { id: 4, value: 'cable-purchase', label: 'Cable TV' },
  { id: 5, value: 'internet-purchase', label: 'Internet' },
  { id: 6, value: 'voucher-purchase', label: 'Vouchers' },
  { id: 7, value: 'betting-purchase', label: 'Betting' },
  { id: 9, value: 'education-purchase', label: 'Education' },
  { id: 10, value: 'success', label: 'Status - Success' },
  { id: 11, value: 'pending', label: 'Status - Pending' },
  { id: 12, value: 'failed', label: 'Status - Failed' },
];

export const providerFilterList = [
  { id: 4, value: '', label: 'All Providers', key: 'api' },
  { id: 1, value: 'paga', label: 'Paga', key: 'api' },
  { id: 2, value: 'vas2nets', label: 'Vas2nets', key: 'api' },
  { id: 3, value: 'quickteller', label: 'Quickteller', key: 'api' },
];
export const frequencies = [
  {
    label: 'JAN',
    value: '01',
  },
  {
    label: 'FEB',
    value: '02',
  },
  {
    label: 'MAR',
    value: '03',
  },
  {
    label: 'APR',
    value: '04',
  },
  {
    label: 'MAY',
    value: '05',
  },
  {
    label: 'JUN',
    value: '06',
  },
  {
    label: 'JUL',
    value: '07',
  },
  {
    label: 'AUG',
    value: '08',
  },
  {
    label: 'SEP',
    value: '09',
  },
  {
    label: 'OCT',
    value: '10',
  },
  {
    label: 'NOV',
    value: '11',
  },
  {
    label: 'DEC',
    value: '12',
  },
];
export const AllCategory = [
  'Electricity',
  'Airtime',
  'Data',
  'Water Bill',
  'Cable TV',
  'Internet',
  'Betting',
  'Vouchers',
];
