import dayjs from 'dayjs';
import React from 'react';
import { useTransferState } from 'store/modules/transfers';
import { formatCurrency } from 'utils/formatCurrency';

const ViewTransfer = () => {
  const { selectedTransfer } = useTransferState();
  const transferType = (type) => {
    switch (type) {
      case 'p2p-transfer':
        return 'P2P';
      case 'local-bank-transfer':
        return 'External';
      case 'fund-wallet':
        return 'Fund Wallet';
    }
  };

  return (
    <section className="mt-6">
      <div className="grid gap-8 grid-cols-12">
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Transfer Type</p>
          <p className="text-dark_blue text-14 mt-1">{transferType(selectedTransfer?.transaction_category)}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Wallet Balance Before </p>
          <p className="text-dark_blue text-14 mt-1">
            {formatCurrency(selectedTransfer?.wallet_balance_before, selectedTransfer?.currency)}
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Wallet Balance After </p>
          <p className="text-dark_blue text-14 mt-1">
            {formatCurrency(selectedTransfer?.wallet_balance_after, selectedTransfer?.currency)}
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Transaction ID</p>
          <p className="text-dark_blue text-14 mt-1">{selectedTransfer?.transaction_id}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Date</p>
          <p className="text-blue_black text-14 mt-1">
            <p>
              {dayjs(selectedTransfer?.created_at).format('MMM DD, YYYY')}
              <span className="text-grey font-dmsans_r"> | {dayjs(selectedTransfer?.created_at).format('h:mm a')}</span>
            </p>
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">
            {selectedTransfer?.transaction_category === 'fund-wallet' ? 'Sender’s Info ' : 'Sender’s Tag'}
          </p>
          {selectedTransfer?.transaction_category === 'fund-wallet' ? (
            <div className="mt-1">
              <p className="text-blue_black text-14 font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {/* {selectedTransfer?.extra_details?.sender_account_name} */}
                {selectedTransfer?.extra_details?.senderName}
              </p>
              <p className="text-grey-500 text-14 font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {/* {selectedTransfer?.extra_details?.bank_name || 'N/A'} |{' '}
                {selectedTransfer.extra_details?.sender_account_number || 'N/A'} */}
                {selectedTransfer?.extra_details?.senderBank || 'N/A'} |{' '}
                {selectedTransfer.extra_details?.senderAccountNumber || 'N/A'}
              </p>
            </div>
          ) : (
            <p className="text-dark_blue text-14 mt-1">@{selectedTransfer?.sender_tag}</p>
          )}
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">
            {selectedTransfer?.transaction_category === 'local-bank-transfer' ? 'Reciever’s Info' : 'Reciever’s Tag'}
          </p>
          {selectedTransfer?.transaction_category === 'local-bank-transfer' ? (
            <div className="mt-1">
              <p className="text-blue_black text-14 font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {selectedTransfer?.extra_details?.receiver_account_name}
              </p>
              <p className="text-grey-500 text-14 font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {selectedTransfer?.extra_details?.bank_name || 'N/A'} |{' '}
                {selectedTransfer.extra_details?.receiver_account_number || 'N/A'}
              </p>
            </div>
          ) : (
            <p className="text-dark_blue text-14 mt-1">@{selectedTransfer?.extra_details?.receiver_tag}</p>
          )}
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Amount Sent</p>
          <p className="text-dark_blue text-14 mt-1">
            {formatCurrency(selectedTransfer?.amount, selectedTransfer?.currency)}
          </p>
        </div>
     
      </div>
    </section>
  );
};

export default ViewTransfer;
