/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export const AdminTransactionIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19922 5.28039C6.19922 3.72039 7.42322 2.40039 8.99922 2.40039C10.5752 2.40039 11.7992 3.72039 11.7992 5.28039C11.7992 6.84039 10.5752 8.16039 8.99922 8.16039C7.42322 8.16039 6.19922 6.84039 6.19922 5.28039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.01042 7.25391C6.43042 7.25391 4.19922 9.23071 4.19922 11.8667L4.20082 13.6011C4.20103 13.8129 4.28519 14.0159 4.43485 14.1657C4.58451 14.3155 4.78746 14.3999 4.99922 14.4003H12.9992C13.2114 14.4003 13.4149 14.316 13.5649 14.166C13.7149 14.016 13.7992 13.8125 13.7992 13.6003V11.8667C13.7992 9.23631 11.5952 7.25391 9.01042 7.25391Z"
        fill={color}
      />
    </g>
    <path
      d="M7.59922 6.8C8.9247 6.8 9.99922 5.72548 9.99922 4.4C9.99922 3.07452 8.9247 2 7.59922 2C6.27374 2 5.19922 3.07452 5.19922 4.4C5.19922 5.72548 6.27374 6.8 7.59922 6.8Z"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M11.9992 13.2008V11.6008C11.9992 9.12238 10.0032 6.80078 7.59922 6.80078C5.19522 6.80078 3.19922 9.12238 3.19922 11.6008V13.2008"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

AdminTransactionIcon.propTypes = {
  color: PropTypes.string,
};
