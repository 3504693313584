import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Spinner from 'components/Spinner';
import { defaultOptions } from '../../constants/mocks/ChartData';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Chart({data, loading}) {

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Bar
          data-testid={`test-chart-js`}
          data={data}
          options={defaultOptions}
          height="300px"
        />
      )}
    </div>
  );
}

Chart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};
