import React from 'react';
import PropTypes from "prop-types"
import Close from 'assets/icons/CloseIcon';
import BackButton from 'components/Button/BackButton';
import Title from 'components/Header/Title';
import Input from 'components/Input/Input';
import TextArea from 'components/Input/TextArea';
import Dropdown from 'components/DropDown/DropDown';
import { MessageType } from 'constants/mocks/notifications';

const ViewMessage = ({ message, setSelectedMessage }) => {
    return (
        <div>
            <div className="flex items-center space-x-2">
                <BackButton onClick={setSelectedMessage} />
                <Title title="Messaging" variant="" />
            </div>
            <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border h-[75vh] mt-6">
                <div className="grid grid-cols-3">
                    <div className="border border-gray-150 p-6 col-span-2 rounded-lg">
                        <div className="flex items-center justify-between">
                            <p className="text-black-200 font-dmsans_b text-16 leading-6">In App Message</p>
                            <div
                                className="cursor-pointer" data-testid="close"
                                onClick={setSelectedMessage}
                            >
                                <Close color="#3A434B" />
                            </div>
                        </div>
                        <hr className="mt-4 mb-6 h-px border-0 bg-light-smoke" />
                        <div className="grid grid-cols-2 gap-6 mb-6">
                            <div>
                                <Input
                                    label="Title"
                                    type="text"
                                    placeholder="Message Title"
                                    id="title"
                                    name="title"
                                    value={message?.title}
                                    readOnly={true}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    variant="w-full"
                                    placeholder=""
                                    innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                                    value={message?.type}
                                    type="input"
                                    inputLabel="Message Type"
                                    inputStyles="border border-gray-600 text-14 rounded-lg font-dmsans_r h-[53px]"
                                    inputPlaceholder="Select message type"
                                    optionContainerVariant="top-[80px] w-full"
                                    inputName="role"
                                    inputId="role"
                                    options={MessageType}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="">
                            <TextArea
                                label="Message"
                                // type="text"
                                placeholder="Enter Message here"
                                id="message"
                                name="message"
                                rows={5}
                                value={message?.body}
                                readOnly={true}
                            />
                        </div>
                        <>{message?.image_url && <img src={message?.image_url} className='w-full' />}</>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewMessage;
ViewMessage.propTypes = {
    message: PropTypes.object,
    setSelectedMessage: PropTypes.func
}
