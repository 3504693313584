import React from 'react'
import PropTypes from 'prop-types'

export default function Title({ title, variant }) {
    return(
        <>
        <p className={`${variant} text-dark_blue font-altone_m xxs:text-18 md:text-24 capitalize` }>{title}</p>
        </>
    )
}

Title.propTypes ={
    title: PropTypes.string,
    variant: PropTypes.string,
}