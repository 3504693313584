import { useEffect, useState } from 'react';

export default function usePIN(numberOfPIN, ref) {
  const [otp, setOtp] = useState([]);

  useEffect(() => {
    let results = [];
    for (let i = 1; i <= numberOfPIN; i++) {
      results.push({
        key: i,
        value: '',
        isFocus: i === 1 ? true : false,
      });
    }

    setOtp(results);
  }, []);

  //Handler Function
  const handleOnPress = (e, currentInput) => {
    if (currentInput.key > 1 && e.key === 'ArrowLeft') ref.current[currentInput.key - 1].focus();

    if (currentInput.key < 6 && e.key === 'ArrowRight') ref.current[currentInput.key + 1].focus();
    //Track for backspace click and change focus to last input
    if (currentInput.key > 1 && e.key === 'Backspace') {
      otp[currentInput.key - 1].value = ''; //Subtract by 1 to target current input
      otp[currentInput.key - 2].value = ''; //Subtract by 2 to target last enter input
      setOtp([...otp]);
      //Update the focus of the next input
      ref.current[currentInput.key - 1].focus();
    }
  };

  const handleOnPaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData;
    const pastedData = clipboardData?.getData('Text');

    const breakCopyCodeToArray = pastedData?.split('');

    otp.forEach((x, index) => {
      x.value = breakCopyCodeToArray[index];
      return x;
    });
    setOtp([...otp]);
    ref.current[numberOfPIN.length]?.focus();
  };

  const handleOnChange = (e, currentInput) => {
    if (e.nativeEvent.inputType === 'insertText') {
      otp[currentInput.key - 1].value = e.target.value;
      setOtp([...otp]); //Update the value of the current input

      currentInput.key < numberOfPIN && ref.current[currentInput.key + 1].focus(); //Update the focus of the next input
    }

    if (currentInput.key > 0 && e.nativeEvent.inputType === 'deleteContentBackward') {
      otp[currentInput.key - 1].value = '';
      setOtp([...otp]); //Update the value of the current input

      currentInput.key === 1 && ref.current[currentInput.key].focus(); //Update the focus of the next input
      currentInput.key !== 1 && ref.current[currentInput.key - 1].focus(); //Update the focus of the next input
    }
  };

  return {
    otp,
    handleOnPaste,
    handleOnChange,
    handleOnPress,
    setOtp,
  };
}
