/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { DatefilterList, EntriesfilterList } from '../../../constants/mocks/customers';
import Dropdown from '../../DropDown/DropDown';
import { BsArrowDownShort, BsArrowUpShort, BsPlusLg } from 'react-icons/bs';
import Status from '../../Status';
import TransactionDetails from '../Transactions/TransactionDetails';
import Modal from '../../Modal/Modal';
import useFormatDate from 'hooks/useFormatDate';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import { groupDataByDate } from 'utils/groupDataByDate';
import EmptyState from 'components/EmptyState';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import SendIcon from 'assets/icons/SendIcon';
import PropTypes from 'prop-types';
import DateFilter from 'components/DropDown/DateFilter';
import SearchInput from 'components/Input/SearchInput';
import { useDebounce } from 'hooks/useDounce';
import { usePaymentState } from 'store/modules/payment';
import useAdmin from 'hooks/fetch/useAdmin';
import { UserInitals } from 'utils/ImagePlaceholder';
import CTable from 'components/Table/Table';
import { paymentFilterList, PaymentHeader, providerFilterList } from 'constants/mocks/payment';
import { TransactionHistoryHeader } from 'constants/mocks/transaction_history';
import Filter from 'assets/icons/Filter';
import { useTransferState } from 'store/modules/transfers';
import {
  NewCategoryHistoryFilter,
  NewStatusHistoryFilter,
  NewTransactionHistoryFilter,
  NewTransactionHistoryHeader,
} from 'constants/mocks/new_transaction_history';
import NewStatus from 'components/Status/NewStatus';
import Pagination from 'components/Pagination';
import CopyIcon from 'assets/icons/CopyIcon';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import CollapseIcon from 'assets/icons/CollapseIcon';

const NewTransactionHistory = ({ toggleTransactionTable, handleToggleTransactionTable, viewingCustomerProfile }) => {
  //Redux Hooks
  const customerState = useCustomerState();
  const { copyToClipboard } = useCopyToClipboard();
  const { getFirstDayOfYear, getLastDayOfYear } = useFormatDate();
  const todayDate = dayjs(new Date()).format('YYYY-MM-DD');

  //Local State
  const [filter, setFilter] = useState({
    id: 2,
    value: 'thismonth',
    label: 'This month',
    startDate: getFirstDayOfYear(),
    endDate: getLastDayOfYear(),
  });
  const [showModal, setShowModal] = useState(false);

  //Hooks
  const { getAllTransactionHistory, handleViewTransactionReceipt } = useCustomer();

  const filterTransactions = useMemo(
    () => groupDataByDate(customerState?.transactionHistory?.histories),
    [customerState?.transactionHistory?.histories],
  );

  const handleViewReceipt = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const [filterTable, setFilterTable] = useState();
  const [statusTable, setStatusTable] = useState();
  const [categoryTable, setCategoryTable] = useState();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const per_page = perPage.value;
  const debouncedSearch = useDebounce(search);
  const [transactionType, setTransactionType] = useState('');
  const [transactionCategory, setTransactionCategory] = useState('');

  const handleTransactionType = useCallback(
    (data) => {
      setTransactionType(data?.value);
      setFilterTable(data);
    },
    [transactionType],
  );

  const handleTransactionStatus = useCallback(
    (data) => {
      setStatus(data?.value);
      setStatusTable(data);
    },
    [status],
  );

  const handleTransactionCategory = useCallback(
    (data) => {
      setTransactionCategory(data?.value);
      setCategoryTable(data);
    },
    [transactionCategory],
  );

  const filterSearch = () => {
    getAllTransactionHistory(customerState?.selectedCustomer?.user_id, {
      fromDate: filter.startDate,
      toDate: filter.endDate,
      page,
      perPage: per_page,
      transactionType,
      status,
      transactionCategory,
      search: debouncedSearch,
    });
  };

  const changeCurrentPage = (data) => {
    setPage(data.selected + 1);
  };

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );

  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  useEffect(() => {
    filterSearch();
  }, [per_page, page, debouncedSearch, status, transactionType, transactionCategory]);

  return (
    <div>
      <div className="font-dmsans_m pb-32">
        <div className="flex justify-between items-center py-16">
          <p className="text-black-transparent text-16">Transaction History</p>
          {viewingCustomerProfile && (
            <button
              type="button"
              onClick={() => handleToggleTransactionTable()}
              className="text-purple capitalize text-12 font-dmsans_r flex items-center gap-1"
            >
              {toggleTransactionTable === 'collapse' && <CollapseIcon />}
              {toggleTransactionTable} Table
            </button>
          )}
        </div>
        <div>
          <div className="border rounded-[8px] border-[#D7DCE0] p-5 h-full">
            <div className="flex items-center justify-end flex-wrap gap-5">
              <SearchInput
                placeholder="Search by beneficiary and transaction ID"
                value={search}
                handleChange={handleChange}
              />
              <Dropdown
                variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
                placeholder={`Filter by Operation`}
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                icon={<Filter />}
                type="filter"
                // optionContainerVariant={filterStyles}
                value={filterTable}
                options={NewTransactionHistoryFilter}
                onChange={handleTransactionType}
              />
              <Dropdown
                variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
                placeholder={`Filter by Payment`}
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                icon={<Filter />}
                type="filter"
                // optionContainerVariant={filterStyles}
                value={categoryTable}
                options={NewCategoryHistoryFilter}
                onChange={handleTransactionCategory}
              />
              <Dropdown
                variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
                placeholder={`Filter by Status`}
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                icon={<Filter />}
                type="filter"
                // optionContainerVariant={filterStyles}
                value={statusTable}
                options={NewStatusHistoryFilter}
                onChange={handleTransactionStatus}
              />
              <div className="flex items-center mt-4 lg:mt-0">
                <span className="text-14 font-dmsans_r mr-2">Showing:</span>
                <div data-testid="pagefilter">
                  <Dropdown
                    variant="border border-light-smoke w-[142px]"
                    placeholder=""
                    innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                    icon={<IoIosArrowDown />}
                    value={perPage}
                    type="filter"
                    options={EntriesfilterList}
                    onChange={handlePerPage}
                  />
                </div>
              </div>
            </div>
            {customerState?.loading && (
              <div className="fixed inset-0 z-20 bg-[#00000030] flex items-center justify-center">
                <Spinner />
              </div>
            )}
            <div className="mt-7">
              <div
                className={`border-y border-purple_light w-full h-11  bg-grey-400 rounded flex items-center gap-5 px-2`}
              >
                {NewTransactionHistoryHeader?.map((title) => (
                  <p
                    key={`header-${title.name}`}
                    className={`${title.variant} text-left text-blue_black text-14 font-dmsans_b truncate line-clamp-1`}
                    data-testid="column-header"
                  >
                    {title.name}
                  </p>
                ))}
              </div>
              <div className="flex flex-col">
                {customerState?.transactionHistory?.history?.map((item) => (
                  <div
                    key={item?.created_at}
                    className="flex items-center w-full gap-5 py-3 px-2 hover:bg-purple-50 transition-all relative overflow-hidden
                     border-b border-[#EAECF0]"
                  >
                    <div className="w-[10%] 2xl:w-[12%]">
                      {/* <Status showIcon status={item?.status} /> */}
                      <NewStatus status={item?.status} />
                    </div>
                    <div className="font-dmsans_r text-14 text-blue_black flex flex-col w-[12%] 2xl:w-[9.5%]">
                      <p className="w-fit whitespace-no-wrap">{dayjs(item?.created_at).format('MMM DD, YYYY')}</p>
                      <p className="">{dayjs(item?.created_at).format('h:mm a')}</p>
                    </div>
                    <p className="text-blue_black text-14 font-dmsans_r capitalize w-[10%] 2xl:w-[8%]">
                      {`${item?.transaction_type}`}
                    </p>
                    <p className="font-dmsans_r truncate text-blue_black flex items-center w-[14%] text-14">
                      <p className="w-3/4 truncate">{item?.transaction_id}</p>
                      <CopyIcon onClick={() => copyToClipboard(item?.transaction_id)} />
                    </p>
                    <p className="font-dmsans_r capitalize truncate text-blue_black w-[15%] text-14">
                      {`${item?.transaction_category?.replaceAll('-', ' ')}`}
                    </p>
                    <button
                      type="button"
                      onClick={() =>
                        handleViewTransactionReceipt(() => setShowModal(true), {
                          id: customerState?.selectedCustomer?.user_id,
                          transaction_id: item?.transaction_id,
                        })
                      }
                      className="font-dmsans_r text-left capitalize truncate text-blue_black w-[14%] text-14"
                    >
                      {`${item?.beneficiary_name}`}
                    </button>
                    <p className="font-dmsans_r truncate text-blue_black w-[13%] text-14">
                      {formatCurrency(item?.wallet_balance_after)}
                    </p>
                    <p className="font-dmsans_b truncate text-blue_black w-[12%] text-14">
                      {formatCurrency(item?.amount)}
                    </p>
                  </div>
                ))}
              </div>
              <div>
                {customerState?.transactionHistory?.history?.length > 0 ? (
                  <Pagination
                    changePage={changeCurrentPage}
                    totalPayload={customerState?.transactionHistory?.total}
                    perPage={per_page}
                    currentPage={customerState?.transactionHistory?.page || 0}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          closeModal={handleViewReceipt}
          title="Transaction Details"
          containerVariant="min-w-[760px]"
          dividerVariant="mb-8 mt-5"
          status={<Status showIcon status={customerState.transactionReceipt.status} />}
        >
          <TransactionDetails transactionReceipt={customerState.transactionReceipt} />
        </Modal>
      )}
    </div>
  );
};

export default NewTransactionHistory;

NewTransactionHistory.propTypes = {
  toggleTransactionTable: PropTypes.string,
  handleToggleTransactionTable: PropTypes.func,
  viewingCustomerProfile: PropTypes.bool,
};
