import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';

const TransactionDetails = ({ transactionReceipt }) => {
  return (
    <section className="mt-6">
      <div>
        {/* {transactionReceipt?.transaction_type === 'credit' && (
        )} */}
        <div className="grid grid-cols-3 gap-4">
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Date</p>
            <p className="text-dark_blue text-14 mt-1">
              {dayjs(transactionReceipt?.created_at).format('MMM DD, YYYY')}
              <span className="text-grey font-dmsans_r">
                {' '}
                | {dayjs(transactionReceipt?.created_at).format('h:mm a')}
              </span>
            </p>
          </div>
          {!transactionReceipt?.transaction_category?.includes('purchase') && (
            <div className="font-dmsans_r w-full break-all">
              <p className="text-grey text-12">Email</p>
              <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.email}</p>
            </div>
          )}
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Description</p>
            <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.description}</p>
          </div>
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey text-12">Transaction ID</p>
            <p className="text-dark_blue text-14 mt-1">
              {transactionReceipt?.transaction_reference ?? transactionReceipt?.transaction_id}
            </p>
          </div>
          <div className="font-dmsans_r w-full break-all">
            <p className="text-grey font-dmsans_r text-12">Service</p>
            <p className="text-dark_blue capitalize text-14 mt-1">
              {transactionReceipt?.transaction_category?.replaceAll('-', ' ')}
            </p>
          </div>
          {transactionReceipt?.transaction_type === 'credit' ? (
            <>
              {transactionReceipt?.transaction_category === 'p2p-transfer' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Sender&apos;s details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    @{transactionReceipt?.extra_details?.sender_tag}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category === 'fund-wallet' &&
                transactionReceipt?.email !== 'partners@pouchers.io' && (
                  <div className="font-dmsans_r break-all">
                    <p className="text-grey font-dmsans_r text-12">Sender&apos;s details</p>
                    <p className="text-dark_blue capitalize text-14 mt-1">
                      {transactionReceipt?.extra_details?.senderName}
                    </p>
                    <p className="text-grey text-14 mt-1">
                      {transactionReceipt?.extra_details?.senderBank} | ****
                      {transactionReceipt?.extra_details?.senderAccountNumber?.slice(4)}
                    </p>
                  </div>
                )}
              {transactionReceipt?.transaction_category === 'voucher-redeem' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Extra details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    {transactionReceipt?.extra_details?.voucher_code}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category.includes('purchase') && <></>}
              {transactionReceipt?.transaction_category !== 'fund-wallet' &&
                !transactionReceipt?.transaction_category.includes('purchase') &&
                transactionReceipt?.transaction_category !== 'voucher-redeem' &&
                transactionReceipt?.transaction_category !== 'p2p-transfer' && (
                  <div className="font-dmsans_r break-all">
                    <p className="text-grey font-dmsans_r text-12">Sender&apos;s details</p>
                    <p className="text-dark_blue capitalize text-14 mt-1">
                      {transactionReceipt?.extra_details?.receiver_account_name}
                    </p>
                    <p className="text-grey text-14 mt-1">
                      {transactionReceipt?.extra_details?.bank_name} | ****
                      {transactionReceipt?.extra_details?.receiver_account_number?.slice(4)}
                    </p>
                  </div>
                )}
            </>
          ) : (
            <>
              {transactionReceipt?.transaction_category === 'money-request' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Recipient&apos;s details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    {transactionReceipt?.extra_details?.sender_name}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category === 'p2p-transfer' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Recipient&apos;s details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    @{transactionReceipt?.extra_details?.receiver_tag}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category === 'voucher-purchase' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Extra details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    {transactionReceipt?.extra_details?.voucher_code}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category === 'voucher-redeem' && (
                <div className="font-dmsans_r break-all">
                  <p className="text-grey font-dmsans_r text-12">Extra details</p>
                  <p className="text-dark_blue capitalize text-14 mt-1">
                    {transactionReceipt?.extra_details?.voucher_code}
                  </p>
                </div>
              )}
              {transactionReceipt?.transaction_category === 'fund-wallet' &&
                transactionReceipt?.email !== 'partners@pouchers.io' && (
                  <div className="font-dmsans_r break-all">
                    <p className="text-grey font-dmsans_r text-12">Sender&apos;s details</p>
                    <p className="text-dark_blue capitalize text-14 mt-1">
                      {transactionReceipt?.extra_details?.senderName}
                    </p>
                  </div>
                )}
              {transactionReceipt?.transaction_category.includes('purchase') && <></>}
              {transactionReceipt?.transaction_category !== 'money-request' &&
                transactionReceipt?.transaction_category !== 'fund-wallet' &&
                transactionReceipt?.transaction_category !== 'voucher-purchase' &&
                transactionReceipt?.transaction_category !== 'voucher-redeem' &&
                !transactionReceipt?.transaction_category.includes('purchase') &&
                transactionReceipt?.transaction_category !== 'p2p-transfer' && (
                  <div className="font-dmsans_r break-all">
                    <p className="text-grey font-dmsans_r text-12">Recipient&apos;s details</p>
                    <p className="text-dark_blue capitalize text-14 mt-1">
                      {transactionReceipt?.extra_details?.receiver_account_name}
                    </p>
                    <p className="text-grey text-14 mt-1">
                      {transactionReceipt?.extra_details?.bank_name} | ****
                      {transactionReceipt?.extra_details?.receiver_account_number?.slice(4)}
                    </p>
                  </div>
                )}
            </>
          )}
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Amount</p>
            {transactionReceipt?.transaction_type === 'credit' ? (
              <p className="text-[#00BB64] text-14 mt-1">
                + {formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
              </p>
            ) : (
              <p className="text-[#FF0000] text-14 mt-1">
                - {formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
              </p>
            )}
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Settlement Info</p>
            <p className="text-dark_blue text-14 mt-1 capitalize">{transactionReceipt?.service_provider}</p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Balance Before</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap">
              {formatCurrency(transactionReceipt?.wallet_balance_before)}
            </p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Balance After</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap">
              {formatCurrency(transactionReceipt?.wallet_balance_after)}
            </p>
          </div>
          <div className="font-dmsans_r break-all">
            <p className="text-grey text-12">Type</p>
            <p className="text-dark_blue text-14 mt-1 text-wrap capitalize">{transactionReceipt?.transaction_type}</p>
          </div>
        </div>
        {/* {transactionReceipt?.transaction_type === 'debit' && (
          <div className="grid grid-cols-3 gap-4">
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Date</p>
              <p className="text-dark_blue text-14 mt-1">
                {dayjs(transactionReceipt?.created_at).format('MMM DD, YYYY')}
                <span className="text-grey font-dmsans_r">
                  {' '}
                  | {dayjs(transactionReceipt?.created_at).format('h:mm a')}
                </span>
              </p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Email</p>
              <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.email}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Description</p>
              <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.description}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Transaction ID</p>
              <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.transaction_reference}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey font-dmsans_r text-12">Service</p>
              <p className="text-dark_blue capitalize text-14 mt-1">
                {transactionReceipt?.transaction_category?.replaceAll('-', ' ')}
              </p>
            </div>
            {transactionReceipt?.transaction_category === 'p2p-transfer' ? (
              <div className="font-dmsans_r">
                <p className="text-grey font-dmsans_r text-12">Recipient&apos;s details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.sender_name}
                </p>
              </div>
            ) : (
              <div className="font-dmsans_r">
                <p className="text-grey font-dmsans_r text-12">Recipient&apos;s details</p>
                <p className="text-dark_blue capitalize text-14 mt-1">
                  {transactionReceipt?.extra_details?.senderName}
                </p>
              </div>
            )}
            <div className="font-dmsans_r">
                <p className="text-grey font-dmsans_r text-12">
                  {transactionReceipt?.transaction_category.includes('purchase')
                    ? 'Service Provider'
                    : 'Recipient Details'}
                </p>
                {!transactionReceipt?.transaction_category.includes('purchase') && (
                  <div>
                    <p className="text-dark_blue text-14 mt-1 capitalize">
                      {transactionReceipt?.transaction_category === 'p2p-transfer'
                        ? '@' + transactionReceipt?.extra_details?.receiver_tag
                        : transactionReceipt?.beneficiary_name}
                    </p>
                    {transactionReceipt?.transaction_category === 'local-bank-transfer' && (
                      <p className="text-grey text-14">
                        {transactionReceipt?.extra_details?.bank_name} |{' '}
                        {transactionReceipt?.extra_details?.receiver_account_number}
                      </p>
                    )}
                  </div>
                )}
              </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Amount</p>
              <p className="text-14 mt-1 text-[#FF0000]">
                -{formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
              </p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Settlement Info</p>
              <p className="text-dark_blue text-14 mt-1 capitalize">{transactionReceipt?.service_provider}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Balance Before</p>
              <p className="text-dark_blue text-14 mt-1 text-wrap">{transactionReceipt?.balance_before}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Balance After</p>
              <p className="text-dark_blue text-14 mt-1 text-wrap">{transactionReceipt?.balance_after}</p>
            </div>
            <div className="font-dmsans_r">
              <p className="text-grey text-12">Type</p>
              <p className="text-dark_blue text-14 mt-1 text-wrap capitalize">
                {transactionReceipt?.transaction_type}
              </p>
            </div>
          </div>
        )} */}
        {/* {transactionReceipt?.transaction_type === 'debit' && (
          <>
            <div className="grid grid-cols-3 gap-4">
              <div className="font-dmsans_r">
                <p className="text-grey text-12">Amount</p>
                <p className="text-dark_blue text-14 mt-1">
                  {formatCurrency(transactionReceipt?.amount, transactionReceipt?.currency)}
                </p>
              </div>
              <div>
                <p className="text-grey font-dmsans_r text-12">Settlement Info</p>
                <p className="text-dark_blue text-14 mt-1">Paystack Inc.</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div className="font-dmsans_r">
                <p className="text-grey text-12">Transaction ID</p>
                <p className="text-dark_blue text-14 mt-1">{transactionReceipt?.transaction_reference}</p>
              </div>
              <div className="font-dmsans_r">
                <p className="text-grey font-dmsans_r text-12">Service</p>
                <p className="text-dark_blue capitalize text-14 mt-1">{transactionReceipt?.transaction_category}</p>
              </div>
              <div className="font-dmsans_r">
                <p className="text-grey font-dmsans_r text-12">
                  {transactionReceipt?.transaction_category.includes('purchase')
                    ? 'Service Provider'
                    : 'Recipient Details'}
                </p>
                {!transactionReceipt?.transaction_category.includes('purchase') && (
                  <div>
                    <p className="text-dark_blue text-14 mt-1 capitalize">
                      {transactionReceipt?.transaction_category === 'p2p-transfer'
                        ? '@' + transactionReceipt?.extra_details?.receiver_tag
                        : transactionReceipt?.beneficiary_name}
                    </p>
                    {transactionReceipt?.transaction_category === 'local-bank-transfer' && (
                      <p className="text-grey text-14">
                        {transactionReceipt?.extra_details?.bank_name} |{' '}
                        {transactionReceipt?.extra_details?.receiver_account_number}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div className="font-dmsans_r">
                <p className="text-grey text-12">Date</p>
                <p className="text-dark_blue text-14 mt-1">
                  {dayjs(transactionReceipt?.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey font-dmsans_r">
                    {' '}
                    | {dayjs(transactionReceipt?.created_at).format('h:mm a')}
                  </span>
                </p>
                <p className="text-dark_blue text-14 mt-1"></p>
              </div>
              <div className="font-dmsans_r">
                <p className="text-grey text-12">Email</p>
                <p className="text-dark_blue text-14 mt-1 ">{transactionReceipt?.email || 'N/A'}</p>
              </div>
              <div className="font-dmsans_r">
                <p className="text-grey text-12">Description</p>
                <div>
                  <p className="text-dark_blue text-14 mt-1 text-wrap">{transactionReceipt?.description}</p>
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
    </section>
  );
};

export default TransactionDetails;
TransactionDetails.propTypes = {
  transactionReceipt: PropTypes.object,
};
