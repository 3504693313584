import Card from 'components/Card/Card';
import React from 'react';
import { useCustomerState } from 'store/modules/customer';
import { formatCurrency } from 'utils/formatCurrency';
import PropTypes from 'prop-types';
import { Airtime2 } from 'assets/icons/Airtime2';
import { Internet2 } from 'assets/icons/Internet2';
import { CableTv2 } from 'assets/icons/CableTv2';
import { Electricity2 } from 'assets/icons/Electricity2';
import { Voucher2 } from 'assets/icons/Voucher2';
import { Data2 } from 'assets/icons/Data2';
import { Betting2 } from 'assets/icons/Betting2';

const CategoryCard = ({ maxValue }) => {
  const { productHistory, selectedCustomer } = useCustomerState();

  return (
    <>
      <Card
        type="custom"
        containerVariant=" h-[200px] scroll-styled overflow-scroll  col-span-4 flex flex-col p-6 bg-smoke rounded-lg"
        cardBody={
          <>
            <p className="font-dmsans_r text-14 text-center text-black-transparent mb-[30px] mx-auto">
              <span className="capitalize">
                {selectedCustomer?.first_name} {selectedCustomer?.last_name}
              </span>{' '}
              mostly spent on <span className="capitalize text-purple font-dmsans_m">{maxValue}</span> this month.
            </p>
            <div className="w-full h-1 flex mb-5">
              <div className="bg-[#0D99F2] w-[45%] h-1"></div>
              <div className="bg-[#F9A000] w-[25%] h-1"></div>
              <div className="bg-[#23BD86] h-1 w-[5%]"></div>
              <div className="w-[10%] h-1 bg-[#AACC45]"></div>
              <div className="w-[15%] h-1 bg-[#8F8E9B]"></div>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Airtime2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Airtime</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{productHistory?.airtime_count} Transactions</p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_airtime_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Data2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Data</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{productHistory?.data_count} Transactions</p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_data_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Internet2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Internet</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">
                    {productHistory?.internet_count} Transactions
                  </p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_internet_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <CableTv2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Cable TV</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{productHistory?.cable_count} Transactions</p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_cable_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Electricity2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Electricity</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">
                    {productHistory?.electricity_count} Transactions
                  </p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_electricity_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Voucher2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Voucher</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{productHistory?.voucher_count} Transactions</p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_voucher_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Betting2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Betting</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">{productHistory?.betting_count} Transactions</p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_betting_purchased)}
              </p>
            </div>
            <div className="flex justify-between items-center p-4">
              <div
                className="flex 
              justify-items-start items-center"
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full mr-3">
                  <Data2 />
                </div>
                <div className="">
                  <p className="text-dark_blue text-14">Transfers</p>
                  <p className="text-grey text-12 font-dmsans_r mt-0.5">
                    {productHistory?.transfer_count} Transactions
                  </p>
                </div>
              </div>
              <p className="text-black-300 text-14 font-dmsans_r mt-0.5">
                {formatCurrency(productHistory?.total_transfers)}
              </p>
            </div>
          </>
        }
      />
    </>
  );
};

export default CategoryCard;
CategoryCard.propTypes = {
  maxValue: PropTypes.any,
};
