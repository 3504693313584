import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from '../../../hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  roleList: {},
  adminList: {},
  selectedRole:{},
  selectedAdmin: {},
  loading: false,
  error: null
}

export const admin = createSlice({
  name: 'admin',
  initialState,
  extraReducers
})

// Selectors
const selectAdmin = (state) => state.admin;

//Roles Redux State
export const useRolesState = () => useAppSelector(selectAdmin);

export default admin.reducer