/* eslint-disable max-len */
import React from 'react'

export default function WhiteLogo () {
  return (
    <svg width="101" height="25" viewBox="0 0 101 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.4897 16.0616C27.705 19.6449 23.369 21.0817 19.7671 19.2877C16.1652 17.4938 14.6993 13.1682 16.4851 9.5849C18.2581 6.02603 22.593 4.58921 26.1949 6.38319C29.7968 8.17717 31.2627 12.5027 29.4897 16.0616Z"
        fill="white"
      />
      <path
        d="M30.6359 5.64673C30.9462 5.02387 29.7365 3.79107 27.9341 2.89319C26.1317 1.99531 24.419 1.77236 24.1087 2.39522C23.7984 3.01808 25.008 4.25089 26.8105 5.14877C28.6129 6.04665 30.3256 6.26959 30.6359 5.64673Z"
        fill="white"
      />
      <path
        d="M42.8414 19.695H41.0602V17.2437C39.649 19.1191 37.9376 20.0568 35.9237 20.0568C34.8918 20.0568 33.9622 19.7776 33.1362 19.218C32.3102 18.6595 31.725 17.9277 31.3818 17.0238C31.0374 16.121 30.8652 14.7912 30.8652 13.0368V5.99115H32.6197V12.5202C32.6197 14.1199 32.693 15.2042 32.8395 15.7719C32.985 16.3397 33.2095 16.8213 33.5108 17.2169C33.8122 17.6125 34.1949 17.9138 34.6591 18.1197C35.1233 18.3256 35.6864 18.4292 36.3496 18.4292C37.0127 18.4292 37.6619 18.2663 38.2983 17.9394C38.9347 17.6125 39.4768 17.1564 39.9247 16.5712C40.3715 15.986 40.6728 15.3845 40.8275 14.7644C40.9823 14.1443 41.0602 12.8983 41.0602 11.0217V5.99115H42.8414V19.695Z"
        fill="white"
      />
      <path
        d="M58.223 8.82987L56.8293 9.70708C55.6251 8.10739 53.9731 7.30697 51.8743 7.30697C50.2222 7.30697 48.8378 7.83632 47.7186 8.89386C46.5994 9.9514 46.0409 11.2637 46.0409 12.8297C46.0409 14.3956 46.5912 15.737 47.693 16.8562C48.7936 17.9754 50.1873 18.5339 51.8743 18.5339C53.887 18.5339 55.539 17.7335 56.8293 16.1338L58.223 17.0633C57.5343 18.0615 56.6094 18.8271 55.4483 19.3599C54.2872 19.8939 53.0354 20.1603 51.6928 20.1603C49.5766 20.1603 47.8082 19.4634 46.3888 18.0697C44.9694 16.6759 44.2598 14.9727 44.2598 12.96C44.2598 10.9473 44.9776 9.22194 46.4144 7.78513C47.8512 6.34832 49.6964 5.63049 51.9499 5.63049C53.275 5.63049 54.5001 5.91437 55.6275 6.48211C56.7536 7.04985 57.6192 7.83283 58.2207 8.83104L58.223 8.82987Z"
        fill="white"
      />
      <path
        d="M59.5898 0.699951H61.371V8.44245C62.7811 6.56703 64.4936 5.62932 66.5075 5.62932C67.5394 5.62932 68.469 5.9097 69.295 6.46814C70.121 7.02774 70.7051 7.75836 71.0494 8.66233C71.3938 9.56514 71.566 10.8949 71.566 12.6493V19.695H69.7848V13.1659C69.7848 11.7558 69.7418 10.7832 69.6557 10.2492C69.5696 9.71638 69.3799 9.20797 69.0879 8.72632C68.7947 8.24467 68.4038 7.87936 67.9141 7.62923C67.4231 7.38026 66.8298 7.25461 66.1329 7.25461C65.436 7.25461 64.7694 7.41865 64.133 7.7444C63.4966 8.07132 62.9544 8.52738 62.5065 9.11257C62.0586 9.69777 61.7584 10.2993 61.6037 10.9194C61.449 11.5394 61.371 12.7866 61.371 14.662V19.695H59.5898V0.699951Z"
        fill="white"
      />
      <path
        d="M84.9338 15.2566L86.4312 16.0314C84.9513 18.784 82.7664 20.1603 79.8753 20.1603C77.8103 20.1603 76.107 19.4507 74.7656 18.0313C73.4231 16.6119 72.7529 14.904 72.7529 12.9076C72.7529 10.9112 73.4277 9.19984 74.7784 7.77117C76.1292 6.34367 77.7986 5.62933 79.7857 5.62933C81.7729 5.62933 83.4412 6.29131 84.7931 7.61644C86.1438 8.94156 86.8453 10.7483 86.8965 13.0368H74.5597C74.6109 14.6888 75.1321 16.0174 76.121 17.0238C77.1099 18.0301 78.3105 18.5339 79.7218 18.5339C82.0439 18.5339 83.7821 17.4414 84.935 15.2566H84.9338ZM84.9338 11.5139C84.693 10.2062 84.0904 9.18239 83.1271 8.44246C82.1638 7.70253 81.0539 7.33257 79.7974 7.33257C77.2169 7.33257 75.53 8.72633 74.7389 11.5139H84.9327H84.9338Z"
        fill="white"
      />
      <path
        d="M87.5664 5.99115H89.3732V7.97826C89.8548 7.16969 90.3237 6.57635 90.7797 6.19708C91.2358 5.81897 91.7431 5.62933 92.3027 5.62933C92.8623 5.62933 93.4253 5.78407 93.9931 6.09353L93.0635 7.59084C92.8052 7.43611 92.5388 7.35816 92.2631 7.35816C91.7291 7.35816 91.2265 7.63389 90.753 8.18418C90.2795 8.73448 89.9316 9.44416 89.7082 10.3132C89.4849 11.1823 89.3732 12.761 89.3732 15.0495V19.695H87.5664V5.99115Z"
        fill="white"
      />
      <path
        d="M100.96 7.48731L99.8249 8.64839C98.8791 7.73628 97.9576 7.28022 97.063 7.28022C96.4778 7.28022 95.988 7.4652 95.5924 7.83517C95.1969 8.20513 94.9991 8.66119 94.9991 9.20334C94.9991 9.74549 95.2097 10.2097 95.6308 10.5971C96.052 10.9845 96.7791 11.4487 97.8122 11.9909C98.8442 12.533 99.6318 13.1182 100.174 13.7453C100.716 14.3735 100.987 15.1437 100.987 16.0547C100.987 17.2076 100.587 18.1802 99.7865 18.9713C98.9861 19.7625 98.0019 20.158 96.8315 20.158C96.0578 20.158 95.3132 19.9858 94.5989 19.6415C93.8845 19.2971 93.2784 18.8154 92.7793 18.1965L93.8892 16.9575C94.8013 17.9731 95.7553 18.4804 96.7535 18.4804C97.4585 18.4804 98.0612 18.2477 98.5603 17.7835C99.0594 17.3193 99.3084 16.7725 99.3084 16.1442C99.3084 15.516 99.0885 15.0006 98.6499 14.5957C98.2113 14.192 97.4795 13.7313 96.4557 13.2148C95.4319 12.6982 94.6663 12.1351 94.1591 11.5243C93.6519 10.9136 93.3982 10.1224 93.3982 9.14982C93.3982 8.17721 93.7554 7.3477 94.4697 6.65896C95.1841 5.97138 96.1299 5.62701 97.3085 5.62701C98.487 5.62701 99.7039 6.24595 100.96 7.48498V7.48731Z"
        fill="white"
      />
      <path
        d="M0.900391 6.00275H2.65365V8.50408C4.04625 6.59609 5.86001 5.64209 8.09492 5.64209C10.0204 5.64209 11.6794 6.35177 13.072 7.76997C14.4646 9.18817 15.1615 10.8949 15.1615 12.889C15.1615 14.8831 14.4739 16.5944 13.0988 18.0208C11.7236 19.4483 10.0727 20.1615 8.14728 20.1615C6.04965 20.1615 4.21844 19.2249 2.65481 17.3507V24.6999H0.900391V6.00275ZM13.4082 12.9146C13.4082 11.3847 12.88 10.0735 11.8225 8.98224C10.7649 7.89096 9.47124 7.34416 7.94135 7.34416C6.41147 7.34416 5.1259 7.88166 4.08581 8.95548C3.04572 10.0305 2.52568 11.3579 2.52568 12.9402C2.52568 14.5224 3.05852 15.8499 4.1242 16.9248C5.18989 17.9998 6.46615 18.5362 7.95415 18.5362C9.44215 18.5362 10.7219 17.9905 11.7969 16.8981C12.8707 15.8068 13.4082 14.4782 13.4082 12.9134V12.9146Z"
        fill="white"
      />
    </svg>
  );
}
