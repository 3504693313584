export const RoleTypes = [
    {
        id: 1, 
        type: 'Dashboard',
        value: 'dashboard_management'
    },
    {
        id: 2, 
        type: 'Transfers',
        value: 'transfers_management'
    },
    {
        id: 3, 
        type: 'Admin Management',
        value: 'admin_management'
    },
    {
        id: 4, 
        type: 'Customer Management',
        value: 'customer_management'
    },
    {
        id: 5, 
        type: 'Virtual Card',
        value: 'virtual_cards_management'
    },
    {
        id: 6, 
        type: 'Vouchers',
        value: 'vouchers_management'
    },
    {
        id: 7, 
        type: 'Discount Management',
        value: 'discount_management'
    },
    {
        id: 8, 
        type: 'Site Settings',
        value: 'site_settings_management'
    },
    {
        id: 9, 
        type: 'Notification Management',
        value: 'notifications_management'
    },
    {
        id: 10, 
        type: 'Schedule And Automated',
        value: 'scheduled_payments_management'
    },
    {
        id: 11, 
        type: 'Audit Logs',
        value: 'audit_trail_management'
    },
    {
        id: 12, 
        type: 'Payment Management',
        value: 'payments_management'
    }
]
export const RoleHeader = [
    {
        name: 'Menu',
        variant: 'pl-6'
    },
    {
        name: 'Create',
        variant: 'pl-8 min-w-max'
    },
    {
        name: 'Read',
        variant: 'pl-8 min-w-max'
    },
    {
        name: 'Update',
        variant: 'pl-8 min-w-max'
    },
    {
        name: 'Delete',
        variant: 'pl-8 min-w-max'
    },
    {
        name: 'All',
        variant: 'pl-8 min-w-max'
    }
]
export const RoleListHeader = [
    {
        name: 'Role Title',
        variant: 'pl-6'
    },
    {
        name: 'Permission',
        variant: 'pl-4 min-w-max'


    },
    {
        name: 'Max Assignee',
      variant: 'pl-4 min-w-max'

    },
    {
        name: 'No of Persons',
      variant: 'pl-4 min-w-max'

    },
    {
        name: 'Date Created',
      variant: 'pl-4 min-w-max'

    },
    {
        name: '',
      variant: 'pl-4 min-w-max'

    }
]