/* eslint-disable max-len */
import CustomButton from 'components/Button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useVirtualCard } from 'store/modules/virtualCards';
import ExportExcel from 'components/ExportExcel';
import Title from '../../../components/Header/Title';
import Tabs from '../../../components/Tabs/Tabs';
import VirtualCardList from './VirtualCardList';

const VirtualCardManagement = () => {
  //local state
  const [selectedTab, setSelectedTab] = useState('all cards');
  const { virtualCardList } = useVirtualCard();
  //Variables
  let TabsHeader = ['all cards', 'active', 'inactive'];

  useEffect(() => {
    document.title = 'Virtual Cards Management - Pouchers Admin';
  }, []);

  return (
    <div>
      <div className="flex justify-between mb-7 items-center">
        <Title title="Virtual Cards Management" variant="" />
        <ExportExcel
          excelData={virtualCardList?.virtual_cards}
          fileName={`file-virtualCardList-${new Date().toISOString()}`}
        >
          <CustomButton
            name="Export"
            variant="border border-purple bg-purple text-white rounded font-dmsans_r xxs:text-14"
          />
        </ExportExcel>
      </div>
      <div className="p-32 bg-white rounded-2xl">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} TabsItem={TabsHeader} />
        <VirtualCardList tab={selectedTab} />
      </div>
    </div>
  );
};

export default VirtualCardManagement;
