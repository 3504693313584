import React from 'react';

const ArrowDown = () => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 5.29289L1.20711 0.5H10.7929L6 5.29289Z" fill="white" stroke="white" />
    </svg>
  );
};

export default ArrowDown;
