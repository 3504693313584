export const defaultOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        // color: 'rgba(0, 0, 0, .04)',
      },
      ticks: {
        display: true,
        beginAtZero: true,
      },
    },
    y: {
      grid: {
        display: true,
        // color: 'rgba(0, 0, 0, .04)',
      },
      ticks: {
        display: true,
        beginAtZero: true,
      },
    },
  },
};
