import {
  getAllCustomers,
  getCustomerReferalTrail,
  getSingleCustomer,
  getWalletDetails,
  activateCustomer,
  getTransactionReceipt,
  updateCard,
  deactivateCustomer,
  upgradeAccount,
  getTransactionHistory,
  getProductHistory,
  updateCustomerWallet,
} from './actions';

const extraReducers = {
  //GET ALL CUSTOMERS
  [getAllCustomers.fulfilled]: (state, action) => {
    state.customerList = action.payload.data;
    state.loading = false;
  },

  //GET SINGLE CUSTOMER
  [getSingleCustomer.fulfilled]: (state, action) => {
    state.selectedCustomer = action.payload.data;
    state.loading = false;
  },
  [getSingleCustomer.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //GET WALLET DETEALS
  [getWalletDetails.fulfilled]: (state, action) => {
    state.walletDetails = action.payload.data;
    state.loading = false;
  },
  [getWalletDetails.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  // FETCH CUSTOMER REFERRAL TRAIL
  [getCustomerReferalTrail.fulfilled]: (state, action) => {
    state.customerReferralTrial = action.payload.data;
    state.loading = false;
  },

  //TRANSACTION HISTORY
  [getTransactionHistory.fulfilled]: (state, action) => {
    state.loading = false;
    state.transactionHistory = action.payload.data;
  },
  [getTransactionHistory.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //PRODUCT HISTORY
  [getProductHistory.fulfilled]: (state, action) => {
    state.loading = false;
    state.productHistory = action.payload.data;
  },
  [getProductHistory.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  //TRANSACTION RECEIPT
  [getTransactionReceipt.fulfilled]: (state, action) => {
    state.transactionReceipt = action.payload.data;
    state.loading = false;
  },
  [getTransactionReceipt.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //UPDATE CARD
  [updateCard.fulfilled]: (state) => {
    state.loading = false;
  },
  [updateCard.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //DEACTIVATE CUSTOMER
  [deactivateCustomer.fulfilled]: (state) => {
    state.loading = false;
  },
  [deactivateCustomer.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //DEACTIVATE CUSTOMER
  [activateCustomer.fulfilled]: (state) => {
    state.loading = false;
  },
  [activateCustomer.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //UPGRADE CUSTOMER TIER ACCOUNT
  [upgradeAccount.fulfilled]: (state) => {
    state.loading = false;
  },
  [upgradeAccount.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  // CUSTOMER WALLET
  [updateCustomerWallet.fulfilled]: (state) => {
    state.loading = false;
  },
  [updateCustomerWallet.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
};

export { extraReducers };
