/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Close from '../../assets/icons/CloseIcon';

export default function Modal({ children, title, dividerVariant, containerVariant, closeModal, status }) {
  const theRef = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking theRef's element or descendent elements
      if (theRef && (!theRef.current || theRef.current.contains(event.target))) {
        return;
      }
      closeModal();
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [theRef, closeModal]);


  return (
    <div>
      <div className="z-[500] overlay"></div>
      <div ref={theRef} className={`modal bg-white md:px-6 px-4 py-8 rounded overflow-y-auto ${containerVariant}`}>
        <div className="flex items-center justify-between">
          <p className="text-black-200 font-dmsans_b text-16 leading-6">{title}</p>
          <div className='flex items-center space-x-[30px]'>
            {status}
            <div data-testid='closeModal' className="cursor-pointer" onClick={closeModal}>
              <Close color="#3A434B" />
            </div>
          </div>
        </div>
        <hr className={`${dividerVariant || 'mt-4'} h-px border-0 bg-light-smoke`} />
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  closeModal: PropTypes.any,
  status: PropTypes.element,
  dividerVariant: PropTypes.string,
  containerVariant: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string,
};
