/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Electricity({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="48" height="48" rx="24" fill={color || "white"}/>
            <path d="M25.1943 22.3318C25.0222 22.2114 24.7851 22.1074 24.5 22.0493V23.4508C24.7851 23.3927 25.0222 23.2887 25.1943 23.1682C25.4623 22.9806 25.5 22.8174 25.5 22.75C25.5 22.6827 25.4623 22.5194 25.1943 22.3318V22.3318Z" fill="#FF6414"/>
            <path d="M23.5 19.5493C23.215 19.6074 22.9778 19.7114 22.8057 19.8318C22.5377 20.0194 22.5 20.1827 22.5 20.25C22.5 20.3174 22.5377 20.4806 22.8057 20.6682C22.9778 20.7887 23.215 20.8927 23.5 20.9508V19.5493Z" fill="#FF6414"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.8855 24.8415C29.8906 23.7187 30.5 22.238 30.5 20.595C30.5 17.0051 27.5898 14.095 24 14.095C20.4102 14.095 17.5 17.0051 17.5 20.595C17.5 22.2395 18.1108 23.7214 19.118 24.8447C19.3998 25.2753 19.73 25.8464 20.0007 26.5C20.3065 27.2386 20.5846 28.2533 20.7695 29H27.2319C27.4168 28.2533 27.6949 27.2386 28.0007 26.5C28.272 25.8448 28.6033 25.2725 28.8855 24.8415Z" fill="#FF6414"/>
            <path d="M27 30H21V30.9982L26.9585 30.5018C26.9724 30.5006 26.9863 30.5 27 30.5V30Z" fill="#FF6414"/>
            <path d="M27 31.5017L21.0415 31.9982C21.0276 31.9994 21.0137 32 21 32C21 32.8284 21.6716 33.5 22.5 33.5H23C23 33.7761 23.2239 34 23.5 34H24.5C24.7761 34 25 33.7761 25 33.5H25.5C26.3285 33.5 27 32.8284 27 32V31.5017Z" fill="black"/>
            <path d="M22.828 19.0909L25.3359 23.1247V19.0909H26.3707V24.782H25.3184L22.7315 20.608V24.782H21.6968V19.0909H22.828Z" fill="#E4EAFC"/>
            <path d="M20.7266 20.9925H27.272V21.6815H20.7266V20.9925Z" fill="#E4EAFC"/>
            <path d="M20.7266 22.1983H27.272V22.8873H20.7266V22.1983Z" fill="#E4EAFC"/>
        </svg>
    )
}

Electricity.propTypes = {
    color: PropTypes.string
}