import React, { useCallback, useEffect, useMemo } from 'react';
import Switch from 'components/Switch/Switch';
import useSettings from 'hooks/fetch/useSettings';
import DashboardCard from 'components/Card/DashboardCard';
import { useAppSelector } from 'hooks/useReduxHook';

const ProductAvailability = () => {
  const { productsStatus } = useAppSelector(state => state.settings)
  // const [products, setProducts] = useState([])
  const { fetchProductStatus, update_product_status } = useSettings([])

  useEffect(() => {
    fetchProductStatus()
  },[])

  const submit = useCallback((e) => {
    update_product_status(e)
  },[])

  const sortedProducts = useMemo(() => {
    let products = [...productsStatus]
    return products.sort((a, b) => {
      const categoryA = a?.product_category
      const categoryB = b?.product_category
      if (categoryA < categoryB) {
        return -1
      }
      if (categoryA > categoryB) {
        return 1
      }
      return 0
    })
  },[productsStatus])

  return (
    <div className='mt-6'>
    <DashboardCard>
      <p className="text-18 font-dmsans_m mb-10">Product Availability</p>
      <div className="grid grid-cols-[300px_minmax(0,_300px)_300px] gap-[50px] ml-6">
        {
          sortedProducts?.map((item) =>
          <Switch
            key={item?.id}
            label={item?.product_category}
            status={item?.status}
            onChange={submit}
        />
          )
        }
      </div>
    </DashboardCard>
    </div>
  );
};

export default ProductAvailability;
