import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({label, status, onChange}) => {
  return (
    <div className="flex items-center justify-between space-x-20">
      <p className="text-black-transparent text-16 font-inter_medium capitalize">{label}</p>
      <div
        className={`switch cursor-pointer ${status === 'active' ? 'switch_active' : 'switch_inactive'}`}
        onClick={() => {
          onChange({
            category: label,
            status: status === 'active' ? 'inactive' : 'active'
          })
        }}
      >
        <span 
        className={`slider switchball round ${status === 'active' ? 
        'active_slider active_switchball' : 'inactive_slider inactive_switchball'}`}></span>
      </div>
    </div>
  );
};

export default Switch;
Switch.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func
};