/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function VoucherIcon ({color}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1341_9955)">
        <path
          d="M7.22179 8.96761V9.5391C6.34 9.54022 5.49198 9.19922 4.85645 8.58783V11.8922H14.286C14.7594 11.8922 15.1433 11.5083 15.1433 11.0349V8.17749H5.25339C5.78274 8.68522 6.48818 8.96845 7.22179 8.96761ZM13.143 10.1777H13.7145V10.7492H13.143V10.1777ZM12.0001 10.1777H12.5716V10.7492H12.0001V10.1777ZM10.8571 10.1777H11.4286V10.7492H10.8571V10.1777ZM9.71411 10.1777H10.2856V10.7492H9.71411V10.1777Z"
          fill={color}
        />
        <path
          d="M2.93337 5.60578C2.62251 5.60898 2.35713 5.83083 2.29881 6.13611C2.24035 6.44138 2.40541 6.74541 2.69297 6.86302L4.09839 7.4274L3.53541 6.0143C3.43789 5.76734 3.19902 5.60522 2.93337 5.60578Z"
          fill={color}
        />
        <path
          d="M12.2123 14.3892C12.3941 14.4763 12.603 14.4873 12.7928 14.4197C12.9827 14.3524 13.1377 14.212 13.2236 14.0298L13.9695 12.4636H8.2002L12.2123 14.3892Z"
          fill="#C8C8C8"
        />
        <path
          d="M0.856445 4.74853V7.60598H3.00693L2.48036 7.39446C1.96175 7.18908 1.64782 6.65875 1.71731 6.10526C1.78679 5.55177 2.22183 5.11548 2.77504 5.04446C3.32839 4.97344 3.85956 5.28584 4.06647 5.80375L4.28538 6.35319V3.8913H1.71368C1.24028 3.8913 0.856445 4.27513 0.856445 4.74853Z"
          fill={color}
        />
        <path
          d="M3.7877 1.67983C3.60604 1.59277 3.39717 1.58188 3.20742 1.64927C3.01753 1.7168 2.86252 1.85716 2.77643 2.03938L2.16699 3.31979H7.20492L3.7877 1.67983Z"
          fill="#C8C8C8"
        />
        <path
          d="M1.99942 8.96761C2.73304 8.96845 3.43847 8.68522 3.96797 8.17749H0.856445V11.0349C0.856445 11.5083 1.24028 11.8922 1.71368 11.8922H4.28538V8.66038C3.65878 9.22671 2.84396 9.53994 1.99942 9.5391V8.96761Z"
          fill={color}
        />
        <path
          d="M0.284982 8.64126V7.27393L0.0740219 7.71747C-0.07778 8.03698 0.0094224 8.41914 0.284982 8.64126Z"
          fill={color}
        />
        <path
          d="M15.7148 7.42798V8.79503L15.9257 8.35177C16.0776 8.03212 15.9904 7.6501 15.7148 7.42798Z"
          fill={color}
        />
        <path
          d="M14.286 3.8913H4.85645V6.55271L5.15475 5.80375C5.36306 5.28891 5.89269 4.97958 6.44339 5.05088C6.99423 5.12231 7.42745 5.55637 7.49777 6.10735C7.56795 6.65819 7.25765 7.18713 6.74239 7.39446L6.21429 7.60598H15.1433V4.74853C15.1433 4.27513 14.7594 3.8913 14.286 3.8913ZM10.2856 5.89151H9.71411V4.74853H10.2856V5.89151ZM11.4286 5.89151H10.8571V4.74853H11.4286V5.89151ZM12.5716 5.89151H12.0001V4.74853H12.5716V5.89151ZM13.7145 5.89151H13.143V4.74853H13.7145V5.89151Z"
          fill={color}
        />
        <path
          d="M6.9363 6.25806C6.93839 5.94622 6.71794 5.67708 6.41169 5.61764C6.10543 5.55821 5.8003 5.7255 5.68561 6.01557L5.12305 7.42852L6.52833 6.86303C6.77557 6.76425 6.93727 6.52441 6.9363 6.25806Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1341_9955">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
VoucherIcon.propTypes = {
  color: PropTypes.string
}
