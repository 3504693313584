import {
    getAllRoles,
    getAllAdmins, 
    getSingleAdmin, 
    getSingleRole, 
    deactivateAdmin, 
    activateAdmin,
    deleteRole
  } from './actions';

const extraReducers = {
  // GET ALL ROLES
  [getAllRoles.pending]: (state) => {
    state.loading = true
  },
  [getAllRoles.fulfilled]: (state, action) => {
    state.roleList = action.payload.data
    state.loading = false
  },
  [getAllRoles.rejected]: (state, action) => {
    state.loading = false
    state.error = action.payload.data.message;
  },
  //GET ALL ADMIN
  [getAllAdmins.pending]: (state) => {
    state.loading = true
  },
  [getAllAdmins.fulfilled]: (state, action) => {
    state.adminList = action.payload.data;
    state.loading = false
  },
  [getAllAdmins.rejected]: (state, action) => {
    state.loading = false
    state.error = action.payload.data.message;
  },
  //GET SINGLE ADMIN
  [getSingleAdmin.fulfilled]: (state, action) => {
    state.selectedAdmin = action.payload.data;
    state.loading = false;
  },

  //GET SINGLE ROLE
  [getSingleRole.fulfilled]: (state, action) => {
    state.selectedRole = action.payload.data;
    state.loading = false;
  },

  //DEACTIVATE ADMIN
  [deactivateAdmin.fulfilled]: (state) => {
    state.loading = false;
  },
  [deactivateAdmin.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  //ACTIVATE ADMIN
  [activateAdmin.fulfilled]: (state) => {
    state.loading = false;
  },
  [activateAdmin.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  //DELETE ROLE
  [deleteRole.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  }
};

export { extraReducers };
