import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  voucherList: {},
  selectedVoucher: {},
  loading: false,
  error: null
}

export const vouchers = createSlice({
  name: 'vouchers',
  initialState,
  extraReducers
})

// Selectors,
const selectVouchers = (state) => state.vouchers;


//Auth Redux State
export const useVoucherState = () => useAppSelector(selectVouchers);

export default vouchers.reducer