import React from 'react';
import { useRolesState } from '../../../store/modules/admin';

const ViewAdmin = () => {
  const roleState = useRolesState();

  return (
    <section className="mt-6">
      <div className="grid gap-8 grid-cols-12">
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Name</p>
          <p className="text-dark_blue text-14 mt-1">
            {roleState?.selectedAdmin?.first_name} {roleState?.selectedAdmin?.last_name}
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Role</p>
          <p className="text-dark_blue text-14 mt-1">{roleState?.selectedAdmin?.role}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Date Created</p>
          <p className="text-dark_blue text-14 mt-1">
            {new Date(roleState?.selectedAdmin?.created_at).toDateString()} |{' '}
            <span className="text-grey">{new Date(roleState?.selectedAdmin?.created_at).toLocaleTimeString()}</span>
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Email</p>
          <p className="text-dark_blue text-14 mt-1">{roleState?.selectedAdmin?.email}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Permission</p>
          <p className="text-dark_blue text-14 mt-1">
            {roleState?.selectedAdmin?.permissions === '11' ? 'All Access' : roleState?.selectedAdmin?.permissions}
          </p>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default ViewAdmin;
