import Airtime from "assets/icons/Airtime"
import Bettings from "assets/icons/Bettings"
import CableTv from "assets/icons/CableTv"
import Data from "assets/icons/Data"
import Education from "assets/icons/Education"
import Electricity from "assets/icons/Electricity"
import Internet from "assets/icons/Internet"
import Tickets from "assets/icons/Tickets"
import Vouchers from "assets/icons/Vouchers"
import Water from "assets/icons/Water"
import React from "react"

let width = window.innerWidth <= 600

export const userServices = [
    {
        icon: <Airtime color={width ? '#EEEBFA' : ''} />,
        title: 'Airtime',
        link: 'airtime',
        id: 1
    },
    {
        icon: <Data color={width ? '#EEEBFA' : ''} />,
        title: 'Data',
        link: 'data',
        id: 2
    },
    {
        icon: <CableTv color={width ? '#EEEBFA' : ''} />,
        title: 'Cable Tv',
        link: 'cable',
        id: 3
    },
    {
        icon: <Electricity color={width ? '#EEEBFA' : ''} />,
        title: 'Electricity',
        link: 'electricity',
        id: 4
    },
    {
        icon: <Education color={width ? '#EEEBFA' : ''} />,
        title: 'Education',
        link: 'education',
        id: 5
    },
    {
        icon: <Internet color={width ? '#EEEBFA' : ''} />,
        title: 'Internet',
        link: 'internet',
        id: 6
    },
    {
        icon: <Bettings color={width ? '#EEEBFA' : ''} />,
        title: 'Betting',
        link: 'betting',
        id: 7
    },
    {
        icon: <Vouchers color={width ? '#EEEBFA' : ''} />,
        title: 'Vouchers',
        link: 'vouchers',
        id: 8
    },
    {
        icon: <Tickets color={width ? '#EEEBFA' : ''} />,
        title: 'Tickets',
        link: 'tickets',
        id: 9
    },
]