/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import BackButton from '../../../components/Button/BackButton';
import Status from '../../../components/Status';
import FreezeIcon from '../../../assets/icons/FreezeIcon';
import Card from '../../../components/Card/Card';

import { useVirtualCard } from 'store/modules/virtualCards';
import { formatCurrency } from 'utils/formatCurrency';
import UnFreezeIcon from 'assets/icons/UnFreezeIcon';
import useCustomer from 'hooks/fetch/useCustomer';
import useAdmin from 'hooks/fetch/useAdmin';
import CardTransactionHistory from 'components/Customers/VirtualCards/CardTransactionHistory';
import usePermissions from 'hooks/usePermissions';
import { ReactComponent as VirtualCard } from 'assets/icons/virtualCard.svg';

const ViewCardDetails = () => {
  //Redux Hooks
  const { selectedCard, cardBalance, loading } = useVirtualCard();
  const { getSingleVirtualCard, handleCardBalance, handleCardHistory } = useAdmin();
  const { updatecard } = useCustomer();
  const { userIsPermittedTo } = usePermissions();

  //Handler Function
  const updateCard = useCallback(() => {
    updatecard(
      selectedCard.dollar_card_id ? 'USD' : 'NGN',
      selectedCard?.user_id,
      selectedCard?.card_sudo_id,
      selectedCard?.card_status === 'inactive' ? 'active' : 'inactive',
      () => getSingleVirtualCard(selectedCard.card_sudo_id, selectedCard.dollar_card_id ? 'USD' : 'NGN'),
    );
  }, []);

  //Effect
  useEffect(() => {
    handleCardBalance(selectedCard.wallet_sudo_id);
    handleCardHistory(selectedCard.card_sudo_id);

  }, []);

  return (
    <div>
      <div className="p-32 bg-white rounded-2xl">
        <BackButton />
        <div className="flex justify-between items-center">
          <p className="mt-[52px] mb-[60px] font-altone_sb text-24 capitalize">
            {selectedCard.first_name} {selectedCard.last_name}’s Card
          </p>
          <Status showIcon status={selectedCard.card_status} />
        </div>
        <hr className="h-px border-0 bg-light-smoke" />
        <div className="grid grid-cols-2 mt-10">
          <div>
            <VirtualCard/>
          </div>
          <div>
            <p className="text-dark_blue font-dmsans_r text-14 mb-2.5">Card Details</p>
            <Card
              containerVariant="flex bg-smoke p-5 rounded-[10px] flex-col space-y-6"
              type="custom"
              cardBody={
                <>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-grey font-dmsans_r text-14">Cardholder Name</p>
                      <p className="text-dark_blue text-14 mt-1 font-dmsans_r capitalize">
                        {selectedCard.account_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-grey font-dmsans_r text-14">Card Type</p>
                      <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                        {selectedCard.dollar_card_id ? 'Dollar' : 'Naira'} Card
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-grey font-dmsans_r text-14">Card Balance</p>
                      <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r">
                        {loading ? '₦....' : formatCurrency(cardBalance?.currentBalance)}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 w-full">
                    <div>
                      <p className="text-grey font-dmsans_r text-14">Card Pan</p>
                      <p className="text-dark_blue text-14 mt-1 font-dmsans_r">*************{selectedCard.last4}</p>
                    </div>
                    <div>
                      <p className="text-grey font-dmsans_r text-14">Expiry Date</p>
                      <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                        {selectedCard.expiry_month}/{selectedCard.expiry_year}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-grey font-dmsans_r text-14">Account Number</p>
                    <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                      {selectedCard?.account_number} ({selectedCard?.provider})
                    </p>
                  </div>
                </>
              }
            />
            {userIsPermittedTo('Update', 'virtual_cards_management') && (
              <div
                className="font-dmsans_r text-14 text-dark_blue flex flex-col items-center mt-6 cursor-pointer"
                onClick={updateCard}
              >
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full">
                  {selectedCard?.card_status === 'active' ? <FreezeIcon /> : <UnFreezeIcon />}
                </div>
                <p>{selectedCard?.card_status === 'active' ? 'Freeze' : 'Unfreeze'}</p>
              </div>
            )}
          </div>
        </div>
        <hr className="h-px border-0 bg-light-smoke my-10" />
        <CardTransactionHistory />
      </div>
    </div>
  );
};

export default ViewCardDetails;
