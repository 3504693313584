export const auth = {
    login: '/admin/login',
    forgot_password: '/admin/forgot-password',
    validate_reset_code: '/admin/validate-password-reset-code',
    reset_password: '/admin/reset-password',
    change_password: '/admin/change-password',
    refresh_token: '/admin/refresh-token',
    invite_admin: '/admin/invite-admin',
    create_password: '/admin/create-password',
    create_role: '/admin/create-role',
}