import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services/axios-instance'
import { auth } from '../../../services/endpoints/auth'

const fetchRequest = (thunkName, method, url) => createAsyncThunk(thunkName, async (payload) => {
    try {
        const response = await api[method || 'get'](url, { ...payload })
        if (response?.data) return response.data ? response.data : {}
        if (response?.response?.data) return response.response.data ? response.response.data : {}
    } catch (error) {
        return error
    }
})


const login = fetchRequest('auth/login', 'post', auth.login)
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'logout'})
})
const refreshToken = fetchRequest('auth/refreshToken', 'put', auth.refresh_token)
const forgotPassword = fetchRequest('auth/forgotPassword', 'post', auth.forgot_password)
const resetPassword = fetchRequest('auth/resetPassword', 'put', auth.reset_password)
const changePassword = fetchRequest('auth/changePassword', 'patch', auth.change_password)
const createPassword = fetchRequest('auth/createPassword', 'patch', auth.create_password)
const inviteAdmin = fetchRequest('auth/inviteAdmin', 'post', auth.invite_admin)
const assignRole = fetchRequest('auth/assignRole', 'post', auth.create_role)
const validatePasswordResetToken = fetchRequest('auth/validatePasswordResetToken', 'post', auth.validate_reset_code)
const editRoleDetails = createAsyncThunk('auth/editRole', async (payload) => {
    try {
        const response = await api.patch(`/admin/role/${payload.role}`, { ...payload.formData })

        return Promise.resolve(response.data ?? {})
    } catch (error) {
        return Promise.reject(error)
    }
})
const editAdmin = createAsyncThunk('auth/editAdmin', async(payload) => {
    try {
        const response = await api.patch(`/admin/${payload.id}`, payload.formData)
        return Promise.resolve(response.data ?? {})
    } catch (error) {
        return Promise.reject(error)
    }
});

export {
    login,
    forgotPassword,
    resetPassword,
    createPassword,
    logout,
    refreshToken,
    inviteAdmin,
    editAdmin,
    validatePasswordResetToken,
    assignRole,
    editRoleDetails,
    changePassword
}