import {
  getVirtualCards,
  getCardDetails,
  getCardBalance,
  getCardHistory
} from './actions';

const extraReducers = {
// GET ALL VIRTUAL CARDS
[getVirtualCards.pending]: (state) => {
  state.loading = true
},
[getVirtualCards.fulfilled]: (state, action) => {
  state.virtualCardList = action.payload.data
  state.loading = false
},
[getVirtualCards.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
// GET CARD DETAILS
[getCardDetails.pending]: (state) => {
  state.loading = true
},
[getCardDetails.fulfilled]: (state, action) => {
  state.selectedCard = action.payload.data
  state.loading = false
},
[getCardDetails.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
// GET CARD BALANCE
[getCardBalance.pending]: (state) => {
  state.loading = true
},
[getCardBalance.fulfilled]: (state, action) => {
  state.cardBalance = action.payload.data
  state.loading = false
},
[getCardBalance.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},

// GET CARD HISTORY
[getCardHistory.pending]: (state) => {
  state.loading = true
},
[getCardHistory.fulfilled]: (state, action) => {
  state.cardHistory = action.payload.data
  state.loading = false
},
[getCardHistory.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
};

export { extraReducers };
