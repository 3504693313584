import Services from 'components/Card/Services'
import Title from 'components/Header/Title'
import { userServices } from 'constants/services'
import React, {useState, useEffect} from 'react'

const DiscountManagement = () => {
  const [current, setCurrent] = useState(0)
  
  useEffect(() => {
      let timer = setInterval(() => {
          if (current < userServices.length) setCurrent(prevState => prevState + 1)
      },100)
      
      return () => clearInterval(timer)
  },[current])

  useEffect(() => {
    document.title = 'Discount Management - Pouchers Admin';
  }, []);


  return (
    <div className=''>
      <Title title="Discount Management" variant="mb-3"/>
      <div className="p-10 bg-white rounded-lg border-light-smoke border lg:h-[80vh]">
        <p className='mb-3.5 font-dmsans_b text-18'>Products</p>
        <section className="grid gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 flex-wrap">
            {
                userServices.map((item, i) => 
                <Services
                    key={item?.id}
                    index={i}
                    icon={item.icon}
                    title={item.title}
                    link={item.link}
                    length={userServices.length}
                    current={current}
                />
                )
            }
        </section>
      </div>
    </div>
  )
}

export default DiscountManagement