/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import CTable from '../../../components/Table/Table';
import { ManageAdminHeader } from '../../../constants/mocks/admin';
import { HiDotsVertical } from 'react-icons/hi';
import Status from '../../../components/Status';
import Dropdown from '../../../components/DropDown/DropDown';
import CustomButton from '../../../components/Button/CustomButton';
import Modal from '../../../components/Modal/Modal';
import InviteAdmin from './InviteAdmin';
import ViewAdmin from './ViewAdmin';
import { EntriesfilterList } from '../../../constants/mocks/customers';
import { useDispatch } from 'react-redux';
import { getAllAdmins } from '../../../store/modules/admin/actions';
import { useRolesState } from '../../../store/modules/admin';
import useAdmin from '../../../hooks/fetch/useAdmin';
import dayjs from 'dayjs';
import { UserInitals } from 'utils/ImagePlaceholder';
import DateFilter from 'components/DropDown/DateFilter';
import { useDebounce } from 'hooks/useDounce';
import usePermissions from 'hooks/usePermissions';
import DashboardLayout from 'components/Layouts/DashboardLayout';

const ManageAdmin = () => {
  // local State
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [page, setPage] = useState(1);
  const [showViewModal, setShowViewModal] = useState(false);

  // Variable
  const per_page = perPage.value;
  const { adminList, loading, selectedAdmin } = useRolesState();
  let dispatch = useDispatch();
  // Hooks
  const { handleViewAdmin, handleGetAllAdmin, deactivateadmin, activateadmin } = useAdmin();
  const { userIsPermittedTo } = usePermissions();
  const debouncedSearch = useDebounce(search);

  // Effect

  useEffect(() => {
    if (debouncedSearch) {
      handleGetAllAdmin({ search: debouncedSearch, per_page, page, start_date: toDate && fromDate, end_date: fromDate && toDate });
    } else {
      handleGetAllAdmin({ per_page, page, start_date: toDate && fromDate, end_date: fromDate && toDate });
    }
  }, [per_page, page, fromDate, toDate, debouncedSearch]);

  // Handler Function
  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  const handleEditModal = useCallback(() => {
    setEditModal(!showEditModal);
  }, [showEditModal]);
  const handleViewAdminModal = useCallback(() => {
    setShowViewModal(!showViewModal);
  }, [showViewModal]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );

  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  useEffect(() => {
    document.title = 'Manage Admin - Pouchers Admin';
  }, []);

  return (
    <DashboardLayout
      pageHeader="Manage Admin"
      perPage={perPage}
      cardTitle="User List"
      SearchFunc={handleChange}
      searchValue={search}
      variant="mb-0"
      filterBy="Status"
      showDefaultFilter={false}
      options={EntriesfilterList}
      placeholder="Search names or roles"
      handlePerPage={handlePerPage}
      customFilter={
        <DateFilter
          endDate={toDate}
          setEndDate={setToDate}
          setStartDate={setFromDate}
          startDate={fromDate}
        />
      }
      actionButtons={
        userIsPermittedTo('Create', 'admin_management') && (
          <CustomButton
            name="Invite Admin"
            icon={
              <>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http:www.w3.org/2000/svg">
                  <path
                    d="M7 1V7M7 7V13M7 7H13M7 7L1 7"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            }
            onClick={handleShowModal}
            variant="border border-purple bg-purple text-white rounded font-dmsans_m"
          />
        )
      }
    >
      <div className="mt-7">
        <CTable
          header={ManageAdminHeader}
          totalPayload={adminList?.total}
          perPage={per_page}
          loading={loading}
          changePage={setPage}
          currentPage={adminList?.page}
          showCheckBox={false}
          data={adminList?.admins}
        >
          {(item) => (
            <>
              <td className="pl-6">
                <div className="flex items-center space-x-3 min-w-max whitespace-no-wrap">
                  <div className="rounded-full product__img flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                        {/* N/A */}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="text-dark_blue font-dmsans_m">
                      {item.first_name} {item.last_name}
                    </p>
                    <p className="text-grey font-dmsans_r">{item.email}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="font-dmsans_m text-black-transparent min-w-max whitespace-no-wrap mx-4">{item.role}</p>
              </td>
              <td>
                <div className="min-w-max mx-4 whitespace-no-wrap">
                  <Status showIcon status={item.status} />
                </div>
              </td>
              <td>
                <p className="font-dmsans_m text-blue_black min-w-max whitespace-no-wrap mx-4">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
              <td>
                <p className="text-grey-500 font-dmsans_b min-w-max whitespace-no-wrap mx-4">{item.permissions}</p>
              </td>
              <td>
              <div className="min-w-max mx-4 whitespace-no-wrap">
                <Dropdown
                  type="filterIcon"
                  filterIcon={<HiDotsVertical className="text-16 text-dark_blue" />}
                  optionContainerVariant="w-[115px]"
                >
                  <div>
                    <div
                      key={1}
                      className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                      onClick={() => handleViewAdmin(item.id, () => setShowViewModal(true))}
                    >
                      View
                    </div>
                    {item.status === 'active' && userIsPermittedTo('Update', 'admin_management') && (
                      <>
                        <div
                          key={2}
                          className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                          onClick={() => handleViewAdmin(item.id, () => setEditModal(true))}
                        >
                          Edit
                        </div>
                        <div
                          key={3}
                          className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                          onClick={() =>
                            deactivateadmin(item.email, () => dispatch(getAllAdmins({ per_page: 10, page: 1 })))
                          }
                        >
                          Deactivate
                        </div>
                      </>
                    )}
                    {item.status !== 'active' && userIsPermittedTo('Update', 'admin_management') && (
                      <div
                        key={4}
                        className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                        onClick={() =>
                          activateadmin(item.id, { status: 'active' }, () => handleGetAllAdmin({ per_page, page }))
                        }
                      >
                        Activate
                      </div>
                    )}
                  </div>
                </Dropdown>
                </div>
              </td>
            </>
          )}
        </CTable>
        {showModal && (
          <Modal closeModal={handleShowModal} title="Invite Admin" containerVariant="min-w-[400px]">
            <InviteAdmin backClick={handleShowModal} type="Add" />
          </Modal>
        )}
        {showEditModal && (
          <Modal closeModal={handleEditModal} title="Edit Admin" containerVariant="md:w-[400px] min-w-[320px]">
            <InviteAdmin backClick={handleEditModal} type="Edit" />
          </Modal>
        )}
        {showViewModal && (
          <Modal
            closeModal={handleViewAdminModal}
            title="Admin User Details"
            containerVariant="md:w-[700px] w-[320px]"
            dividerVariant="mb-8 mt-5"
            status={<Status showIcon status={selectedAdmin?.status} />}
          >
            <ViewAdmin />
          </Modal>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ManageAdmin;
