import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import Notification from './components/Notification/Notification';
import { router } from './router';
import { useIdleTimer } from 'react-idle-timer';
import { logout } from 'store/modules/auth/actions';
import { useAppDispatch } from 'hooks/useAppDispatch';

function App() {
  let dispatch = useAppDispatch();

  const onIdle = () => dispatch(logout());
  // const { start } = useIdleTimer({ onIdle, timeout: 1000 * 60 * 5 });
  const { start } = useIdleTimer({ onIdle, timeout: 1000 * 60 * 120 });
  useEffect(() => start(), []);

  return (
    <div className="relative h-screen overflow-x-hidden">
      <Notification />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
