import CustomButton from "components/Button/CustomButton"
import Status from "components/Status"
import useSettings from "hooks/fetch/useSettings"
import React, { useState } from "react"
import PropTypes from "prop-types"

export default function TableRow({item}) {
    const [loading, setLoading] = useState(false)
    const { delete_banner, update_banner } = useSettings()
    const { get_banners } = useSettings()
  
    const updateBannerProxy = (id, status) => {
      let payload = {id, status}
      setLoading(true)
      update_banner(payload)
      .then((res) => {
        if ([200, 201].includes(res?.code)) get_banners()
      })
      .finally(() => setLoading(false))
    }
  
    const deleteBannerProxy = (id) => {
      setLoading(true)
      delete_banner(id)
      .then((res) => {
          if ([200, 201].includes(res?.code)) get_banners()
      })
      .finally(() => setLoading(false))
    }
  
    return(
      <>
        <td role="cell" className='py-[8px] pl-[24px]'>
          <img src={item?.image_url} width="101px" height="56px" className="h-[56px] object-contain" alt={item?.image_url} />
        </td>
        <td>
          <Status showIcon status={item?.status} />
        </td>
        <td>
          <div className='flex'>
            <CustomButton
              name={item?.status?.toLowerCase() === "active" ? "Unpublish" : "Publish"}
              disabled={loading}
              onClick={() => updateBannerProxy(item?.id, item?.status?.toLowerCase() === 'active' ? 'inactive' : 'active')}
              variant={`
                rounded font-dmsans_r mr-[10px] w-[120px] border
                ${item?.status?.toLowerCase() === "active" ? "border-purple text-purple" : "border-purple bg-purple text-white"}
            `}
            />
            <CustomButton
              name="Delete"
              disabled={loading}
              onClick={() => deleteBannerProxy(item?.id)}
              variant="border border-0 text-red rounded font-dmsans_r w-[120px]"
            />
          </div>
        </td>
      </>
    )
  }
  
  TableRow.propTypes = {
    item: PropTypes.object
  } 