/* eslint-disable max-len */
import React from 'react'

const CalenderIcon = () => {
  return (
<svg width="18" height="20" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.06152 6.26931H12.9442" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.96123 8.87307H9.96741" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.00298 8.87307H7.00915" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.03887 8.87307H4.04504" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.96123 11.4641H9.96741" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.00298 11.4641H7.00915" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.03887 11.4641H4.04504" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.69552 1.33325V3.52711" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.31002 1.33325V3.52711" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.82551 2.38599H4.18064C2.22285 2.38599 1 3.47661 1 5.48134V11.5144C1 13.5507 2.22285 14.6665 4.18064 14.6665H9.81933C11.7833 14.6665 13 13.5696 13 11.5649V5.48134C13.0062 3.47661 11.7895 2.38599 9.82551 2.38599Z" stroke="#7A7978" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default CalenderIcon