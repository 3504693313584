import Modal from 'components/Modal/Modal';
import useCustomer from 'hooks/fetch/useCustomer';
import ViewUtilityBill from 'pages/Dashboard/KycMangement/ViewUtilityBill';
import React, { useEffect, useRef, useState } from 'react';
import { useCustomerState } from 'store/modules/customer';
import DoubleCheck from '../../../assets/icons/DoubleCheck';
import CustomButton from '../../Button/CustomButton';
import Card from '../../Card/Card';
import Status from '../../Status';
import Close from 'assets/icons/CloseIcon';

const Kyc = () => {
  //local State
  const [showModal, setShowModal] = useState(false)
  const customerState = useCustomerState();
  const { upgradeTierAccount, handleViewCustomer } = useCustomer();

  //Handler Function
  const upgradeTierLevel = (status) => {
    upgradeTierAccount(customerState.selectedCustomer.user_id, status, () => handleViewCustomer(customerState.selectedCustomer?.user_id));
  };

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const [bvnModal, setBvnModal] = useState(false)
  const [meansOfIdModal, setMeansOfIdModal] = useState(false)
  const [utilityModal, setUtilityModal] = useState(false)
  const bvnRef = useRef()
  const meansOfIdRef = useRef()
  const utitlityRef = useRef()
  
  const handleBvnModal = () => setBvnModal(!bvnModal)
  const handleMeansOfIdModal = () => setMeansOfIdModal(!meansOfIdModal)
  const handleUtilityModal = () => setUtilityModal(!utilityModal)

  const handleOutsideBvnClick = (event) => {
    if (bvnRef.current && !bvnRef.current.contains(event.target)) {
      handleBvnModal();
    }
  };
  const handleOutsideMeansOfIdClick = (event) => {
    if (meansOfIdRef.current && !meansOfIdRef.current.contains(event.target)) {
      handleMeansOfIdModal();
    }
  };
  const handleOutsideUtilityClick = (event) => {
    if (utitlityRef.current && !utitlityRef.current.contains(event.target)) {
      handleUtilityModal();
    }
  };

  useEffect(() => {
    if (bvnModal) {
      document.addEventListener('mousedown', handleOutsideBvnClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideBvnClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideBvnClick);
    };
  }, [bvnModal]);
  useEffect(() => {
    if (meansOfIdModal) {
      document.addEventListener('mousedown', handleOutsideMeansOfIdClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideMeansOfIdClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideMeansOfIdClick);
    };
  }, [meansOfIdModal]);
  useEffect(() => {
    if (utilityModal) {
      document.addEventListener('mousedown', handleOutsideUtilityClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideUtilityClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideUtilityClick);
    };
  }, [utilityModal]);
  

  return (
    <div className="font-dmsans_m py-32 px-24">
      <p className="text-black-transparent text-16 mb-12">KYC Document</p>
      <div className="flex space-y-12 flex-col">
        <div
          onClick={() => customerState?.selectedCustomer?.tier_levels > 1 ? handleBvnModal() : null }
          className={`${customerState?.selectedCustomer?.tier_levels > 1 && 'cursor-pointer'}`}
        >
          <Card
            containerVariant="py-4 px-32 justify-between rounded-lg shadow-shadowOne flex items-center"
            type="custom"
            cardBody={
              <>
                <div className="flex items-center space-x-6">
                  <div className="bg-white w-8 h-8 flex items-center justify-center shadow-iconShadow rounded-lg">
                    <DoubleCheck />
                  </div>
                  <p className="text-14 text-dark_blue">Bank Verification</p>
                </div>
                <Status showIcon status={customerState?.selectedCustomer?.tier_levels < 2 ? 'pending' : 'verified'} />
              </>
            }
          />
        </div>
        <div
          onClick={() => customerState?.selectedCustomer?.is_uploaded_identity_card ? handleMeansOfIdModal() : null }
          className={`${customerState?.selectedCustomer?.is_uploaded_identity_card && 'cursor-pointer'}`}
        >
          <Card
            containerVariant="py-4 px-32 flex justify-between rounded-lg shadow-shadowOne"
            type="custom"
            cardBody={
              <>
                <div className="flex items-center space-x-6">
                  <div className="bg-white w-8 h-8 flex items-center justify-center shadow-iconShadow rounded-lg">
                    <DoubleCheck />
                  </div>
                  <p className="text-14 text-dark_blue">Means of Identification</p>
                </div>
                <Status
                  showIcon
                  status={customerState?.selectedCustomer?.is_uploaded_identity_card ? 'verified' : 'pending'}
                />
              </>
            }
          />
        </div>
        <div className="w-full grid grid-cols-5 justify-between">
          <Card
            containerVariant="py-4 px-32 flex justify-between rounded-lg shadow-shadowOne col-span-4 w-full min-w-fit"
            type="custom"
            cardBody={
              <>
                <div
                  className={`flex items-center space-x-6
                  ${customerState?.selectedCustomer?.utility_bill !== null && customerState?.selectedCustomer?.tier_levels === 3 && 'cursor-pointer'}
                  `}
                  onClick={() => (customerState?.selectedCustomer?.utility_bill !== null &&
                  customerState?.selectedCustomer?.tier_levels === 3) ? handleUtilityModal() : null }
                >
                  <div className="bg-white w-8 h-8 flex items-center justify-center shadow-iconShadow rounded-lg">
                    <DoubleCheck />
                  </div>
                  <p className="text-14 text-dark_blue">
                    Utility Bill <span className="text-grey ml-2">
                      {customerState?.selectedCustomer?.utility_bill &&
                        customerState?.selectedCustomer?.utility_bill?.replace(
                          'https://poucher-upload.s3.amazonaws.com/',
                          '',
                        )}
                    </span>
                  </p>
                </div>
                <div
                  className={`flex justify-end items-center space-x-12
                  ${customerState?.selectedCustomer?.utility_bill !== null && customerState?.selectedCustomer?.tier_levels === 3 && 'cursor-pointer'}
                  `}
                  onClick={() => (customerState?.selectedCustomer?.utility_bill !== null &&
                  customerState?.selectedCustomer?.tier_levels === 3) ? handleUtilityModal() : null }
                >
                  {customerState?.selectedCustomer?.utility_bill === null && <Status showIcon status="pending" />}
                  {customerState?.selectedCustomer?.utility_bill !== null &&
                    customerState?.selectedCustomer?.tier_levels === 3 && <Status showIcon status="verified" />}
                  {customerState?.selectedCustomer?.utility_bill !== null &&
                    customerState?.selectedCustomer?.tier_levels === 2 && (
                        <CustomButton
                          name="Open"
                          onClick={handleShowModal}
                          variant="text-purple text-12 font-dmsans_r bg-purple_lighter cursor-pointer rounded-lg"
                        />
                    )}
                </div>
              </>
            }
          />
          {customerState?.selectedCustomer?.utility_bill !== null &&
            customerState?.selectedCustomer?.tier_levels !== 3 && (
              <div className='flex justify-end items-center space'>
                <CustomButton
                  name="Verify"
                  loading={customerState.loading}
                  variant="border-purple bg-purple text-white border rounded-lg text-14 font-dmsans_r"
                  onClick={() => upgradeTierLevel('upgrade')}
                />
              </div>
            )}
        </div>
      </div>
      {
        showModal && 
      <Modal closeModal={handleShowModal} title="Kyc Document" containerVariant="min-w-[400px]">
            <ViewUtilityBill {...customerState.selectedCustomer} />
          </Modal>
      }
      {bvnModal && (
        <div className="fixed inset-0 bg-[#00000060] flex items-center justify-center">
          <div
            ref={bvnRef}
            className='relative overflow-y-auto max-h-4/5
            w-[90%] h-fit max-w-[401px] bg-[#FFF] py-6 px-8 rounded-[4px] flex flex-col gap-6'>
            <div className='flex items-center justify-between pb-4 border-b border-[#D7DCE0]'>
              <h4 className='font-dmsans_b text-black-200 text-16'>Bank Verfication Number</h4>
              <button
                type="button" 
                data-testid="closebtn"
                className="cursor-pointer w-[24px] h-[24px] bg-white flex items-center justify-center
                rounded-full" 
                onClick={handleBvnModal}
              >
                <Close color="#000" />
              </button>
            </div>
            <div className='flex flex-col gap-2'>
              <p className='text-12 text-[#8F8E9B] uppercase'>bvn</p>
              <p className='text-14 text-[#060628] break-words'>{customerState?.selectedCustomer?.bvn_number}</p>
            </div>
          </div>
        </div>
      )}
      {meansOfIdModal && (
        <div className="fixed inset-0 bg-[#00000060] flex items-center justify-center">
          <div
            ref={meansOfIdRef}
            className='relative h-4/5 w-[90%] max-h-[562.5px] max-w-[409px] bg-[#FFF] py-6 px-8 rounded-[4px] flex flex-col gap-6'
          >
            <div className='flex items-center justify-between pb-4 border-b border-[#D7DCE0]'>
              <h4 className='font-dmsans_b text-black-200 text-16'>Means of Identification</h4>
              <button
                type="button" 
                data-testid="closebtn"
                className="cursor-pointer w-[24px] h-[24px] bg-white flex items-center justify-center
                rounded-full" 
                onClick={handleMeansOfIdModal}
              >
                <Close color="#000" />
              </button>
            </div>
            <div
              className={`w-full h-full relative overflow-hidden
                ${customerState?.selectedCustomer?.means_of_identification && 'bg-purple_light'}
              `}
            >
              {customerState?.selectedCustomer?.means_of_identification ? (
                <img src={customerState?.selectedCustomer?.utility_bill} alt="Utility bill" className="object-contain absolute h-full w-full" />
              ) : (
                <h3>The means of identification for this user was not saved</h3>
              )}
            </div>
          </div>
        </div>
      )}
      {utilityModal && (
        <div className="fixed inset-0 bg-[#00000060] flex items-center justify-center">
          <div
            ref={utitlityRef}
            className='relative h-4/5 w-[90%] max-h-[562.5px] max-w-[409px] bg-[#FFF] py-6 px-8 rounded-[4px] flex flex-col gap-6'
          >
            <div className='flex items-center justify-between pb-4 border-b border-[#D7DCE0]'>
              <h4 className='font-dmsans_b text-black-200 text-16'>Utility Bill</h4>
              <button
                type="button" 
                data-testid="closebtn"
                className="cursor-pointer w-[24px] h-[24px] bg-white flex items-center justify-center
                rounded-full" 
                onClick={handleUtilityModal}
              >
                <Close color="#000" />
              </button>
            </div>
            <div className='w-full h-full bg-purple_light relative overflow-hidden'>
              <img src={customerState?.selectedCustomer?.utility_bill} alt="Utility bill" className="object-contain absolute h-full w-full" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Kyc;
