import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'hooks/useReduxHook';
import { extraReducers } from './reducers';

const initialState = {
  transferList: {},
  selectedTransfer: {},
  transactionHistory: {},
  customersTransactionHistory: {},
  loading: false,
  error: null,
};

export const transfer = createSlice({
  name: 'transfer',
  initialState,
  extraReducers,
});

// Selectors,
const selectTransfer = (state) => state.transfer;

//Auth Redux State
export const useTransferState = () => useAppSelector(selectTransfer);

export default transfer.reducer;
