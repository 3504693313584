/* eslint-disable max-len */
import React from 'react';

export const InviteIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56 45H49V38C49 37.4696 48.7893 36.9609 48.4142 36.5858C48.0391 36.2107 47.5304 36 47 36C46.4696 36 45.9609 36.2107 45.5858 36.5858C45.2107 36.9609 45 37.4696 45 38V45H38C37.4696 45 36.9609 45.2107 36.5858 45.5858C36.2107 45.9609 36 46.4696 36 47C36 47.5304 36.2107 48.0391 36.5858 48.4142C36.9609 48.7893 37.4696 49 38 49H45V56C45 56.5304 45.2107 57.0391 45.5858 57.4142C45.9609 57.7893 46.4696 58 47 58C47.5304 58 48.0391 57.7893 48.4142 57.4142C48.7893 57.0391 49 56.5304 49 56V49H56C56.5304 49 57.0391 48.7893 57.4142 48.4142C57.7893 48.0391 58 47.5304 58 47C58 46.4696 57.7893 45.9609 57.4142 45.5858C57.0391 45.2107 56.5304 45 56 45Z"
        fill="#8F8E9B"
      />
      <path
        d="M32 57.9998H12C11.4696 57.9998 10.9609 57.7891 10.5858 57.4141C10.2107 57.039 10 56.5303 10 55.9998C10.0069 50.1672 12.3269 44.5754 16.4513 40.4511C20.5756 36.3268 26.1673 34.0067 32 33.9998C35.8656 34.0118 39.6057 32.6282 42.5329 30.1033C45.46 27.5783 47.3775 24.0818 47.933 20.2563C48.4885 16.4307 47.6446 12.5332 45.5565 9.28009C43.4684 6.02694 40.2763 3.63672 36.5669 2.54873C32.8575 1.46073 28.8801 1.74806 25.3656 3.35792C21.8511 4.96777 19.0357 7.79198 17.4368 11.3115C15.8379 14.831 15.563 18.8093 16.6626 22.5153C17.7622 26.2213 20.1623 29.4059 23.422 31.4838C18.3377 33.2645 13.9314 36.579 10.8108 40.9702C7.69028 45.3614 6.00937 50.6128 6 55.9998C6 57.5911 6.63214 59.1173 7.75736 60.2425C8.88258 61.3677 10.4087 61.9998 12 61.9998H32C32.5304 61.9998 33.0391 61.7891 33.4142 61.4141C33.7893 61.039 34 60.5303 34 59.9998C34 59.4694 33.7893 58.9607 33.4142 58.5856C33.0391 58.2105 32.5304 57.9998 32 57.9998ZM20 17.9998C20 15.6265 20.7038 13.3064 22.0224 11.333C23.3409 9.3596 25.2151 7.82153 27.4078 6.91328C29.6005 6.00503 32.0133 5.76739 34.3411 6.23041C36.6689 6.69343 38.8071 7.83632 40.4853 9.51455C42.1635 11.1928 43.3064 13.331 43.7694 15.6588C44.2324 17.9865 43.9948 20.3993 43.0866 22.592C42.1783 24.7847 40.6402 26.6589 38.6668 27.9775C36.6935 29.296 34.3734 29.9998 32 29.9998C28.8184 29.9967 25.768 28.7314 23.5182 26.4816C21.2685 24.2319 20.0032 21.1815 20 17.9998Z"
        fill="#8F8E9B"
      />
    </svg>
  );
};
