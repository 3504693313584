import React from 'react';

const ExpandIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 13L9 9M13 13V9.8M13 13H9.8M1 9.8V13M1 13H4.2M1 13L5 9M13 4.2V1M13 1H9.8M13 1L9 5M1 4.2V1M1 1H4.2M1 1L5 5"
        stroke="#5034C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandIcon;
