import React, { useState, useCallback, useEffect } from 'react';
import CTable from 'components/Table/Table';
import { UserInitals } from 'utils/ImagePlaceholder';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import CustomButton from 'components/Button/CustomButton';
import useSettings from 'hooks/fetch/useSettings';
import { useDebounce } from 'hooks/useDounce';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { convertTime } from 'utils/convertTime';
import DateFilter from 'components/DropDown/DateFilter';
import ViewMessage from './ViewMessage';

const MessageList = () => {
  const { notifications } = useSelector(state => state.settings)
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState(null);
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState('1');
  const { get_notifications, loading } = useSettings()
  const debouncedSearch = useDebounce(search);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState()
  const start_date = toDate && fromDate
  const end_date = fromDate && toDate
  const [selectedMessage, setSelectedMessage] = useState()

  let category;
  if (filter?.value !== '') category = filter?.value;

  const per_page = perPage.value;
  const navigate = useNavigate();

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, [search]);

  const handlePerPage = useCallback((data) => {
    setPerPage(data);
  }, [perPage])

  useEffect(() => {
    if (debouncedSearch) {
      get_notifications({ search: debouncedSearch, per_page, page, category, fromDate, toDate })
    } else {
      get_notifications({ per_page, page, category, fromDate, toDate })
    }
  }, [per_page, page, filter, start_date, end_date, debouncedSearch])

  return (
    <>
      {!selectedMessage ?
        <DashboardLayout
          hasBackButton={!selectedMessage?.title ? true : false}
          pageHeader="Messaging"
          variant="mb-0"
          hasCardTitle={false}
          searchValue={search}
          placeholder="Search names"
          SearchFunc={handleChange}
          showDefaultFilter={false}
          //dropDownValue={filter}
          customFilter={
            <DateFilter
              endDate={toDate}
              setEndDate={setToDate}
              setStartDate={setFromDate}
              startDate={fromDate}
            />
          }
          actionButtons={
            <CustomButton
              name="Send a new message"
              onClick={() => {
                setSelectedMessage()
                navigate('/settings/notifications/send-message')
              }}
              variant="border border-purple bg-purple text-white rounded font-dmsans_m"
            />
          }
          //Dropdownfunc={handleFilter}
          handlePerPage={handlePerPage}
          perPage={perPage}
        >
          <div className="mt-7">
            <CTable
              hasHeader={false}
              totalPayload={notifications?.total}
              searchValue={search}
              perPage={per_page}
              loading={loading}
              changePage={setPage}
              currentPage={page}
              showCheckBox={false}
              data={notifications?.notifications}
            >
              {(item) => (
                <>
                  <td role="cell">
                    <div className="flex items-center space-x-3 pl-6 w-[276px]">
                      <div className="rounded-full product__img bg-grey-400 flex items-center justify-center shrink-0">
                        {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                        {!item.profile_picture && (
                          <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                            {UserInitals(item.first_name, item.last_name)}
                          </p>
                        )}
                      </div>
                      <div className='truncate'>
                        <p className="text-grey-900 text-14 font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                          {item?.title}
                        </p>
                        <p className="text-grey-500 text-14 font-dmsans_r mx-4 md:mx-0 truncate">
                          {item?.body}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td role="cell">
                    <p className="font-dmsans_r text-dark min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                      Sent by: {item?.first_name} {item?.last_name}
                    </p>
                  </td>
                  <td role="cell">
                    <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                      {dayjs(item.created_at).format('MMM DD, YYYY')}
                      <span className="text-grey"> | {convertTime(item?.created_at)}</span>
                    </p>
                  </td>
                  <td role="cell">
                    <p
                      className="font-dmsans_b underline text-purple text-14 cursor-pointer"
                      onClick={() => setSelectedMessage(item)}
                    >
                      View Details
                    </p>
                  </td>
                </>
              )}
            </CTable>
          </div>
        </DashboardLayout> :
        <ViewMessage message={selectedMessage} setSelectedMessage={() => setSelectedMessage()} />
      }
    </>
  );
};

export default MessageList;
