import {
getDashboardAnalytics,
getProviderBalancesUserAnalytics
} from './actions';

const extraReducers = {

//GET DASHBOARD ANALYTICS
[getDashboardAnalytics.pending]: (state) => {
  state.loading = true
},
[getDashboardAnalytics.fulfilled]: (state, action) => {
  state.dashboardAnalytics = action.payload.data;
  state.loading = false
},
[getDashboardAnalytics.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},

//GET PROVIDER BALANCES USER ANALYTICS
[getProviderBalancesUserAnalytics.pending]: (state) => {
  state.loadingProvider = true
},
[getProviderBalancesUserAnalytics.fulfilled]: (state, action) => {
  state.providerBalancesUserAnalytics = action.payload.data;
  state.loadingProvider = false
},
[getProviderBalancesUserAnalytics.rejected]: (state, action) => {
  state.loadingProvider = false;
  state.error = action.payload.data.message;
},
}

export { extraReducers };
