import React, { useEffect } from 'react'
import SendIcon2 from 'assets/icons/SendIcon2'
import SingleFileIcon from 'assets/icons/SingleFileIcon'
import Title from 'components/Header/Title'
import { useNavigate } from 'react-router-dom'

const Notifications = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Notification - Pouchers Admin';
  }, []);

  return (
    <div>
      <div className="flex justify-between mb-4 py-2">
        <Title title="Notification" variant="mb-3" />
      </div>
      <div className="py-10 px-10 bg-white rounded-lg border-light-smoke border h-[75vh]" >
        <div className='grid grid-cols-3 gap-10'>
          <div
            className='bg-purple_light border border-purple rounded-[10px] py-8 px-6 cursor-pointer'
            data-testid="send-message"
            onClick={() => navigate('send-message')}
          >
            <SendIcon2 />
            <div className='mt-64'>
              <p className='text-24 text-purple font-dmsans_b'>
                Send a message
              </p>
              <p className='text-grey-600 font-dmsans_r leading-6 mt-4'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
            </div>
          </div>
          <div
            data-testid="message-list"
            className='bg-white border border-purple_light rounded-[10px] py-8 px-6 cursor-pointer'
            onClick={() => navigate('messaging')}
          >
            <SingleFileIcon />
            <div className='mt-64'>
              <p className='text-24 text-purple font-dmsans_b'>
                Previous Messages
              </p>
              <p className='text-grey-600 font-dmsans_r leading-6 mt-4'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications