import Filter from 'assets/icons/Filter';
import DashboardCard from 'components/Card/DashboardCard';
import Dropdown from 'components/DropDown/DropDown';
import Title from 'components/Header/Title';
import SearchInput from 'components/Input/SearchInput';
import { EntriesfilterList, filterList } from 'constants/mocks/customers';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import PropTypes from 'prop-types';
import BackButton from 'components/Button/BackButton';

const DashboardLayout = ({
  pageHeader,
  hasActionButton = true,
  actionButtons,
  customFilter,
  cardTitle,
  hasBackButton = false,
  hasFilter = true,
  filterBy = '',
  dropDownValue,
  hasSearch = true,
  options = filterList,
  Dropdownfunc,
  showDefaultFilter = true,
  filterStyles,
  SearchFunc,
  searchValue,
  cardVariant,
  perPage,
  handlePerPage,
  children,
  variant,
  placeholder,
  hasCardTitle = true,
}) => {
  return (
    <div>
      {(pageHeader || hasActionButton) && (
        <div className="flex justify-between mb-4 py-2">
          <div className="flex items-center space-x-2">
            {hasBackButton && <BackButton />}
            <Title title={pageHeader} variant={variant || 'mb-3'} />
          </div>
          {hasActionButton && actionButtons}
        </div>
      )}
      <DashboardCard variant={cardVariant}>
        <div className="flex justify-between items-center flex-wrap gap-2">
          {hasCardTitle && <p className="font-dmsans_m text-18">{cardTitle}</p>}
          {hasFilter && (
            <div className="flex md:space-x-4 flex-wrap mt-4 lg:mt-0 items-center">
              {hasSearch && <SearchInput placeholder={placeholder} value={searchValue} handleChange={SearchFunc} />}
              {showDefaultFilter && (
                <Dropdown
                  variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
                  placeholder={`Filter by ${filterBy}`}
                  innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                  icon={<Filter />}
                  type="filter"
                  optionContainerVariant={filterStyles}
                  value={dropDownValue}
                  options={options}
                  onChange={Dropdownfunc}
                />
              )}

                <div className="xxs:mt-4 md:mt-0">{customFilter}</div>
                <div className="flex items-center mt-4 lg:mt-0">
                  <span className="text-14 font-dmsans_r mr-2">Showing:</span>
                  <div data-testid="pagefilter">
                    <Dropdown
                      variant="border border-light-smoke w-[142px]"
                      placeholder=""
                      innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                      icon={<IoIosArrowDown />}
                      value={perPage}
                      type="filter"
                      options={EntriesfilterList}
                      onChange={handlePerPage}
                    />
                  </div>
                </div>
              </div>
          )}
        </div>
        {children}
      </DashboardCard>
    </div>
  );
};

export default DashboardLayout;
DashboardLayout.propTypes = {
  pageHeader: PropTypes.string,
  placeholder: PropTypes.string,
  cardTitle: PropTypes.string,
  filterBy: PropTypes.string,
  customFilter: PropTypes.any,
  options: PropTypes.array,
  variant: PropTypes.string,
  searchValue: PropTypes.string,
  cardVariant: PropTypes.string,
  filterType: PropTypes.string,
  dropDownValue: PropTypes.any,
  filterStyles: PropTypes.string,
  perPage: PropTypes.object,
  datePlaceholder: PropTypes.func,
  actionButtons: PropTypes.any,
  pageFilters: PropTypes.any,
  children: PropTypes.any,
  hasActionButton: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  hasCardTitle: PropTypes.bool,
  hasSearch: PropTypes.bool,
  showDefaultFilter: PropTypes.bool,
  hasFilter: PropTypes.bool,
  SearchFunc: PropTypes.func,
  Dropdownfunc: PropTypes.func,
  handlePerPage: PropTypes.func,
  setFromDate: PropTypes.any,
  setToDate: PropTypes.any,
  toDate: PropTypes.any,
  fromDate: PropTypes.any,
};
