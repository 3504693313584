import { createAsyncThunk } from '@reduxjs/toolkit';
import { settings } from 'services/endpoints/settings';
import { fetchRequest } from 'utils/createAsyncThunk';
import api from '../../../services/axios-instance';

const getDiscountByCategory = createAsyncThunk('settings/getDiscountByCategory', async ({id, ...params}) => {
  try {
    const response = await api.get(`/admin-overview/discount/${id}`, {params});
    return response.data ? response.data : {};
  } catch (error) {
    return Promise.reject(error);
  }
});
const deletediscount = createAsyncThunk('settings/deleteDiscount', async (id) => {
  try {
      const response = await api.delete(`/admin-settings/discount/${id}`)
      return response.data ? response.data : {}
  } catch (error) {
      return Promise.reject(error)
  }
})
const editDiscount = createAsyncThunk('settings/deleteDiscount', async(payload) => {
  try {
      const response = await api.patch(`/admin-settings/discount/${payload.id}`, {...payload.data})
      return response.data ? response.data : {}
  } catch (error) {
      return Promise.reject(error)
  }
})
const deleteBanner = createAsyncThunk('settings/deleteBanner', async(id) => {
  try {
      const response = await api.delete(`${settings.banner}/${id}`)
      return response.data ? response.data : {}
  } catch (error) {
      return Promise.reject(error)
  }
})
const getNotifications = createAsyncThunk('payment/getNotifications', async(params) => {
  try {
      const response = await api.get(settings.get_notifications, {params})
      return response.data ? response.data : {}
  } catch (error) {
      return Promise.reject(error)
  }
});

const createDiscount = fetchRequest('settings/createDiscount', 'post', settings.create_discount);
const getEnvs = fetchRequest('settings/getEnvs', 'get', settings.get_envs)
const getProductStatus = fetchRequest('settings/getProductStatus', 'get', settings.get_product_status)
const updateProductStatus = fetchRequest('settings/updateProductStatus', 'patch', settings.update_product_status)
const updateEnv = fetchRequest('settings/updateEnv', 'patch', settings.update_env)
const requestUploadUrl = fetchRequest('settings/requestUploadUrl', 'post', settings.request_upload_url)
const uploadBanner = fetchRequest('settings/uploadBanner', 'post', settings.banner)
const updateBanner = fetchRequest('settings/updateBanner', 'patch', settings.banner)
const getBanners = fetchRequest('settings/getBanners', 'get', settings.get_banners)
const getExchangeRate = fetchRequest('settings/getExchangeRate', 'get', settings.exchange_rate)
const createNotification = fetchRequest('settings/createNotification', 'post', settings.create_notification)

export {
  getEnvs,
  editDiscount,
  getProductStatus,
  createDiscount,
  deletediscount,
  getDiscountByCategory,
  updateEnv,
  requestUploadUrl,
  uploadBanner,
  deleteBanner,
  updateBanner,
  updateProductStatus,
  getBanners,
  getExchangeRate,
  getNotifications,
  createNotification
}
