/* eslint-disable max-len */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import Header from '../Header/Header';
import usePIN from "../../hooks/usePIN";
import useAuth from "../../hooks/fetch/useAuth"
import { useSearchParams } from 'react-router-dom';
import useResendOtp from 'hooks/useResendOtp';

export default function VerifyAccountForm() {
  const [params] = useSearchParams()
  const email = params.get('email')
  //Hooks
  const inputRef = useRef([]);
  const { loading, recoverUser } = useSelector((state) => state.auth);
  const { resend_forgot_password_otp } = useAuth()
  const { otp, handleOnChange, handleOnPress, handleOnPaste } = usePIN(6, inputRef)
  const { timeLeft, resetTimer } = useResendOtp()
  const { verifyaccount } = useAuth()
  const makeRequest = (e) => {
    e.preventDefault()

    verifyaccount({ otp, email })
  }

const resend = async () => {
  resend_forgot_password_otp({email})
  resetTimer()
}




  return (
    <section className="w-full flex justify-center transition_left">
      <form className="mx-auto w-full sm:w-[400px] pt-112 sm:pt-168 lg:mx-0" onSubmit={(e) => makeRequest(e)}>
        <Header
          title="Enter OTP Code"
          description={
            <>
              Enter OTP unique code sent to your email address{' '}
              <span className="text-blue_deep"> {recoverUser.email ? recoverUser.email : ''}</span> to reset password
            </>
          }
        />

        <div className="flex space-x-3 mt-2">
        {otp.map((currentInput, index) => {
              return (
                <div key={currentInput.key}>
                  <input
                    data-testid={`otp_input-${index}`}
                    id="otp_input"
                    ref={(el) => (inputRef.current[currentInput.key] = el)}
                    onChange={(e) => handleOnChange(e, currentInput)}
                    onKeyDown={(e) => handleOnPress(e, currentInput)}
                    onPaste={(e) => handleOnPaste(e)}
                    type="text"
                    className="w-[42px] h-[42px] md:w-[57px] md:h-[57px] xs:w-[50px] xs:h-[50px] lg:text-18 md:text-16 text-14 text-center md:p-16 p-12 outline-0 font-dmsans_r font-normal rounded-lg border border-gray_light_100 focus:border-purple focus:border bg-green_light"
                    placeholder=""
                    maxLength={1}
                    readOnly={loading}
                    value={currentInput.value}
                    autoFocus={currentInput.isFocus}
                  />
                </div>
              );
            })}
        </div>
        <div className="flex justify-between items-center mt-10">
          <button type="button" disabled={loading || timeLeft !== 0} onClick={resend} className="font-dmsans_m text-16 disabled:cursor-not-allowed hover:bg-purple-50 p-3 rounded-xl">
            Resend Code { timeLeft === 0 ? '' : <span>: in {timeLeft}s</span> }
          </button>
          <Button name="Continue" theme="dark" disabled={loading} loading={loading} styles="" />
        </div>
      </form>
    </section>
  );
}
