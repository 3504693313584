import { getScheduledPayment } from 'store/modules/scheduleAndAutomated/actions';
import { getAllTransfers, getSingleTransfer } from 'store/modules/transfers/actions';
import { getVouchers } from 'store/modules/vouchers/actions';
import {
  getAllCustomers,
  getCustomerReferalTrail,
  getTransactionHistory,
  getSingleCustomer,
  deactivateCustomer,
  activateCustomer,
  getWalletDetails,
  upgradeAccount,
  getTransactionReceipt,
  updateCard,
  getProductHistory,
  updateCustomerWallet,
} from '../../store/modules/customer/actions';
import useMakeRequest from '../useMakeRequest';

//Handler Functions
export default function useCustomer() {
  const { makeRequest } = useMakeRequest();

  const handleGetAllCustomer = async (params) => {
    makeRequest({
      action: getAllCustomers(params),
      alert: false,
    });
  };

  const getAllTransactionHistory = async (id, params) => {
    makeRequest({
      action: getTransactionHistory({ id, ...params }),
      alert: false,
    });
  };

  const getAllProductHistory = async (id, params) => {
    makeRequest({
      action: getProductHistory({ id, ...params }),
      alert: false,
    });
  };
  const handleViewTransactionReceipt = async (callback, payload) => {
    makeRequest({
      action: getTransactionReceipt(payload),
      alert: false,
      callback,
    });
  };

  const handleViewCustomer = async (id) => {
    makeRequest({
      action: getSingleCustomer(id),
      to: `/manage-customer/${id}`,
      alert: false,
    });
  };

  const handleWalletDetails = async (id) => {
    makeRequest({
      action: getWalletDetails(id),
      alert: false,
    });
  };

  const deactivatecustomer = async (id, callback) => {
    makeRequest({ action: deactivateCustomer({ user_id: id }), callback });
  };

  const activatecustomer = async (id, callback) => {
    makeRequest({ action: activateCustomer({ user_id: id }), callback });
  };

  const fetchCustomerReferralTrail = async (id) => {
    makeRequest({
      action: getCustomerReferalTrail(id),
      alert: false,
    });
  };

  const upgradeTierAccount = async (id, status, callback) => {
    makeRequest({
      action: upgradeAccount({ user_id: id, status }),
      callback,
    });
  };

  const updatecard = async (currency, user_id, card_id, status, callback) => {
    const payload = {
      currency,
      user_id,
      card_id,
      status,
    };
    makeRequest({
      action: updateCard(payload),
      callback,
    });
  };

  const handleGetAllTransfers = async (params) => {
    makeRequest({
      action: getAllTransfers(params),
      alert: false,
    });
  };

  const handleViewTransfer = async (id, callback) => {
    makeRequest({
      action: getSingleTransfer(id),
      alert: false,
      callback,
    });
  };

  const handleGetVoucher = async (params) => {
    makeRequest({
      action: getVouchers(params),
      alert: false,
    });
  };
  const handleSchedulePayment = async (params) => {
    makeRequest({
      action: getScheduledPayment(params),
      alert: false,
    });
  };

  const handleUpdateCustomerWallet = async (params, formData) => {
    const payload = {
      reason: formData.reason,
      amount: Number(formData.amount),
      password: formData.password,
    };
    makeRequest({
      action: updateCustomerWallet({ params, payload }),
      to: `/manage-customer/success-page?customer_id=${params.id}&type=${params.type}`,
      alert: false,
    });
  };

  return {
    handleGetAllCustomer,
    handleViewCustomer,
    handleGetAllTransfers,
    handleWalletDetails,
    updatecard,
    handleViewTransfer,
    deactivatecustomer,
    getAllProductHistory,
    activatecustomer,
    handleSchedulePayment,
    getAllTransactionHistory,
    fetchCustomerReferralTrail,
    handleViewTransactionReceipt,
    upgradeTierAccount,
    handleGetVoucher,
    handleUpdateCustomerWallet,
  };
}
