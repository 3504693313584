import { getVouchers } from "./actions";

const extraReducers = {

//GET VOUCHERS
[getVouchers.pending]: (state) => {
  state.loading = true
},
[getVouchers.fulfilled]: (state, action) => {
  state.voucherList = action.payload.data;
  state.loading = false
},
[getVouchers.rejected]: (state, action) => {
  state.loading = false
  state.error = action.payload.data.message;

}
};

export { extraReducers };
