import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import { formatCurrency } from 'utils/formatCurrency';
import FreezeIcon from 'assets/icons/FreezeIcon';
import UnFreezeIcon from 'assets/icons/UnFreezeIcon';
import usePermissions from 'hooks/usePermissions';

import NairaCard  from 'assets/icons/Cards24.svg';
import DollarCard from 'assets/icons/Cards23.svg';
const WalletCard = ({ cardDetails, updateCard, type }) => {
  //Hook
  const { userIsPermittedTo } = usePermissions();
  // const VirtualCard = type === 'dollar' ? DollarCard : NairaCard
  return (
    <div className="mt-12 flex space-x-4">
      

      <img src={type === 'dollar' ? DollarCard : NairaCard } alt="credit card" className="w-1/3 h-full" />
       <div>
       {/* <VirtualCard /> */}
       </div>
      <div className=" w-2/3">
        <Card
          containerVariant="flex bg-purple_transparent p-5 rounded-[10px] flex-col space-y-6"
          type="custom"
          cardBody={
            <>
              <div className="grid grid-cols-2">
                <div>
                  <p className="text-grey font-dmsans_r text-14">Status</p>
                  <p
                    className={`text-14 mt-1.5 font-dmsans_b ${
                      cardDetails?.status === 'active' ? 'text-green' : 'text-red'
                    } capitalize`}
                  >
                    {cardDetails?.status}
                  </p>
                </div>
                <div>
                  <p className="text-grey font-dmsans_r text-14">Card Balance</p>
                  <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r">{formatCurrency(cardDetails?.balance)}</p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div>
                  <p className="text-grey font-dmsans_r text-14">Cardholder Name</p>
                  <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r capitalize">
                    {type === 'dollar' ? cardDetails?.dollar_cardholder_name : cardDetails?.naira_cardholder_name}
                  </p>
                </div>
                <div>
                  <p className="text-grey font-dmsans_r text-14">Card Type</p>
                  <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r capitalize">{type} Card</p>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full">
                <div>
                  <p className="text-grey font-dmsans_r text-14">Card Pan</p>
                  <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r">*************{cardDetails?.last4}</p>
                </div>
                <div>
                  <p className="text-grey font-dmsans_r text-14">Expiry Date</p>
                  <p className="text-dark_blue text-14 mt-1.5 font-dmsans_r">
                    {cardDetails?.expiry_month}/{cardDetails?.expiry_year}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-grey font-dmsans_r text-14">Account Number</p>
                <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                  {cardDetails?.account_number} ({cardDetails?.provider})
                </p>
              </div>
            </>
          }
        />
        {userIsPermittedTo('Update', 'virtual_cards_management') && (
          <div
            className="font-dmsans_r text-14 text-dark_blue flex flex-col items-center mt-6 cursor-pointer"
            onClick={updateCard}
          >
            <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full">
              {cardDetails?.status === 'active' ? <FreezeIcon /> : <UnFreezeIcon />}
            </div>
            <p>{cardDetails?.status === 'active' ? 'Freeze' : 'Unfreeze'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletCard;
WalletCard.propTypes = {
  cardDetails: PropTypes.any,
  updateCard: PropTypes.func,
  type: PropTypes.string,
};
