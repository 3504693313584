import React, { useEffect, useState } from 'react';
import ScheduleAndAutomated from 'pages/Dashboard/ScheduleAndAutomated/ScheduleAndAutomated';
import Title from 'components/Header/Title';
import ExportExcel from 'components/ExportExcel';
import { useScheduleState } from 'store/modules/scheduleAndAutomated';
import CustomButton from 'components/Button/CustomButton';
import Tabs from 'components/Tabs/Tabs';

const Index = () => {
  const [selectedTab, setSelectedTab] = useState('all')
  const { scheduledPayment } = useScheduleState();

  //Variables
  let TabsHeader = ["all", "active", "inactive"];
  useEffect(() => {
    document.title = 'Scheduled & Automated Bills - Pouchers Admin';
  }, []);

  return (
    <div>
      <div className="flex justify-between mb-7 items-center">
        <Title title="Scheduled & Automated Bills" variant="" />
        <ExportExcel
          excelData={scheduledPayment?.scheduled_payments}
          fileName={`file-scheduleAutomatedList-${new Date().toISOString()}`}
        >
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r xxs:text-14" />
        </ExportExcel>
      </div>
      <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border">
        <Tabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            TabsItem={TabsHeader}
        />
        <ScheduleAndAutomated tab={selectedTab} />
      </div>
    </div>
  );
};

export default Index;
