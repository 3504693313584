import React from "react";
import Account from "../assets/icons/Account";
import Kyc from "../assets/icons/Kyc";
import ProductHistory from "../assets/icons/ProductHistory";
import Profile from "../assets/icons/Profile";
import Reward from "../assets/icons/Reward";
import TransactionHistory from "../assets/icons/TransactionHistory";

export const nav_links = [
    {
        id: 1,
        title: 'Profile',
        icon: <Profile />,
    },
    {
        id: 2,
        title: 'Transaction History',
        icon: <TransactionHistory />,
    },
    {
        id: 3,
        title: 'Account Information',
        icon: <Account />,
    },
    {
        id: 4,
        title: 'Product History',
        icon: <ProductHistory />,
    },
    {
        id: 5,
        title: 'KYC',
        icon: <Kyc />,
    },
    {
        id: 6,
        title: 'Refer and Earn',
        icon: <Reward />,
    },
]
