export const admin = {
  get_roles: '/admin/roles',
  get_admins: '/admin',
  get_transfers: '/admin-overview/transfers',
  get_customers: '/admin-customer',
  deactivate_admin: '/admin/deactivate',
  deactivate_customer: '/admin-customer/deactivate',
  activate_customer: '/admin-customer/reactivate',
  upgrade_account: '/admin-customer/edit-tier',
  audit_trail: 'admin-overview/audit-trail',
  update_card: '/admin-customer/update-card',
  get_virtual_cards: '/admin-overview/virtual-cards',
  get_vouchers: 'admin-overview/vouchers',
  get_user_transaction_history: '/admin-overview/transactions-history',
  get_customer_transaction_history: '/admin-overview/customer-transactions-history',
  get_payment_history: 'admin-overview/payments',
  get_guest_payment_history: 'admin-overview/guest-user-payments',
  get_scheduled_payment: 'admin-overview/scheduled-payments',
  get_category_analytics: 'admin-overview/payments-analytics',
  dashboard_analytics: 'admin-overview/dashboard-analytics',
  provider_balances_user_analytics: 'admin-overview/provider-balances-user-analytics',
};
