import React, { useCallback, useState } from 'react';
import FormContainers from 'components/Containers/FormContainers';
import PropTypes from "prop-types"
import Modal from 'components/Modal/Modal';
import EditReferralBonus from './EditReferralBonus';
import LoadingSkeleton from 'components/Skeleton/Skeleton';

export default function ReferralBonus({amount, loading}) {
  const [showModal, setShowModal] = useState(false)

  const closeModal = useCallback(() => {
      setShowModal(false)
  },[])

  return (
    <>
    <FormContainers
        title="Referral Bonus"
        formContent={
            <div className="ml-6">
            {
            loading ? <LoadingSkeleton count={2}/> :
            <div className="mt-[50px] flex flex-col space-y-[50px]">
                <div className="grid grid-cols-4">
                <p className="text-black-transparent text-16 font-dmsans_m">Referral Bonus</p>
                <p className="text-black-transparent text-16 font-dmsans_r">₦ {amount}</p>
                </div>
            </div>
          }
            </div>
        }
        btnText="Edit"
        onSubmit={(e) => {
          e.preventDefault()
          setShowModal(true)
        }}
    />
    {
      showModal &&
      <Modal
        closeModal={closeModal}
        title="Edit Referral Bonus"
        containerVariant="min-w-[437px]"
      >
          <EditReferralBonus 
            cancel={closeModal}
            referralBonus={amount}
          />
      </Modal>
    }
    </>
  );
}

ReferralBonus.propTypes = {
  amount: PropTypes.string,
  loading: PropTypes.bool
}
