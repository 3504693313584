import {
  getPaymentHistory,
  getCategoryAnalytics,
  getSinglePayment,
  getGuestPaymentHistory
} from './actions';

const extraReducers = {

//GET PAYMENT HISTORY
[getPaymentHistory.pending]: (state) => {
  state.loading = true;
},
[getPaymentHistory.fulfilled]: (state, action) => {
  state.paymentHistory = action.payload.data;
  state.loading = false
},
[getPaymentHistory.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
//GET PAYMENT HISTORY
// [getSinglePayment.fulfilled]: (state) => {
//   state.loading = true
// },  
[getSinglePayment.fulfilled]: (state, action) => {
  state.selectedPayment = action.payload.data;
  state.loading = false
},
[getSinglePayment.rejected]: (state, action) => {
  state.loading = false
  state.error = action.payload.data.message;
},
[getCategoryAnalytics.pending]: (state) => {
  state.loading = true;
},
//GET CATEGORY ANALYTICS
[getCategoryAnalytics.fulfilled]: (state, action) => {
  state.categoryAnalytics = action.payload.data;
  state.loading = false
},
[getCategoryAnalytics.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
//GET Guest Payment history
[getGuestPaymentHistory.fulfilled]: (state, action) => {
  state.guestPaymentHistory = action.payload.data;
  state.loading = false
},
[getGuestPaymentHistory.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},
};


export { extraReducers };
