const base = 'admin-settings'

export const settings = {
    get_envs: `${base}/envs`,
    update_env: `${base}/env`,
    get_product_status: `${base}/products`,
    update_product_status: `${base}/product-status`,
    request_upload_url: `${base}/pre-signed-url`,
    banner: `${base}/banner`,
    get_banners: `${base}/banners`,
    create_discount: `${base}/discount`,
    exchange_rate: `card/exchange_rate/USDNGN`,
    get_notifications: `/admin-overview/notifications`,
    create_notification: `/admin-customer/notification`
}