import api from '../services/axios-instance'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchRequest = (thunkName, method, url) => {
    return createAsyncThunk(thunkName, async (payload) => {
        try {
            const response = await api[method || 'get'](url, { ...payload })
            if (response?.data) return response?.data ? response?.data : {}
            if (response?.response?.data) return response?.response?.data ? response?.response?.data : {}
        } catch (error) {
            return error
        }
    })
}