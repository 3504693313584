/* eslint-disable no-unused-vars */
import CustomButton from 'components/Button/CustomButton';
import React, { useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import CTable from 'components/Table/Table';
import Status from 'components/Status';
import { UserInitals } from 'utils/ImagePlaceholder';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { formatCurrency } from 'utils/formatCurrency';
import ExportExcel from 'components/ExportExcel';
import { useDebounce } from 'hooks/useDounce';
import { TransactionHistoryFilter, TransactionHistoryHeader } from 'constants/mocks/transaction_history';
import { useTransferState } from 'store/modules/transfers';
import useAdmin from 'hooks/fetch/useAdmin';
import useCustomer from 'hooks/fetch/useCustomer';
import { useAuthState } from 'store/modules/auth';

const TransactionHistory = () => {
  //Redux Hooks
  const {
    loggedUser: { role },
  } = useAuthState();
  const { transactionHistory, loading } = useTransferState();
  const { handleViewCustomer } = useCustomer();
  //local State
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState('1');

  //Hooks
  const { handleGetUserTransactionHistory } = useAdmin();
  const debouncedSearch = useDebounce(search);

  //Variable
  const per_page = perPage.value;

  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleGetUserTransactionHistory({ per_page, page, status: filter && filter.value, search: debouncedSearch });
    } else {
      handleGetUserTransactionHistory({ per_page, page, status: filter && filter.value });
    }
  }, [per_page, page, filter, debouncedSearch]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleFilter = useCallback(
    (data) => {
      setFilter(data);
    },
    [filter],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  useEffect(() => {
    document.title = 'Admin Transactions - Pouchers Admin';
  }, []);

  useEffect(() => {
    if (role !== 'Super-Admin') {
      window.location.href = '/home';
    }
  }, [role]);

  if (role !== 'Super-Admin') {
    return null;
  }

  return (
    <DashboardLayout
      pageHeader="Admin Transactions"
      perPage={perPage}
      cardTitle="Admin Transactions"
      SearchFunc={handleChange}
      searchValue={search}
      filterBy="Status"
      options={TransactionHistoryFilter}
      Dropdownfunc={handleFilter}
      dropDownValue={filter}
      placeholder="Search by customer name and tag"
      handlePerPage={handlePerPage}
      actionButtons={
        <ExportExcel
          excelData={transactionHistory?.transactionHistory}
          fileName={`file-transactionHistory-${new Date().toISOString()}`}
        >
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
        </ExportExcel>
      }
    >
      <div className="mt-7">
        <CTable
          header={TransactionHistoryHeader}
          totalPayload={transactionHistory?.total}
          perPage={per_page}
          loading={loading}
          changePage={setPage}
          currentPage={transactionHistory?.page}
          showCheckBox={false}
          data={transactionHistory?.transactionHistory}
        >
          {(item) => (
            <>
              <td
                data-testid="test-customer"
                className="cursor-pointer"
                // onClick={() => handleViewCustomer(item.user_id)}
                onClick={() => {
                  window.open(`/manage-customer/${item.user_id}`, '_blank');
                }}
              >
                <div className="flex items-center space-x-3 min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="text-dark font-dmsans_r">
                      {item.first_name} {item.last_name}
                    </p>
                    <p className="text-grey font-dmsans_r">{item.tag}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="text-grey-500 font-dmsans_r pl-6 min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  {formatCurrency(item.amount)}
                </p>
              </td>

              <td>
                <p className="text-grey-500 font-dmsans_r pl-6 min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  {`${item.reason?.substr(0, 50)}`}
                </p>
              </td>

              <td>
                <div className="font-dmsans_r min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  <Status showIcon status={item.status} />
                </div>
              </td>
              <td>
                <div className="font-dmsans_r min-w-max  whitespace-no-wrap text-blue_black">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </div>
              </td>
            </>
          )}
        </CTable>
      </div>
    </DashboardLayout>
  );
};

export default TransactionHistory;
