import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.84442" cy="6.84442" r="5.99237" stroke="#23273B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.0122 11.3232L13.3616 13.6665"
        stroke="#23273B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
