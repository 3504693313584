export const TransfersHeader = [
  {
      name: 'Transaction ID',
      variant: 'pl-6'
  },
  {
      name: 'Sender’s Info',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Amount Sent',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Reciever’s Info',
      variant: 'pl-4 min-w-max',

  },
  {
    name: 'Payment  Method/ API',
    variant: 'pl-4 min-w-max',
  },
  {
      name: 'Transaction Date',
      variant: 'pl-4 min-w-max',
  },
  {
      name: '',
      variant: 'pl-4 md:pl-8',
  }
]

export const PTPTransfersHeader = [
  {
      name: 'Transaction ID',
      variant: 'pl-6'
  },
  {
      name: 'Sender’s Info',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Amount Sent',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Reciever’s Info',
      variant: 'pl-4 min-w-max',

  },
  {
      name: 'Transaction Date',
      variant: 'pl-4 min-w-max',
  },
  {
      name: '',
      variant: 'pl-4 md:pl-8',
  }
]

export const ALLTransfersHeader = [
  {
      name: 'Transaction ID',
      variant: 'pl-6'
  },
  {
      name: 'Sender’s Info',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Amount Sent',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Reciever’s Info',
      variant: 'pl-4 min-w-max',

  },
  {
      name: 'P2P/External',
      variant: 'pl-4 min-w-max',

  },
  {
    name: 'Payment  Method/ API',
    variant: 'pl-4 min-w-max',
  },
  {
      name: 'Transaction Date',
      variant: 'pl-4 min-w-max',
  },
  {
      name: '',
      variant: 'pl-4 md:pl-8',
  }
]

export const TransfersData = [
  {
      transaction_no: "#00000001",
      first_name: "Livia",
      last_name: "Livia",
      profile_picture: null,
      senders_tag: "@Livia",
      amount: "₦ 500,000",
      reciever_tag: "@Livia", 
      payment_method: "Wallet",
      api: "Paystack",
      date_created: "May 27, 2020",
      time: "12:38pm"
  },
  {
    transaction_no: "#00000002",
    profile_picture: null,
    first_name: "Foenix",
    last_name: "Foenix",
    senders_tag: "@Foenix",
    amount: "₦ 500,000",
    reciever_tag: "@Foenix", 
    payment_method: "Wallet",
    api: "Flutterwave",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000003",
    first_name: "Lana",
    last_name: "Lana",
    profile_picture: null,
    senders_tag: "@Lana220",
    amount: "₦ 500,000",
    reciever_tag: "@Lana220", 
    payment_method: "Wallet",
    api: "Providus Bank",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000004",
    first_name: "wikins",
    last_name: "wikins",
    profile_picture: null,
    senders_tag: "@Wikinsss",
    amount: "₦ 500,000",
    reciever_tag: "@Wikinsss", 
    payment_method: "Wallet",
    api: "Paystack",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000005",
    profile_picture: null,
    first_name: "Candice",
    last_name: "Candice",
    senders_tag: "@Candice",
    amount: "₦ 500,000",
    reciever_tag: "@Candice", 
    payment_method: "Wallet",
    api: "Paystack",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000006",
    profile_picture: null,
    first_name: "Nataliiii",
    last_name: "Nataliiii",
    senders_tag: "@Nataliiii",
    amount: "₦ 500,000",
    reciever_tag: "@Nataliiii", 
    payment_method: "Wallet",
    api: "Paystack",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000007",
    last_name: "Drew",
    first_name: "Drew",
    profile_picture: null,
    senders_tag: "@Drew",
    amount: "₦ 500,000",
    reciever_tag: "@Drew", 
    payment_method: "Wallet",
    api: "Paystack",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    transaction_no: "#00000008",
    first_name: "Solana",
    last_name: "Solana",
    profile_picture: null,
    senders_tag: "@Solana",
    amount: "₦ 500,000",
    reciever_tag: "@Solana", 
    payment_method: "Wallet",
    api: "Paystack",
    date_created: "May 27, 2020",
    time: "12:38pm"
  }
]