import React, { useState, useEffect, useCallback } from 'react';
import Input from '../../../components/Input/Input';
import { useAuthState } from '../../../store/modules/auth';
import CustomButton from '../../../components/Button/CustomButton';
import PropTypes from 'prop-types';
import Dropdown from '../../../components/DropDown/DropDown';
import useAuth from '../../../hooks/fetch/useAuth';
import { useDispatch } from 'react-redux';
import { getAllRoles } from '../../../store/modules/admin/actions';
import { useRolesState } from '../../../store/modules/admin';
import useAdmin from 'hooks/fetch/useAdmin';

const InviteAdmin = ({ backClick, type }) => {
  //Local State
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
  });
  const [entries, setEntries] = useState(null);
  const { handleGetAllAdmin } = useAdmin()

  //Redux hooks
  const roleState = useRolesState();
  const authState = useAuthState();
  const { invite_admin, edit_admin } = useAuth();
  let dispatch = useDispatch();

  //Custom Hooks
  const { loading } = authState;

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  //Handler function
  const roleDropDownList = roleState?.roleList?.roles?.map((list, index) => {
    return {
      id: index,
      value: list?.name,
      label: list?.name,
    };
  });
  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData],
  );
  const handleCallback = () => {
    backClick
    handleGetAllAdmin({per_page: 10, page: 1})
  }
  const makeRequest = (e) => {
    e.preventDefault();
    type === 'Add'
    ? 
    invite_admin(formData, handleCallback)
    :
    edit_admin(formData, roleState.selectedAdmin.id, () => handleGetAllAdmin({per_page: 10, page: 1}), backClick)
  };

  useEffect(() => {
    if (type === 'Edit' && roleState?.selectedAdmin) {
      setFormData({
        first_name: roleState.selectedAdmin.first_name ?? '',
        last_name: roleState.selectedAdmin.last_name ?? '',
        email: roleState.selectedAdmin.email ?? '',
        role: roleState.selectedAdmin.role ?? '',
      });
      setEntries({
        id: roleState.selectedAdmin.role,
        value: roleState.selectedAdmin.role,
        label: roleState.selectedAdmin.role,
      });
    }
  }, [roleState?.selectedAdmin]);

  return (
    <section className="mt-6">
      <form onSubmit={(e) => makeRequest(e)}>
        <div className="mb-6">
          <Input
            label="First Name"
            type="text"
            placeholder="Enter First Name"
            id="first_name"
            name="first_name"
            readOnly={loading}
            value={formData.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <Input
            label="Last Name"
            type="text"
            placeholder="Enter Last Name"
            id="last_name"
            name="last_name"
            readOnly={loading}
            value={formData.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <Input
            label="Email Address"
            type="text"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            readOnly={type === 'Edit'}
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="mb-6">
          <Dropdown
            variant=" w-full"
            placeholder="Select Role"
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            value={entries}
            type="input"
            inputLabel="Role"
            inputStyles="border border-gray-600 text-14 rounded-lg font-dmsans_r"
            optionContainerVariant='max-h-36 w-full scroll-styled top-[72px]'
            inputPlaceholder="Select Role"
            inputName="role"
            inputId="role"
            options={roleDropDownList}
            onChange={(data) => {
              setEntries(data);
              setFormData({ ...formData, role: data.value });
            }}
          />
        </div>
        <div className="flex justify-end items-center space-x-6 mt-10">
          <p className="text-purple font-dmsans_r cursor-pointer" onClick={backClick}>
            Back
          </p>
          <CustomButton
            name="Save"
            disabled={loading}
            loading={loading}
            variant="border border-purple bg-purple text-white rounded font-dmsans_r"
          />
        </div>
      </form>
    </section>
  );
};

export default InviteAdmin;
InviteAdmin.propTypes = {
  backClick: PropTypes.any,
  type: PropTypes.string,
};
