/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function PaymentIcon ({color}) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4641 4.30627H4.30896C4.30896 4.30627 0 7.9987 0 12.3077C0 14.7687 1.85295 16.0001 3.07759 16.0001H11.6955C12.9252 16.0001 14.7731 14.7687 14.7731 12.3077C14.7731 7.9987 10.4641 4.30627 10.4641 4.30627ZM8.00139 12.8602V13.8456L11.9802 13.7311V12.749C11.0739 12.5806 10.5046 12.0213 10.4641 11.2094L10.0228 10.9492C10.075 11.3754 6.80371 12.0247 7.42529 12.0247C7.99802 12.0247 8.40399 11.7467 8.40399 11.3509C8.40399 11.014 8.1412 10.8219 7.49435 10.6771L6.80876 10.5305C5.84859 10.3301 5.37693 9.82976 5.37693 9.02625C5.37693 8.22274 5.91261 7.64327 6.76496 7.45293V6.46075H7.99465L7.74871 5.47457C7.49604 5.05344 4.68966 6.05909 4.71661 6.82217L8.37872 8.97066C8.3265 8.55459 5.24891 6.2326 4.71661 6.2326C4.18431 6.2326 4.30896 8.18999 4.30896 8.5909C4.30896 8.91601 6.74138 9.43895 7.35959 9.57708L7.99465 9.71184C9.0542 9.9342 3.45323 7.26183 3.45323 8.08555C3.45323 8.96654 8.94471 12.6799 8.00139 12.8602Z"
        fill={color}
      />
      <path
        d="M10.4643 0.612227H8.76299C8.58925 0.419507 8.37697 0.265426 8.13989 0.159961C7.90282 0.0544967 7.64623 0 7.38676 0C7.12728 0 6.8707 0.0544967 6.63362 0.159961C6.39654 0.265426 6.18426 0.419507 6.01052 0.612227H4.30917C4.20894 0.612661 4.11032 0.637526 4.02187 0.684668C3.93342 0.731809 3.85779 0.799806 3.80154 0.882767C3.7453 0.965729 3.71012 1.06115 3.69907 1.16077C3.68802 1.26039 3.70142 1.36121 3.73812 1.45448L4.30917 3.07329H10.4643L11.0354 1.45448C11.0721 1.36121 11.0855 1.26039 11.0744 1.16077C11.0634 1.06115 11.0282 0.965729 10.972 0.882767C10.9157 0.799806 10.8401 0.731809 10.7516 0.684668C10.6632 0.637526 10.5646 0.612661 10.4643 0.612227Z"
        fill="#C8C8C8"
      />
    </svg>
  );
}
PaymentIcon.propTypes = {
  color: PropTypes.string
}
