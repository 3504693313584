import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import LoadingSkeleton from 'components/Skeleton/Skeleton';
import SiteSettingsEditIcon from 'assets/icons/SiteSettingsEditIcon';
import EditExternalTransfer from './EditExternalTransfer';
import EditWalletFunding from './EditWalletFunding';
import EditNairaCardFunding from './EditNairaCardFunding';
import EditDollarCardFunding from './EditDollarCardFunding';
import DashboardCard from 'components/Card/DashboardCard';
import usePermissions from 'hooks/usePermissions';
import EditElectricityFeeFunding from './EditElectricityFeeFunding';
import EditDataFeeFunding from './EditDataFeeFunding';
import EditEducationFeeFunding from './EditEducationFeeFunding';
import EditVouchersFeeFunding from './EditVouchersFeeFunding';
import EditInternetFeeFunding from './EditInternetFeeFunding';
import EditAirtimeFeeFunding from './EditAirtimeFeeFunding';
import EditBettingWalletFeeFunding from './EditBettingWalletFeeFunding';
import EditCableTvFeeFunding from './EditCableTvFeeFunding';

export default function CommissionFee({ p2p, external, wallet, nairaCard, dollarCard, electricityAmount,
  vouchersAmount,
  internetAmount,
  educationAmount,
  dataAmount,
  cableAmount,
  airtimeAmount,
  betAmount,
  loading }) {
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState('');
  const { userIsPermittedTo } = usePermissions();

  const closeModal = useCallback(() => {
    setType('');
    setShowModal(false);
  }, []);
  
  const setTypeProxy = useCallback((step) => {
    setType(step);
    setShowModal(true);
  }, []);

  return (
    <>
      <div className='mt-6'>
        <DashboardCard>
          <p className="text-18 font-dmsans_m mb-10">Commission Fee</p>
          <div className="ml-6">
            <div className="mt-[50px] flex flex-col space-y-[50px]">
              {loading ? (
                <LoadingSkeleton count={6} />
              ) : (
                <>
                  <div className="text-black-transparent text-16 font-dmsans_m grid grid-cols-4">
                    <p>P-2-P transfer</p>
                    <p>₦ {p2p}</p>
                  </div>
                  <div className="text-black-transparent text-16 font-dmsans_m grid grid-cols-4">
                    <p>External transfer</p>
                    <p>₦ {external}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('external')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Wallet Funding</p>
                    <p>{wallet} %</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('wallet')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent text-16 font-dmsans_m grid grid-cols-4">
                    <p>Naira Card Funding</p>
                    <p>{nairaCard} %</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('naira')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent text-16 font-dmsans_m grid grid-cols-4">
                    <p>Dollar Card Funding</p>
                    <p>{dollarCard} %</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('dollar')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Electricity Fee</p>
                    <p>₦ {electricityAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('electricity')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Cable TV Fee</p>
                    <p>₦ {cableAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('cable')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Internet Fee</p>
                    <p>₦ {internetAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('internet')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Vouchers Fee</p>
                    <p>₦ {vouchersAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('voucher')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Education Fee</p>
                    <p>₦ {educationAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('education')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Data Fee</p>
                    <p>₦ {dataAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('data')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Airtime Fee</p>
                    <p>₦ {airtimeAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('airtime')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className="text-black-transparent font-dmsans_m text-16 grid grid-cols-4">
                    <p>Bet Wallet Fee</p>
                    <p>₦ {betAmount}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setTypeProxy('bet')}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </DashboardCard>
      </div>
      {showModal && (
        <Modal closeModal={closeModal} title="Edit commission fee" containerVariant="min-w-[559px]">
          <>
            {type === 'external' && <EditExternalTransfer cancel={closeModal} external={external} />}
            {type === 'wallet' && <EditWalletFunding cancel={closeModal} wallet={wallet} />}
            {type === 'naira' && <EditNairaCardFunding cancel={closeModal} nairaCard={nairaCard} />}
            {type === 'dollar' && <EditDollarCardFunding cancel={closeModal} dollarCard={dollarCard} />}
            {type === 'electricity' && <EditElectricityFeeFunding cancel={closeModal} amount={electricityAmount} />}
            {type === 'data' && <EditDataFeeFunding cancel={closeModal} amount={dataAmount} />}
            {type === 'voucher' && <EditVouchersFeeFunding cancel={closeModal} amount={vouchersAmount} />}
            {type === 'internet' && <EditInternetFeeFunding cancel={closeModal} amount={internetAmount} />}
            {type === 'airtime' && <EditAirtimeFeeFunding cancel={closeModal} amount={airtimeAmount} />}
            {type === 'education' && <EditEducationFeeFunding cancel={closeModal} amount={educationAmount} />}
            {type === 'bet' && <EditBettingWalletFeeFunding cancel={closeModal} amount={betAmount} />}
            {type === 'cable' && <EditCableTvFeeFunding cancel={closeModal} amount={cableAmount} />}
          </>
        </Modal>
      )}
    </>
  );
}

CommissionFee.propTypes = {
  p2p: PropTypes.string,
  external: PropTypes.string,
  wallet: PropTypes.string,
  nairaCard: PropTypes.string,
  dollarCard: PropTypes.string,
  electricityAmount: PropTypes.string,
  vouchersAmount: PropTypes.string,
  internetAmount: PropTypes.string,
  educationAmount: PropTypes.string,
  dataAmount: PropTypes.string,
  cableAmount: PropTypes.string,
  airtimeAmount: PropTypes.string,
  betAmount: PropTypes.string,
  loading: PropTypes.bool,
};
