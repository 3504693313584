/* eslint-disable max-len */
import React from 'react';

export const CableTv2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#F9A000" />
      <g clipPath="url(#clip0_1724_33992)">
        <path
          d="M27.8671 14.1172C27.6083 13.923 27.241 13.9755 27.0468 14.2344L23.9366 18.3814L20.8987 15.3435C20.6699 15.1147 20.2989 15.1147 20.07 15.3435C19.8412 15.5723 19.8412 15.9433 20.07 16.1721L23.5856 19.6877C23.6959 19.798 23.8451 19.8594 23.9999 19.8594C24.0137 19.8594 24.0276 19.8589 24.0415 19.8579C24.2109 19.8458 24.3668 19.7609 24.4687 19.625L27.9843 14.9375C28.1785 14.6786 28.126 14.3114 27.8671 14.1172ZM19.5745 30.5461C19.2851 30.4014 18.9331 30.5187 18.7884 30.8081L17.6166 33.1519C17.4718 33.4413 17.5892 33.7932 17.8786 33.938C17.9627 33.9801 18.0521 34 18.1401 34C18.3551 34 18.562 33.8813 18.6647 33.676L19.8365 31.3322C19.9813 31.0428 19.864 30.6908 19.5745 30.5461ZM30.4224 33.1519L29.2506 30.8082C29.1059 30.5187 28.7539 30.4014 28.4645 30.5462C28.175 30.6909 28.0577 31.0428 28.2024 31.3323L29.3743 33.676C29.477 33.8814 29.6839 34 29.8988 34C29.9869 34 30.0763 33.9801 30.1604 33.938C30.4498 33.7933 30.5672 33.4413 30.4224 33.1519Z"
          fill="white"
        />
        <path
          d="M32.2422 18.6875H15.7579C14.7887 18.6875 14.0001 19.476 14.0001 20.4453V29.8983C14.0001 30.8676 14.7887 31.6561 15.7579 31.6561H32.2422C33.2114 31.6561 34 30.8676 34 29.8983V20.4453C34 19.476 33.2115 18.6875 32.2422 18.6875Z"
          fill="white"
        />
        <path
          d="M22.6129 23.1133L26.2801 24.579C26.3995 24.6268 26.4778 24.7424 26.4778 24.871C26.4778 24.9995 26.3995 25.1152 26.2801 25.1629L22.6129 26.6287C22.5753 26.6437 22.5357 26.6511 22.4963 26.6511C22.4343 26.6511 22.3727 26.6329 22.32 26.5971C22.2336 26.5386 22.1819 26.4411 22.1819 26.3368V23.4052C22.1819 23.3009 22.2336 23.2034 22.32 23.1449C22.4064 23.0864 22.5161 23.0746 22.6129 23.1133Z"
          fill="#FF6414"
        />
      </g>
      <path
        d="M44.0386 34.8763C44.6211 35.1924 45.3525 34.9777 45.6391 34.3802C47.283 30.9532 48.092 27.1792 47.9917 23.3682C47.8808 19.1568 46.6633 15.0488 44.4615 11.457C42.2598 7.86529 39.1514 4.91638 35.4487 2.90671C32.0982 1.08815 28.3679 0.0966743 24.5681 0.00672065C23.9056 -0.0089644 23.3824 0.545389 23.3998 1.2079C23.4173 1.87041 23.9688 2.38986 24.6313 2.40922C28.0096 2.50799 31.3236 3.39849 34.3038 5.01604C37.6362 6.82474 40.4338 9.47876 42.4154 12.7113C44.3969 15.9439 45.4927 19.6411 45.5925 23.4314C45.6818 26.8211 44.9717 30.1784 43.5268 33.2338C43.2435 33.8329 43.4562 34.5601 44.0386 34.8763Z"
        fill="#F9A000"
      />
      <defs>
        <clipPath id="clip0_1724_33992">
          <rect width="20" height="20" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};
