import React from "react";
import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { LeftAuth } from "../../components/Onboarding/LeftAuth";

const ForgotPassword = () => {
    return (
      <AuthLayout 
        left={<LeftAuth/>}
        right={<ForgotPasswordForm/>}
      />
    );
  }
  
  export default ForgotPassword;
  