import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ id, name, placeholder, label, value, onChange, readOnly, rows }) => {

  return (
    <>
      <label htmlFor={id} className="text-grey mb-2 block text-14">
        {label}
      </label>
      <textarea
        id={`textarea${name}`}
        name={name}
        rows={rows}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={`test-${id}`}
        aria-labelledby={id}
        readOnly={readOnly}
        value={value}
        className={`p-16 text-14 w-full outline-0 font-dmsans_r font-normal
        rounded-lg focus:border-purple focus:border bg-green_light`}
      />
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  styles: PropTypes.string,
};
