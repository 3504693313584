/* eslint-disable max-len */
import React from 'react';

export default function SuccessIcon() {
  return (
    <svg
      width="239"
      height="212"
      viewBox="0 0 239 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="success-icon"
    >
      <path
        d="M173.484 39.1067C177.789 39.519 186.792 38.1525 188.371 29.3881C189.949 20.6236 194.17 18.1969 196.083 18.0791"
        stroke="#FF6414"
        strokeWidth="1.79502"
        strokeLinecap="round"
      />
      <circle cx="117.74" cy="99.4352" r="70.8099" fill="#53B175" />
      <g filter="url(#filter0_d_10675_36337)">
        <circle cx="117.74" cy="99.435" r="61.3156" stroke="white" strokeOpacity="0.7" strokeWidth="1.19668" />
      </g>
      <path
        d="M146.539 83.8996C146.539 85.8159 145.815 87.6661 144.497 88.9877L113.872 119.78C112.488 121.102 110.644 121.895 108.734 121.895C106.824 121.895 104.98 121.102 103.663 119.78L88.317 104.384C86.9998 103.062 86.2753 101.212 86.2753 99.2959C86.2753 97.3797 87.0657 95.5955 88.3829 94.2079C89.766 92.8863 91.5443 92.1594 93.4543 92.0934C95.3643 92.0934 97.1426 92.8202 98.5257 94.1418L108.734 104.384L134.289 78.7455C135.672 77.4239 137.45 76.697 139.36 76.697C141.27 76.7631 143.048 77.49 144.431 78.8776C145.749 80.1992 146.539 81.9833 146.539 83.8996Z"
        fill="white"
      />
      <path
        d="M172.816 172.082C178.973 173.965 190.877 181.122 189.235 194.681"
        stroke="#FFE400"
        strokeWidth="1.79502"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.73235 144.181C8.90277 146.931 24.3611 148.904 28.8311 134.791M28.8311 134.791C29.483 130.902 28.8312 123.21 21.0089 123.552C19.5189 127.298 18.9973 134.791 28.8311 134.791ZM28.8311 134.791C32.0904 135.407 39.6706 134.819 43.917 127.535"
        stroke="#5034C4"
        strokeWidth="1.79502"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="139.63" cy="205.22" r="6.77967" fill="#637BFE" />
      <circle cx="231.488" cy="97.0209" r="6.4805" stroke="#5034C4" strokeWidth="0.598339" />
      <circle cx="83.8418" cy="6.77967" r="6.77967" fill="#53B175" />
      <circle cx="116.872" cy="15.4065" r="3.76649" fill="#F3603F" />
      <circle cx="29.8191" cy="73.4782" r="6.4805" stroke="#D8F042" strokeWidth="0.598339" />
      <circle cx="62.7494" cy="188.324" r="11.7536" stroke="#FF5FCD" strokeWidth="0.598339" />
      <circle cx="3.01319" cy="3.01319" r="3.01319" transform="matrix(-1 0 0 1 122.075 193.8)" fill="#53B175" />
      <circle cx="3.01319" cy="3.01319" r="3.01319" transform="matrix(-1 0 0 1 216.919 82.8627)" fill="#FFE400" />
      <defs>
        <filter
          id="filter0_d_10675_36337"
          x="53.4323"
          y="36.9228"
          width="128.615"
          height="128.615"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.79502" />
          <feGaussianBlur stdDeviation="1.19668" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10675_36337" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10675_36337" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
