/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import TierIcon from '../../../assets/icons/TierIcon';
import { nav_links } from '../../../constants/navLinks';
import TransactionHistory from './TransactionHistory';
import Profile from './Profile';
import AccountInformation from './AccountInformation';
import ProductHistory from './ProductHistory';
import Kyc from './Kyc';
import ReferAndEarn from './ReferAndEarn';
import Danger from 'assets/icons/Danger';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import usePermissions from 'hooks/usePermissions';
import { useLocation, useParams } from 'react-router-dom';
import NewTransactionHistory from './NewTransactionHistory';

const General = () => {
  //Local State
  const [selectedTab, setSelectedTab] = useState(0);
  //Hooks
  const { selectedCustomer } = useCustomerState();
  const { userIsPermittedTo } = usePermissions();
  const customerState = useCustomerState();
  const { upgradeTierAccount, handleViewCustomer } = useCustomer();
  const { id } = useParams();
  const location = useLocation();
  useEffect(() => {
    handleViewCustomer(id);
    return () => handleViewCustomer(id);
  }, [id]);
  const [toggleTransactionTable, setToggleTransactionTable] = useState('expand');
  const handleToggleTransactionTable = () => {
    if (toggleTransactionTable === 'expand') {
      setToggleTransactionTable('collapse');
    } else {
      setToggleTransactionTable('expand');
    }
  };
  const handleLimit = (tierLevel) => {
    switch (tierLevel) {
      case 0:
        return '0,000';
      case 1:
        return '50,000';
      case 2:
        return '500,000';
      case 3:
        return '1,000,000';
    }
  };
  //Variable
  const TabsHeader = nav_links.filter((link) => {
    if (link.title === 'KYC') {
      return userIsPermittedTo('Update', 'customer_management');
    }
    return true;
  });

  const kycStatus = [
    !customerState?.selectedCustomer?.is_uploaded_identity_card ? 'pending' : 'verified',
    customerState?.selectedCustomer?.tier_levels < 2 ? 'pending' : 'verified',
    customerState?.selectedCustomer?.utility_bill === null ? 'pending' : 'verified',
  ];
  const pendingState = () => kycStatus.filter((item) => item === 'pending').length;

  //Handler Function
  const upgradeTierLevel = useCallback(() => {
    upgradeTierAccount(customerState.selectedCustomer.user_id, 'upgrade', () =>
      handleViewCustomer(customerState?.selectedCustomer?.user_id),
    );
  }, []);

  return (
    <section className="flex space-x-5 mt-[30px]">
      {selectedCustomer?.user_id && (
        <>
          <div
            className={`${toggleTransactionTable !== 'expand' ? 'w-0 opacity-0' : 'w-1/3 opacity-100'} transition-all`}
          >
            <div className="p-5 bg-white shadow-cardShadow rounded-[10px]">
              <ul className="flex flex-col font-dmsans_m gap-2">
                {TabsHeader.map((item, index) => (
                  <li
                    data-testid="test-tab"
                    className={`cursor-pointer py-2.5 relative ${selectedTab === index ? 'active text-purple' : 'text-dark_blue'
                      } w-full`}
                    key={item.id}
                  >
                    <button
                      type="button"
                      onClick={() => setSelectedTab(index)}
                      className="absolute inset-0 h-full w-full"
                    ></button>
                    <div className={`${selectedTab === index ? 'text-purple' : 'text-grey-600'} flex justify-between`}>
                      <div className="flex items-center space-x-6">
                        <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full">
                          <div className={`${selectedTab === index ? 'text-purple' : 'fill-current stroke-current'}`}>
                            {item.icon}
                          </div>
                        </div>
                        <p className="text-14">{item.title}</p>
                      </div>
                      {!customerState?.selectedCustomer?.is_completed_kyc && item.title === 'KYC' && (
                        <div className="text-12 text-orange-700 bg-orange-50 py-2 px-4 rounded-xl font-dmsans_m inline-flex items-center">
                          <Danger /> <p className="ml-2"> {pendingState()} pending</p>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-5 mt-4 bg-white shadow-cardShadow rounded-[10px]">
              <div className="flex items-center space-x-6">
                <div className="bg-purple_transparent w-10 h-10 flex items-center justify-center rounded-full">
                  <TierIcon />
                </div>
                <div className="font-dmsans_m">
                  <p className="text-dark_blue">{`Tier ${customerState?.selectedCustomer?.tier_levels} Account`}</p>
                  <p className="text-grey text-14">
                    Limit is ₦{handleLimit(customerState?.selectedCustomer?.tier_levels)} per day
                  </p>
                </div>
              </div>
              {customerState?.selectedCustomer?.tier_levels !== 3 && (
                <p className="text-14 text-purple font-dmsans_m ml-6 mt-5 cursor-pointer" onClick={upgradeTierLevel}>
                  Upgrade Account Tier
                </p>
              )}
            </div>
          </div>
          <div
            className={`bg-white shadow-cardShadow rounded-[10px] transition-all
              ${toggleTransactionTable !== 'expand' ? 'w-full' : 'w-2/3'}
            `}
          >
            {selectedTab === 0 && <Profile />}
            {selectedTab === 1 && (
              <>
                {toggleTransactionTable === 'collapse' ? (
                  <NewTransactionHistory
                    toggleTransactionTable={toggleTransactionTable}
                    handleToggleTransactionTable={handleToggleTransactionTable}
                    viewingCustomerProfile={location?.pathname === `/manage-customer/${id}`}
                  />
                ) : (
                  <TransactionHistory
                    toggleTransactionTable={toggleTransactionTable}
                    handleToggleTransactionTable={handleToggleTransactionTable}
                    viewingCustomerProfile={location?.pathname === `/manage-customer/${id}`}
                  />
                )}
              </>
            )}
            {selectedTab === 2 && <AccountInformation />}
            {selectedTab === 3 && <ProductHistory />}
            {selectedTab === 4 && <Kyc />}
            {selectedTab === 5 && <ReferAndEarn />}
          </div>
        </>
      )}
      {customerState?.loading === false && customerState?.selectedCustomer === undefined && <>USER NOT FOUND</>}
    </section>
  );
};

export default General;
