import { useState } from "react"

export default function useInputValidate() {
    const [error, setError] = useState('')
    const passwordLength = 6
    const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
    const regexPassword = /^(?=.*[a-zA-Z])(?=.*[\d!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/;
    const regexLetter = /^[a-zA-Z]+$/ // alphabet (just a word)

    const checkPassword = ({name, value}) => {
        if (value.length < passwordLength) {
            setError(`Password must be ${passwordLength} or more characters`)
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false   
        }
        if (value.length < passwordLength && !regexPassword.test(value)) {
            setError(`Password must be ${passwordLength} or more characters and must contain alphabets & numbers`)
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }
        if (!regexPassword.test(value)) {
            setError('Password must contain at least 1 number or a special character and alphabets.')
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        setError('')
        document.getElementById(name).style.border = "1px solid #F4F4FB" 
        return true
    }

    const checkEmail = ({name, value}) => {
        if (!regexEmail.test(value)) {
            setError('Please enter a valid email')
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        setError('')
        document.getElementById(name).style.border = "1px solid #F4F4FB" 
        return true
    }

    const checkName = ({name, value}) => {
        if (value.length < 2) {
            setError("Name must be more than 1 character")
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        if (!regexLetter.test(value)) {
            setError("Name must contain only alphabets and cannot have spaces")
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        setError('')
        document.getElementById(name).style.border = "1px solid #F4F4FB" 
        return true
    }

    const checkConfirmPassword = ({ name, value }) => {
        if (value.length === 0) {
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        if (document.getElementById(name).value !== document.getElementById('password').value) {
            setError('Passwords do not match!')
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        setError('')
        document.getElementById(name).style.border = "1px solid #F4F4FB" 
        return true
    }
    const checkConfirmNewPassword = ({ name, value }) => {
        if (value.length === 0) {
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        if (document.getElementById(name).value !== document.getElementById('new_password').value) {
            setError('Passwords do not match!')
            document.getElementById(name).style.border = "1px solid #FF6414" 
            return false
        }

        setError('')
        document.getElementById(name).style.border = "1px solid #F4F4FB" 
        return true
    }

    const validate = ({ name, value, length }) => {
        switch(name) {
            case 'password':
            case 'new_password':
            case 'old_password':
                return checkPassword({name, value, length})
            case 'first_name':
            case 'last_name':
                return checkName({name, value})
            case 'email_address':
            case 'email': 
                return checkEmail({name, value})
            case 'confirm_password':
                return checkConfirmPassword({name, value})
            case 'confirm_new_password':
                return checkConfirmNewPassword({name, value})
            default:
                setError('')
                document.getElementById(name).style.border = "1px solid #F4F4FB" 
                return true
        }
    }

    return { error, setError, validate }
}