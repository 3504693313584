import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/axios-instance'
import { admin } from '../../../services/endpoints/admin'

const getScheduledPayment = createAsyncThunk('scheduledPayment/GetScheduledPayment', async(params) => {
    try {
        const response = await api.get(admin.get_scheduled_payment, {params})
        // return response.data ? response.data : {}
        if (response.data) {
            return response.data
          }
          return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

export {
  getScheduledPayment,
}