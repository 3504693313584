/* eslint-disable max-len */
import React from 'react';

const FilterIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77349 9.59851C6.79149 9.61451 6.80849 9.62951 6.82549 9.64751C7.90449 10.7535 8.49949 12.2185 8.49949 13.7735V17.7575L10.7355 16.5395C10.9115 16.4435 11.0205 16.2555 11.0205 16.0485V13.7615C11.0205 12.2125 11.6095 10.7525 12.6785 9.65251L17.5155 4.50751C17.8285 4.17451 18.0005 3.73751 18.0005 3.27651V2.34051C18.0005 1.87651 17.6345 1.49951 17.1865 1.49951H2.31549C1.86649 1.49951 1.50049 1.87651 1.50049 2.34051V3.27651C1.50049 3.73751 1.67249 4.17451 1.98549 4.50651L6.77349 9.59851ZM8.14649 19.5005C7.94449 19.5005 7.74449 19.4465 7.56249 19.3385C7.21049 19.1285 6.99949 18.7575 6.99949 18.3455V13.7735C6.99949 12.6385 6.57649 11.5695 5.80549 10.7505C5.78249 10.7315 5.75949 10.7105 5.73949 10.6885L0.893488 5.53551C0.317488 4.92351 0.000488281 4.12051 0.000488281 3.27651V2.34051C0.000488281 1.04951 1.03949 -0.000488281 2.31549 -0.000488281H17.1865C18.4615 -0.000488281 19.5005 1.04951 19.5005 2.34051V3.27651C19.5005 4.11951 19.1835 4.92151 18.6095 5.53451L13.7625 10.6885C12.9595 11.5165 12.5205 12.6055 12.5205 13.7615V16.0485C12.5205 16.8045 12.1115 17.4965 11.4535 17.8565L8.69249 19.3605C8.52049 19.4535 8.33349 19.5005 8.14649 19.5005Z"
        fill="#23273B"
      />
    </svg>
  );
};

export default FilterIcon;
