import React from 'react';
import SuccessIcon from 'assets/icons/sucessIcon';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import useCustomer from 'hooks/fetch/useCustomer';
import { useSearchParams } from 'react-router-dom';
import { useCustomerState } from 'store/modules/customer';

export default function CustomerSuccessPage() {
  const [params] = useSearchParams();
  const customer_id = params.get('customer_id');
  const type = params.get('type');
  const { loading } = useCustomerState();
  const { handleViewCustomer } = useCustomer();

  return (
    <section className="w-full md:px-40 md:py-48 flex flex-col justify-center items-center h-screen">
      <div className="w-full max-w-md mx-auto mt-0  flex flex-col justify-center items-center">
        <div className="lg:flex mb-48">
          <SuccessIcon />
        </div>

        <div className="text-center">
          <Header title="Completed" description={`Wallet has been successfully ${type}ed.`} />
        </div>
        <Button
          disabled={loading}
          loading={loading}
          name="Continue"
          theme="dark"
          styles="mt-0 w-full"
          // onClick={() => handleViewCustomer(customer_id)}
          onClick={() => {
            window.open(`/manage-customer/${customer_id}`,'_blank')
          }}
        />
      </div>
    </section>
  );
}
