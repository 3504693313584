import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PropTypes from 'prop-types';

const LoadingSkeleton = ({count}) => {
  return (
    <>
    <Skeleton count={count} />
    </>
  )
}

export default LoadingSkeleton

LoadingSkeleton.propTypes = {
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };