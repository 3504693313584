/* eslint-disable max-len */
import React from 'react';

const Danger = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6768_11373"
        // style={mask-type:luminance}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="11"
        height="10"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5H10.0069V9.59275H0V0.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6768_11373)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.00638 1.25C4.76838 1.25 4.55638 1.373 4.43738 1.5795L0.838385 7.862C0.721385 8.067 0.722385 8.3115 0.840885 8.516C0.959385 8.7205 1.17138 8.843 1.40788 8.843H8.59938C8.83538 8.843 9.04738 8.7205 9.16588 8.516C9.28488 8.3115 9.28588 8.067 9.16788 7.862L5.57538 1.5795C5.45688 1.373 5.24488 1.25 5.00638 1.25ZM8.59938 9.593H1.40788C0.900885 9.593 0.446385 9.331 0.191885 8.892C-0.0626154 8.4535 -0.0641154 7.929 0.187385 7.4895L3.78738 1.2065C4.04038 0.764 4.49588 0.5 5.00638 0.5H5.00688C5.51688 0.5 5.97338 0.7645 6.22638 1.2075L9.81938 7.4895C10.0709 7.929 10.0694 8.4535 9.81488 8.892C9.56038 9.331 9.10588 9.593 8.59938 9.593Z"
          fill="#F79009"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00195 6.08198C4.79495 6.08198 4.62695 5.91398 4.62695 5.70698V4.15698C4.62695 3.94998 4.79495 3.78198 5.00195 3.78198C5.20895 3.78198 5.37695 3.94998 5.37695 4.15698V5.70698C5.37695 5.91398 5.20895 6.08198 5.00195 6.08198Z"
        fill="#F79009"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00299 7.74951C4.72649 7.74951 4.50049 7.52601 4.50049 7.24951C4.50049 6.97301 4.72199 6.74951 4.99799 6.74951H5.00299C5.27949 6.74951 5.50299 6.97301 5.50299 7.24951C5.50299 7.52601 5.27949 7.74951 5.00299 7.74951Z"
        fill="#F79009"
      />
    </svg>
  );
};

export default Danger;
