/* eslint-disable max-len */
import React from 'react';

const SingleFileIcon = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42" cy="42" r="42" fill="#E9E6FD" />
      <g clipPath="url(#clip0_3050_41097)">
        <path
          d="M58 62H26C25.4696 62 24.9609 61.7893 24.5858 61.4142C24.2107 61.0391 24 60.5304 24 60V24C24 23.4696 24.2107 22.9609 24.5858 22.5858C24.9609 22.2107 25.4696 22 26 22H58C58.5304 22 59.0391 22.2107 59.4142 22.5858C59.7893 22.9609 60 23.4696 60 24V60C60 60.5304 59.7893 61.0391 59.4142 61.4142C59.0391 61.7893 58.5304 62 58 62ZM34 32V36H50V32H34ZM34 40V44H50V40H34ZM34 48V52H44V48H34Z"
          fill="#5034C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3050_41097">
          <rect width="48" height="48" fill="white" transform="translate(18 18)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SingleFileIcon;
