import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import Modal from 'components/Modal/Modal';
import LoadingSkeleton from 'components/Skeleton/Skeleton';
import SiteSettingsEditIcon from 'assets/icons/SiteSettingsEditIcon';
import EditDollarVirtualCard from './EditDollarVirtualCard';
import EditNairaVirtualCard from './EditNairaVirtualCard';
import DashboardCard from 'components/Card/DashboardCard';
import usePermissions from 'hooks/usePermissions';

export default function VirtualCard ({nairaFee, dollarFee, loading}) {
  //local state
  const [showModal, setShowModal] = useState(false)
  const [currency, setCurrency] = useState('')

  //Handler function
  const closeModal = useCallback(() => {
      setShowModal(false)
  },[])
  const setCurrencyProxy = useCallback((step) => {
    setCurrency(step)
    setShowModal(true)
  },[])
  //Hooks
  const { userIsPermittedTo } = usePermissions();


  return (
    <>
    <div className='mt-6'>
      <DashboardCard>
        <p className="text-18 font-dmsans_m mb-10">Virtual Card Creation Fee</p>
        <div className="ml-6">
        <div className="mt-[50px] flex flex-col space-y-[50px]">
        {
            loading ? <LoadingSkeleton count={2}/> :
            <>
            <div className="grid grid-cols-4">
            <p className="text-black-transparent text-16 font-dmsans_m">Pouchers Creation Fee (Naira)</p>
            <p className="text-black-transparent text-16 font-dmsans_r">₦ {nairaFee}</p>
          {userIsPermittedTo('Update', 'site_settings_management') && (
            
            <div className="text-black-transparent text-16 font-dmsans_r" data-testid='edit' onClick={() => setCurrencyProxy('naira')}>
              <SiteSettingsEditIcon/>
            </div>
          )}
            </div>
            <div className="grid grid-cols-4">
            <p className="text-black-transparent text-16 font-dmsans_m">Pouchers Creation Fee (Dollar)</p>
            <p className="text-black-transparent text-16 font-dmsans_m">$ {dollarFee}</p>
          {userIsPermittedTo('Update', 'site_settings_management') && (

            <div className="text-black-transparent text-16 font-dmsans_r" data-testid='edit' onClick={() => setCurrencyProxy('dollar')}>
              <SiteSettingsEditIcon/>
            </div>
          )}
            </div>
            </>
        }
        </div>
        </div>
      </DashboardCard>
      </div>
    {
      showModal &&
      <Modal
        closeModal={closeModal}
        title="Edit virtual card creation fee"
        containerVariant="min-w-[559px]"
      >
        <>
        {currency === 'naira' && <EditNairaVirtualCard cancel={closeModal} nairaFee={nairaFee} />}
        {currency === 'dollar' && <EditDollarVirtualCard cancel={closeModal} dollarFee={dollarFee} />}
        </>
      </Modal>
    }
    </>
  );
}

VirtualCard.propTypes = {
  nairaFee: PropTypes.string,
  dollarFee: PropTypes.string,
  loading: PropTypes.bool
}
