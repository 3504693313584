/* eslint-disable max-len */
import React from 'react';

const SendIcon2 = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="42" cy="42" r="42" fill="white" />
    <g clipPath="url(#clip0_3050_41089)">
      <path
        d="M21.8922 36.6301C20.8482 36.2821 20.8382 35.7201 21.9122 35.3621L60.0862 22.6381C61.1442 22.2861 61.7502 22.8781 61.4542 23.9141L50.5462 62.0861C50.2462 63.1441 49.6362 63.1801 49.1882 62.1761L42.0002 46.0001L54.0002 30.0001L38.0002 42.0001L21.8922 36.6301Z"
        fill="#5034C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_3050_41089">
        <rect width="48" height="48" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
  );
};

export default SendIcon2;
