/* eslint-disable max-len */
import CustomButton from 'components/Button/CustomButton';
import React, { useEffect, useState } from 'react';
import { useCustomerState } from 'store/modules/customer';
import ExportExcel from 'components/ExportExcel';
import Title from '../../../components/Header/Title';
import Tabs from '../../../components/Tabs/Tabs';
import CustomerList from './CustomerList';

const ManageCustomers = () => {
  //local state
  const [selectedTab, setSelectedTab] = useState('all');
  const { customerList } = useCustomerState();

  //Variables
  let TabsHeader = ['all', 'active', 'inactive', 'deactivated'];

  useEffect(() => {
    document.title = 'Manage Customers - Pouchers Admin';
  }, []);

  return (
    <div>
      <div className="flex justify-between mb-4 py-2">
        <Title title="Manage Customers" variant="mb-3" />
        <ExportExcel excelData={customerList?.customers} fileName={`file-customerList-${new Date().toISOString()}`}>
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
        </ExportExcel>
      </div>
      <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} TabsItem={TabsHeader} />
        <CustomerList tab={selectedTab} />
      </div>
    </div>
  );
};

export default ManageCustomers;
