import React, { useCallback, useEffect, useState } from 'react';
import CTable from 'components/Table/Table';
import { bannerHeader } from 'constants/mocks/settings';
import useSettings from 'hooks/fetch/useSettings';
import { useAppSelector } from 'hooks/useReduxHook';
import TableRow from './TableRow';
import Modal from 'components/Modal/Modal';
import UploadBanner from './UploadBanner';
import DashboardCard from 'components/Card/DashboardCard';
import CustomButton from 'components/Button/CustomButton';

const BannerSettings = () => {
  const { banners } = useAppSelector(state => state.settings)
  const { loading, get_banners } = useSettings()
  const [showModal, setShowModal] = useState(false)

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  useEffect(() => {
    get_banners()
  }, [])

  return (
    <div className="mt-6">
      <DashboardCard>
        <div className='flex justify-between items-center'>
          <p className="text-18 font-dmsans_m">Banner Settings</p>
          <CustomButton
            name='Add Banner'
            onClick={openModal}
            type='button'
            variant="border border-purple bg-purple text-white rounded font-dmsans_r"
          />
        </div>
        <div className="mt-10" data-testid="test-container">
          <>
            <CTable
              header={bannerHeader}
              showCheckBox={false}
              loading={loading}
              data={banners}
              hasPagination={false}
            >
              {(item) => <TableRow item={item} />}
            </CTable>
            {showModal &&
              <Modal
                closeModal={closeModal}
                title="Add New Banner"
                containerVariant="min-w-[559px]"
              >
                <UploadBanner closeModal={closeModal} />
              </Modal>
            }
          </>
        </div>
      </DashboardCard>
    </div>
  );
};

export default BannerSettings;