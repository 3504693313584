export const TransactionHistoryHeader = [
  {
    name: 'Customer',
    variant: 'xxs:pl-10 md:pl-6 pl-6 min-w-max',
  },
  {
    name: 'Amount',
    variant: 'xxs:pl-10 lg:pl-6 md:pl-10 min-w-max',
  },
  {
    name: 'Reason',
    variant: 'xxs:pl-10 lg:pl-6 md:pl-10 min-w-max',
  },
  {
    name: 'Status',
    variant: 'xxs:pl-10 lg:pl-6 md:pl-10 min-w-max',
  },
  {
    name: 'Date Created',
    variant: 'xxs:pl-6 lg:pl-0 md:pl-6 min-w-max',
  },
];
export const TransactionHistoryFilter = [
  { id: 1, value: 'deducted', label: 'Deducted' },
  { id: 2, value: 'added', label: 'Added' },
];
export const TransactionHistoryData = [
  {
    Customer: '1234567897865467',
    Amount: '₦ 500,000',
    first_name: 'Livia',
    last_name: 'Livia',
    status: 'active',
    reason: 'testing',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    Customer: '1234567897865467',
    Amount: '₦ 500,000',
    purchased_by: '@Foenix',
    first_name: 'Foenix',
    last_name: 'Foenix',
    reason: 'testing',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    Customer: '1234567897865467',
    Amount: '₦ 500,000',
    first_name: 'Lana220',
    last_name: 'Lana220',
    reason: 'testing',
    purchased_by: '@Lana220',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
];
