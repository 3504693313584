/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomButton from 'components/Button/CustomButton';
import Lottie from 'react-lottie-player'
import successLottie from 'assets/Lottie/success';

export default function NotificationModal({ title, containerVariant, customWidth, onClick, closeModal, buttonName, description }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking theRef's element or descendent elements
      if (modalRef && (!modalRef.current || modalRef.current.contains(event.target))) {
        return;
      }
      closeModal();
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [modalRef, closeModal]);

  return (
    <div >
      <div className="z-[500] overlay"></div>
      <div ref={modalRef} className={`modal bg-white px-[68px] py-48 rounded overflow-y-auto ${containerVariant}`}>
        <div className="w-full flex justify-center items-center h-[200px]">
          <Lottie loop={false} animationData={successLottie} play style={{ width: 150, height: 150 }} />
        </div>
        {title && <p className={`text-18 text-dark_blue font-dmsans_m font-medium ${customWidth} text-center`}>{title}</p>}
        {description && (
          <p className="text-grey mt-2 text-14 font-dmsans_r font-normal text-center mb-[24px]">{description}</p>
        )}
        <CustomButton
          name={buttonName}
          onClick={onClick}
          variant="border border-purple bg-purple text-white w-full mt-11 rounded font-dmsans_m"
        />
      </div>
    </div>
  );
}

NotificationModal.propTypes = {
  onClick: PropTypes.func,
  closeModal: PropTypes.func,
  containerVariant: PropTypes.string,
  buttonName: PropTypes.string,
  customWidth: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
