import React from "react"

export default function EmptyState() {
    return(
        <section className="flex justify-center items-center py-[40px]">
            <div className="flex flex-col items-center">
                <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-[28px]">
                    <rect x="-0.000244141" width="73.0004" height="73.0004" rx="10.9501" fill="#F7F7F8"/>
                    <rect x="8.10938" y="10.9531" width="56.7781" height="7.30004" rx="2" fill="#8F8E9B"/>
                    <rect x="8.10938" y="25.5508" width="56.7781" height="7.30004" rx="2" fill="#8F8E9B"/>
                    <rect x="8.10938" y="47.4531" width="40.5558" height="7.30004" rx="2" fill="#C8C8C8"/>
                </svg>
                <p className="text-black-transparent text-16 font-dmsans_m">Sorry, you have no transaction</p>
            </div>
        </section>
    )
}