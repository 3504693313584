import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useInputValidate from '../../hooks/useInputValidate';

export default function Input({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  type,
  readOnly,
  inputVariant,
  disabled,
}) {
  const [passwordView, setPasswordView] = useState(false);
  const { error, validate, setError } = useInputValidate();

  return (
    <>
      <label htmlFor={id} className="text-grey mb-2 block text-14">
        {label}
      </label>
      <div className="relative mb-2">
        {/* Handles type of not password */}
        {type !== 'password' && (
          <>
            <input
              id={id}
              disabled={disabled}
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              data-testid={`test-${id}`}
              aria-labelledby={id}
              onChange={onChange}
              readOnly={readOnly}
              onBlur={() => validate({ name, value })}
              onKeyDown={() => setError('')}
              className={`${error !== '' && 'border border-red'} 
                                ${inputVariant} p-16 text-14 w-full outline-0 font-dmsans_r font-normal
                                rounded-lg focus:border-purple focus:border bg-green_light`}
            />
          </>
        )}

        {/* Handles type of password */}
        {type === 'password' && (
          <>
            <input
              id={id}
              name={name}
              disabled={disabled}
              type={passwordView ? 'text' : 'password'}
              placeholder={placeholder}
              value={value}
              data-testid={`test-${id}`}
              onChange={onChange}
              readOnly={readOnly}
              onBlur={() => validate({ name, value, length: 6 })}
              onKeyDown={() => setError('')}
              className={`${error !== '' && 'border-red'}
                                p-16 text-14 w-full outline-0 font-dmsans_r font-normal
                                 rounded-lg focus:border-purple focus:border bg-green_light`}
            />
            <div
              data-testid="view"
              className="absolute bottom-1 right-1 p-3 text-16 cursor-pointer bg-green_light text-light_blue rounded-lg"
              onClick={() => setPasswordView(!passwordView)}
            >
              {!passwordView ? <p data-testid="show">Show</p> : <p data-testid="hide">Hide</p>}
            </div>
          </>
        )}
      </div>
      <p className="font-dmsans_r text-red text-12 leading-[16px]">{error}</p>
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  inputVariant: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  styles: PropTypes.string,
  disabled: PropTypes.bool,
};
