/* eslint-disable max-len */
import React from 'react'

const Reward = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.27255 6.54541H20.7271C21.3296 6.54541 21.818 7.03383 21.818 7.63632V8.72723C21.818 9.32972 21.3296 9.81814 20.7271 9.81814H3.27255C2.67006 9.81814 2.18164 9.32972 2.18164 8.72723V7.63632C2.18164 7.03383 2.67006 6.54541 3.27255 6.54541ZM4.36346 12H9.818C10.4205 12 10.9089 12.4884 10.9089 13.0909V20.7272C10.9089 21.3297 10.4205 21.8181 9.818 21.8181H4.36346C3.76097 21.8181 3.27255 21.3297 3.27255 20.7272V13.0909C3.27255 12.4884 3.76097 12 4.36346 12ZM14.1816 12H19.6362C20.2387 12 20.7271 12.4884 20.7271 13.0909V20.7272C20.7271 21.3297 20.2387 21.8181 19.6362 21.8181H14.1816C13.5791 21.8181 13.0907 21.3297 13.0907 20.7272V13.0909C13.0907 12.4884 13.5791 12 14.1816 12Z" fill="#3A434B"/>
<path opacity="0.4" d="M14.6676 2.36497C15.1689 2.03077 15.8462 2.16623 16.1804 2.66754C16.5146 3.16884 16.3791 3.84615 15.8778 4.18036L12 6.76559L8.12213 4.18036C7.62082 3.84615 7.48536 3.16884 7.81956 2.66754C8.15377 2.16623 8.83108 2.03077 9.33238 2.36497L12 4.14337L14.6676 2.36497Z" fill="#3A434B"/>
</svg>

  )
}

export default Reward