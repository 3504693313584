import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "../../store/modules/global/index"

// Components
import GreenThick from "../../assets/icons/GreenTickIcon";
import Close from "../../assets/icons/CloseIcon";
import { logout } from "store/modules/auth/actions";

export default function Notification() {
    let dispatch = useDispatch()
    const token = useSelector(state => state.global)

    const close = () => {
        const element = document.getElementById('notification')
        
        element.style.transform = `translateX(120%)`
        dispatch(notify({display: false}))
    } 

    
    const vetUser = () => {
        if (token?.notification?.message === "Token session expired") {
            dispatch(logout())
        }
    }

    useEffect(() => {
        vetUser()
    },[token])

    useEffect(() => {
        const element = document.getElementById('notification')

        let timer = setTimeout(() => {
            dispatch(notify({display: false}))
            element.style.transform = `translateX(120%)`
        }, 5000)

        if (token?.notification.display) element.style.transform = `translateX(0%)`

        return () => clearTimeout(timer)
    },[token?.notification.display])

    return(
        <div 
            id="notification"
            className={
                `${token?.notification.status === 'success' ? 'bg-green_notification border-green' : 'bg-[#FFEBEB] border-[#FF0000]'}
                translate-x-[120%] transition ease-in-out duration-1000
                fixed top-[1rem] right-[1rem] z-50 border border-1px max-w-[91.5%] rounded-lg flex items-center p-[1rem] sm:p-[1.25rem]`
            }
        >
            {token?.notification.status === 'success' && <GreenThick />}
            <p 
                className={
                    `${token?.notification.status === 'success' ? 'text-green' : 'text-[#FF0000]'}
                    font-dmsans_m font-medium text-14 ml-[1rem] mr-[2rem]`
                }
            >
                {token?.notification.message}
            </p>
            <div 
                data-testid="closebtn"
                className="cursor-pointer" 
                onClick={() => close()}
            >
                <Close color={token?.notification.status === 'success' ? 'green' : 'red'} />
            </div>
        </div>
    )
}