import React, { useState, useCallback, useEffect } from 'react';
import { InviteIcon } from '../../../assets/icons/Invite';
import CustomButton from '../../../components/Button/CustomButton';
import Card from '../../../components/Card/Card';
import { useNavigate } from 'react-router-dom';
import { InviteAdmin } from '../ManageAdmin';
import Modal from '../../../components/Modal/Modal';
import PermissionIcon from '../../../assets/icons/PermissionIcon';
import { useAuthState } from 'store/modules/auth';

const GetStarted = () => {
  //Variable
  const {
    loggedUser: { role },
  } = useAuthState();
  let navigate = useNavigate();
  //local State
  const [showModal, setShowModal] = useState(false);
  const handleNavigation = useCallback(() => {
    navigate('/settings/roles-and-permissions');
  }, []);
  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  useEffect(() => {
    document.title = 'Get Started - Pouchers Admin';
  }, []);

  useEffect(() => {
    if (role !== 'Super-Admin') {
      window.location.href = '/home';
    }
  }, [role]);

  if (role !== 'Super-Admin') {
    return null;
  }

  return (
    <section className="flex flex-col items-center justify-center mx-auto md:pb-0 pb-10">
      <div className="mt-12 text-center mx-auto flex flex-col items-center justify-center">
        <p className="text-24 font-altone_sb"> Welcome to Pouchers!</p>
        <div className="mt-3 font-dmsans_r leading-24 text-16 text-grey xl:w-[61%] lg:w-[90%] w-full">
          <p>
            Your account is currently in booting mode. This means there are a couple more things you have to do before
            you can start managing payments and others transactionsin pouchers.
          </p>
          <p className="mt-3">Click on the “Roles & Permission” card below to get started.</p>
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:space-x-8 md:space-y-0 space-y-8 mt-8">
        <Card
          icon={<PermissionIcon />}
          iconStyles="pt-[64px]"
          title="Roles & Permission"
          description="Create roles and set permission for efficient admin management."
          button={
            <CustomButton
              name="Get Started"
              variant="border border-purple bg-white text-purple rounded font-dmsans_b"
              onClick={handleNavigation}
            />
          }
        />
        <Card
          icon={<InviteIcon />}
          iconStyles="pt-[56px]"
          title="Invite Admin"
          description="Create roles and set permission for efficient admin management."
          button={
            <CustomButton
              name="Invite Admin"
              variant="border border-purple bg-white text-purple rounded font-dmsans_b"
              onClick={handleShowModal}
            />
          }
        />
      </div>
      {showModal && (
        <Modal closeModal={handleShowModal} title="Invite Admin" containerVariant="min-w-[400px]">
          <InviteAdmin backClick={handleShowModal} type="Add" />
        </Modal>
      )}
    </section>
  );
};

export default GetStarted;
