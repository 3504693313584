import React from 'react'
import PropTypes from 'prop-types';

const FilterDropDown = ({innerVariant,labelVariant, label, placeholder, icon}) => {
  return (
    <div className={innerVariant}>
      <p className={`${labelVariant || 'text-black-transparent'}`}>{label ?? placeholder}</p> <p>{icon}</p>
    </div>
  )
}

export default FilterDropDown
FilterDropDown.propTypes = {
  innerVariant: PropTypes.string,
  labelVariant: PropTypes.string,
  placeholder: PropTypes.any,
  icon: PropTypes.any,
  label: PropTypes.string,
}