import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Header from '../Header/Header';
import useAuth from "../../hooks/fetch/useAuth"

export default function ForgotPasswordForm() {
  //Local State
  const [formData, setFormData] = useState({
    email: ''
  });

 //Custom Hooks
 const { loading } = useSelector((state) => state.auth);
 const { forgot_password } = useAuth()
 let navigate = useNavigate();

  // Handler function
  const makeRequest = (e) => {
    e.preventDefault()

    forgot_password(formData)
  }

  return (
    <section className="w-full flex justify-center transition_left">
      <form className="mx-auto w-full sm:w-[400px] pt-70 sm:pt-128 lg:mx-0" onSubmit={(e) => makeRequest(e)}>
        <Header title="Forgot Password" description="Enter your email address to reset password" />
        <div className="mb-4">
          <Input
            label="Email Address/Phone Number"
            type="text"
            placeholder="Email Address"
            id="email"
            name="email"
            readOnly={loading}
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
        <div className='flex justify-between items-center mt-10'>
        <p className="cursor-pointer font-dmsans_m text-16" onClick={() => navigate('/')}>Back to Login</p>
        <Button name="Continue" theme="dark" disabled={loading} loading={loading} styles="" />
        </div>
      </form>
    </section>
  );
}
