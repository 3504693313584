export const groupDataByDate = (data) => {
  const groups = data?.reduce((groups, transaction) => {
      const date = transaction?.created_at?.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(transaction);
      return groups;
  }, {});
  
  const groupArrays = Object.keys(groups || {}).map((date) => {
      return {
        date,
        items: groups[date]
      };
  });

  return groupArrays.sort((a,b) => new Date(b.date) - new Date(a.date))
}
