import React from 'react';

export default function BackButtonIcon() {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.833008 5.18282L10.833 5.18282" stroke="#5034C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.86621 9.19911L0.832877 5.18311L4.86621 1.16644" stroke="#5034C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
}
