import React from 'react'
import PropTypes from 'prop-types';

const DashboardCard = ({children, variant}) => {
  return (
    <div className={`${variant || 'border p-32'}  bg-white rounded-lg border border-light-smoke `}>
      {children}
    </div>
  )
}
export default DashboardCard

DashboardCard.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
};