export const NotificationData = [
  {
    title: 'Happy New Month',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Happy New Month',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'Push Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'lana@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Demi Wilkinson',
    description: 'demi@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Demi',
    last_name: 'Wilkinson',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'lana@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Natali Craig',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Natali',
    last_name: 'Craig',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
];
export const MessageType = [
  {
    id: 1,
    value: 'In App Notifcation',
    label: 'In App Notifcation',
    styles: '',
  },
  {
    id: 2,
    value: 'Push Notification',
    label: 'Push Notification',
    styles: '',
  },
  {
    id: 3,
    value: 'In App & Push Notification',
    label: 'In App & Push Notification',
    styles: '',
  },
];
export const DiscountType = [
  {
    id: 1,
    value: 'flat',
    label: 'Flat Rate',
    styles: '',
  },
  {
    id: 2,
    value: 'percentage',
    label: 'Percentage',
    styles: '',
  }
];
export const frequncyOptions = [
  {
    id: 1,
    value: 'per_purchase',
    label: 'Per Purchase',
    styles: '',
  },
  {
    id: 2,
    value: 'daily',
    label: 'Daily',
    styles: '',
  },
  {
    id: 3,
    value: 'weekly',
    label: 'Weekly',
    styles: '',
  },
  {
    id: 4,
    value: 'monthly',
    label: 'Monthly',
    styles: '',
  }
];
export const DiscountHeader = [
  {
      name: 'Discount Type',
      variant: 'pl-6'
  },
  {
    name: 'Discount Value',
    variant: 'pl-4'
},
  {
      name: 'Amount',
      variant: 'pl-4'
  },
  {
      name: 'Frequency',
      variant: 'pl-4'
  },
  {
      name: 'Status',
      variant: ''
  },
  {
    name: 'Scheduled Date',
    variant: 'pl-4'
  },
  {
    name: '',
    variant: 'pl-4'
  }

]
export const DiscountData = [
  {
      discount_type: "Flat Rate",
      amount: "₦ 500,000",
      frequency: "Monthly",
      status: "active",
      date_created: "May 27, 2020",
      time: "12:38pm"
  },
  {
      discount_type: "Percentage",
      amount: "₦ 500,000",
      frequency: "Once",
      status: "suspended",
      date_created: "May 27, 2020",
      time: "12:38pm"
  },
  {
    discount_type: "Percentage",
    amount: "₦ 500,000",
    frequency: "Weekly",
    status: "active",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Flat Rate",
    amount: "₦ 500,000",
    frequency: "Annually",
    status: "active",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Percentage",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "suspended",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Flat Rate",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "active",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Percentage",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "suspended",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Flat Rate",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "suspended",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Percentage",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "suspended",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    discount_type: "Flat Ratee",
    amount: "₦ 500,000",
    frequency: "Once",
    status: "suspended",
    date_created: "May 27, 2020",
    time: "12:38pm"
  }
]
export const TierLimit = [
  {
      name: 'Tier'
  },
  {
      name: 'Transaction Limit'
  },
  {
      name: 'Maximum Balance'
  }
]
export const bannerHeader = [
  {
      name: 'Banner Thumbnail'
  },
  {
      name: 'Status'
  },
  {
    name: 'Action'
}
]