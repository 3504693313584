/* eslint-disable max-len */
import React from 'react'

const SendIcon = () => {
  return (
<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M0.504921 10.2096C0.376868 10.2096 0.248816 10.1609 0.151443 10.0629C-0.0439705 9.86747 -0.0439705 9.55134 0.151443 9.35593L1.06182 8.44489C1.25723 8.25014 1.57403 8.25014 1.76944 8.44489C1.96418 8.6403 1.96418 8.9571 1.76944 9.15251L0.858399 10.0629C0.761026 10.1609 0.632973 10.2096 0.504921 10.2096ZM3.01322 11.0065C2.88517 11.0065 2.75712 10.9578 2.65974 10.8598C2.46433 10.6643 2.46433 10.3482 2.65974 10.1528L3.57012 9.24176C3.76553 9.04702 4.08233 9.04702 4.27774 9.24176C4.47249 9.43718 4.47249 9.75397 4.27774 9.94939L3.3667 10.8598C3.26933 10.9578 3.14127 11.0065 3.01322 11.0065ZM3.18391 13.3891C3.28129 13.4871 3.40934 13.5358 3.53739 13.5358C3.66544 13.5358 3.7935 13.4871 3.89087 13.3891L4.80191 12.4787C4.99666 12.2833 4.99666 11.9665 4.80191 11.7711C4.6065 11.5763 4.2897 11.5763 4.09429 11.7711L3.18391 12.6821C2.9885 12.8775 2.9885 13.1936 3.18391 13.3891Z" fill="#41DC65"/>
<path opacity="0.4" d="M8.87948 4.58008L5.3833 8.07625" stroke="#41DC65" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.38164 8.07894L1.28846 5.57618C0.75211 5.24825 0.860822 4.43922 1.46504 4.26444L11.1289 1.47279C11.6785 1.31362 12.1866 1.82655 12.022 2.37431L9.1547 11.9625C8.97512 12.5631 8.16969 12.6664 7.84416 12.1307L5.38164 8.07894" stroke="#41DC65" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default SendIcon