import React, { useEffect, useState } from 'react';
import { HiArrowRight, HiArrowLeft } from 'react-icons/hi';
import Datepicker from 'tailwind-datepicker-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const options = (variant, defaultValue, maxDate, minDate) => ({
  title: '',
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  maxDate: new Date(maxDate || '2030-01-01'),
  minDate: new Date(minDate || "1950-01-01"),
  theme: {
    background: 'bg-white dark:bg-gray-800 w-full',
    todayBtn: 'bg-purple text-white cursor-pointer font-dmsans_m',
    clearBtn: 'bg-white border text-blue_black border-light-smoke cursor-pointer hover:bg-grey-100 font-dmsans_m',
    icons: '',
    text: 'hover:bg-grey-100 rounded-full',
    disabledText: 'text-purple_light hover:text-blue_black hover:bg-grey-100',
   input: `border border-grey-100 outline-none text-blue_black text-14 ${variant}`,
    inputIcon: 'text-grey-500 mr-10',
    selected: 'bg-purple text-red',
  },
  icons: {
    prev: () => (
      <span className="text-16 hover:bg-grey-100">
        <HiArrowLeft />
      </span>
    ),
    next: () => (
      <span className="text-16 hover:bg-grey-100">
        <HiArrowRight />
      </span>
    ),
  },
  datepickerClassNames: `top-18 right-0`,
  defaultDate: new Date(defaultValue),
  language: 'en',
});

const DatePickerInner = ({
  onChange,
  variant,
  defaultValue,
  selectedDate: value,
  maxDate = '2100-01-01',
  minDate = '2023-01-01',
}) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const handleChange = (selectedDate) => {
    setSelectedDate(dayjs(selectedDate).format('YYYY-MM-DD'));
    onChange(dayjs(selectedDate).format('YYYY-MM-DD'));
  };
  const handleClose = (state) => {
    setShow(state);
  };
  useEffect(() => {
    if (value < minDate || value > maxDate) {
      setSelectedDate(new Date());
    }
  }, [value, minDate, maxDate]);

  return (
    <div>
      <Datepicker
        classNames="outline-none"
        options={options(variant, selectedDate, maxDate, minDate)}
        onChange={handleChange}
        show={show}
        setShow={handleClose}
      />
    </div>
  );
};

export default DatePickerInner;

DatePickerInner.propTypes = {
  onChange: PropTypes.func,
  variant: PropTypes.string,
  defaultValue: PropTypes.any,
  selectedDate: PropTypes.any,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
};
