/* eslint-disable max-len */
import React from 'react';

export const Voucher2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#FF6414" />
      <g clipPath="url(#clip0_9418_106490)">
        <path
          d="M22.8333 25.4513V26.3085C21.5106 26.3102 20.2386 25.7987 19.2853 24.8816V29.8381H33.4296C34.1397 29.8381 34.7155 29.2624 34.7155 28.5523V24.2661H19.8807C20.6747 25.0277 21.7329 25.4526 22.8333 25.4513ZM31.7152 27.2664H32.5724V28.1237H31.7152V27.2664ZM30.0007 27.2664H30.8579V28.1237H30.0007V27.2664ZM28.2862 27.2664H29.1435V28.1237H28.2862V27.2664ZM26.5718 27.2664H27.429V28.1237H26.5718V27.2664Z"
          fill="white"
        />
        <path
          d="M16.4007 20.4087C15.9344 20.4135 15.5363 20.7463 15.4488 21.2042C15.3611 21.6621 15.6087 22.1181 16.0401 22.2946L18.1482 23.1411L17.3037 21.0215C17.1574 20.651 16.7991 20.4079 16.4007 20.4087Z"
          fill="white"
        />
        <path
          d="M30.3181 33.5839C30.5908 33.7145 30.9041 33.731 31.1888 33.6297C31.4736 33.5287 31.7061 33.3181 31.835 33.0448L32.9539 30.6956H24.2999L30.3181 33.5839Z"
          fill="#F7F9FB"
        />
        <path
          d="M13.2845 19.1228V23.4089H16.5103L15.7204 23.0917C14.9425 22.7836 14.4716 21.9881 14.5758 21.1579C14.6801 20.3276 15.3326 19.6732 16.1624 19.5667C16.9925 19.4601 17.7892 19.9287 18.0996 20.7056L18.428 21.5298V17.8369H14.5704C13.8603 17.8369 13.2845 18.4127 13.2845 19.1228Z"
          fill="white"
        />
        <path
          d="M17.6817 14.5198C17.4092 14.3892 17.0959 14.3729 16.8113 14.474C16.5264 14.5753 16.2939 14.7858 16.1648 15.0591L15.2506 16.9798H22.8075L17.6817 14.5198Z"
          fill="#F7F9FB"
        />
        <path
          d="M14.999 25.4513C16.0994 25.4526 17.1576 25.0277 17.9518 24.2661H13.2845V28.5523C13.2845 29.2624 13.8603 29.8381 14.5704 29.8381H18.428V24.9905C17.488 25.8399 16.2658 26.3098 14.999 26.3085V25.4513Z"
          fill="white"
        />
        <path
          d="M12.4274 24.9619V22.9109L12.1109 23.5762C11.8832 24.0555 12.014 24.6287 12.4274 24.9619Z"
          fill="white"
        />
        <path
          d="M35.5726 23.1418V25.1924L35.8889 24.5275C36.1168 24.0481 35.986 23.475 35.5726 23.1418Z"
          fill="white"
        />
        <path
          d="M33.4296 17.8369H19.2853V21.829L19.7327 20.7056C20.0452 19.9333 20.8396 19.4693 21.6657 19.5763C22.492 19.6834 23.1418 20.3345 23.2473 21.161C23.3525 21.9873 22.8871 22.7807 22.1142 23.0917L21.322 23.4089H34.7155V19.1228C34.7155 18.4127 34.1397 17.8369 33.4296 17.8369ZM27.429 20.8372H26.5718V19.1228H27.429V20.8372ZM29.1435 20.8372H28.2862V19.1228H29.1435V20.8372ZM30.8579 20.8372H30.0007V19.1228H30.8579V20.8372ZM32.5724 20.8372H31.7152V19.1228H32.5724V20.8372Z"
          fill="white"
        />
        <path
          d="M22.4047 21.387C22.4078 20.9192 22.0772 20.5155 21.6178 20.4263C21.1584 20.3372 20.7007 20.5881 20.5287 21.0232L19.6848 23.1427L21.7927 22.2944C22.1636 22.1463 22.4062 21.7865 22.4047 21.387Z"
          fill="white"
        />
      </g>
      <path
        d="M44.0386 34.8763C44.6211 35.1924 45.3525 34.9777 45.6391 34.3802C47.283 30.9532 48.092 27.1792 47.9917 23.3682C47.8808 19.1568 46.6633 15.0488 44.4615 11.457C42.2598 7.86529 39.1514 4.91638 35.4487 2.90671C32.0982 1.08815 28.3679 0.0966743 24.5681 0.00672065C23.9056 -0.0089644 23.3824 0.545389 23.3998 1.2079C23.4173 1.87041 23.9688 2.38986 24.6313 2.40922C28.0096 2.50799 31.3236 3.39849 34.3038 5.01604C37.6362 6.82474 40.4338 9.47876 42.4154 12.7113C44.3969 15.9439 45.4927 19.6411 45.5925 23.4314C45.6818 26.8211 44.9717 30.1784 43.5268 33.2338C43.2435 33.8329 43.4562 34.5601 44.0386 34.8763Z"
        fill="#FF6414"
      />
      <defs>
        <clipPath id="clip0_9418_106490">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};
