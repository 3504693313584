/* eslint-disable max-len */
import React from 'react'

const Profile = () => {
  return (
<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99651 12.1324C3.68351 12.1324 -0.000488281 12.7209 -0.000488281 15.0748C-0.000488281 17.4296 3.66051 18.0388 7.99651 18.0388C12.3095 18.0388 15.9935 17.4512 15.9935 15.0964C15.9935 12.7417 12.3335 12.1324 7.99651 12.1324Z" fill="#3A434B"/>
<path opacity="0.4" d="M7.99659 9.89025C10.9346 9.89025 13.2886 7.85223 13.2886 5.31054C13.2886 2.76886 10.9346 0.730835 7.99659 0.730835C5.05959 0.730835 2.70459 2.76886 2.70459 5.31054C2.70459 7.85223 5.05959 9.89025 7.99659 9.89025Z" fill="#3A434B"/>
</svg>

  )
}

export default Profile