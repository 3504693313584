const reducers = {
  notify: (state, action) => {
    if (action.payload.display === false) {
      state.notification.display = false;
      return 
    }

    state.notification.display = true;
    state.notification.status = action.payload?.status;
    state.notification.message = action.payload.message;
  },
};

export { reducers };
