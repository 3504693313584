/* eslint-disable max-len */

import DefaultDatePicker from 'components/Input/DefaultDatePicker';
import useFormatDate from 'hooks/useFormatDate';
import React, { useCallback, useState } from 'react';
import FilterDropDown from './FilterDropDown';
import PropTypes from 'prop-types';
import CalenderIcon from 'assets/icons/CalenderIcon';
import dayjs from 'dayjs';
import { TransparentCalender } from 'assets/icons/TransparentCalender';

const DateFilter = ({ setStartDate, startDate, endDate, setEndDate }) => {
  //Local State
  const { getFirstDayOfMonth, getLastDayOfMonth } = useFormatDate();
  const [showDropdown, setShowDropdown] = useState(false);

  //Handler Functions
  const toggleDropdown = useCallback(() => {
    setShowDropdown((prevState) => !prevState);
  }, []);
  
  const placeholder = () => {
    if (startDate && endDate) {
      return `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')}`;
    }
    return 'Filter by Date';
  };
  return (
    <div className="relative">
      <div
        onClick={toggleDropdown}
        className="border border-light-smoke text-16 h-11 w-full font-dmsans_b rounded cursor-pointer p-3"
        data-testid="date-dropdown"
      >
        <FilterDropDown
          innerVariant="text-black-transparent font-dmsans_r text-16 flex space-x-2"
          labelVariant="text-14 flex items-center"
          placeholder={
            <>
              {startDate && endDate && <span className="mr-2">
                <TransparentCalender />
              </span>}
              <span>{placeholder()}</span>
            </>
          }
          icon={!startDate && !endDate && <CalenderIcon />}
        />
      </div>
      {showDropdown && (
        <div className="shadow-dropDownShadow space-y-2 border border-light-smoke p-2 items-center absolute rounded bg-white  right-0 mt-0.5 z-10 min-w-[195px]">
          <div className="">
            <p className="font-dmsans_r text-14 text-blue_black">From:</p>
            <DefaultDatePicker
              defaultValue={getFirstDayOfMonth()}
              onHandleDateChange={(date) => {
                setStartDate(date);
              }}
              selectedDate={new Date(startDate)}
            />
          </div>
          <div className="">
            <p className="font-dmsans_r text-14 text-blue_black">to:</p>
            <DefaultDatePicker
              onHandleDateChange={(date) => {
                setEndDate(date);
              }}
              selectedDate={new Date(endDate)}
              defaultValue={getLastDayOfMonth()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
DateFilter.propTypes = {
  setStartDate: PropTypes.any,
  startDate: PropTypes.any,
  setEndDate: PropTypes.any,
  endDate: PropTypes.any,
};
