import RoleForm from 'components/Forms/RoleForm';
import React from 'react';

const CreateRole = () => {

  //Variables
  const pathname = window.location.pathname

  return (
    <RoleForm formTitle={pathname.includes('edit-role') ? "Edit Role" : 'Create Role'}/>
  );
};

export default CreateRole;
