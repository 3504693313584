import React from 'react';

const CollapseIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 13L9.25 9.25M9.25 9.25V12.25M9.25 9.25H12.25M1 13L4.75 9.25M4.75 9.25V12.25M4.75 9.25H1.75M13 1L9.25 4.75M9.25 4.75V1.75M9.25 4.75H12.25M1 1L4.75 4.75M4.75 4.75V1.75M4.75 4.75H1.75"
        stroke="#5034C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollapseIcon;
