import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/axios-instance';
import { admin } from '../../../services/endpoints/admin';

const getAllTransfers = createAsyncThunk('transfer/getAllTransfers', async (params) => {
  try {
    const response = await api.get(admin.get_transfers, { params });
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
  } catch (error) {
    return Promise.reject(error);
  }
});
const getSingleTransfer = createAsyncThunk('transfer/getSingleTransfer', async (id) => {
  try {
    const response = await api.get(`/admin-overview/transfer/${id}`);
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
  } catch (error) {
    return Promise.reject(error);
  }
});

const getUserTransactionHistory = createAsyncThunk('transfer/getUserTransactionHistory', async (params) => {
  try {
    const response = await api.get(admin.get_user_transaction_history, { params });
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
  } catch (error) {
    return Promise.reject(error);
  }
});

const getCustomerTransactionHistory = createAsyncThunk('transfer/getCustomerTransactionHistory',
  // async (params) => {
  //   try {
  //     const response = await api.get(admin.get_customer_transaction_history, { params });
  //     if (response.data) {
  //       return response.data
  //     }
  //     return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }
  async ({
    page,
    perPage,
    transactionType,
    status,
    transactionCategory,
    search,
  }) => {
    try {
      let baseUrl = `${admin.get_customer_transaction_history}`
      let url = `${baseUrl}?page=${page}&per_page=${perPage}`
      if (transactionType) {
        url += `&transaction_type=${transactionType}`
      }
      if (status) {
        url += `&status=${status}`
      }
      if (transactionCategory) {
        url += `&transaction_category=${transactionCategory}`
      }
      if (search) {
        url += `&search=${search}`
      }
      const response = await api.get(url);
      // const response = await api.get(baseUrl);
      // const response = await api.get(`${admin.get_customer_transaction_history}`);
      if (response.data) {
        return response.data;
      }
      return {
        code: response?.response?.data?.code,
        message: response?.response?.data?.message,
        status: response?.response?.data?.message,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export { getAllTransfers, getSingleTransfer, getUserTransactionHistory, getCustomerTransactionHistory };
