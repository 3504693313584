import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services/axios-instance'
import { admin } from '../../../services/endpoints/admin'


const getAllRoles = createAsyncThunk('roles/getAllRoles', async(params) => {
    try {
        const response = await api.get(admin.get_roles, {params})
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

const getSingleRole = createAsyncThunk('admin/getSinglRole', async(id) => {
    try {
        const response = await api.get(`/admin/role/${id}`)
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

const getAllAdmins = createAsyncThunk('roles/getAllAdmin', async(params) => {
    try {
        const response = await api.get(admin.get_admins, {params})
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

const getSingleAdmin = createAsyncThunk('roles/getSingleAdmin', async(id) => {
    try {
        const response = await api.get(`/admin/${id}`)
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});
const  deactivateAdmin = createAsyncThunk('roles/deactivateAdmin', async (payload) => {
    try {
        const response = await api.patch(admin.deactivate_admin, {...payload})
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
})
const  activateAdmin = createAsyncThunk('admin/activateAdmin', async (data) => {
    try {
        const response = await api.patch(`/admin/${data.id}`, {...data.payload})
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
})
const deleteRole = createAsyncThunk('admin/deleteRole', async (role_name) => {
    try {
        const response = await api.delete(`/admin/role/${role_name}`)
    if (response.data) {
      return response.data
    }
    return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
})



export {
    getAllRoles,
    getAllAdmins,
    getSingleAdmin,
    getSingleRole,
    activateAdmin, 
    deactivateAdmin,
    deleteRole
}