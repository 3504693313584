import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input/Input';
import CustomButton from 'components/Button/CustomButton';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';

export default function AddMoneyForm({ cancel, openPasswordModal, setOpenPasswordModal, customer_id, wallet_balance }) {
  const [isLoading, setIsLoading] = useState(false);
  const { loading } = useCustomerState();
  const { handleUpdateCustomerWallet } = useCustomer();
  const [formData, setFormData] = useState({
    reason: '',
    amount: '',
    password: '',
  });

  const handleFirstForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenPasswordModal(false);
    setTimeout(() => {
      setIsLoading(false);
      setOpenPasswordModal(true);
    }, 500);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const params = {
      id: customer_id,
      type: 'credit',
    };

    if (formData.password) handleUpdateCustomerWallet(params, formData);
  };

  return (
    <form onSubmit={!openPasswordModal ? handleFirstForm : handleSubmitForm}>
      {!openPasswordModal ? (
        <section className="grid grid-cols-1mb-[32px] mt-[12px] mb-[32px]">
          <div className="mt-[12px]">
            <Input
              label="Wallet Balance"
              type="number"
              placeholder={`${wallet_balance}`}
              id={'wallet_balance'}
              name="wallet_balance"
              disabled
            />
          </div>
          <div className="mt-[12px]">
            <Input
              label="Amount"
              type="number"
              placeholder="Enter Amount"
              id={'amount'}
              name="amount"
              readOnly={isLoading}
              value={formData.amount}
              onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
            />
          </div>
          <div className="mt-[12px]">
            <Input
              label="Reason"
              type="text"
              placeholder="Type here"
              id={'reason'}
              name="reason"
              readOnly={isLoading}
              value={formData.reason}
              onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
            />
          </div>
        </section>
      ) : (
        <section className="grid grid-cols-1mb-[32px] mt-[12px] mb-[32px]">
          <div className="mt-[12px]">
            <Input
              label="Password"
              type="password"
              placeholder={'Enter password'}
              id="password"
              name="password"
              readOnly={isLoading}
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
          </div>
        </section>
      )}

      <div className="flex justify-end items-center space-x-6">
        <p className="text-purple font-dmsans_r cursor-pointer" onClick={cancel}>
          Cancel
        </p>

        <CustomButton
          name="Proceed"
          disabled={formData.reason === '' || formData.amount === ''}
          loading={isLoading || loading}
          variant="border border-purple bg-purple text-white rounded font-dmsans_r"
        />
      </div>
    </form>
  );
}

AddMoneyForm.propTypes = {
  cancel: PropTypes.func,
  setOpenPasswordModal: PropTypes.func,
  openPasswordModal: PropTypes.bool,
  customer_id: PropTypes.string,
  wallet_balance: PropTypes.string,
};
