import React from "react";
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from '../store/modules/auth';
export default function RequireAuth({ children }) {

  const authState = useAuthState()
  const { token, loginSuccess } = authState
  let location = useLocation();

  // Redirect user to login page if user is not logged in
  if (!token || loginSuccess !== "success") return <Navigate to="/login" state={{ from: location }} replace />

  if (location.pathname === '/') return <Navigate to="/home" />

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.element
};