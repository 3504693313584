export const NewTransactionHistoryHeader = [
  {
    name: 'Status',
    variant: 'w-[10%] 2xl:w-[12%]',
  },
  {
    name: 'Date',
    variant: 'w-[12%] 2xl:w-[9.5%]',
  },
  {
    name: 'Operation',
    variant: 'w-[10%] 2xl:w-[8%]',
  },
  {
    name: 'Transaction ID',
    variant: 'w-[14%]',
  },
  {
    name: 'Payment Type',
    variant: 'w-[15%]',
  },
  {
    name: 'Beneficiary',
    variant: 'w-[14%]',
  },
  {
    name: 'Balance After',
    variant: 'w-[13%]',
  },
  {
    name: 'Amount',
    variant: 'w-[12%]',
  }
];

export const NewTransactionHistoryFilter = [
  { id: 0, value: '', label: 'All' },
  { id: 1, value: 'credit', label: 'Credit' },
  { id: 2, value: 'debit', label: 'Debit' },
];

export const NewStatusHistoryFilter = [
  { id: 0, value: '', label: 'All' },
  { id: 1, value: 'fail', label: 'Failed' },
  { id: 2, value: 'pending', label: 'Pending' },
  { id: 3, value: 'success', label: 'Success' },
];

export const NewCategoryHistoryFilter = [
  { id: 0, value: '', label: 'All' },
  { id: 1, value: 'fund-wallet', label: 'Fund Wallet' },
  { id: 2, value: 'p2p-transfer', label: 'P2P Transfer' },
  { id: 3, value: 'local-bank-transfer', label: 'Local Bank Transfer' },
  { id: 4, value: 'airtime-purchase', label: 'Airtime Purchase' },
  { id: 5, value: 'voucher-purchase', label: 'Voucher Purchase' },
  { id: 6, value: 'data-purchase', label: 'Data Purchase' },
  { id: 7, value: 'internet-purchase', label: 'Internet Purchase' },
  { id: 8, value: 'electricity-purchase', label: 'Electricity Purchase' },
  { id: 9, value: 'cable-purchase', label: 'Cable Purchase' },
  { id: 10, value: 'betting-purchase', label: 'Betting Purchase' },
  { id: 11, value: 'fund-virtual-card', label: 'Fund Virtual Card' },
  { id: 12, value: 'education-purchase', label: 'Education Purchase' },
];
