import React from 'react'
import PropTypes from 'prop-types'

export default function Header({ title, description }) {
    return(
        <>
        <h1 className="font-altone_sb text-32 font-medium">{title}</h1>
        <div className="text-16 font-dmsans_r font-normal leading-24 text-grey mb-10">{description}</div>
        </>
    )
}

Header.propTypes ={
    title: PropTypes.string,
    description: PropTypes.any
}