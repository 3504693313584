import React from 'react';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';

export default function ViewPayment(selectedPayment) {
  return (
    <section className="mt-6">
      <div className="grid gap-8 grid-cols-12">
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Transaction ID</p>
          <p className="text-dark_blue text-14 mt-1">{selectedPayment?.transaction_id?.replace('trans-', '')}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Name</p>
          <p className="text-blue_black text-14 mt-1">{selectedPayment?.name || 'N/A'}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Email Address</p>
          <p className="text-blue_black text-14 mt-1">{selectedPayment?.email || 'N/A'}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Payment Method</p>
          <p className="text-dark_blue text-14 mt-1">{selectedPayment?.payment_method || 'N/A'}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Category</p>
          <p className="text-dark_blue text-14 mt-1 capitalize">
            {selectedPayment?.extra_details?.category?.replace('-purchase', '') || 'N/A'} |{' '}
            <span className="text-grey">
              {selectedPayment?.extra_details?.sub_category?.replace('-purchase', ' ') || 'N/A'}
            </span>
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Date</p>
          <p className="text-dark_blue text-14 mt-1">
            {dayjs(selectedPayment?.created_at).format('MMM DD, YYYY')}
            <span className="text-grey"> | {dayjs(selectedPayment?.created_at).format('h:mm a')} </span>
          </p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Phone Number</p>
          <p className="text-dark_blue text-14 mt-1">{selectedPayment?.recipient}</p>
        </div>
        <div className="sm:col-span-6 col-span-12 lg:col-span-4">
          <p className="text-grey font-dmsans_r text-12">Amount</p>
          <p className="text-dark_blue text-14 mt-1">{formatCurrency(selectedPayment?.amount)}</p>
        </div>
      </div>
    </section>
  );
}
