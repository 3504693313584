import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import Input from 'components/Input/Input';
import CustomButton from 'components/Button/CustomButton';
import useSettings from 'hooks/fetch/useSettings';
import { useDispatch } from 'react-redux';
import { notify } from 'store/modules/global';

export default function EditConversion({ amount, cancel, exchangeRate }) {
  let dispatch = useDispatch()
  const { loading, update_env } = useSettings()
  const [formData, setFormData] = useState({
    current_dollar_rate: amount || '',
  })

  const handleChange = useCallback((e) => {
    if (isNaN(e.target.value)) return
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }, [formData])

  // handle update
  const submit = useCallback((e) => {
    e.preventDefault()
    if (Number(exchangeRate) > Number(formData?.current_dollar_rate)) {
      dispatch(notify({display: true, status: 'error', message: 'Cannot set amount below the exchange rate'}))
    } else {
      update_env({ formData, callback: cancel })
    }
  }, [formData, exchangeRate])

  return (
    <form onSubmit={submit}>
      <p className='text-purple font-dmsans_b text-16 mt-[24px] mb-[16px]'>Edit conversion rate</p>
      <div className='mb-[32px]'>
        <Input
          type="text"
          placeholder=""
          id="current_dollar_rate"
          name="current_dollar_rate"
          readOnly={loading}
          value={formData.current_dollar_rate}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-end items-center space-x-6">
        <p className="text-purple font-dmsans_r cursor-pointer" onClick={cancel}>
          Cancel
        </p>
        <CustomButton
          name="Update"
          disabled={loading}
          loading={loading}
          variant="border border-purple bg-purple text-white rounded font-dmsans_r"
        />
      </div>
    </form>
  );
}

EditConversion.propTypes = {
  amount: PropTypes.string,
  cancel: PropTypes.func,
  exchangeRate: PropTypes.string
}