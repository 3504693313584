import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import FormContainers from 'components/Containers/FormContainers';
import Modal from 'components/Modal/Modal';
import EditConversion from './EditConversion';
import LoadingSkeleton from 'components/Skeleton/Skeleton';

export default function Conversion({amount, exchangeRate, loading}) {
  const [showModal, setShowModal] = useState(false)

  const closeModal = useCallback(() => {
    setShowModal(false)
  },[])

  return (
    <>
    <FormContainers
        title="Conversion"
        formContent={
          <div className="ml-6">
            <div className="mt-[50px] flex flex-col space-y-[50px]">
            {
              loading ? <LoadingSkeleton count={2}/> :
              <>
                <div className="grid grid-cols-4">
                  <p className="text-black-transparent text-16 font-dmsans_m">Conversion rate (1$ to Naira) </p>
                  <p className="text-black-transparent text-16 font-dmsans_r">₦ {amount}</p>
                </div>
                <div className="grid grid-cols-4">
                  <p className="text-black-transparent text-16 font-dmsans_m">Exchange rate (sudo) </p>
                  <p className="text-black-transparent text-16 font-dmsans_r">₦ {exchangeRate}</p>
                </div>
              </>
            }
            </div>
          </div>
        }
        btnText="Edit"
        onSubmit={(e) => {
          e.preventDefault()
          setShowModal(true)
        }}
      />
    {
        showModal &&
        <Modal
          closeModal={closeModal}
          title="Edit conversion"
          containerVariant="min-w-[437px]"
        >
          <EditConversion 
            cancel={closeModal}
            amount={amount}
            exchangeRate={exchangeRate}
          />
        </Modal>
    }
    </>
  );
}

Conversion.propTypes = {
  amount: PropTypes.string,
  loading: PropTypes.bool,
  exchangeRate: PropTypes.string
}
