import React, { useEffect } from 'react';
import Card from 'components/Card/Card';
import Title from 'components/Header/Title';
import PaymentHistory from './PaymentHistory';
import PaymentAnalytics from 'components/Customers/PaymentAnalytics';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';

const Payment = () => {
  //Local State
  const { categoryAnalytics, loading } = usePaymentState();
  //Handler Function
  const SingleCategory = categoryAnalytics
    ? Object.keys(categoryAnalytics)
        .filter((key) => !key.includes('_revenue'))
        .reduce((obj, key) => {
          return Object.assign(obj, { [key]: categoryAnalytics[key] });
        }, {})
    : [];
  const billerCount = () => Object.values(SingleCategory)?.filter((item) => item !== null).length;

  useEffect(() => {
    document.title = 'Payments List - Pouchers Admin';
  }, []);

  return (
    <div className="my-1">
      <Title title="Payment" variant="mb-3" />
      <div className="grid grid-cols-5 gap-6 mt-7">
        <div className="flex flex-col space-y-4">
          <Card
            type="custom"
            containerVariant="p-6 border border-t-purple border-t-4 border-light-smoke rounded-b-lg bg-white items-center flex-grow flex"
            cardBody={
              <div>
                <p className="text-12 font-dmsans_m text-grey">TOTAL REVENUE/PAYMENT</p>
                <p className="text-20 text-dark_blue font-dmsans_m mt-4">
                  {loading ? '₦....' : formatCurrency(categoryAnalytics?.total_revenue)}
                </p>
              </div>
            }
          />
          <Card
            type="custom"
            containerVariant="p-6 border border-t-green border-t-4 border-light-smoke rounded-b-lg bg-white items-center flex-grow flex "
            cardBody={
              <div>
                <p className="text-12 font-dmsans_m text-grey">ALL CATEGORIES</p>
                <p className="text-20 text-dark_blue font-dmsans_m mt-4">
                  {categoryAnalytics?.category_count ? categoryAnalytics?.category_count : 1}
                </p>
              </div>
            }
          />
          <Card
            type="custom"
            containerVariant="p-6 border border-t-grey-600 border-t-4 border-light-smoke rounded-b-lg bg-white items-center flex-grow flex "
            cardBody={
              <div>
                <p className="text-12 font-dmsans_m text-grey">ALL BILLER</p>
                <p className="text-20 text-dark_blue font-dmsans_m mt-4">
                  {categoryAnalytics?.all_biller ? categoryAnalytics?.all_biller : billerCount()}
                </p>
              </div>
            }
          />
        </div>
        <PaymentAnalytics />
      </div>
      <PaymentHistory />
    </div>
  );
};

export default Payment;
