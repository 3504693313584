/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({color}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill='none' xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 7.00016C0.333496 3.32016 3.3135 0.333496 7.00016 0.333496C10.6802 0.333496 13.6668 3.32016 13.6668 7.00016C13.6668 10.6868 10.6802 13.6668 7.00016 13.6668C3.3135 13.6668 0.333496 10.6868 0.333496 7.00016ZM8.48683 8.22016L9.56683 4.80683C9.64016 4.5735 9.42683 4.3535 9.1935 4.42683L5.78016 5.4935C5.64016 5.54016 5.52683 5.64683 5.48683 5.78683L4.42016 9.20683C4.34683 9.4335 4.56683 9.6535 4.7935 9.58016L8.1935 8.5135C8.3335 8.4735 8.44683 8.36016 8.48683 8.22016Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;

HomeIcon.propTypes = {
  color: PropTypes.string
}