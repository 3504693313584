import React from 'react';
import DashboardCard from 'components/Card/DashboardCard';
import CustomButton from 'components/Button/CustomButton';
import PropTypes from 'prop-types';
import usePermissions from 'hooks/usePermissions';

  const FormContainers = ({ title, onSubmit, formContent, btnText, loading, showButton }) => {
  //Hooks
  const { userIsPermittedTo } = usePermissions();

  return (
    <div className="mt-6">
      <DashboardCard>
        <p className="text-18 font-dmsans_m ">{title}</p>
        <form className="mt-10" onSubmit={(e) => onSubmit(e)} data-testid="test-container">
          {formContent}
          {userIsPermittedTo('Update', 'site_settings_management') && (
          <div className="flex justify-end items-center space-x-6 mt-10">
            {
              showButton === false ? '' :
              <CustomButton
                name={btnText || "Save Changes"}
                loading={loading}
                variant="border border-purple bg-purple text-white rounded font-dmsans_r"
              />
            }
          
        </div>)}
        </form>
      </DashboardCard>
    </div>
  );
};

export default FormContainers;
FormContainers.propTypes = {
  children: PropTypes.element,
  formContent: PropTypes.element,
  title: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  loading:PropTypes.bool,
  onSubmit: PropTypes.func,
  showButton: PropTypes.any
};
