import {
  getAuditTrail

} from './actions';

const extraReducers = {

//GET AUDIT TRAIL
[  getAuditTrail.pending]: (state) => {
  state.loading = true
},
[  getAuditTrail.fulfilled]: (state, action) => {
  state.auditTrail = action.payload.data;
  state.loading = false
},
[getAuditTrail.rejected]: (state, action) => {
  state.loading = false;
  state.error = action.payload.data.message;
},

};

export { extraReducers };
