export const VouchersHeader = [
  {
      name: 'Voucher  Code',
      variant: 'xxs:pl-10 md:pl-6 pl-6 min-w-max'
  },
  {
      name: '',
      variant: 'md:pl-8'

  },
  {
      name: 'Worth (₦)',
      variant: 'xxs:pl-10 lg:pl-6 md:pl-10 min-w-max'
  },
  {
      name: 'Purchased by',
      variant: 'xxs:pl-6 lg:pl-0 md:pl-6 min-w-max'
  },
  {
      name: 'Status',
      variant: 'xxs:pl-6 lg:pl-0 md:pl-6 min-w-max'
  },
  {
      name: 'Date Created',
      variant: 'xxs:pl-4 lg:pl-0 md:pl-6 min-w-max'

  },
]
export const voucherFilter = [
  { id: 1, value: 'active', label: 'Active' },
  { id: 2, value: 'inactive', label: 'Inactive' },
]
export const VouchersData = [
  {
      voucher_code: "1234567897865467",
      worth: "₦ 500,000",
      purchased_by: "@Livia", 
      first_name: "Livia", 
      last_name: "Livia", 
      status: "active",
      date_created: "May 27, 2020",
      time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    purchased_by: "@Foenix", 
    first_name: "Foenix", 
    last_name: "Foenix", 
    status: "claimed",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    first_name: "Lana220", 
    last_name: "Lana220", 
    purchased_by: "@Lana220", 
    status: "active",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    purchased_by: "@Candice", 
    first_name: "Candice", 
    last_name: "Candice", 
    status: "revoked",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    purchased_by: "@Nataliiii", 
    first_name: "Nataliiii", 
    last_name: "Nataliiii", 
    status: "active",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    first_name: "Drew", 
    last_name: "Drew", 
    purchased_by: "@Drew", 
    status: "claimed",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    voucher_code: "1234567897865467",
    worth: "₦ 500,000",
    first_name: "Solana", 
    last_name: "Solana", 
    purchased_by: "@Solana", 
    status: "claimed",
    date_created: "May 27, 2020",
    time: "12:38pm"
  }
]