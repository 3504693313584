import React from 'react'
import { AuthLady } from '../../assets/icons/AuthLady'
import Logo from '../../assets/icons/Logo'

export const LeftAuth = () => {
  return (
    <div className='w-[45%] hidden lg:block px-50 bg-purple_transparent'>
    <div className='lg:h-screen my-auto lg:flex lg:flex-col lg:justify-center'>
        <div className='pl-30 mb-30'><Logo/></div>
        <AuthLady/>
        </div>
    </div>
  )
}
