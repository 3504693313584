/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export default function Services({ icon, title, link, current, index, onClick }) {
    const [scale, setScale] = useState('0')
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        if (current === index) {
            setScale('1')
            setOpacity(1)
        }
    },[current])

    return(
        <Link to={`/settings/discount-management/${link}`}>
            <div className="
                flex sm:block flex-col items-center bg-green_light text-dark_blue transition duration-700 ease-in-out p-[10px] sm:p-24 rounded-[8px]
                hover:bg-purple hover:text-white cursor-pointer hide_tap "
                style={{ scale, opacity }}
                data-testid={title}
                onClick={() => onClick ? onClick(title) : {}}
            >
                <div className="mb-[12px] sm:mb-16 lg:mb-32">{ icon }</div>
                <p className="font-dmsans_m text-12 lg:text-16">{ title }</p>
            </div>
        </Link>
    )
}

Services.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    link: PropTypes.string,
    index: PropTypes.number,
    current: PropTypes.number,
    onClick: PropTypes.func
}