import { useState } from "react"
import { useAppDispatch } from "hooks/useAppDispatch";
import { notify } from "store/modules/global";
import useHandleError from "hooks/useHandleError";
import { unwrapResult } from "@reduxjs/toolkit"
import NProgress from 'nprogress';
// Actions
import { requestUploadUrl } from "store/modules/settings/actions"
import instance from "services/axios-instance";

export default function useUpload() {
    let dispatch = useAppDispatch()
    const [uploading, setUploading] = useState(false)
    const { handleError } = useHandleError()

    const getSignedUrl = async (file, formData) => {
        try{
            if (file?.size > 3000000) return dispatch(notify({display: true, message: "File size cannot be more than 3mb"}))

            setUploading(true)
            return dispatch(requestUploadUrl(formData))
            .then(unwrapResult)
            .then(async (res) => {
                if (res.code === 200) {
                    let reqInstance = instance.create({
                        headers: {
                          'Content-Type' : '/image/*'
                        }
                    })
                    
                    NProgress.start()
                    delete reqInstance.defaults.headers.common.Authorization
                    const response = await reqInstance.put(res.data, file)
                    return response
                } 
            })
            .catch(error => {
                dispatch(notify({display: true, status: 'error', message: handleError({message: error.message})}))
            }).finally(() => {
                NProgress.done()
                setUploading(false)
            })
        } catch(error) {
            dispatch(notify({display: true, status: 'error', message: handleError({})}))
        }
    };

    return {
        getSignedUrl,
        uploading
    }
}