/* eslint-disable max-len */
import React from 'react'

const TierIcon = () => {
  return (
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7333 23.4285C19.3607 23.4285 24.7333 18.1838 24.7333 11.7142C24.7333 5.24464 19.3607 0 12.7333 0C6.10586 0 0.733276 5.24464 0.733276 11.7142C0.733276 18.1838 6.10586 23.4285 12.7333 23.4285Z" fill="#5034C4"/>
<path d="M10.4465 6.87501C11.0489 6.0451 11.8209 5.33146 12.7095 4.78284C12.7588 4.75163 12.8133 4.72965 12.8696 4.71822C12.926 4.7068 12.9831 4.70616 13.0375 4.71635C13.0919 4.72654 13.1424 4.74735 13.1861 4.77752C13.2298 4.80769 13.2657 4.8466 13.2916 4.8919L14.2845 6.62124" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.8557 7.07263C12.8637 6.77761 11.7982 6.68458 10.7246 6.79924C9.65103 6.91389 8.592 7.23382 7.6126 7.73938C7.56083 7.76587 7.51431 7.80161 7.4759 7.84442C7.43749 7.88723 7.408 7.93619 7.38924 7.9883C7.37048 8.04041 7.36285 8.09458 7.36681 8.14746C7.37077 8.20034 7.38625 8.25083 7.41229 8.29582L8.3849 9.98365" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5702 17.1094L18.7323 20.4984" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.5972 12.9649C17.9391 11.2766 15.6865 10.2104 13.1863 9.93062C10.6861 9.65082 8.07692 10.1729 5.76072 11.4165C5.70957 11.444 5.66379 11.4806 5.62613 11.524C5.58847 11.5674 5.55969 11.6167 5.54152 11.669C5.52335 11.7214 5.51616 11.7756 5.52038 11.8286C5.5246 11.8815 5.54014 11.932 5.56607 11.9771L7.10491 14.658C7.47885 15.4156 8.01979 16.0771 8.69465 16.6021C9.3695 17.127 10.1641 17.5044 11.0297 17.7111C11.8954 17.9178 12.8139 17.9495 13.7292 17.8042C14.6444 17.6589 15.5371 17.3397 16.3526 16.8662C17.1681 16.3926 17.8893 15.7746 18.472 15.05C19.0547 14.3254 19.4867 13.5094 19.7416 12.652C19.9964 11.7946 20.0688 10.9138 19.9542 10.0635C19.8396 9.21316 19.5405 8.4112 19.0752 7.70668L17.5357 5.02459C17.5099 4.97956 17.4742 4.94084 17.4309 4.91074C17.3876 4.88063 17.3374 4.85975 17.2834 4.84935C17.2294 4.83894 17.1727 4.83922 17.1166 4.85016C17.0605 4.8611 17.0062 4.88249 16.957 4.91304C14.9885 6.10441 13.4239 7.84108 12.5031 9.85672" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default TierIcon