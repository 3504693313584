import React, { useState, useCallback, useEffect } from 'react';
import CTable from 'components/Table/Table';
import { AuditTrailHeader, auditFilter } from 'constants/mocks/auditTrail';
import { UserInitals } from 'utils/ImagePlaceholder';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { useAuditTrail } from 'store/modules/auditTrail';
import useAdmin from 'hooks/fetch/useAdmin';
import dayjs from 'dayjs';
import { useDebounce } from 'hooks/useDounce';
import DateFilter from 'components/DropDown/DateFilter';

const AuditTrail = () => {
  //Redux Hooks
  const { auditTrail, loading } = useAuditTrail();
  //Local State
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  //Variable
  const per_page = perPage.value;
  //Hooks
  const { handleGetAuditTrail } = useAdmin();
  const debouncedSearch = useDebounce(search);

  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleGetAuditTrail({
        per_page,
        page,
        fromDate: toDate && fromDate,
        toDate: fromDate && toDate,
        module: filter && filter.value,
        search: debouncedSearch,
      });
    } else {
      handleGetAuditTrail({
        per_page,
        page,
        fromDate: toDate && fromDate,
        toDate: fromDate && toDate,
        module: filter && filter.value,
      });
    }
  }, [per_page, page, filter, fromDate, toDate, debouncedSearch]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleFilter = useCallback(
    (data) => {
      setFilter(data);
    },
    [filter],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  useEffect(() => {
    document.title = 'Audit Trail - Pouchers Admin';
  }, []);

  return (
    <DashboardLayout
      pageHeader="Audit Trail"
      cardTitle="Recent Activities"
      SearchFunc={handleChange}
      filterBy="Module"
      filterStyles="h-[200px] scroll-styled overflow-y-scroll"
      options={auditFilter}
      searchValue={search}
      variant="mb-0"
      dropDownValue={filter}
      placeholder="Search names or roles"
      handlePerPage={handlePerPage}
      Dropdownfunc={handleFilter}
      perPage={perPage}
      filter={
        <DateFilter
          endDate={toDate}
          setEndDate={setToDate}
          setStartDate={setFromDate}
          startDate={fromDate}
        />
      }
    >
      <div className="mt-7">
        <CTable
          header={AuditTrailHeader}
          totalPayload={auditTrail?.total}
          perPage={per_page}
          loading={loading}
          changePage={setPage}
          currentPage={auditTrail?.page}
          showCheckBox={false}
          data={auditTrail?.audit_trail}
        >
          {(item) => (
            <>
              <td>
                <div className="flex items-center space-x-3 pl-6  min-w-max whitespace-no-wrap ml-0 mr-4">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    <p className=" text-gray_dark_100 font-dmsans_m text-14">
                      {UserInitals(item.admin_firstname, item.admin_lastname)}
                    </p>
                  </div>
                  <div>
                    <p className="text-grey-900 text-14 font-dmsans_m">
                      {item.admin_firstname} {item.admin_lastname}
                    </p>
                    <p className="text-grey-500 text-14 font-dmsans_r">{item.admin_role}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="capitalize text-grey-500 font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  {item.description}
                </p>
              </td>
              <td>
                <div className="flex items-center space-x-3 min-w-max mx-4 whitespace-no-wrap">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    {item.customer_profilepicture && <img src={`${item.customer_profilepicture}`} alt="item" />}
                    {!item.customer_profilepicture && item.customer_firstname && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.customer_firstname, item.customer_lastname)}
                      </p>
                    )}
                    {!item.customer_firstname && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.admin_firstname, item.admin_lastname)}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <p className="text-gray-900 font-dmsans_m">
                      {item.customer_firstname
                        ? item.customer_firstname + ' ' + item.customer_lastname
                        : item.admin_firstname + ' ' + item.admin_lastname}
                    </p>
                    <p className="text-grey-500 font-dmsans_r">{item.customer_tag}</p>
                  </div>
                </div>
              </td>
              <td className="">
                <p className="capitalize text-dark_blue font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  {item.action_module.replace('_', ' ').replace('_', ' ')}
                </p>
              </td>
              <td>
                <p className="text-blue_black font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>

              <td></td>
            </>
          )}
        </CTable>
      </div>
    </DashboardLayout>
  );
};

export default AuditTrail;
