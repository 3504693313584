/* eslint-disable max-len */
import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { HiDotsHorizontal } from 'react-icons/hi'

const Pagination = ({totalPayload, currentPage, changePage, perPage}) => {
  const totalPages = Math.ceil(totalPayload / perPage)

  return (
    <div className="mt-10 flex justify-between items-center flex-wrap">
    <div className="text-12 text-black-transparent font-inter_regular">Showing Page {currentPage} of {totalPages}</div>
      <ReactPaginate
        previousLabel={
            <span className="mr-1">Prev</span>
        }
        nextLabel={
            <span className="ml-1">Next</span>
        }
        breakLabel={<HiDotsHorizontal/>}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        forcePage={currentPage - 1}
        onPageChange={changePage}
        activeLinkClassName={'block bg-purple text-white'}
        previousClassName={'text-gray-light w-2/5 text-left'}
        disabledLinkClassName={'text-gray-[#B8B8B810]'}
        previousLinkClassName={
          'relative inline-flex items-center bg-white px-1 py-2 text-sm font-medium text-black-transparent'
        }
        nextClassName={'text-black-transparent w-2/5 text-right'}
        nextLinkClassName={
          'relative inline-flex items-center rounded-r-md bg-white px-2 py-2 2xl:ml-4/5 text-sm font-medium text-gray-light hover:text-gray-500 focus:z-20'
        }
        pageClassName={'text-black-transparent ml-2'}
        pageLinkClassName={
          ' relative inline-flex items-center px-2 py-0.5 text-sm font-medium rounded-sm'
        }
        breakClassName={'text-neutral-500'}
        breakLinkClassName={'relative inline-flex items-center px-6 py-2 text-18'}
        containerClassName={'flex items-center space-x-4px text-12 rounded-md px-2 text-black-transparent font-inter_regular'}
      />
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
    totalPages: PropTypes.any,
    changePage: PropTypes.func,
    styles: PropTypes.string,
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPayload: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };