import React from 'react'
import { useCustomerState } from 'store/modules/customer'
import { formatDate } from 'utils/dateFunctions'
import { UserInitals } from 'utils/ImagePlaceholder'
import CalenderIcon from '../../../assets/icons/CalenderIcon'

const Profile = () => {
  const { selectedCustomer } = useCustomerState();
  
  
  return (
    <div>
     <div className='font-dmsans_m py-32 px-24'>
        <p className='text-black-transparent text-16'>Personal Details</p>
        <div>
            <div 
                className=' rounded-full bottom-0 left-7 w-[100px] h-[100px] bg-grey-400 flex items-center justify-center my-6'
            >
        {selectedCustomer?.profile_picture && 
            <img src={ selectedCustomer?.profile_picture } alt='profile' className='profile_picture'/> }
        {!selectedCustomer?.profile_picture && 
                <p className='uppercase text-gray_dark_100 font-dmsans_m text-40'>
                    {UserInitals(selectedCustomer?.first_name, selectedCustomer?.last_name)}
                </p>
              }
            </div>
        </div>
        <div>
        <div className=''>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className='text-10 text-grey-600'>First Name</p>
                <p className='text-16 text-dark_blue mt-1 capitalize break-words'>{selectedCustomer?.first_name}</p>
            </div>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className='text-10 text-grey-600'>Last Name</p>
                <p className='text-16 text-dark_blue mt-1 capitalize break-words'>{selectedCustomer?.last_name}</p>
            </div>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className='text-10 text-grey-600'>Poucher Tag</p>
                <p className='text-16 text-dark_blue mt-1 capitalize break-words'>{selectedCustomer?.tag ? ('@' + selectedCustomer?.tag) : 'N/A'}</p>
            </div>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className='text-10 text-grey-600'>Gender</p>
                <p className='text-16 text-dark_blue mt-1 capitalize'>{selectedCustomer?.gender || 'N/A'}</p>
            </div>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r flex justify-between items-center">
            <div>
                <p className='text-10 text-grey-600'>Date of Birth</p>
                <p className='text-16 text-dark_blue mt-1'>
                {selectedCustomer?.dob ? formatDate(selectedCustomer?.dob) : 'N/A'}
                </p>
                </div>
                <div>
                    <CalenderIcon/>
                </div>
            </div>
            <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className='text-10 text-grey-600'>Residential Address</p>
                <p className='text-16 text-dark_blue mt-1 break-words'>{selectedCustomer?.address ? `${selectedCustomer?.address + ','}` : ''} {selectedCustomer?.address ? `${selectedCustomer?.state + ','}` : ''} {`${selectedCustomer?.country || ''}`}</p>
            </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Profile