import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  authReducer,
  globalReducer,
  adminReducer,
  transferReducer,
  customerReducer,
  auditTrailReducer,
  voucherReducer,
  scheduledPaymentReducer,
  virtualCardReducer,
  paymentReducer,
  dashboardReducer,
  settingsReducer,
} from './modules/index'

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  admin: adminReducer,
  transfer: transferReducer,
  customer: customerReducer,
  auditTrail: auditTrailReducer,
  vouchers: voucherReducer,
  scheduledPayment: scheduledPaymentReducer,
  virtualCard: virtualCardReducer,
  payment: paymentReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer
})

const reducerProxy = (state, action) => {
  if (action.type === 'logout') return rootReducer({}, action)

  const actionType = action.meta?.requestStatus
  const key = action.type.split('/')[0]

  if (actionType) {
    const loading = { ...state[key], loading: actionType === 'pending' }
    return rootReducer({ ...state, [key]: loading }, action)
  }

  return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, reducerProxy)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store)

export { store, persistor }
