/* eslint-disable max-len */
import React from "react"
import PropTypes from 'prop-types';

export default function DashBoardIcon({color}) {
    return(
        <svg width="14" height="14" viewBox="0 0 14 14" fill='none' xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M9.71676 0.333313H11.9741C12.9089 0.333313 13.6664 1.09721 13.6664 2.03996V4.31633C13.6664 5.25907 12.9089 6.02297 11.9741 6.02297H9.71676C8.78192 6.02297 8.02441 5.25907 8.02441 4.31633V2.03996C8.02441 1.09721 8.78192 0.333313 9.71676 0.333313Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.02536 0.333313H4.28267C5.21751 0.333313 5.97502 1.09721 5.97502 2.03996V4.31633C5.97502 5.25907 5.21751 6.02297 4.28267 6.02297H2.02536C1.09051 6.02297 0.333008 5.25907 0.333008 4.31633V2.03996C0.333008 1.09721 1.09051 0.333313 2.02536 0.333313ZM2.02536 7.97699H4.28267C5.21751 7.97699 5.97502 8.74089 5.97502 9.68363V11.96C5.97502 12.9021 5.21751 13.6666 4.28267 13.6666H2.02536C1.09051 13.6666 0.333008 12.9021 0.333008 11.96V9.68363C0.333008 8.74089 1.09051 7.97699 2.02536 7.97699ZM11.974 7.97699H9.71668C8.78184 7.97699 8.02433 8.74089 8.02433 9.68363V11.96C8.02433 12.9021 8.78184 13.6666 9.71668 13.6666H11.974C12.9088 13.6666 13.6663 12.9021 13.6663 11.96V9.68363C13.6663 8.74089 12.9088 7.97699 11.974 7.97699Z" fill={color}/>
        </svg>
        
    )
}
DashBoardIcon.propTypes = {
    color: PropTypes.string
  }
