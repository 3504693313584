/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function ScheduleIcon ({color}) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6663 6.99992C13.6663 10.6826 10.6817 13.6666 6.99967 13.6666C3.31767 13.6666 0.333008 10.6826 0.333008 6.99992C0.333008 3.31859 3.31767 0.333252 6.99967 0.333252C10.6817 0.333252 13.6663 3.31859 13.6663 6.99992Z"
        fill={color}
      />
      <path
        d="M9.38229 9.54294C9.29496 9.54294 9.20696 9.52028 9.12629 9.47294L6.50896 7.91161C6.35829 7.82094 6.26562 7.65761 6.26562 7.48161V4.11694C6.26562 3.84094 6.48963 3.61694 6.76562 3.61694C7.04163 3.61694 7.26562 3.84094 7.26562 4.11694V7.19761L9.63896 8.61294C9.87563 8.75494 9.95363 9.06161 9.81229 9.29894C9.71829 9.45561 9.55229 9.54294 9.38229 9.54294Z"
        fill={color}
      />
    </svg>
  );
}
ScheduleIcon.propTypes = {
  color: PropTypes.string
}
