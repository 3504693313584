import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useInputValidate from '../../hooks/useInputValidate';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

const CustomInput = ({ id, name, placeholder, value, label, onChange, type, readOnly, styles, variant, isDisabled = false }) => {
  const { error, validate, setError } = useInputValidate();
  const [passwordView, setPasswordView] = useState(false)

  return (
    <>
      <label htmlFor={id} className={`${variant} text-black-transparent mb-2 block text-14`}>
        {label}
      </label>
        {
        type !== 'password' &&
        <div className="relative mb-2">
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          data-testid={`test-${id}`}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly}
          disabled={isDisabled}
          onBlur={() => validate({ name, value })}
          onKeyDown={() => setError('')}
          className={`${
            error === '' ? 'border-deep-grey' : 'border-red'
          } ${styles || 'rounded' } px-16 py-12 text-16 w-full border outline-0 font-dmsans_r 
                        `}
        />
      </div>}
      {
        type === 'password' &&
      <div className="relative mb-2">
        <input
          id={id}
          name={name}
          type={passwordView ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          data-testid={`test-${id}`}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly}
          onBlur={() => validate({ name, value })}
          onKeyDown={() => setError('')}
          className={`${
            error === '' ? 'border-deep-grey' : 'border-red'
          } ${styles || 'rounded' } px-16 py-12 text-16 w-full border outline-0  font-dmsans_r 
                        `}
        />
        <div
          data-testid="view" 
          className="absolute bottom-1 right-1 p-3 text-16 cursor-pointer text-light_blue rounded-lg" 
          onClick={() => setPasswordView(!passwordView)}
        >
          {
              !passwordView ? 
              <AiOutlineEyeInvisible data-testid='hide' />
               : <AiOutlineEye data-testid='show' />
          }
        </div>
      </div>
      }
      <p className="font-dmsans_r text-red text-12 leading-[16px]">{error}</p>
    </>
  );
};

export default CustomInput;

CustomInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.string,
  styles: PropTypes.string,
};
