import React, { useState, useCallback, useEffect } from 'react';
import SearchInput from 'components/Input/SearchInput';
import PropTypes from 'prop-types';
import Dropdown from 'components/DropDown/DropDown';
import { IoIosArrowDown } from 'react-icons/io';
import CTable from 'components/Table/Table';
import { EntriesfilterList } from 'constants/mocks/customers';
import { ALLTransfersHeader, PTPTransfersHeader, TransfersHeader } from 'constants/mocks/transfers';
import Modal from 'components/Modal/Modal';
import Status from 'components/Status';
import ViewTransfer from './ViewTransfer';
import CustomButton from 'components/Button/CustomButton';
import useCustomer from 'hooks/fetch/useCustomer';
import { useTransferState } from 'store/modules/transfers';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';
import ExportExcel from 'components/ExportExcel';
import { useDebounce } from 'hooks/useDounce';
import DateFilter from 'components/DropDown/DateFilter';
import useFormatDate from 'hooks/useFormatDate';

const TransferList = ({ tab }) => {
  //Hook
  const { transferList, loading, selectedTransfer } = useTransferState();

  //local State
  const { getFirstDayOfMonth, getLastDayOfMonth } = useFormatDate();

  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const [showViewModal, setShowViewModal] = useState(false);
  const [fromDate, setFromDate] = useState(getFirstDayOfMonth());
  const [toDate, setToDate] = useState(getLastDayOfMonth());

  //Variable
  const per_page = perPage.value;
  let type;
  if (tab?.name !== 'all') type = tab?.value;

  //Redux Hooks
  const { handleGetAllTransfers, handleViewCustomer, handleViewTransfer } = useCustomer();
  const debouncedSearch = useDebounce(search);
  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleGetAllTransfers({
        search: debouncedSearch,
        per_page,
        page,
        type,
        fromDate: toDate && fromDate,
        toDate: fromDate && toDate,
      });
    } else {
      handleGetAllTransfers({ per_page, page, type, fromDate, toDate });
      // handleGetAllTransfers({ per_page, page, type, fromDate: toDate && fromDate, toDate: fromDate && toDate });
    }
  }, [per_page, page, tab?.value, debouncedSearch, fromDate, toDate]);

  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  const handleViewModal = useCallback(() => {
    setShowViewModal(!showViewModal);
  }, [showViewModal]);

  return (
    <div className="mt-7">
      <div className="flex justify-between items-center mb-6">
        <p className="font-dmsans_b text-18">Payments List</p>
        <ExportExcel excelData={transferList?.transfers} fileName={`file-transactionList-${new Date().toISOString()}`}>
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
        </ExportExcel>
      </div>
      <div className="flex justify-between mb-6 flex-wrap">
        <div className="flex space-x-6">
          <SearchInput
            placeholder="Search name, tag, transaction ID, account num."
            value={search}
            handleChange={handleChange}
          />
          <DateFilter endDate={toDate} setEndDate={setToDate} setStartDate={setFromDate} startDate={fromDate} />
        </div>
        <div className="flex items-center mt-4 md:mt-0">
          <span className="text-14 font-dmsans_r mr-2">Showing:</span>
          <Dropdown
            variant="border border-light-smoke w-[142px]"
            placeholder="10 Entries"
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            icon={<IoIosArrowDown />}
            value={perPage}
            type="filter"
            options={EntriesfilterList}
            onChange={handlePerPage}
          />
        </div>
      </div>
      <CTable
        // header={TransfersHeader}
        header={tab.name === "p2p" ? PTPTransfersHeader : tab.name === "all" ? ALLTransfersHeader : TransfersHeader}
        totalPayload={transferList?.total}
        changePage={setPage}
        perPage={per_page}
        showCheckBox={false}
        currentPage={transferList?.page}
        loading={loading}
        data={transferList?.transfers}
      >
        {(item) => (
          <>
            <td>
              <p className="text-grey-500 font-dmsans_r pl-6 min-w-max whitespace-no-wrap">{item.transaction_id}</p>
            </td>
            <td className="" data-testid="test-customer">
              <div className="font-dmsans_r min-w-max whitespace-no-wrap">
                {item.transaction_category === 'fund-wallet' ? (
                  <div className="mx-4">
                    <p className="text-gray-900">{item.extra_details?.senderName || 'N/A'}</p>
                    <p className="text-grey-500">
                      {item.extra_details?.senderBank || 'N/A'} | {item.extra_details?.senderAccountNumber || 'N/A'}
                    </p>
                  </div>
                ) : (
                  <p
                    className="text-grey-500 font-dmsans_r min-w-max mx-4 whitespace-no-wrap cursor-pointer"
                    onClick={() => {
                      window.open(`/manage-customer/${item.user_id}`,'_blank')
                      // handleViewCustomer(item.user_id)
                    }}
                  >
                    @{item.sender_tag}
                  </p>
                )}
              </div>
            </td>
            <td>
              <p className="font-dmsans_r text-grey-500 min-w-max mx-4 whitespace-no-wrap">
                {formatCurrency(
                  item.transaction_category === 'fund-wallet' ? item.extra_details.originalAmountSent : item.amount,
                  item.currency,
                )}
              </p>
            </td>
            <td>
              <div className="font-dmsans_r min-w-min mx-4 whitespace-no-wrap">
                {item.transaction_category === 'local-bank-transfer' && (
                  <div>
                    <p className="text-gray-900">{item.extra_details?.receiver_account_name || 'N/A'}</p>
                    <p className="text-grey-500">
                      {item.extra_details?.bank_name || 'N/A'} | {item.extra_details?.receiver_account_number || 'N/A'}
                    </p>
                  </div>
                )}
                {item.transaction_category === 'p2p-transfer' && (
                  <p className="text-grey-500">@{item.extra_details.receiver_tag || 'N/A'}</p>
                )}
                {item.transaction_category === 'fund-wallet' && (
                  <p className="text-grey-500">@{item.receiver_tag || 'N/A'}</p>
                )}
              </div>
            </td>
            {tab.name === "all" && (
              <td>
                <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                  {item.transaction_category === "p2p-transfer" ? "P2P" : "External"}
                </p>
              </td>
            )}
            {tab.name !== "p2p" && (
              <td>
                <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                  Wallet
                  <span className="text-grey font-dmsans_r"> | Anchor</span>
                </p>
              </td>
            )}
            <td>
              <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                {dayjs(item.created_at).format('MMM DD, YYYY')}
                <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
              </p>
            </td>
            <td>
              <p
                className=" text-purple-300 font-inter_medium cursor-pointer min-w-max mx-4 whitespace-no-wrap"
                data-testid="view-dropDown"
                onClick={() => handleViewTransfer(item.transaction_id, () => setShowViewModal(true))}
              >
                View
              </p>
            </td>
          </>
        )}
      </CTable>
      {showViewModal && (
        <Modal
          closeModal={handleViewModal}
          title="Transfer Details"
          containerVariant="md:w-[700px] w-[320px]"
          dividerVariant="mb-8 mt-5"
          status={
            <Status
              showIcon
              status={selectedTransfer?.status === 'success' ? 'successful' : selectedTransfer?.status}
            />
          }
        >
          <ViewTransfer />
        </Modal>
      )}
    </div>
  );
};

export default TransferList;
TransferList.propTypes = {
  tab: PropTypes.object,
};
