export const scheduleAndAutomatedHeader = [
  {
    name: 'Customer',
    variant: 'pl-6',
  },
  {
    name: 'Bill Type',
    variant: 'pl-4 min-w-max',
  },
  {
    name: 'Amount',
    variant: 'pl-4 min-w-max',
  },
  {
    name: 'Frequency',
    variant: 'pl-4 min-w-max',
  },
  {
    name: 'Method/API',
    variant: 'pl-4 min-w-max',
  },
  {
    name: 'Status',
    variant: 'pl-4 min-w-max',
  },
  {
    name: 'Scheduled Date',
    variant: 'pl-4 min-w-max',
  },
];
export const scheduleFilterList = [
  { id: 1, value: 'airtime-purchase', label: 'Airtime' },
  { id: 2, value: 'data-purchase', label: 'Data' },
  { id: 3, value: 'electricity-purchase', label: 'Electricity' },
  { id: 4, value: 'education-purchase', label: 'Education' },
  { id: 5, value: 'cable-purchase', label: 'Cable TV' },
  { id: 6, value: 'internet-purchase', label: 'Internet' },
  { id: 7, value: 'voucher-purchase', label: 'Vouchers' },
  { id: 8, value: 'betting-purchase', label: 'Betting' },
  { id: 9, label: 'P2P transfer', value: 'p2p-transfer' },
  { id: 10, label: 'Local Bank Transfer', value: 'local-bank-transfer' },
  { id: 11, label: 'Fund Wallet', value: 'fund-wallet' },
];
export const scheduleAndAutomatedData = [
  {
    customer: 'Olivia Rhye',
    tag: '@Muhandis',
    first_name: 'Olivia',
    last_name: 'Rhye',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Monthly',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Phoenix Baker',
    tag: 'Muhandis',
    first_name: 'Olivia',
    last_name: 'Rhye',
    bill_type: 'Transfers',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Lana Steiner',
    tag: '@Isla',
    first_name: 'Lana',
    last_name: 'Steiner',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Weekly',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Demi Wilkinson',
    tag: '@Isla',
    first_name: 'Demi',
    last_name: 'Wilkinson',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Annually',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Candice Wu',
    tag: '@Isla',
    first_name: 'Candice',
    last_name: 'Wu',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'revoked',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Natali Craig',
    tag: '@Isla',
    first_name: 'Natali',
    last_name: 'Craig',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Drew Cano',
    tag: '@Muhandis',
    first_name: 'Drew',
    last_name: 'Cano',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Drew Cano',
    tag: '@Muhandis',
    first_name: 'Drew',
    last_name: 'Cano',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Drew Cano',
    tag: '@Muhandis',
    first_name: 'Drew',
    last_name: 'Cano',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    customer: 'Drew Cano',
    tag: '@Muhandis',
    first_name: 'Drew',
    last_name: 'Cano',
    bill_type: 'Bill Payment',
    amount: '₦ 500,000',
    frequency: 'Once',
    status: 'claimed',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
];
