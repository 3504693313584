import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  dashboardAnalytics: {},
  providerBalancesUserAnalytics: {},
  loading: false,
  loadingProvider: false,
  error: null
}

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers
})

// Selectors,
const selectDashboard = (state) => state.dashboard;


//Auth Redux State
export const useDashboardState = () => useAppSelector(selectDashboard);

export default dashboard.reducer