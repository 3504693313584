/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import BackButton from 'components/Button/BackButton';
import CustomButton from 'components/Button/CustomButton';
import Dropdown from 'components/DropDown/DropDown';
import SearchInput from 'components/Input/SearchInput';
import CTable from 'components/Table/Table';
import { RoleListHeader } from 'constants/mocks/roles_permission';
import { HiDotsVertical } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useRolesState } from 'store/modules/admin';
import dayjs from 'dayjs';
import useAdmin from 'hooks/fetch/useAdmin';
import { useDebounce } from 'hooks/useDounce';
import DateFilter from 'components/DropDown/DateFilter';
import usePermissions from 'hooks/usePermissions';
import Title from 'components/Header/Title';

const RolesAndPermissions = () => {
  //Local State
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState();
  //Hooks
  const { handleSingleRole, handleGetAllRole, deleterole } = useAdmin();
  const navigate = useNavigate();
  const { roleList, loading } = useRolesState();
  const debouncedSearch = useDebounce(search);
  const { userIsPermittedTo } = usePermissions();

  //Variable
  const per_page = 10;

  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleNavigation = useCallback(() => {
    navigate('/settings/create-role');
  }, []);

  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleGetAllRole({
        search: debouncedSearch,
        per_page,
        page,
        start_date: toDate && fromDate,
        end_date: fromDate && toDate,
      });
    } else {
      handleGetAllRole({ per_page, page, start_date: toDate && fromDate, end_date: fromDate && toDate });
    }
  }, [per_page, page, fromDate, toDate, debouncedSearch]);

  useEffect(() => {
    document.title = 'Roles & Permission - Pouchers Admin';
  }, []);

  return (
    <div>
      <Title title="Roles & Permission" variant="mb-4" />
      <div className="p-32 bg-white rounded-2xl">
        <div className="">
          <BackButton />
          <div className="flex mt-10 justify-between flex-wrap items-center">
            <div className="flex md:space-x-4 md:space-y-0 xxs:space-y-4 flex-wrap">
              <SearchInput placeholder="Search by name" value={search} handleChange={handleChange} />
              <DateFilter endDate={toDate} setEndDate={setToDate} setStartDate={setFromDate} startDate={fromDate} />
            </div>
            {userIsPermittedTo('Create', 'admin_management') && (
              <CustomButton
                name="Create Role"
                icon={
                  <>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7 1V7M7 7V13M7 7H13M7 7L1 7"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </>
                }
                onClick={handleNavigation}
                variant="border border-purple bg-purple text-white rounded font-dmsans_m xxs:mt-4 lg:mt-0"
              />
            )}
          </div>
          <div className="mt-10">
            <CTable
              header={RoleListHeader}
              totalPayload={roleList?.total}
              perPage={per_page}
              changePage={setPage}
              loading={loading}
              currentPage={roleList?.page}
              showCheckBox={false}
              data={roleList?.roles}
            >
              {(item) => (
                <>
                  <td className="pl-6">
                    <p className="font-dmsans_r text-black-transparent min-w-max whitespace-no-wrap mr-4">
                      {item.name}
                    </p>
                  </td>
                  <td>
                    <p className="font-dmsans_r text-black-transparent min-w-max whitespace-no-wrap mx-4">
                      {item.permissions}
                    </p>
                  </td>
                  <td>
                    <p className="font-dmsans_r text-black-transparent min-w-max whitespace-no-wrap mx-4">
                      {item.max_assignees}
                    </p>
                  </td>
                  <td>
                    <p className="font-dmsans_r text-black-transparent min-w-max whitespace-no-wrap mx-4">
                      {item.no_of_persons}
                    </p>
                  </td>
                  <td>
                    <p className="font-dmsans_m text-blue_black min-w-max whitespace-no-wrap mx-4">
                      {dayjs(item.created_at).format('MMM DD, YYYY')}
                      <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
                    </p>
                  </td>
                  {(userIsPermittedTo('Delete', 'admin_management') ||
                    userIsPermittedTo('Update', 'admin_management')) && (
                    <td>
                      <div className="min-w-max mx-4 whitespace-no-wrap">
                        <Dropdown
                          type="filterIcon"
                          filterIcon={<HiDotsVertical className="text-16 text-dark_blue" />}
                          optionContainerVariant="w-[115px] -top-10 z-30"
                        >
                          <div>
                            {userIsPermittedTo('Update', 'admin_management') && (
                              <div
                                key={1}
                                className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                                onClick={() => handleSingleRole(item.name)}
                              >
                                Edit
                              </div>
                            )}
                            {userIsPermittedTo('Delete', 'admin_management') && (
                              <div
                                key={2}
                                className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r text-red-500"
                                onClick={() => deleterole(item.name, () => handleGetAllRole({ per_page: 10, page: 1 }))}
                              >
                                Delete
                              </div>
                            )}
                          </div>
                        </Dropdown>
                      </div>
                    </td>
                  )}
                </>
              )}
            </CTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPermissions;
