import React from 'react';

const UnFreezeIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F4F4FB" />
      <path
        d="M28.707 16.9688L25.707 19.9688L28.707 22.9688"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9688 28.7031L19.9688 25.7031L16.9688 28.7031"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2342 22.9653L14.2342 19.9653L11.2342 16.9653"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9722 11.2302L19.9722 14.2302L22.9722 11.2302"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9932 19.9688H9.94922"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1099 27.106L12.8359 12.832"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1099 12.832L12.8359 27.106"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9727 9.94531V29.9893"
        stroke="#FF6414"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnFreezeIcon;
