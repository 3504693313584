import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import Close from 'assets/icons/CloseIcon';
import BackButton from 'components/Button/BackButton';
import Title from 'components/Header/Title';
import Input from 'components/Input/Input';
import { useNavigate } from 'react-router-dom';
import TextArea from 'components/Input/TextArea';
import CustomButton from 'components/Button/CustomButton';
import NotificationModal from 'components/Modal/NotificationModal';
import useSettings from 'hooks/fetch/useSettings';
import useUpload from 'hooks/fetch/useUpload';
import moment from "moment";
import Dropdown from 'components/DropDown/DropDown';
import { MessageType } from 'constants/mocks/notifications';
import { AWS_S3_BUCKET } from 'constants';

const SendMessage = () => {
  const navigate = useNavigate();
  const { create_notification, loading } = useSettings()
  const [showModal, setShowModal] = useState(false);
  const [selectedMessageType, setSelectedMessageType] = useState('')
  const { getSignedUrl, uploading } = useUpload()
  const [image, setImage] = useState()
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    message_type: ''
  });

  const handleShowModal = useCallback(() => {
    setFormData({ title: '', message: '', message_type: '' })
    setImage()
    setShowModal(!showModal)
  }, [showModal])

  const handleChange = useCallback(e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }, [formData])

  const submit = (e) => {
    e.preventDefault()
    if (!image) {
      create_notification(
        { title: formData.title, subject: formData.message },
        () => { handleShowModal() }
      )
      return
    }

    const timestamp = moment()?.format('YYYYMMDD-HHmmss')
    getSignedUrl(image, { notification_image: `${timestamp}${image?.name}` })
      .then((res) => {
        if (res?.status === 200) {
          create_notification(
            { title: formData.title, subject: formData.message, image: `${AWS_S3_BUCKET}/${timestamp}${image?.name}` },
            () => { handleShowModal() }
          )
        }
      })
  }

  return (
    <div>
      <div className="flex items-center space-x-2">
        <BackButton />
        <Title title="Messaging" variant="" />
      </div>
      <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border h-[75vh] mt-6">
        <div className="grid grid-cols-3">
          <div className="border border-gray-150 p-6 col-span-2 rounded-lg">
            <div className="flex items-center justify-between">
              <p className="text-black-200 font-dmsans_b text-16 leading-6">In App Message</p>
              <div
                className="cursor-pointer" data-testid="close"
                onClick={() => navigate(-1)}
              >
                <Close color="#3A434B" />
              </div>
            </div>
            <hr className="mt-4 mb-6 h-px border-0 bg-light-smoke" />
            <form onSubmit={submit}>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <Input
                    label="Title"
                    type="text"
                    placeholder="Message Title"
                    id="title"
                    name="title"
                    value={formData.title}
                    readOnly={loading}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <Dropdown
                    variant="w-full"
                    placeholder=""
                    innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
                    value={selectedMessageType}
                    type="input"
                    inputLabel="Message Type"
                    inputStyles="border border-gray-600 text-14 rounded-lg font-dmsans_r h-[53px]"
                    inputPlaceholder="Select message type"
                    optionContainerVariant="top-[80px] w-full"
                    inputName="role"
                    inputId="role"
                    options={MessageType}
                    onChange={(data) => {
                      setSelectedMessageType(data);
                      setFormData({ ...formData, message_type: data.value });
                    }}
                  />
                </div>
              </div>
              <div className="">
                <TextArea
                  label="Message"
                  // type="text"
                  placeholder="Enter Message here"
                  id="message"
                  name="message"
                  rows={5}
                  value={formData.message}
                  readOnly={loading}
                  onChange={handleChange}
                />
              </div>
              <div className='w-full text-center my-[24px] cursor-pointer border border-[#D0D5DD] rounded-[8px] py-[16px] px-[24px]'>
                <label htmlFor='file' className='cursor-pointer'>
                  <p className="text-grey font-dmsans_r mb-1"><span className='text-purple font-dmsans_b'>Click to upload an image(optional)</span> or drag and drop</p>
                  <p className="text-grey font-dmsans_r mb-1">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                  <p>{image?.name}</p>
                  <input
                    type="file"
                    id="file"
                    data-testid='file-web'
                    hidden
                    accept='image/png, image/jpeg, image/jpg'
                    disabled={uploading}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </label>
              </div>
              <div className="flex justify-end items-center space-x-6 mt-10">
                <CustomButton
                  name="Cancel"
                  type='button'
                  disabled={loading || uploading}
                  onClick={() => navigate(-1)}
                  variant="text-purple rounded font-dmsans_r"
                />
                <CustomButton
                  name="Send Notification"
                  disabled={!formData.title || !formData.message || loading || uploading}
                  loading={loading || uploading}
                  variant="border border-purple bg-purple text-white rounded font-dmsans_r"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal && (
        <NotificationModal
          closeModal={handleShowModal}
          onClick={handleShowModal}
          title="Your message has been sent!"
          containerVariant="min-w-[400px]"
          buttonName="Done"
        />
      )}
    </div>
  );
};

export default SendMessage;
SendMessage.propTypes = {
  message: PropTypes.object,
  setSelectedMessage: PropTypes.func
}
