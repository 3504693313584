/* eslint-disable max-len */
import React from 'react';

export const KycIcon = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M6.05782 13.6673C5.97527 13.6673 5.89272 13.6484 5.81822 13.6098L3.41749 12.3671C2.73627 12.014 2.20337 11.6179 1.78725 11.1564C0.876488 10.147 0.369764 8.85132 0.36171 7.50726L0.333521 3.08349C0.330166 2.57293 0.659704 2.11467 1.15233 1.94209L5.5605 0.405176C5.82225 0.311694 6.1142 0.310386 6.38065 0.4006L10.8056 1.88521C11.3009 2.05061 11.6358 2.5056 11.6385 3.0155L11.6667 7.44254C11.6754 8.78464 11.1861 10.0856 10.2895 11.106C9.87805 11.5741 9.34985 11.9761 8.67534 12.3357L6.29609 13.6065C6.22226 13.6464 6.14038 13.6667 6.05782 13.6673Z"
        fill="#3A434B"
      />
      <path
        d="M5.5464 8.54728C5.41754 8.54793 5.28868 8.50152 5.18935 8.40607L3.91146 7.17707C3.71414 6.98618 3.71213 6.67631 3.90744 6.48412C4.10274 6.29127 4.42154 6.28931 4.61954 6.47954L5.53902 7.36338L7.78404 5.14986C7.98002 4.95701 8.29882 4.95505 8.49614 5.14528C8.69413 5.33617 8.69615 5.64669 8.50084 5.83823L5.90145 8.40149C5.80346 8.49825 5.67527 8.54662 5.5464 8.54728Z"
        fill="#130F26"
      />
    </svg>
  );
};
