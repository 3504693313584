import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import Input from 'components/Input/Input';
import CustomButton from 'components/Button/CustomButton';
import useSettings from 'hooks/fetch/useSettings';

export default function EditInternetFeeFunding({amount, cancel}) {
    const { loading, update_env } = useSettings()
    const [formData, setFormData] = useState({
      internet_fee: amount,
    })

    const handleChange = useCallback((e) => {
        if (isNaN(e.target.value)) return
        setFormData({...formData, [e.target.name]: e.target.value})
    },[formData])

    const submit = useCallback((e) => {
        e.preventDefault()
        update_env({formData, callback: cancel})
    },[formData])

  return (
    <form onSubmit={submit}>
        <p className='text-purple font-dmsans_b text-16 mt-[24px] mb-[16px]'>Internet Fee</p>
        <div>
            <Input
                type="text"
                placeholder=""
                id="internet_fee"
                name="internet_fee"
                readOnly={loading}
                value={formData.internet_fee}
                onChange={handleChange}
            />
        </div>
        <div className="flex justify-end items-center space-x-6">
          <p className="text-purple font-dmsans_r cursor-pointer" onClick={cancel}>
            Cancel
          </p>
          <CustomButton
            name="Update"
            disabled={loading}
            loading={loading}
            variant="border border-purple bg-purple text-white rounded font-dmsans_r"
          />
        </div>
    </form>
  );
}

EditInternetFeeFunding.propTypes = {
    amount: PropTypes.string,
    cancel: PropTypes.func
}