/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../assets/icons/Loader';

export default function CustomButton({ name, icon, disabled, onClick, variant, type, loading }) {
  return (
    <button
      type={type || 'submit'}
      disabled={disabled}
      onClick={onClick}
      className={`${variant} text-16 py-2 px-6 flex justify-center items-center cursor-pointer`}
    >
      <p className='flex items-center'><span className={`${icon && 'mr-2.5'}`}>{icon}</span>
        {
          loading ? <Loader /> : <span>{name}</span>
        }
      </p>
    </button>
  );
}

CustomButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};
