/* eslint-disable max-len */
import React from 'react';

export default function SettingIcon () {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00815 8.88666C5.9385 8.88666 5.07324 8.05333 5.07324 7.00666C5.07324 5.95999 5.9385 5.12 7.00815 5.12C8.0778 5.12 8.92261 5.95999 8.92261 7.00666C8.92261 8.05333 8.0778 8.88666 7.00815 8.88666Z"
        fill="#6D6D6F"
      />
      <path
        opacity="0.4"
        d="M13.1537 8.58004C13.0243 8.38004 12.8403 8.18004 12.6019 8.05337C12.4111 7.96004 12.2885 7.80671 12.1795 7.62671C11.832 7.05337 12.0364 6.30004 12.6155 5.96004C13.2968 5.58004 13.5148 4.73337 13.1197 4.07337L12.6632 3.28671C12.2749 2.62671 11.4232 2.39337 10.7487 2.78004C10.1492 3.10004 9.37932 2.88671 9.03185 2.32004C8.92284 2.13337 8.86152 1.93337 8.87515 1.73337C8.89559 1.47337 8.81383 1.22671 8.6912 1.02671C8.43912 0.613374 7.98264 0.333374 7.47848 0.333374H6.51784C6.02048 0.346707 5.56401 0.613374 5.31193 1.02671C5.18248 1.22671 5.10753 1.47337 5.12116 1.73337C5.13479 1.93337 5.07347 2.13337 4.96446 2.32004C4.617 2.88671 3.84712 3.10004 3.25439 2.78004C2.57308 2.39337 1.72826 2.62671 1.33311 3.28671L0.876631 4.07337C0.488288 4.73337 0.706305 5.58004 1.3808 5.96004C1.95991 6.30004 2.1643 7.05337 1.82365 7.62671C1.70782 7.80671 1.58519 7.96004 1.39442 8.05337C1.16278 8.18004 0.958388 8.38004 0.849379 8.58004C0.597296 8.99337 0.610923 9.51337 0.863005 9.94671L1.33311 10.7467C1.58519 11.1734 2.05529 11.44 2.54583 11.44C2.77747 11.44 3.04999 11.3734 3.26801 11.24C3.43834 11.1267 3.64273 11.0867 3.86756 11.0867C4.54205 11.0867 5.10753 11.64 5.12116 12.3C5.12116 13.0667 5.74796 13.6667 6.53828 13.6667H7.46485C8.24835 13.6667 8.87515 13.0667 8.87515 12.3C8.89559 11.64 9.46107 11.0867 10.1356 11.0867C10.3536 11.0867 10.558 11.1267 10.7351 11.24C10.9531 11.3734 11.2188 11.44 11.4573 11.44C11.941 11.44 12.4111 11.1734 12.6632 10.7467L13.1401 9.94671C13.3854 9.50004 13.4058 8.99337 13.1537 8.58004Z"
        fill="#6D6D6F"
      />
    </svg>
  );
}
