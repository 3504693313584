import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from '../../../hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  auditTrail: {},
  selectedTrail: {},
  loading: false,
  error: null
}

export const auditTrail = createSlice({
  name: 'auditTrail',
  initialState,
  extraReducers
})

// Selectors
const selectAuditTrail = (state) => state.auditTrail;

//Roles Redux State
export const useAuditTrail = () => useAppSelector(selectAuditTrail);

export default auditTrail.reducer