/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import BackButton from 'components/Button/BackButton';
import CustomButton from 'components/Button/CustomButton';
import Title from 'components/Header/Title';
import CheckBox from 'components/Input/CheckBox';
import CustomInput from 'components/Input/CustomInput';
import CTable from 'components/Table/Table';
import { RoleHeader, RoleTypes } from 'constants/mocks/roles_permission';
import useAuth from 'hooks/fetch/useAuth';
import { useRolesState } from 'store/modules/admin';
import { useAuthState } from 'store/modules/auth';
import PropTypes from 'prop-types';

const RoleForm = ({ formTitle }) => {
  const authState = useAuthState();
  const permissions = ['Create', 'Read', 'Update', 'Delete', 'All'];
  const { loading } = authState;
  const { editRole, createRole } = useAuth();
  const roleState = useRolesState();
  const [roles, setRoles] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    max_assignees: '',
  });

  //Handler functions
  const checkSelectAll = (allSelected, data, key) => {
    return {
      ...data,
      [key]: allSelected ? [] : permissions.slice(0, -1),
    };
  };
  const handleClearForm = () => {
    setFormData({
      name: '',
      max_assignees: '',
    })
    setRoles({})
  }
  const handleChange = useCallback(e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }, [formData]
  )
  const makeRequest = (e) => {
    e.preventDefault();
    formTitle === 'Edit Role' ? editRole(roleState?.selectedRole?.name, { ...formData, ...roles }) : createRole({ ...formData, ...roles });
  }

  const handleSelect = (key, value) => {
    const roleOptions = roles?.[key] ?? [];

    setRoles((prevRoles) => {
      if (value === 'All') {
        return checkSelectAll(roleOptions.length === 4, prevRoles, key);
      }

      if (roleOptions.includes(value)) {
        const newRoleOptions = roleOptions.filter((option) => option !== value);
        return {
          ...prevRoles,
          [key]: newRoleOptions,
        };
      }

      return {
        ...prevRoles,
        [key]: [...roleOptions, value],
      };
    });
  };

  const handleVerifyChecked = (roleType, value) => {
    if (value === 'All') return roleType.length === 4;
    return (roleType ?? []).map(el => el.toLowerCase()).includes(value.toLowerCase());
  };

  useEffect(() => {
    if (formTitle === 'Edit Role' && roleState?.selectedRole) {
      const initialRoles = RoleTypes.reduce((acc, { value }) => {
        if (roleState?.selectedRole?.[value]?.length) {
          return {
            ...acc,
            [value]: roleState?.selectedRole?.[value]
          }
        }
        return { ...acc }
      }, {})
      setRoles(initialRoles)

      setFormData({
        name: roleState?.selectedRole?.name ?? '',
        max_assignees: roleState?.selectedRole?.max_assignees ?? '',
      })
    }
  }, [roleState?.selectedRole])


  return (
    <div className="p-32 bg-white rounded-2xl">
      <BackButton />
      <Title title={formTitle} variant="mt-6" />
      <form className="mt-10" onSubmit={(e) => makeRequest(e)}>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          <div>
            <CustomInput
              label="Role Name"
              type="text"
              name="name"
              value={formData?.name}
              id="name"
              placeholder="Role Name"
              readOnly={loading}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-10'>
          <div>
            <CustomInput
              label="Maximum Number to Assigned"
              type="number"
              value={formData?.max_assignees}
              name="max_assignees"
              id="max_assignees"
              placeholder="2"
              readOnly={loading}
              onChange={handleChange}
            />
          </div>
        </div>
        <hr className="my-10 h-px border-0 bg-light-smoke" />
        <CTable header={RoleHeader} showCheckBox={false} hasPagination={false} data={RoleTypes}>
          {(item) => (
            <>
              <td className="pl-6">
                <p className="min-w-max whitespace-no-wrap mr-4">
                  {item.type}
                </p>
              </td>
              {permissions.map(el => el).map((permission) => (
                <td key={permission}>
                  <p className="min-w-max whitespace-no-wrap mx-8">
                    <CheckBox
                      variant="ml-0"
                      value={permission}
                      id={`check-${permission}`}
                      checked={handleVerifyChecked(roles?.[item.value] ?? [], permission)}
                      onChange={e => handleSelect(item.value, e.target.value)}
                    />
                  </p>
                </td>
              ))}
            </>
          )}
        </CTable>
        <div className="flex justify-end items-center space-x-6 mt-10">
          <p className="text-purple font-dmsans_r cursor-pointer" onClick={handleClearForm}>Cancel</p>
          <CustomButton
            name={formTitle}
            disabled={!formData.name || !formData.max_assignees || !roles || loading}
            loading={loading}
            variant="border border-purple bg-purple text-white rounded font-dmsans_r"
          />
        </div>
      </form>
    </div>
  );
};

export default RoleForm;

RoleForm.propTypes = {
  formTitle: PropTypes.string
}