/* eslint-disable max-len */
import React from "react";
import PropTypes from 'prop-types';
import Loader from "../../assets/icons/Loader";

export default function Button({ name, disabled, onClick, children, type, theme, styles, loading }) {
    return(
        <button 
            disabled={disabled} 
            onClick={onClick}
            type={type || 'submit'}
            style={{ borderRadius: '8px', fontFamily: "dmsans_r", fontWeight: 500 }}
            className={
                `${theme === 'dark' && disabled ? 'bg-button_disabled text-purple' : 'bg-purple hover:bg-[#5134c4ea] text-white'}
                ${styles} flex justify-center py-12 px-16 text-16 border-1px transition ease-in-out duration-500`
            }
        >
            {
                loading ? 
            <Loader/> : <span>{ name || children }</span>
            }
        </button>
    )
}

Button.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.element,
    theme: PropTypes.string,
    styles: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool
};