/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types'

const Tabs = ({ TabsItem, selectedTab, setSelectedTab }) => {
  return (
    <>
      <ul className=" overflow-x-scroll xl:overflow-x-hidden scrollbar-hide border-b border-light-smoke flex overflow-scroll-none justify-start space-x-8 font-dmsans_m">
        {TabsItem?.map((tab) => (
          <li
            className={`tabs__item ${
              selectedTab === tab ? 'active text-purple' : 'text-dark_blue'
            } w-max pr-5 `}
            data-testid='test-tab'
            onClick={() => setSelectedTab(tab)}
            key={tab}
          >
            <div className={` min-w-max whitespace-no-wrap md:text-16 xxs:text-14 capitalize text-dark_blue`}>
              {tab}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Tabs;


Tabs.propTypes = {
    TabsItem: PropTypes.array,
    // onClick: PropTypes.func,
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
  };
