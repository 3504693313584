import { getScheduledPayment } from "./actions";

const extraReducers = {

//GET SCHEDULED PAYMENT
[getScheduledPayment.pending]: (state) => {
  state.loading = true
},
[getScheduledPayment.fulfilled]: (state, action) => {
  state.scheduledPayment = action.payload.data;
  state.loading = false
},
[getScheduledPayment.rejected]: (state, action) => {
  state.loading = false
  state.error = action.payload.data.message;

},
}

export { extraReducers };
