import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  paymentHistory: {},
  guestPaymentHistory: {},
  categoryAnalytics: {},
  selectedPayment: {},
  revenueSummary: {},
  loading: false,
  error: null
}

export const payment = createSlice({
  name: 'payment',
  initialState,
  extraReducers
})

// Selectors,
const selectPayment = (state) => state.payment;


//Auth Redux State
export const usePaymentState = () => useAppSelector(selectPayment);

export default payment.reducer