/* eslint-disable max-len */
import React from 'react';

export const Data2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#5034C4" />
      <path
        d="M21.8933 15C21.751 15 21.6176 15.0687 21.535 15.1843L16.7606 21.8683C16.6996 21.954 16.6666 22.0567 16.6666 22.1617C16.6666 22.4407 16.8926 22.6667 17.1716 22.6667H19.6666V33H22.3333V15.44C22.3333 15.197 22.1363 15 21.8933 15Z"
        fill="white"
      />
      <path
        d="M26.1066 33C26.249 33 26.3823 32.9313 26.465 32.8157L31.2393 26.1317C31.3003 26.046 31.3333 25.9433 31.3333 25.8383C31.3333 25.5593 31.1073 25.3333 30.8283 25.3333H28.3333V15H25.6666V32.56C25.6666 32.803 25.8636 33 26.1066 33Z"
        fill="white"
      />
      <path
        d="M44.0386 34.8763C44.6211 35.1924 45.3525 34.9777 45.6391 34.3802C47.283 30.9532 48.092 27.1792 47.9917 23.3682C47.8808 19.1568 46.6633 15.0488 44.4615 11.457C42.2598 7.86529 39.1514 4.91638 35.4487 2.90671C32.0982 1.08815 28.3679 0.0966743 24.5681 0.00672065C23.9056 -0.0089644 23.3824 0.545389 23.3998 1.2079C23.4173 1.87041 23.9688 2.38986 24.6313 2.40922C28.0096 2.50799 31.3236 3.39849 34.3038 5.01604C37.6362 6.82474 40.4338 9.47876 42.4154 12.7113C44.3969 15.9439 45.4927 19.6411 45.5925 23.4314C45.6818 26.8211 44.9717 30.1784 43.5268 33.2338C43.2435 33.8329 43.4562 34.5601 44.0386 34.8763Z"
        fill="#5034C4"
      />
    </svg>
  );
};
