import React from 'react'
import CheckBox from '../Input/CheckBox'
import PropTypes from 'prop-types'

const TableHeader = ({ headerVariant, showCheckBox, onHandleAllCheckbox, header }) => {
  return (
    <thead className="" data-testid="table-head">
        <tr className={`${headerVariant || 'border-y border-purple_light'} w-full h-11  bg-grey-400 rounded`}>
            {showCheckBox && (
            <th role="columnheader" className=" w-[60px] bg-gray-400">
            <CheckBox onChange={onHandleAllCheckbox}/>
            </th>
            )}
            {header?.map((title) => (
            <th key={`header-${title.name}`} className={`${title.variant} text-left text-grey-500 text-12`} data-testid="column-header">
                <p className={`${title.innerVariant || 'text-grey-500 font-inter_medium min-w-max'} `}>{title.name}</p>
            </th>
            ))}
        </tr>
    </thead>
  )
}

TableHeader.propTypes = {
    onHandleAllCheckbox: PropTypes.func,
    showCheckBox: PropTypes.bool,
    headerVariant: PropTypes.string,
    header: PropTypes.any,
};

export default TableHeader