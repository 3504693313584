import React from 'react';
import FormContainers from 'components/Containers/FormContainers';
import Switch from 'components/Switch/Switch';
import { useAppSelector } from 'hooks/useReduxHook';
import useSettings from 'hooks/fetch/useSettings';
import { APIData } from './mockData';

const APIAvailability = () => {
  const { envs } = useAppSelector((state) => state.settings);
  const { update_env } = useSettings();

  //Handler Function
  function findUtilityProviders(searchValue) {
    const value = envs.find((item) => item.name === searchValue);
    return value;
  }

  const updateAPIAvailabilty = (name, label) => {
    const formData = {
      [name]: label?.toLowerCase(),
    };
    update_env({ formData });
  };

  return (
    <FormContainers
      title="API Availability"
      showButton={false}
      formContent={
        <div className="flex flex-col space-y-[38px] gap-3 mt-[50px]">
          {Object.entries(APIData).map(([key, { payments, name }]) => (
            <div key={key}>
              <div className="flex flex-col space-y-[20px] gap-5 mt-[5px] px-[40px] py-[50px] bg-white rounded-lg border border-light-smoke">
                <p className="text-dark font-dmsans_r text-18 font-bold">{key}</p>
                <div className="grid grid-cols-[240px_minmax(0,_270px)_250px] gap-20 ml-[20px]">
                  {payments.map((label) => (
                    <div key={label}>
                      <Switch
                        label={label}
                        status={findUtilityProviders(name)?.value === label?.toLowerCase() ? 'active' : 'inactive'}
                        onChange={() => updateAPIAvailabilty(name, label)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
};

export default APIAvailability;
