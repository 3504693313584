/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function UserIcon ({color}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1341_9973)">
        <path
          d="M6.00033 6.66667C7.84127 6.66667 9.33366 5.17428 9.33366 3.33333C9.33366 1.49238 7.84127 0 6.00033 0C4.15938 0 2.66699 1.49238 2.66699 3.33333C2.66699 5.17428 4.15938 6.66667 6.00033 6.66667Z"
          fill={color}
        />
        <path
          d="M7.68933 13.8667C7.342 13.2653 7.41133 12.5247 7.82467 12C7.41133 11.4753 7.342 10.7353 7.68933 10.1333L8.22867 9.2C8.52533 8.686 9.07867 8.36667 9.67267 8.36667C9.754 8.36667 9.834 8.37267 9.91333 8.384C9.93533 8.32933 9.96333 8.278 9.99067 8.226C9.63067 8.084 9.242 8 8.83333 8H3.16667C1.42067 8 0 9.42067 0 11.1667V13.5C0 13.776 0.224 14 0.5 14H7.766L7.68933 13.8667Z"
          fill={color}
        />
        <path
          d="M14.4682 12.3127C14.4815 12.2094 14.4995 12.1067 14.4995 12C14.4995 11.8927 14.4809 11.7907 14.4682 11.6874L15.0689 11.2327C15.2029 11.1314 15.2402 10.946 15.1562 10.8L14.6169 9.86669C14.5329 9.72135 14.3535 9.66069 14.1989 9.72602L13.5075 10.0174C13.3389 9.88869 13.1595 9.77535 12.9615 9.69269L12.8695 8.95869C12.8482 8.79202 12.7069 8.66669 12.5389 8.66669H11.4609C11.2929 8.66669 11.1509 8.79202 11.1302 8.95869L11.0382 9.69269C10.8402 9.77535 10.6602 9.88935 10.4922 10.0174L9.80086 9.72602C9.64619 9.66069 9.46686 9.72069 9.38286 9.86669L8.84352 10.8C8.75952 10.9454 8.79686 11.1307 8.93086 11.2327L9.53153 11.6874C9.51819 11.7907 9.50019 11.8934 9.50019 12C9.50019 12.1074 9.51886 12.2094 9.53153 12.3127L8.93086 12.7674C8.79686 12.8687 8.75952 13.054 8.84352 13.2L9.38286 14.134C9.46686 14.2794 9.64619 14.34 9.80086 14.2747L10.4922 13.9827C10.6609 14.1114 10.8402 14.2247 11.0382 14.3074L11.1302 15.0414C11.1509 15.208 11.2922 15.3334 11.4602 15.3334H12.5382C12.7062 15.3334 12.8482 15.208 12.8689 15.0414L12.9609 14.3074C13.1589 14.2247 13.3389 14.1107 13.5069 13.9827L14.1982 14.274C14.3529 14.3394 14.5322 14.2794 14.6162 14.1334L15.1555 13.1994C15.2395 13.054 15.2022 12.8687 15.0682 12.7667L14.4682 12.3127ZM11.9995 13.3334C11.2629 13.3334 10.6662 12.7367 10.6662 12C10.6662 11.2634 11.2629 10.6667 11.9995 10.6667C12.7362 10.6667 13.3329 11.2634 13.3329 12C13.3329 12.7367 12.7362 13.3334 11.9995 13.3334Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1341_9973">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
UserIcon.propTypes = {
  color: PropTypes.string
}
