import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/axios-instance'
import { admin } from '../../../services/endpoints/admin'

const getVouchers = createAsyncThunk('vouchers/getVouchers', async(params) => {
    try {
        const response = await api.get(admin.get_vouchers, {params})
        if (response.data) {
            return response.data
        }
        return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

export {
  getVouchers,
}