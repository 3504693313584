/* eslint-disable no-unused-vars */
import CustomButton from 'components/Button/CustomButton';
import React, { useState, useCallback, useEffect } from 'react';
import CTable from 'components/Table/Table';
import { VouchersHeader, voucherFilter } from 'constants/mocks/vouchers';
import Status from 'components/Status';
import { UserInitals } from 'utils/ImagePlaceholder';
import { FiCopy } from 'react-icons/fi';
import { useVoucherState } from 'store/modules/vouchers';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import useCustomer from 'hooks/fetch/useCustomer';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';
import ExportExcel from 'components/ExportExcel';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useDebounce } from 'hooks/useDounce';

const Vouchers = () => {
  //Redux Hooks
  const { voucherList, loading } = useVoucherState();
  const { copyToClipboard } = useCopyToClipboard();

  
  //local State
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState('1');
  
  //Hooks
  const { handleGetVoucher, handleViewCustomer } = useCustomer();
  const debouncedSearch = useDebounce(search)

  //Variable
  const per_page = perPage.value;

  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleGetVoucher({ per_page, page, status: filter && filter.value, search: debouncedSearch});
    } else {
      handleGetVoucher({ per_page, page, status: filter && filter.value});
    }
  }, [per_page, page, filter, debouncedSearch]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleFilter = useCallback(
    (data) => {
      setFilter(data);
    },
    [filter],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  useEffect(() => {
    document.title = 'Vouchers History - Pouchers Admin';
  }, []);

  return (
    <DashboardLayout
      pageHeader="Vouchers"
      perPage={perPage}
      cardTitle="Vouchers History"
      SearchFunc={handleChange}
      searchValue={search}
      filterBy= 'Status'
      options={voucherFilter}
      Dropdownfunc={handleFilter}
      dropDownValue={filter}
      placeholder="Search by tag"
      handlePerPage={handlePerPage}
      actionButtons={
        <ExportExcel excelData={voucherList?.vouchers} fileName={`file-vouchersList-${new Date().toISOString()}`}>
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
        </ExportExcel>
      }
    >
      <div className="mt-7">
        <CTable
          header={VouchersHeader}
          totalPayload={voucherList?.total}
          perPage={per_page}
          loading={loading}
          changePage={setPage}
          currentPage={voucherList?.page}
          showCheckBox={false}
          data={voucherList?.vouchers}
        >
          {(item) => (
            <>
              <td className='pl-6'>
                <p className="text-grey-500 font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                  {item.code}
                </p>
              </td>
              <td>
                <p
                  data-testid="copyVoucher"
                  className="text-grey-500 font-dmsans_r pl-6 min-w-max mx-4 lg:mx-0 whitespace-no-wrap"
                  onClick={() => copyToClipboard(item.code)}
                >
                  <FiCopy className="text-20 text-purple-150 cursor-pointer" />
                </p>
              </td>
              <td>
                <p className="text-grey-500 font-dmsans_r pl-6 min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  {formatCurrency(item.amount)}
                </p>
              </td>
              <td 
                data-testid="test-customer"
                className="cursor-pointer" 
                // onClick={() => handleViewCustomer(item.buyer_id)}
                onClick={() => {
                  window.open(`/manage-customer/${item.buyer_id}`,'_blank')
                }}
              >
                <div className="flex items-center space-x-3 min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="text-grey font-dmsans_r">@{item.tag}</p>
                  </div>
                </div>
              </td>
              <td>
                <div className="font-dmsans_r min-w-max mx-4 lg:mx-0 whitespace-no-wrap">
                  <Status showIcon status={item.status} />
                </div>
              </td>
              <td>
                <p className="font-dmsans_r min-w-max mx-4 lg:mx-0 whitespace-no-wrap text-blue_black">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
            </>
          )}
        </CTable>
      </div>
    </DashboardLayout>
  );
};

export default Vouchers;
