/* eslint-disable max-len */
import React, { useEffect, useCallback } from 'react';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';
import WalletCard from 'components/Card/WalletCard';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';

const AccountInformation = () => {
  //Redux
  const customerState = useCustomerState();

  //Hooks
  const { handleWalletDetails, updatecard } = useCustomer();
  const updateCard = useCallback(
    (type) => {
      const key = type === 'dollar' ? 'dollarCardInfo' : 'nairaCardInfo';
      updatecard(
        type === 'dollar' ? 'USD' : 'NGN',
        customerState?.walletDetails?.[key]?.user_id,
        customerState?.walletDetails?.[key]?.sudo_id,
        customerState?.walletDetails?.[key]?.status === 'active' ? 'inactive' : 'active',

        () => handleWalletDetails(customerState?.selectedCustomer?.user_id),
      );
    },
    [customerState?.walletDetails],
  );

  //Handler Function
  useEffect(() => {
    handleWalletDetails(customerState?.selectedCustomer?.user_id);
  }, []);

  return (
    <div className="font-dmsans_m py-32 px-24">
      <p className="text-black-transparent text-16 mb-12">Wallet Information </p>
      {customerState?.loading ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-3 gap-8">
            <div>
              <p className="text-grey font-dmsans_r text-12">Account Number</p>
              <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                {customerState?.walletDetails?.walletInfo?.account_number || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Service Provider</p>
              <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                {customerState?.walletDetails?.walletInfo?.bank_provider || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Date Created</p>
              {customerState?.walletDetails?.walletInfo?.wallet_created_at ? <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                {dayjs(customerState?.walletDetails?.walletInfo?.wallet_created_at).format('MMM DD, YYYY')}
                <span className="text-grey">
                  {' '}
                  | {dayjs(customerState?.walletDetails?.walletInfo?.wallet_created_at).format('hh:mm a')}
                </span>
              </p> : 'N/A'}
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Account Holder Name</p>
              <p className="text-dark_blue text-14 mt-1 font-dmsans_r overflow-wrap">
                {customerState?.walletDetails?.walletInfo?.account_name || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Email</p>
              <p className="text-dark_blue text-14 mt-1 font-dmsans_r overflow-wrap">
                {customerState?.walletDetails?.walletInfo?.email || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Phone Number</p>
              <p className="text-dark_blue text-14 mt-1 font-dmsans_r">
                {customerState?.walletDetails?.walletInfo?.phone_number || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Poucher Tag</p>
              <p className="text-dark_blue text-14 font-dmsans_r mt-1">
                @{customerState?.walletDetails?.walletInfo?.tag || 'N/A'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Virtual Card </p>
              <p className="text-dark_blue font-dmsans_r text-14 mt-1">
                {customerState?.walletDetails?.dollarCardInfo || customerState?.walletDetails?.nairaCardInfo
                  ? 'Yes'
                  : 'No'}
              </p>
            </div>
            <div>
              <p className="text-grey font-dmsans_r text-12">Account Tier</p>
              <p className="text-dark_blue font-dmsans_r text-14 mt-1">
                Tier {customerState?.walletDetails?.walletInfo?.tier_levels}
              </p>
            </div>
          </div>
          <div className="h-[450px] scroll-styled overflow-scroll scroll-smooth scroll-visible">
            {customerState?.walletDetails?.dollarCardInfo !== null && (
              <WalletCard
                cardDetails={customerState?.walletDetails?.dollarCardInfo}
                updateCard={() => updateCard('dollar')}
                type="dollar"
              />
            )}
            {customerState?.walletDetails?.nairaCardInfo !== null && (
              <>
                <hr className="pr-10 h-px border-0 bg-purple_light rotate my-9" />
                <WalletCard
                  cardDetails={customerState?.walletDetails?.nairaCardInfo}
                  updateCard={() => updateCard('naira')}
                  type="naira"
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AccountInformation;
