import React from 'react'

const FreezeIcon = () => {
  return (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.707 7.96875L16.707 10.9688L19.707 13.9688" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.9688 19.7031L10.9688 16.7031L7.96875 19.7031" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.23419 13.9653L5.23419 10.9653L2.23419 7.96533" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.97223 2.23022L10.9722 5.23022L13.9722 2.23022" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.9932 10.9688H0.949219" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.1099 18.106L3.83594 3.83203" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.1099 3.83203L3.83594 18.106" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.9727 0.945312V20.9893" stroke="#5034C4" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default FreezeIcon