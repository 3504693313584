import React, { useState } from 'react';
import PropTypes from "prop-types"
import useUpload from 'hooks/fetch/useUpload';
import useSettings from 'hooks/fetch/useSettings';
import moment from "moment";
import Input from 'components/Input/Input';
import CustomButton from 'components/Button/CustomButton';
import { AWS_S3_BUCKET } from 'constants';

export default function UploadBanner({ closeModal }) {
    const { getSignedUrl, uploading } = useUpload()
    const { loading, get_banners } = useSettings()
    const [bannerToUpload, setBannerToUpload] = useState()
    const [url, setUrl] = useState('')
    const { upload_banner } = useSettings()

    const uploadBanner = (timestamp, file) => {
        upload_banner(({
            image_url: `${AWS_S3_BUCKET}/${timestamp}${file?.name}`,
            type: 'web',
            routing_url: url
        }))
        .then((res) => {
            if ([200, 201].includes(res?.code)) {
                get_banners()
                setBannerToUpload()
                setUrl('')
                closeModal()
            }
        })
    }

    const uploadProxy = (e) => {
        e.preventDefault()
        if (bannerToUpload && url) {
            const timestamp = moment()?.format('YYYYMMDD-HHmmss')
            getSignedUrl(bannerToUpload, { web_banner: `${timestamp}${bannerToUpload?.name}` })
            .then((res) => {
                if (res?.status === 200) uploadBanner(timestamp, bannerToUpload)
            })
        }
    }

    return (
        <form className='mt-[32px]' onSubmit={uploadProxy}>
            <div className='mb-[24px]'>
                <label htmlFor="file" className="font-dmsans_r cursor-pointer">
                    <div className="flex flex-col items-center rounded border border-gray_light_100 bg-smoke p-[16px]">
                        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
                            <g clipPath="url(#clip0_9140_145541)">
                                <path d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" strokeWidth="6" />
                            <defs>
                                <clipPath id="clip0_9140_145541">
                                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className='mb-[24px] text-center'>
                            <p className="text-grey font-dmsans_r mb-1"><span className='text-purple font-dmsans_b'>Click to upload</span> or drag and drop</p>
                            <p className="text-grey font-dmsans_r mb-1">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            <p>{bannerToUpload?.name}</p>
                            <input
                                type="file"
                                id="file"
                                data-testid='file-web'
                                hidden
                                accept='image/png, image/jpeg, image/jpg'
                                disabled={uploading}
                                onChange={(e) => setBannerToUpload(e.target.files[0])}
                            />
                        </div>
                    </div>
                </label>
            </div>
            <div>
                <Input
                    label="Add URL"
                    type="url"
                    placeholder="paste here"
                    id="url"
                    name="url"
                    readOnly={loading}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </div>
            <div className='flex justify-end mt-[32px]'>
                <CustomButton
                    name='Cancel'
                    onClick={closeModal}
                    type='button'
                    variant="text-purple rounded font-dmsans_r"
                />
                <CustomButton
                    name='Save'
                    loading={loading}
                    disabled={loading || !bannerToUpload?.name || (!url && !bannerToUpload?.name)}
                    variant="border border-purple bg-purple text-white rounded font-dmsans_r"
                />
            </div>
        </form>
    );
}

UploadBanner.propTypes = {
    closeModal: PropTypes.func
}