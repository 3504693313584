/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { DatefilterList } from '../../../constants/mocks/customers';
import Dropdown from '../../DropDown/DropDown';
import { BsArrowDownShort, BsArrowUpShort, BsPlusLg } from 'react-icons/bs';
import Status from '../../Status';
import TransactionDetails from '../Transactions/TransactionDetails';
import Modal from '../../Modal/Modal';
import useFormatDate from 'hooks/useFormatDate';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import { groupDataByDate } from 'utils/groupDataByDate';
import EmptyState from 'components/EmptyState';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import SendIcon from 'assets/icons/SendIcon';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDounce';
import ExpandIcon from 'assets/icons/ExpandIcon';

const TransactionHistory = ({ toggleTransactionTable, handleToggleTransactionTable, viewingCustomerProfile }) => {
  //Redux Hooks
  const customerState = useCustomerState();
  const { getFirstDayOfMonth, getLastDayOfMonth } = useFormatDate();
  const todayDate = dayjs(new Date()).format('YYYY-MM-DD');

  //Local State
  const [filter, setFilter] = useState({
    id: 2,
    value: 'thismonth',
    label: 'This month',
    startDate: getFirstDayOfMonth(),
    endDate: getLastDayOfMonth(),
  });
  const [showModal, setShowModal] = useState(false);

  //Hooks
  const { getAllTransactionHistory, handleViewTransactionReceipt } = useCustomer();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const per_page = perPage.value;
  const debouncedSearch = useDebounce(search);
  const [transactionType, setTransactionType] = useState('');
  const [transactionCategory, setTransactionCategory] = useState('');
  const filterSearch = () => {
    getAllTransactionHistory(customerState?.selectedCustomer?.user_id, {
      fromDate: filter.startDate,
      toDate: filter.endDate,
      page,
      perPage: per_page,
      transactionType,
      status,
      transactionCategory,
      search: debouncedSearch,
    });
    // if (debouncedSearch.trim() !== "") {
    //   } else {
    //   getAllTransactionHistory(customerState?.selectedCustomer?.user_id, {
    //     fromDate: filter.startDate,
    //     toDate: filter.endDate,
    //     page,
    //     perPage,
    //     transactionType,
    //     status,transactionCategory,
    //    search: debouncedSearch, });
    // }
  };

  //Effect
  useEffect(() => {
    filterSearch();
  }, [per_page, page, debouncedSearch, status]);
  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  // const handleFilter = useCallback(
  //   (data) => {
  //     setStatus(data);
  //   },
  //   [status],
  // );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  //Handler function
  const handleFilter = (data) => {
    setFilter(data);
    getAllTransactionHistory(customerState?.selectedCustomer?.user_id, {
      fromDate: data.startDate,
      toDate: data.endDate,
      page,
      perPage: per_page,
      transactionType,
      status,
      transactionCategory,
      search: debouncedSearch,
    });
  };

  const filterTransactions = useMemo(
    // () => groupDataByDate(customerState?.transactionHistory?.histories),
    () => groupDataByDate(customerState?.transactionHistory?.history),
    // [customerState?.transactionHistory?.histories],
    [customerState?.transactionHistory?.history],
  );

  const handleViewReceipt = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  //Effect
  // useEffect(() => {
  //   getAllTransactionHistory(customerState?.selectedCustomer?.user_id, {
  //     fromDate: filter.startDate,
  //     toDate: filter.endDate,
  //   });
  // }, []);



  return (
    <div>
      <div className="font-dmsans_m pb-32 px-24 relative">
        <div className="flex justify-between items-center py-16">
          <p className="text-black-transparent text-16">Transaction History</p>
          {viewingCustomerProfile && (
            <button
              type="button"
              onClick={() => handleToggleTransactionTable()}
              className="text-purple capitalize text-12 font-dmsans_r flex items-center gap-1"
            >
              {toggleTransactionTable === 'expand' && <ExpandIcon />}
              {toggleTransactionTable} Table
            </button>
          )}
        </div>
        <div className="flex items-center justify-center gap-5">
          <Dropdown
            variant="border border-purple w-[142px] h-[34px] py-0 pt-[7px]"
            placeholder=""
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            labelVariant="text-purple"
            icon={<IoIosArrowDown className="text-purple" />}
            value={filter}
            type="filter"
            options={DatefilterList}
            onChange={handleFilter}
          />
          <p className="text-14 text-center text-gray-100 uppercase">
            {/* {filter.label}:{' '} */}
            <span className="text-purple">
              {' '}
              {dayjs(filter.startDate).format('D MMM')} - {dayjs(filter.endDate).format('D MMM')}
            </span>
          </p>
        </div>
        {customerState?.loading && (
          <div className="my-10">
            <Spinner />
          </div>
        )}
        {!customerState?.loading &&
          (filterTransactions?.length > 0 ? (
            <>
              <div className="flex justify-center items-center space-x-10 mt-6 pb-5">
                <div>
                  <p className="text-20 font-dmsans_b">
                    {formatCurrency(customerState.transactionHistory.total_inflow)}
                  </p>
                  <div className="flex items-center justify-center text-center mt-1">
                    <BsArrowDownShort className="text-green-200 text-18" />
                    <p className="font-dmsans_r text-12">
                      {/* <span className="text-green-200 mr-[5px]">
                        {formatCurrency(customerState.transactionHistory.total_inflow)}
                      </span> */}
                      <span className="text-gray-100">Money In</span>
                    </p>
                  </div>
                </div>
                <hr className="pr-10 h-px border-0 bg-deep-grey rotate-90" />
                <div>
                  <p className="text-20 font-dmsans_b">
                    {formatCurrency(customerState.transactionHistory.total_outflow)}
                  </p>
                  <div className="flex items-center justify-center text-center mt-1">
                    <BsArrowUpShort className="text-red-100 text-18" />
                    <p className="font-dmsans_r text-12">
                      {/* <span className="text-red-100 mr-[5px]">
                        {formatCurrency(customerState.transactionHistory.total_outflow)}
                      </span> */}
                      <span className=" text-gray-100">Money Out</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-[320px] scroll-styled overflow-scroll scroll-smooth scroll-visible">
                {filterTransactions.map((item) => (
                  <div key={item.date}>
                    <p className="border-b pb-1 mt-2 border-smoke text-grey font-dmsans_r">
                      {item.date === todayDate ? 'Today' : dayjs(item.date).format(' MMM D, YYYY')}
                    </p>
                    {item.items.map((data, i) => (
                      <div
                        key={data.transaction_id}
                        data-testid={`transaction-id-${i}`}
                        className="mt-[18px] grid grid-cols-3 border border-white gap-2 relative
                      items-stretch p-4 hover:bg-purple-50 cursor-pointer hover:border-purple rounded-[10px] overflow-hidden"
                      >
                        <button
                          type="button"
                          onClick={() =>
                            handleViewTransactionReceipt(() => setShowModal(true), {
                              id: data.user_id,
                              transaction_id: data.transaction_id,
                            })
                          }
                          className="absolute inset-0 h-full w-full"
                        ></button>
                        {/* <div
                          className="flex 
                    justify-between items-start"
                        >
                          <div className="bg-purple_transparent hover:bg-white w-10 h-10 flex items-center justify-center rounded-full mr-3">
                            {data.transaction_type === 'credit' ? (
                              <BsPlusLg className="text-12 text-green-200" />
                            ) : (
                              <SendIcon />
                            )}
                          </div>
                        </div> */}
                        <div className="flex flex-col gap-1">
                          <p className="text-grey font-dmsans_m">
                            <span className="text-[#060628] capitalize">{data.beneficiary_name || 'N/A'}</span>
                          </p>
                          <p className="text-grey text-12 font-dmsans_r mt-0.5 capitalize">
                            {dayjs(data?.created_at).format('MMM D, YYYY h:mm A')}
                          </p>
                        </div>
                        <div className="flex flex-col items-center gap-1">
                          <p
                            className={`${
                              data?.transaction_type === 'debit' ? 'text-[#FF0000]' : 'text-[#00BB64]'
                            }  font-dmsans_m text-14`}
                          >
                            {data?.transaction_type === 'debit' ? '-' : '+'}{' '}
                            {formatCurrency(data.amount, data.currency)}
                          </p>
                          <p className="text-12 text-[#8F8E9B] font-dmsans_r capitalize">
                            {data?.transaction_category?.replaceAll('-', ' ')}
                          </p>
                        </div>
                        <div className="flex flex-col gap-1 items-end">
                          <Status showIcon status={data.status} />
                          <p className="text-12 text-[#8F8E9B] font-dmsans_r capitalize">
                            {data?.transaction_type?.replace('-', ' ')}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyState />
          ))}
      </div>
      {showModal && (
        <Modal
          closeModal={handleViewReceipt}
          title="Transaction Details"
          containerVariant="min-w-[760px]"
          dividerVariant="mb-8 mt-5"
          status={<Status showIcon status={customerState.transactionReceipt.status} />}
        >
          <TransactionDetails transactionReceipt={customerState.transactionReceipt} />
        </Modal>
      )}
    </div>
  );
};

export default TransactionHistory;

TransactionHistory.propTypes = {
  toggleTransactionTable: PropTypes.string,
  handleToggleTransactionTable: PropTypes.func,
  viewingCustomerProfile: PropTypes.bool,
};
