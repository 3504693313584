import React from "react";
import PropTypes from 'prop-types';
import RedirectIfToken from "../../HOC/RedirectIfToken";

// Components
import Logo from "../../assets/icons/Logo";

export default function AuthLayout({ left, center, right }) {
    return(
        <RedirectIfToken>
            <div className="flex lg:p-0 p-6 min-h-[100vh] relative bg-authBg bg-fixed bg-no-repeat bg-right-bottom overflow-hidden">
                { left }
                { center }
                { 
                    right && 
                    <section className="w-full lg:my-right-layout lg:absolute lg:right-4 lg:px-64 xl:pl-112 h-screen">
                        <div className="block lg:hidden">
                            <Logo/>
                        </div>
                        <div className="overflow-y-auto">
                            { right }
                        </div>
                    </section>
                }
            </div>
        </RedirectIfToken>
    )
}

AuthLayout.propTypes = {
    left: PropTypes.element,
    center: PropTypes.element,
    right: PropTypes.element
};