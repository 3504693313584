/* eslint-disable max-len */
import React from 'react';

export const Electricity2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#FF6414" />
      <path
        d="M24.9952 22.61C24.8518 22.5096 24.6542 22.4229 24.4166 22.3745V23.5424C24.6542 23.494 24.8518 23.4073 24.9952 23.307C25.2185 23.1506 25.25 23.0146 25.25 22.9585C25.25 22.9023 25.2185 22.7663 24.9952 22.61Z"
        fill="white"
      />
      <path
        d="M23.5833 20.291C23.3458 20.3394 23.1481 20.4261 23.0048 20.5265C22.7814 20.6828 22.75 20.8188 22.75 20.875C22.75 20.9311 22.7814 21.0671 23.0048 21.2235C23.1481 21.3238 23.3458 21.4105 23.5833 21.4589V20.291Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0706 24.7013C28.9083 23.7656 29.4161 22.5317 29.4161 21.1625C29.4161 18.171 26.991 15.7458 23.9994 15.7458C21.0079 15.7458 18.5828 18.171 18.5828 21.1625C18.5828 22.533 19.0918 23.7679 19.9311 24.7039C20.1659 25.0628 20.4411 25.5387 20.6667 26.0834C20.9215 26.6989 21.1533 27.5445 21.3073 28.1667H26.6927C26.8468 27.5445 27.0785 26.6989 27.3333 26.0834C27.5594 25.5373 27.8355 25.0605 28.0706 24.7013Z"
        fill="white"
      />
      <path d="M26.5 29H21.5V29.8319L26.4654 29.4181C26.477 29.4171 26.4885 29.4167 26.5 29.4166V29Z" fill="white" />
      <path
        d="M26.5 30.2515L21.5346 30.6652C21.523 30.6662 21.5115 30.6667 21.5 30.6667C21.5 31.357 22.0597 31.9167 22.75 31.9167H23.1667C23.1667 32.1468 23.3532 32.3333 23.5833 32.3333H24.4167C24.6468 32.3333 24.8333 32.1468 24.8333 31.9167H25.25C25.9404 31.9167 26.5 31.357 26.5 30.6667V30.2515Z"
        fill="white"
      />
      <path
        d="M23.0239 19.9092L25.1139 23.2706V19.9092H25.9761V24.6518H25.0992L22.9435 21.1734V24.6518H22.0812V19.9092H23.0239Z"
        fill="#FF6414"
      />
      <path d="M21.2727 21.4939H26.7273V22.068H21.2727V21.4939Z" fill="#FF6414" />
      <path d="M21.2727 22.4987H26.7273V23.0728H21.2727V22.4987Z" fill="#FF6414" />
      <path
        d="M44.0386 34.8763C44.6211 35.1924 45.3525 34.9777 45.6391 34.3802C47.283 30.9532 48.092 27.1792 47.9917 23.3682C47.8808 19.1568 46.6633 15.0488 44.4615 11.457C42.2598 7.86529 39.1514 4.91638 35.4487 2.90671C32.0982 1.08815 28.3679 0.0966743 24.5681 0.00672065C23.9056 -0.0089644 23.3824 0.545389 23.3998 1.2079C23.4173 1.87041 23.9688 2.38986 24.6313 2.40922C28.0096 2.50799 31.3236 3.39849 34.3038 5.01604C37.6362 6.82474 40.4338 9.47876 42.4154 12.7113C44.3969 15.9439 45.4927 19.6411 45.5925 23.4314C45.6818 26.8211 44.9717 30.1784 43.5268 33.2338C43.2435 33.8329 43.4562 34.5601 44.0386 34.8763Z"
        fill="#FF6414"
      />
    </svg>
  );
};
