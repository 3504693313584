/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function VirtualCardIcon ({color}) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 10C16 10.5304 15.7893 11.0391 15.4142 11.4142C15.0391 11.7893 14.5304 12 14 12H2C1.46957 12 0.960859 11.7893 0.585787 11.4142C0.210714 11.0391 0 10.5304 0 10V5H16V10ZM4.5 9C4.63261 9 4.75979 8.94732 4.85355 8.85355C4.94732 8.75979 5 8.63261 5 8.5V7.5C5 7.36739 4.94732 7.24021 4.85355 7.14645C4.75979 7.05268 4.63261 7 4.5 7H2.5C2.36739 7 2.24021 7.05268 2.14645 7.14645C2.05268 7.24021 2 7.36739 2 7.5V8.5C2 8.63261 2.05268 8.75979 2.14645 8.85355C2.24021 8.94732 2.36739 9 2.5 9H4.5ZM16 3V2C16 1.46957 15.7893 0.960859 15.4142 0.585787C15.0391 0.210714 14.5304 0 14 0H2C1.46957 0 0.960859 0.210714 0.585787 0.585787C0.210714 0.960859 0 1.46957 0 2V3H16Z"
        fill={color}
      />
    </svg>
  );
}
VirtualCardIcon.propTypes = {
  color: PropTypes.string
}