import { useAppDispatch } from "./useAppDispatch"
import { unwrapResult } from "@reduxjs/toolkit"
import { notify } from "../store/modules/global"
import useHandleError from "./useHandleError"
import { useNavigate } from 'react-router-dom';

export default function useMakeRequest() {
    let dispatch = useAppDispatch()
    const { handleError } = useHandleError()
    let navigate = useNavigate();

    const makeRequest = ({ action, to, callback, alert = true, successMessage }) => {
        return dispatch(action)
            .then(unwrapResult)
            .then(res => {
                if ([200, 201].includes(res?.code)) {
                    if (alert) dispatch(notify({ display: true, status: 'success', message: successMessage || res.message }))
                    if (to) navigate(to)
                    if (callback) callback()
                }
                // if(res.code === 401 && ['Session expired', 'jwt malformed'].includes(res.message)) return res
                if(res.code === 401 && ['Session expired', 'jwt malformed', 'refresh_token is required', 'invalid signature',''].includes(res.message)) return res
                if (![200, 201].includes(res?.code)) {
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                }
                if (!res?.code) dispatch(notify({ display: true, status: 'error', message: 'Internal Server Error' }))
                return res
            })
            .catch(error =>
                dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
            )
    }

    return { makeRequest }
}