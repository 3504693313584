/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Title from '../../../components/Header/Title';
import CustomerList from './KycManagement';

const KycManagement = () => {
  //local state
  const [selectedTab, setSelectedTab] = useState({ name: 'all', value: '' });

  //Variables
  let TabsHeader = [
    { name: 'all', value: '' },
    { name: 'pending', value: false },
    { name: 'completed', value: true },
  ];

  useEffect(() => {
    document.title = 'KYC Management - Pouchers Admin';
  }, []);

  return (
    <div>
      <Title title="KYC" variant="my-6" />
      <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border">
      <ul className=" overflow-x-scroll xl:overflow-x-hidden scrollbar-hide border-b border-light-smoke flex overflow-scroll-none justify-start space-x-8 font-dmsans_m">
          {TabsHeader?.map((tab) => (
            <li
              className={`tabs__item ${
                selectedTab.name === tab?.name ? 'active text-purple' : 'text-dark_blue'
              } w-max pr-5 `}
              data-testid="test-tab"
              onClick={() => setSelectedTab(tab)}
              key={tab?.name}
            >
              <div className={` min-w-max whitespace-no-wrap text-16 ${tab.variant || 'capitalize'}`}>{tab?.name}</div>
            </li>
          ))}
        </ul>
        <CustomerList tab={selectedTab} />
      </div>
    </div>
  );
};

export default KycManagement;
