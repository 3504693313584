import CustomButton from 'components/Button/CustomButton';
import useCustomer from 'hooks/fetch/useCustomer';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewUtilityBill = (selectedCustomer) => {
  //Hooks
  const { upgradeTierAccount } = useCustomer();

  const navigate = useNavigate()

  //Handler Function
  const upgradeTierLevel = (status) => {
    // upgradeTierAccount(selectedCustomer.user_id, status, () => handleViewCustomer(selectedCustomer?.user_id));
    upgradeTierAccount(selectedCustomer.user_id, status, () => navigate(0));
  };

  return (
    <div className="my-5">
      {selectedCustomer.utility_bill ? (
        <img
          src={`${
            selectedCustomer.utility_bill.includes('amazonaws')
              ? selectedCustomer.utility_bill
              : 'https://photow-profile-images.s3.us-west-2.amazonaws.com/' + selectedCustomer.utility_bill
          } `}
          // src='https://poucher-upload.s3.amazonaws.com/20230417-125758pouuch.png'
          alt="utility bill"
          className="w-full"
        />
      ) : (
        <p className="text-center text-grey-600 font-dmsans_m uppercase"> No Utility Bill Available</p>
      )}

      {selectedCustomer.utility_bill  && selectedCustomer.tier_levels !== 3 && <div className="flex space-x-6 mt-10 justify-end items-center">
        <CustomButton
          name="Approve"
          variant="border border-purple bg-purple text-white rounded font-dmsans_r"
          onClick={() => upgradeTierLevel('upgrade')}
        />
        <button type="button" className="text-red-200 rounded font-dmsans_r cursor-pointer" onClick={() => upgradeTierLevel('reject')}>
          Reject
        </button>
      </div>}
    </div>
  );
};

export default ViewUtilityBill;
