import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Status from 'components/Status';
import Modal from 'components/Modal/Modal';
import CopyIcon from 'assets/icons/CopyIcon';
import Pagination from 'components/Pagination';
import useCustomer from 'hooks/fetch/useCustomer';
import NewStatus from 'components/Status/NewStatus';
import { formatCurrency } from 'utils/formatCurrency';
import { useCustomerState } from 'store/modules/customer';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import LoadingSkeleton from 'components/Skeleton/Skeleton';
import { NewTransactionHistoryHeader } from 'constants/mocks/new_transaction_history';
import TransactionDetails from 'components/Customers/Transactions/TransactionDetails';
import Spinner from 'components/Spinner';
import TableEmpty from './TableEmpty';
import { useAuthState } from 'store/modules/auth';

const NewTable = ({ changeCurrentPage, currentPage, data, hidePagination, loading, per_page, total }) => {
  const customerState = useCustomerState();
  const { handleViewTransactionReceipt } = useCustomer();
  const {
    loggedUser: { role },
  } = useAuthState();

  const { copyToClipboard } = useCopyToClipboard();

  const [showModal, setShowModal] = useState(false);

  const handleViewReceipt = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);


  return (
    <>
      <div className="mt-7">
        <div className={`border-y border-purple_light w-full h-11  bg-grey-400 rounded flex items-center gap-5 px-2`}>
          {NewTransactionHistoryHeader?.map((title) => (
            <p
              key={`header-${title.name}`}
              className={`${title.variant} text-left text-blue_black text-14 font-dmsans_b truncate line-clamp-1`}
              data-testid="column-header"
            >
              {title.name}
            </p>
          ))}
        </div>
        {loading ? (
          <LoadingSkeleton count={5} />
        ) : (
          <>
            {data?.length > 0 ? (
              <div className="flex flex-col">
                {data?.map((item) => (
                  <div
                    key={item?.created_at}
                    className="flex items-center w-full gap-5 py-3 px-2 hover:bg-purple-50 transition-all relative overflow-hidden
                        border-b border-[#EAECF0]"
                  >
                    <div className="w-[10%] 2xl:w-[12%]">
                      <NewStatus status={item?.status} />
                    </div>
                    <div className="font-dmsans_r text-14 text-blue_black flex flex-col w-[12%] 2xl:w-[9.5%]">
                      <p className="w-fit whitespace-no-wrap">{dayjs(item?.created_at).format('MMM DD, YYYY')}</p>
                      <p className="">{dayjs(item?.created_at).format('h:mm a')}</p>
                    </div>
                    <p className={`${item?.transaction_type?.toLocaleLowerCase() === "credit" ? "text-green" : "text-red-500"} text-14 font-dmsans_r capitalize w-[10%] 2xl:w-[8%]`}>
                      {`${item?.transaction_type}`}
                    </p>
                    <p className="font-dmsans_r truncate text-blue_black flex items-center w-[14%] text-14">
                      <p className="w-3/4 truncate">{item?.transaction_id}</p>
                      <CopyIcon onClick={() => copyToClipboard(item?.transaction_id)} />
                    </p>
                    <p className="font-dmsans_r capitalize truncate text-blue_black w-[15%] text-14">
                      {`${item?.transaction_category?.replaceAll('-', ' ')}`}
                    </p>
                    <button
                      type="button"
                      onClick={() =>
                        handleViewTransactionReceipt(() => setShowModal(true), {
                          id: item?.user_id,
                          transaction_id: item?.transaction_id,
                        })
                      }
                      className="font-dmsans_r text-left capitalize truncate text-blue_black w-[14%] text-14"
                    >
                      {`${item?.beneficiary_name}`}
                    </button>
                    <p className="font-dmsans_r truncate text-blue_black w-[13%] text-14">
                      {formatCurrency(item?.wallet_balance_after)}
                    </p>
                    <p className="font-dmsans_b truncate text-blue_black w-[12%] text-14">
                      {formatCurrency(item?.amount)}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <TableEmpty />
            )}
          </>
        )}
        {hidePagination ? (
          <>
            {role === 'Super-Admin' && (
              <div className="flex items-center justify-center mt-5">
                <Link
                  to="/customer-transactions"
                  className="text-[#5034C4] text-16 text-center w-fit mx-auto font-dmsans_r"
                >
                  View all
                </Link>
              </div>
            )}
          </>
        ) : (
          <div className="mt-5">
            {data?.length > 0 ? (
              <Pagination
                changePage={changeCurrentPage}
                totalPayload={total}
                perPage={per_page}
                currentPage={currentPage || 0}
              />
            ) : null}
          </div>
        )}
      </div>
      {/* {customerState?.loading && !hidePagination && (
        <div className="fixed inset-0 z-20 bg-[#00000030] flex items-center justify-center">
          <Spinner />
        </div>
      )} */}
      {showModal && (
        <Modal
          closeModal={handleViewReceipt}
          title="Transaction Details"
          containerVariant="min-w-[760px]"
          dividerVariant="mb-8 mt-5"
          status={<Status showIcon status={customerState.transactionReceipt.status} />}
        >
          <TransactionDetails transactionReceipt={customerState.transactionReceipt} />
        </Modal>
      )}
    </>
  );
};

export default NewTable;

NewTable.propTypes = {
  changeCurrentPage: PropTypes.func,
  currentPage: PropTypes.any,
  data: PropTypes.array,
  hidePagination: PropTypes.bool,
  loading: PropTypes.bool,
  per_page: PropTypes.number,
  total: PropTypes.string,
};
