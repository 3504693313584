import React from "react";
import PropTypes from "prop-types"

export default function Education({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="48" height="48" rx="24" fill={color || "white"}/>
        <path d="M30.3077 23.1753H17.6903C17.4966 23.1753 17.3398 23.3322 17.3398 23.5257V26.0344C17.3398 28.1652 20.2649 29.8344 23.999 29.8344C27.7332 29.8344 30.6582 28.1652 30.6582 26.0344V23.5257C30.6582 23.3322 30.5014 23.1753 30.3077 23.1753Z" fill="black"/>
        <path d="M24.01 23.1753H17.6903C17.4966 23.1753 17.3398 23.3322 17.3398 23.5257V26.0344C17.3398 28.1652 20.2649 29.8344 23.999 29.8344C24.0027 29.8344 24.0063 29.8341 24.01 29.8341V23.1753Z" fill="black"/>
        <path d="M34.1505 19.4336L25.2409 15.6149C24.4523 15.277 23.5467 15.2772 22.7574 15.6157L13.8499 19.4333C13.3255 19.658 13 20.1518 13 20.7221C13 21.2921 13.3255 21.7855 13.8495 22.01L22.7588 25.8287C23.1527 25.9974 23.5757 26.0818 23.9991 26.0818C24.4232 26.0818 24.8472 25.9972 25.2422 25.8278L34.1498 22.0103C34.6741 21.7858 35 21.2919 35 20.7217C35 20.1517 34.6745 19.6581 34.1505 19.4336Z" fill="#6463FD"/>
        <path d="M15.2372 28.082C15.0435 28.082 14.8867 27.9251 14.8867 27.7316V22.4187C14.8867 22.0767 15.1311 21.7863 15.4679 21.728L22.5375 20.4984C22.7281 20.4655 22.9095 20.593 22.9427 20.7837C22.9759 20.9743 22.8482 21.1559 22.6572 21.1891L15.5877 22.4187V27.7315C15.5877 27.9251 15.4309 28.082 15.2372 28.082Z" fill="white"/>
        <path d="M15.2381 28.783C15.6252 28.783 15.939 28.4692 15.939 28.082C15.939 27.6949 15.6252 27.3811 15.2381 27.3811C14.8509 27.3811 14.5371 27.6949 14.5371 28.082C14.5371 28.4692 14.8509 28.783 15.2381 28.783Z" fill="#817ED2"/>
        <path d="M22.5977 20.3714V21.0724C22.5977 21.4595 23.2253 21.7733 23.9996 21.7733C24.7738 21.7733 25.4015 21.4595 25.4015 21.0724V20.3714H22.5977Z" fill="#817ED2"/>
        <path d="M23.9996 21.0724C24.7739 21.0724 25.4015 20.7585 25.4015 20.3714C25.4015 19.9843 24.7739 19.6704 23.9996 19.6704C23.2253 19.6704 22.5977 19.9843 22.5977 20.3714C22.5977 20.7585 23.2253 21.0724 23.9996 21.0724Z" fill="#A6AAE0"/>
        <path d="M14.5174 29.481L14.2651 32.2561C14.2465 32.4613 14.4081 32.6383 14.6142 32.6383H15.9495C16.1556 32.6383 16.3172 32.4613 16.2985 32.2561L16.0463 29.481C16.0103 29.0857 15.6788 28.783 15.2818 28.783H15.2818C14.8848 28.783 14.5534 29.0857 14.5174 29.481Z" fill="#A6AAE0"/>
        </svg>
    )
}

Education.propTypes = {
    color: PropTypes.string
}