/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../assets/icons/SearchIcon';

const SearchInput = ({ handleChange, placeholder, value }) => {
  
  return (
    <div className="relative flex items-center">
      <input
        type="text"
        data-testid='test-search'
        name="search"
        className="md:min-w-[350px] w-full placeholder:text-light-grey rounded text-dark_blue font-dmsans_r text-16 border border-light-smoke outline-none focus:border-purple pl-4 pr-3 py-12 h-11 truncate placeholder:text-14"
        placeholder={`${placeholder || 'Search'}`}
        value={value}
        onChange={handleChange}
      />
      <div className="absolute right-2 top-[36%] cursor-pointer">
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;

SearchInput.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  delay: PropTypes.number,
  onDebounce: PropTypes.func
};


