import useFormatDate from 'hooks/useFormatDate';

const {
  getFirstDayOfYear,
  getLastDayOfYear,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getFirstDayOfWeek,
  getLastDayOfWeek,
} = useFormatDate();

export const ManageCustomerHeader = [
  {
    name: 'Customer',
    variant: 'pl-6 pr-4 min-w-max',
  },
  {
    name: 'Date Created',
    variant: 'pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Tier',
    variant: 'w-[13%]',
  },
  {
    name: 'Wallet Balance',
    variant: 'pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Cards',
    variant: 'w-[9%]',
  },
  {
    name: 'Status',
    variant: 'pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: '',
  },
];
export const kycHeader = [
  {
    name: 'Customer',
    variant: 'pl-6 pr-4 min-w-max',
  },
  {
    name: 'Date Created',
    variant: 'pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Tier',
    variant: 'w-[13%]',
  },
  {
    name: 'Last KYC Update',
    variant: 'pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Document ',
    variant: 'w-[9%]',
  },
  {
    name: '',
  },
];
export const kycFilter = [
  { id: 1, value: 'active', label: 'Active' },
  { id: 2, value: 'inactive', label: 'Inactive' },
  { id: 3, value: 'deactivated', label: 'Deactivated' },
  { id: 4, value: 'suspended', label: 'Suspended' },
  { id: 5, value: '0', label: 'Tier 0' },
  { id: 6, value: '1', label: 'Tier 1' },
  { id: 7, value: '2', label: 'Tier 2' },
  { id: 8, value: '3', label: 'Tier 3' },
]
export const VirtualCardsHeader = [
  {
    name: 'Poucher Tag',
    variant: 'pl-6',
  },
  {
    name: 'Customer',
    variant: 'xxs:pl-4 lg:pl-6 min-w-max',
  },
  {
    name: 'Card Type',
    variant: 'xxs:pl-4 lg:pl-6 min-w-max',
  },
  {
    name: 'Status',
    variant: 'xxs:pl-6 lg:pl-4 min-w-max',
  },
  {
    name: 'Date Created',
    variant: 'xxs:pl-4 lg:pl-4 md:pl-6 min-w-max',
  },
  {
    name: '',
    variant: 'pr-4 md:pl-8',
  },
];
export const VirtualCardsData = [
  {
    poucher_id: '@Livia24',
    first_name: 'Olivia',
    last_name: 'Rhye',
    email: 'olivia@untitledui.com',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@Baker',
    first_name: 'Phoenix',
    last_name: 'Baker',
    email: 'phoenix@untitledui.com',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@Steiner2002',
    last_name: 'Steiner',
    first_name: 'Lana',
    email: 'lana@untitledui.com',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@DemiGod',
    first_name: 'Demi',
    last_name: 'Wilkinson',
    email: 'demi@untitledui.com',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@Wu Shi ',
    first_name: 'Candice',
    last_name: 'Wu',
    email: 'candice@untitledui.com',
    status: 'not active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@Livia24',
    first_name: 'Natali',
    last_name: 'Craig',
    email: 'natalie@untitledui.com',
    status: 'active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
  {
    poucher_id: '@Livia24',
    first_name: 'Drew',
    last_name: 'Cano',
    email: 'drew@untitledui.com',
    status: 'not active',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    balance: '₦ 500,000',
  },
];
export const ReferHeader = [
  {
    name: 'Date',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Referrals',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Earnings',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
  {
    name: 'Status',
    variant: 'pl-6 pr-4 min-w-max mx-4 md:mx-0',
  },
];
export const ReferalData = [
  {
    id: 1,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '3',
    earnings: '₦ 500,000',
    status: 'paid',
  },
  {
    id: 2,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'paid',
  },
  {
    id: 3,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'paid',
  },
  {
    id: 4,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'paid',
  },
  {
    id: 5,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'not paid',
  },
  {
    id: 6,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'paid',
  },
  {
    id: 7,
    date_created: 'May 27, 2020',
    time: '12:38pm',
    referral: '1',
    earnings: '₦ 500,000',
    status: 'not paid',
  },
];
export const ManageCustomerData = [
  {
    id: 1,
    name: 'Olivia Rhye',
    email: '@Livia24',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'No',
    status: 'active',
  },
  {
    id: 2,
    name: 'Phoenix Baker',
    email: '@Muhandis',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'Yes',
    status: 'active',
  },
  {
    id: 3,
    name: 'Lana Steiner',
    email: '@Isla',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'Yes',
    status: 'active',
  },
  {
    id: 4,
    name: 'Demi Wilkinson',
    email: 'demi@untitledui.com',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'Yes',
    status: 'active',
  },
  {
    id: 5,
    name: 'Candice Wu',
    email: '@Isla',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'No',
    status: 'not active',
  },
  {
    id: 6,
    name: 'Natali Craig',
    email: '@Isla',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'No',
    role_title: '@Livia24',
    status: 'active',
  },
  {
    id: 7,
    name: 'Drew Cano',
    email: '@Muhandis',
    date_created: 'May 27, 2020',
    time: '12:38pm',
    tier: 'Tier 3',
    balance: '₦ 500,000',
    cards: 'No',
    role_title: '@Livia24',
    status: 'not active',
  },
];
export const filterList = [{ id: 1, value: 'date', label: 'Date' }];

export const EntriesfilterList = [
  { id: 1, value: 10, label: '10 Entries' },
  { id: 2, value: 25, label: '25 Entries' },
  { id: 3, value: 50, label: '50 Entries' },
  { id: 4, value: 100, label: '100 Entries' },
];
export const DatefilterList = [
    { 
        id: 1, 
        value: 'thisyear',
        label: 'This Year', 
        startDate: getFirstDayOfYear(), 
        endDate: getLastDayOfYear() 
    },
    { 
        id: 2, 
        value: 'thismonth', 
        label: 'This Month', 
        startDate: getFirstDayOfMonth(), 
        endDate: getLastDayOfMonth()
    },
    {   id: 3, 
        value: 'thisweek', 
        label: 'This Week', 
        startDate: getFirstDayOfWeek(), 
        endDate: getLastDayOfWeek()
    },
];
