/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Data({color}) {
    return(
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill={color || "white"}/>
            <g clipPath="url(#clip0_674_10783)">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5147 32.4854C10.8284 27.7992 10.8284 20.2012 15.5147 15.515C20.2009 10.8288 27.7988 10.8288 32.4851 15.515C37.1713 20.2012 37.1713 27.7992 32.4851 32.4854C27.7993 37.1716 20.2009 37.1716 15.5147 32.4854ZM28.4291 21.6984L24.9256 18.1948V29.098H25.9254V20.6092L28.0753 22.7592L28.7824 22.0521L28.4291 21.6984ZM19.5712 26.3016L23.0747 29.8051V18.9019H22.0748V27.3907L19.9249 25.2408L19.2179 25.9478L19.5712 26.3016Z" fill="#1C3DDF"/>
            </g>
            <defs>
            <clipPath id="clip0_674_10783">
            <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

Data.propTypes = {
    color: PropTypes.string
}