import React, { useState } from 'react';
import Title from 'components/Header/Title';
import CustomButton from 'components/Button/CustomButton';
import Tabs from 'components/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import Service from './Service';
import BackButton from 'components/Button/BackButton';
import usePermissions from 'hooks/usePermissions';

const Index = () => {
  const [selectedTab, setSelectedTab] = useState('all');
  const navigate = useNavigate();
  const { userIsPermittedTo } = usePermissions();

  //Variables
  let TabsHeader = ['all', 'active', 'inactive', 'scheduled'];

  const { id } = useParams();

  return (
    <div>
      <div className="flex justify-between my-7 items-center">
        <div className="flex items-center space-x-2">
          <BackButton />
          <Title title={id} variant="" />
        </div>
        {userIsPermittedTo('Create', 'discount_management') && (
          <CustomButton
            name="Create new discount"
            variant="border border-purple bg-purple text-white rounded font-dmsans_m"
            onClick={() => navigate(`/settings/discount-management/${id}/create-account`)}
          />
        )}
      </div>
      <div className="py-6 px-6 bg-white rounded-lg border-light-smoke border">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} TabsItem={TabsHeader} />
        <Service tab={selectedTab} />
      </div>
    </div>
  );
};

export default Index;
