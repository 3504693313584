import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';

export default function AppLayout() {
  const location = useLocation();
  const successPage = location.pathname !== '/manage-customer/success-page';

  return (
    <div className="h-screen overflow-y-auto bg-authBg bg-fixed bg-no-repeat bg-right-bottom bg-purple_transparent">
      <NavBar />
      <div className="flex screen-height overflow-hidden">
        {successPage && <Sidebar />}
        <div className={`px-24 ${successPage && 'overflow-scroll lg:py-32'} lg:py-0 pb-20 pt-[100px] w-full`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
