import React from "react";
import LoginForm from "../../components/Forms/LoginForm";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { LeftAuth } from "../../components/Onboarding/LeftAuth";
import { useSearchParams } from 'react-router-dom';

const Login = () => {
  const [searchParams] = useSearchParams();
  const invited = searchParams.get('invited');

    return (
      <AuthLayout 
        left={<LeftAuth/>}
        right={<LoginForm invited={invited}/>}
      />
    );
  }
  
  export default Login;
  