import React, { useEffect } from 'react';
import { ReferHeader } from '../../../constants/mocks/customers';
import Status from '../../Status';
import CTable from '../../Table/Table';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';

const ReferAndEarn = () => {
  const customerState = useCustomerState();

  const { fetchCustomerReferralTrail } = useCustomer();
  useEffect(() => {
    fetchCustomerReferralTrail(customerState?.selectedCustomer?.user_id);
  }, []);

  return (
    <div>
      <p className="text-black-transparent text-16 mb-12">Referrals</p>
      <CTable
        header={ReferHeader}
        showCheckBox={false}
        loading={customerState?.loading}
        data={customerState?.customerReferralTrial}
        hasPagination={false}
      >
        {(item) => (
          <>
            <td className="pl-6" role="cell">
              <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {dayjs(item.created_at).format('MMM DD, YYYY')}
                <span className="text-grey"> | {new Date(item.created_at).toLocaleTimeString('en-US')}</span>
              </p>
            </td>
            <td className="pl-6">
              <p className="text-grey-500 font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap capitalize">{item.first_name} {item.last_name}</p>
            </td>
            <td className="pl-6">
              <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {formatCurrency(item.referral_amount)}
              </p>
            </td>
            <td className="pl-6">
              <Status showIcon status={item.is_paid ? 'paid' : 'not paid'} />
            </td>
          </>
        )}
      </CTable>
    </div>
  );
};

export default ReferAndEarn;
