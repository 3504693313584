import Chart from 'components/Chart/Chart';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePaymentState } from 'store/modules/payment';
import useFormatDate from 'hooks/useFormatDate';
import Dropdown from 'components/DropDown/DropDown';
import { IoMdArrowDropdown } from 'react-icons/io';
import { paymentFilterList, providerFilterList } from 'constants/mocks/payment';
import Card from 'components/Card/Card';
import { useSecondRunEffect } from 'hooks/useSecondEffect';
import useAdmin from 'hooks/fetch/useAdmin';
import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';

const PaymentAnalytics = () => {
  // Hook
  const { getMonth, getYear } = useFormatDate();
  const { handleCategoryAnalytics } = useAdmin();
  //Local State
  const [filter, setFilter] = useState();
  const [sort, setSort] = useState({ id: 0, value: '', label: 'Sort by' });
  const [providerFilter, setProviderFilter] = useState({ id: 0, value: '', label: 'Sort by Provider', key: '' });
  const { categoryAnalytics } = usePaymentState();
  const month = dayjs(filter).format('MM');
  const year = dayjs(filter).format('YYYY');
  //Variable
  const sort_value = sort.value;
  const provider_value = providerFilter?.value;

  //Effect
  useEffect(() => {
    handleCategoryAnalytics({ month: getMonth(), year: getYear() });
  }, []);

  useSecondRunEffect(() => {
    let selectedCategory = '';
    if (sort_value !== '') selectedCategory = sort_value;

    let selectedProvider = '';
    if (provider_value !== '') selectedProvider = provider_value;

    const analyticsParams = { month, year };
    if (selectedCategory) analyticsParams.category = selectedCategory;
    if (selectedProvider) analyticsParams.provider = selectedProvider;

    handleCategoryAnalytics(analyticsParams);
  }, [sort, providerFilter]);

  const AllCategory =
    (categoryAnalytics && sort_value === '') || provider_value !== undefined
      ? Object.keys(categoryAnalytics || {})
          .filter((key) => key.includes('purchased'))
          .reduce((obj, key) => {
            return Object.assign(obj, { [key]: categoryAnalytics[key] });
          }, {})
      : {};

  const SingleCategory =
    (categoryAnalytics && sort_value !== '') || (provider_value !== '' && filter)
      ? Object.keys(categoryAnalytics || {})
          .filter((key) => !key.includes('_revenue'))
          .reduce((obj, key) => {
            return Object.assign(obj, { [key]: categoryAnalytics[key] });
          }, {})
      : [];

  const productLabels = useMemo(() => {
    const keysArray = categoryAnalytics ? Object.keys(AllCategory) : [];
    const filteredLabel = categoryAnalytics
      ? keysArray.map((item) => {
          const label = item.replace('_purchased', '').split('_');
          const firstWord = label[0]?.charAt(0).toUpperCase() + label[0]?.slice(1);
          const lastWord = label[1].charAt(0).toUpperCase() + label[1].slice(1);
          return firstWord + ' ' + lastWord;
        })
      : [];
    return filteredLabel;
  }, [categoryAnalytics]);

  const categoryLabels = useMemo(() => {
    let keysArray = categoryAnalytics ? Object.keys(categoryAnalytics).filter((key) => !key.includes('_revenue')) : [];
    const filteredLabel = categoryAnalytics ? keysArray.map((item) => item && item.replace('_total', '')) : [];
    return filteredLabel;
  }, [categoryAnalytics]);

  const categoryData = useMemo(() => {
    let valueArray = categoryAnalytics ? Object.values(SingleCategory) : [];
    return valueArray;
  }, [categoryAnalytics]);

  const productData = useMemo(() => {
    let valueArray = categoryAnalytics ? Object.values(AllCategory) : [];
    return valueArray;
  }, [categoryAnalytics]);

  const handleSort = useCallback(
    (data) => {
      if (data?.key === 'api') {
        setProviderFilter(data);
        setSort('');
      } else {
        setSort(data);
        setProviderFilter('');
      }
    },
    [sort, providerFilter],
  );
  const switchLabel = () => {
    switch (sort_value ?? provider_value) {
      case 'voucher-purchase':
        return Object.keys(categoryAnalytics);
      case '':
      case 'paga':
      case 'quickteller':
      case 'vas2nets':
        return productLabels;
      default:
        return categoryLabels;
    }
  };

  const switchData = () => {
    switch (sort_value ?? provider_value) {
      case 'voucher-purchase':
        return Object.values(categoryAnalytics);
      case '':
      case 'paga':
      case 'quickteller':
      case 'vas2nets':
        return productData;
      default:
        return categoryData;
    }
  };

  //Handler Functions
  const handleFilter = useCallback(
    (e) => {
      const date = e.target.value;
      setFilter(e.target.value);

      if (sort_value !== '' || (provider_value !== '' && date)) {
        const analyticsParams = {
          month: dayjs(date).format('MM'),
          year: dayjs(date).format('YYYY'),
        };
        if (sort_value) analyticsParams.category = sort_value;
        if (provider_value) analyticsParams.provider = provider_value;
        handleCategoryAnalytics(analyticsParams);
      }
      if (sort_value === '' || (provider_value === '' && date)) {
        handleCategoryAnalytics({
          month: dayjs(date).format('MM'),
          year: dayjs(date).format('YYYY'),
        });
      }
    },
    [filter, sort_value, provider_value],
  );

  const data = {
    labels: switchLabel(),
    datasets: [
      {
        data: switchData(),
        backgroundColor: 'rgba(80, 52, 196, 1)',
        barThickness: 48,
        borderRadius: 8,
      },
    ],
  };

  return (
    <Card
      type="custom"
      containerVariant="col-span-4 flex flex-col p-6 bg-white rounded-lg"
      cardBody={
        <>
          <div className="flex justify-between items-center mb-10">
            <p className="text-16 font-dmsans_m text-black-200 ">
              Revenue/Payment for {sort?.value === '' ? 'All Categories' : sort?.label}
            </p>
            <div className="flex space-x-3">
              <div className="flex items-center space-x-2">
                <p className="font-dmsans_r ">Filter:</p>
                <div className="cursor-pointer ">
                  <div className="w-[180px]">
                    <Calendar
                      inputClassName="placeholder:text-dark_blue text-dark_blue h-11"
                      value={filter}
                      onChange={(e) => handleFilter(e)}
                      view="month"
                      dateFormat="MM, yy"
                      placeholder={dayjs(new Date()).format('MMMM, YYYY')}
                    />
                  </div>
                </div>
              </div>
              <Dropdown
                variant="w-[148px] bg-gray-150"
                placeholder="Filter"
                innerVariant="text-14 justify-between font-dmsans_r flex items-center text-blue_black"
                icon={<IoMdArrowDropdown className="w-6 h-6 text-deep-smoke" />}
                optionContainerVariant="max-h-136 scroll-styled min-w-[147px]"
                value={providerFilter.key === 'api' ? providerFilter : sort}
                showIcon={true}
                type="filter"
                options={paymentFilterList}
                providers={providerFilterList}
                onChange={handleSort}
              />
            </div>
          </div>
          <div className="flex-grow h-full">
            <Chart loading={false} data={data} />
          </div>
        </>
      }
    />
  );
};

export default PaymentAnalytics;
