/* eslint-disable no-unused-vars */

import React, { ReactNode } from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import PropTypes from 'prop-types';


const ExportExcel = ({ excelData, fileName, children }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';
    const filteredData = excelData?.map(({profile_picture, ...others}) =>  { 
        return others;
    })
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
 
    return (
        <div onClick={(e) => exportToExcel(e)} className="border-none bg-[#00000000]">
            {children}
        </div>
    )
}

export default ExportExcel;

ExportExcel.propTypes = {
    excelData: PropTypes.any,
    children: PropTypes.any,
    fileName: PropTypes.string
  };