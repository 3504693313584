/* eslint-disable no-unused-vars */
import CustomButton from 'components/Button/CustomButton';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import ExportExcel from 'components/ExportExcel';
import { useDebounce } from 'hooks/useDounce';
import { useTransferState } from 'store/modules/transfers';
import useAdmin from 'hooks/fetch/useAdmin';
import {
  NewCategoryHistoryFilter,
  NewStatusHistoryFilter,
  NewTransactionHistoryFilter,
} from 'constants/mocks/new_transaction_history';
import NewTable from 'components/Table/NewTable';
import Dropdown from 'components/DropDown/DropDown';
import Filter from 'assets/icons/Filter';
import SearchInput from 'components/Input/SearchInput';
import { EntriesfilterList } from 'constants/mocks/customers';
import { IoIosArrowDown } from 'react-icons/io';
import { useAuthState } from 'store/modules/auth';

const CustomerTransactionHistory = () => {
  //Redux Hooks
  const {
    loggedUser: { role },
  } = useAuthState();
  const { handleGetCustomerTransactionHistory } = useAdmin();
  const { customersTransactionHistory, loading } = useTransferState();


  const [filterTable, setFilterTable] = useState();
  const [statusTable, setStatusTable] = useState();
  const [categoryTable, setCategoryTable] = useState();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const per_page = perPage.value;
  const debouncedSearch = useDebounce(search);
  const [transactionType, setTransactionType] = useState('');
  const [transactionCategory, setTransactionCategory] = useState('');

  const filterSearch = () => {
    handleGetCustomerTransactionHistory({
      page,
      perPage: per_page,
      transactionType,
      status,
      transactionCategory,
      search: debouncedSearch,
    });
  };

  useEffect(() => {
    filterSearch();
  }, [per_page, page, debouncedSearch, status, transactionType, transactionCategory]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );

  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );
  const changeCurrentPage = (data) => {
    setPage(data.selected + 1);
  };

  const handleTransactionType = useCallback(
    (data) => {
      setTransactionType(data?.value);
      setFilterTable(data);
    },
    [transactionType],
  );

  const handleTransactionStatus = useCallback(
    (data) => {
      setStatus(data?.value);
      setStatusTable(data);
    },
    [status],
  );

  const handleTransactionCategory = useCallback(
    (data) => {
      setTransactionCategory(data?.value);
      setCategoryTable(data);
    },
    [transactionCategory],
  );

  useEffect(() => {
    document.title = 'Transactions - Pouchers Admin';
  }, []);
  

  useEffect(()=>{
    if (role !== 'Super-Admin') {
      window.location.href="/home"
    }
  },[role])

  if (role !== 'Super-Admin') {
    return null
  }

  return (
    <DashboardLayout
      pageHeader="Transactions"
      hasCardTitle={false}
      hasFilter={false}
      variant="mb-0"
      actionButtons={
        <ExportExcel
          excelData={customersTransactionHistory?.transactionHistory}
          fileName={`file-customersTransactionHistory-${new Date().toISOString()}`}
        >
          <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
        </ExportExcel>
      }
    >
      <div className="flex items-center justify-end flex-wrap gap-5">
        <SearchInput
          placeholder="Search by beneficiary and transaction ID"
          value={search}
          handleChange={handleChange}
        />
        <Dropdown
          variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
          placeholder={`Filter by Operation`}
          innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
          icon={<Filter />}
          type="filter"
          // optionContainerVariant={filterStyles}
          value={filterTable}
          options={NewTransactionHistoryFilter}
          onChange={handleTransactionType}
        />
        <Dropdown
          variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
          placeholder={`Filter by Payment`}
          innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
          icon={<Filter />}
          type="filter"
          // optionContainerVariant={filterStyles}
          value={categoryTable}
          options={NewCategoryHistoryFilter}
          onChange={handleTransactionCategory}
        />
        <Dropdown
          variant="border border-light-smoke w-[175px] mt-4 md:mt-0"
          placeholder={`Filter by Status`}
          innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex items-center"
          icon={<Filter />}
          type="filter"
          // optionContainerVariant={filterStyles}
          value={statusTable}
          options={NewStatusHistoryFilter}
          onChange={handleTransactionStatus}
        />
        <div className="flex items-center mt-4 lg:mt-0">
          <span className="text-14 font-dmsans_r mr-2">Showing:</span>
          <div data-testid="pagefilter">
            <Dropdown
              variant="border border-light-smoke w-[142px]"
              placeholder=""
              innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
              icon={<IoIosArrowDown />}
              value={perPage}
              type="filter"
              options={EntriesfilterList}
              onChange={handlePerPage}
            />
          </div>
        </div>
      </div>
      <NewTable
        changeCurrentPage={changeCurrentPage}
        currentPage={customersTransactionHistory?.page || 0}
        data={customersTransactionHistory?.transactionHistory ?? []}
        hidePagination={false}
        loading={loading}
        per_page={per_page}
        total={customersTransactionHistory?.total}
      />
    </DashboardLayout>
  );
};

export default CustomerTransactionHistory;

CustomerTransactionHistory.proptypes = {
  hidePagination: PropTypes.bool,
};
