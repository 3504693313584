/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Internet({color}) {
    return(
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill={color || "white"}/>
        <g clipPath="url(#clip0_347_6549)">
        <path d="M23.9948 28.5675C22.9433 28.5675 22.0879 29.4201 22.0879 30.4676C22.0879 31.5155 22.9433 32.3685 23.9948 32.3685C25.0466 32.3685 25.902 31.5155 25.902 30.4676C25.902 29.4201 25.0464 28.5675 23.9948 28.5675Z" fill="black"/>
        <path d="M28.6485 25.9786C27.4071 24.7378 25.7545 24.0542 23.996 24.0542C22.2442 24.0542 20.5962 24.7333 19.3551 25.9663C18.8538 26.4651 18.8529 27.2779 19.353 27.7776C19.5959 28.0208 19.9193 28.1544 20.2636 28.1544C20.6063 28.1544 20.9285 28.0215 21.171 27.7803C21.9263 27.0297 22.9296 26.6163 23.996 26.6163C25.0664 26.6163 26.0724 27.0323 26.8282 27.7875C27.0711 28.03 27.3944 28.164 27.7383 28.164C28.0813 28.164 28.4034 28.0311 28.6461 27.7899C29.1481 27.291 29.149 26.4787 28.6485 25.9786Z" fill="#FF6414"/>
        <path d="M34.6245 20.0217C31.7862 17.1904 28.0111 15.6315 23.9953 15.6315C19.9849 15.6315 16.2136 17.1867 13.3762 20.0113C12.8749 20.5102 12.8746 21.3229 13.3755 21.8226C13.6182 22.0647 13.9411 22.1978 14.2847 22.1978C14.6281 22.1978 14.9506 22.0652 15.1934 21.8233C17.5453 19.4823 20.6711 18.1927 23.9953 18.1927C27.3243 18.1927 30.4532 19.4849 32.8057 21.8322C33.0485 22.0744 33.3719 22.208 33.7153 22.208C34.0585 22.208 34.381 22.0747 34.6236 21.8332C35.1251 21.334 35.1256 20.5209 34.6245 20.0217Z" fill="#FF6414"/>
        <path d="M31.5612 23.0748C29.5408 21.0591 26.8541 19.9492 23.996 19.9492C21.1432 19.9492 18.4605 21.0556 16.4418 23.0642C16.1988 23.3059 16.065 23.6274 16.0645 23.9698C16.0645 24.3118 16.1977 24.6336 16.4405 24.8757C16.6832 25.1179 17.0063 25.2515 17.3498 25.2515C17.6929 25.2515 18.0154 25.1183 18.2585 24.8771C19.792 23.3514 21.8297 22.5109 23.996 22.5109C26.1669 22.5109 28.2074 23.3543 29.7419 24.8852C29.985 25.1278 30.3079 25.2614 30.6516 25.2614C30.9948 25.2614 31.3176 25.1281 31.56 24.8866C31.8027 24.6449 31.9368 24.3234 31.937 23.9811C31.9373 23.6387 31.8037 23.3172 31.5612 23.0748Z" fill="#FF6414"/>
        </g>
        <defs>
        <clipPath id="clip0_347_6549">
        <rect width="22" height="22" fill="white" transform="translate(13 13)"/>
        </clipPath>
        </defs>
        </svg>
    )
}

Internet.propTypes = {
    color: PropTypes.string
}