import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  scheduledPayment: {},
  loading: false,
  error: null
}

export const scheduledPayment = createSlice({
  name: 'scheduled',
  initialState,
  extraReducers
})

// Selectors,
const selectSchedule = (state) => state.scheduledPayment;


//Auth Redux State
export const useScheduleState = () => useAppSelector(selectSchedule);

export default scheduledPayment.reducer