/* eslint-disable max-len */
import React from 'react';

export const Internet2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z"
        fill="#ECECEC"
      />
      <circle cx="24" cy="24" r="18" fill="#5034C4" />
      <g clipPath="url(#clip0_9418_106388)">
        <path
          d="M23.9958 28.1523C23.0399 28.1523 22.2622 28.9275 22.2622 29.8797C22.2622 30.8323 23.0399 31.6078 23.9958 31.6078C24.952 31.6078 25.7296 30.8323 25.7296 29.8797C25.7296 28.9275 24.9518 28.1523 23.9958 28.1523Z"
          fill="white"
        />
        <path
          d="M28.2253 25.7988C27.0966 24.6708 25.5943 24.0493 23.9957 24.0493C22.4031 24.0493 20.9049 24.6667 19.7766 25.7876C19.3209 26.2411 19.3201 26.9799 19.7747 27.4342C19.9956 27.6554 20.2896 27.7768 20.6026 27.7768C20.9141 27.7768 21.207 27.656 21.4275 27.4367C22.1142 26.7544 23.0262 26.3785 23.9957 26.3785C24.9688 26.3785 25.8833 26.7567 26.5704 27.4432C26.7913 27.6637 27.0851 27.7855 27.3978 27.7855C27.7096 27.7855 28.0024 27.6647 28.2231 27.4454C28.6794 26.9919 28.6802 26.2534 28.2253 25.7988Z"
          fill="white"
        />
        <path
          d="M33.6586 20.3834C31.0783 17.8095 27.6464 16.3923 23.9957 16.3923C20.3499 16.3923 16.9215 17.8061 14.342 20.374C13.8863 20.8275 13.886 21.5663 14.3413 22.0206C14.562 22.2406 14.8556 22.3616 15.1679 22.3616C15.4801 22.3616 15.7733 22.2411 15.994 22.0212C18.1321 19.893 20.9738 18.7207 23.9957 18.7207C27.0221 18.7207 29.8666 19.8954 32.0052 22.0293C32.2259 22.2495 32.5199 22.3709 32.8321 22.3709C33.1441 22.3709 33.4373 22.2498 33.6578 22.0302C34.1137 21.5764 34.1142 20.8372 33.6586 20.3834Z"
          fill="white"
        />
        <path
          d="M30.8731 23.1589C29.0364 21.3264 26.594 20.3174 23.9957 20.3174C21.4022 20.3174 18.9634 21.3233 17.1282 23.1493C16.9072 23.369 16.7856 23.6613 16.7852 23.9725C16.7852 24.2834 16.9063 24.576 17.127 24.796C17.3477 25.0163 17.6414 25.1377 17.9537 25.1377C18.2655 25.1377 18.5588 25.0166 18.7798 24.7973C20.1738 23.4103 22.0263 22.6462 23.9957 22.6462C25.9692 22.6462 27.8242 23.413 29.2192 24.8047C29.4402 25.0252 29.7337 25.1467 30.0462 25.1467C30.3582 25.1467 30.6516 25.0256 30.872 24.806C31.0927 24.5863 31.2146 24.294 31.2147 23.9827C31.2151 23.6715 31.0936 23.3792 30.8731 23.1589Z"
          fill="white"
        />
      </g>
      <path
        d="M44.0386 34.8763C44.6211 35.1924 45.3525 34.9777 45.6391 34.3802C47.283 30.9532 48.092 27.1792 47.9917 23.3682C47.8808 19.1568 46.6633 15.0488 44.4615 11.457C42.2598 7.86529 39.1514 4.91638 35.4487 2.90671C32.0982 1.08815 28.3679 0.0966743 24.5681 0.00672065C23.9056 -0.0089644 23.3824 0.545389 23.3998 1.2079C23.4173 1.87041 23.9688 2.38986 24.6313 2.40922C28.0096 2.50799 31.3236 3.39849 34.3038 5.01604C37.6362 6.82474 40.4338 9.47876 42.4154 12.7113C44.3969 15.9439 45.4927 19.6411 45.5925 23.4314C45.6818 26.8211 44.9717 30.1784 43.5268 33.2338C43.2435 33.8329 43.4562 34.5601 44.0386 34.8763Z"
        fill="#5034C4"
      />
      <defs>
        <clipPath id="clip0_9418_106388">
          <rect width="20" height="20" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};
