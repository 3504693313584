/* eslint-disable max-len */
import React from 'react'

export const AuthLady = () => {
  return (
  <div >
    <svg className='h-auto w-auto'  viewBox="0 0 525 472" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="415.5" x2="525" y2="415.5" stroke="#C0C0C0"/>
    <path d="M446.624 461.908V469.17C446.62 469.741 446.418 470.293 446.053 470.733C445.643 471.224 445.1 471.588 444.49 471.779C444.291 471.844 444.087 471.891 443.881 471.921C443.686 471.951 443.49 471.967 443.293 471.967H305.515C303.74 471.967 302.277 470.783 302.181 469.311C302.181 469.266 302.181 469.215 302.181 469.17V461.908C302.181 461.858 302.181 461.812 302.181 461.762C302.272 460.291 303.736 459.107 305.515 459.107H443.289C443.486 459.107 443.682 459.122 443.877 459.153C444.083 459.182 444.287 459.229 444.485 459.294C445.096 459.486 445.639 459.849 446.049 460.341C446.416 460.781 446.62 461.335 446.624 461.908Z" fill="#FFB93E"/>
    <path d="M378.969 471.971H305.157C304.417 471.988 303.698 471.723 303.147 471.23C302.595 470.737 302.251 470.052 302.185 469.316C302.185 469.27 302.185 469.22 302.185 469.174V461.908C302.185 461.858 302.185 461.812 302.185 461.762C302.251 461.025 302.595 460.341 303.147 459.848C303.698 459.355 304.417 459.09 305.157 459.107H369.906C371.285 464.344 374.501 468.91 378.969 471.971Z" fill="#FFA412"/>
    <path d="M429.862 459.107H428.295V471.971H429.862V459.107Z" fill="#FFA412"/>
    <path d="M424.56 459.107H422.992V471.971H424.56V459.107Z" fill="#FFA412"/>
    <path d="M418.203 459.107H416.636V471.971H418.203V459.107Z" fill="#FFA412"/>
    <path d="M408.461 459.107H406.894V471.971H408.461V459.107Z" fill="#FFA412"/>
    <path d="M398.156 459.107H396.589V471.971H398.156V459.107Z" fill="#FFA412"/>
    <path d="M382.362 459.107H380.795V471.971H382.362V459.107Z" fill="#FFA412"/>
    <path d="M367.576 459.107H366.009V471.971H367.576V459.107Z" fill="#FFA412"/>
    <path d="M354.37 459.107H352.803V471.971H354.37V459.107Z" fill="#FFA412"/>
    <path d="M434.906 459.107H433.339V471.971H434.906V459.107Z" fill="#FFA412"/>
    <path d="M438.241 459.107H436.674V471.971H438.241V459.107Z" fill="#FFA412"/>
    <path d="M441.459 459.107H439.892V471.971H441.459V459.107Z" fill="#FFA412"/>
    <path d="M443.877 459.153V471.925C443.683 471.955 443.486 471.971 443.289 471.971H442.31V459.107H443.289C443.486 459.107 443.683 459.122 443.877 459.153Z" fill="#FFA412"/>
    <path d="M446.052 460.345V470.733C445.643 471.224 445.1 471.588 444.489 471.779V459.274C445.103 459.472 445.646 459.844 446.052 460.345Z" fill="#FFA412"/>
    <path d="M431.892 449.044V456.301C431.888 456.873 431.687 457.425 431.321 457.865C430.911 458.356 430.369 458.719 429.758 458.911C429.56 458.976 429.356 459.023 429.149 459.053C428.955 459.083 428.758 459.098 428.562 459.099H290.784C289.008 459.099 287.545 457.915 287.449 456.443C287.449 456.393 287.449 456.347 287.449 456.301V449.044C287.449 448.994 287.449 448.948 287.449 448.898C287.541 447.427 289.004 446.243 290.784 446.243H428.562H428.824H428.937H429.062H429.149C429.356 446.274 429.56 446.322 429.758 446.389L429.846 446.418L429.929 446.447L430.096 446.514L430.192 446.56L430.275 446.601L430.433 446.685L430.604 446.793L430.733 446.881L430.817 446.943C430.901 447.008 430.982 447.077 431.059 447.152L431.125 447.218L431.188 447.285L431.313 447.427C431.693 447.879 431.899 448.453 431.892 449.044Z" fill="#FFB93E"/>
    <path d="M364.237 459.107H290.425C289.686 459.124 288.967 458.859 288.415 458.366C287.863 457.873 287.52 457.188 287.453 456.451C287.453 456.401 287.453 456.356 287.453 456.31V449.044C287.453 448.994 287.453 448.948 287.453 448.898C287.52 448.161 287.863 447.477 288.415 446.984C288.967 446.491 289.686 446.226 290.425 446.243H355.171L355.262 446.56C356.683 451.669 359.861 456.112 364.237 459.107Z" fill="#FFA412"/>
    <path d="M415.131 446.243H413.563V459.107H415.131V446.243Z" fill="#FFA412"/>
    <path d="M409.828 446.243H408.261V459.107H409.828V446.243Z" fill="#FFA412"/>
    <path d="M403.472 446.243H401.904V459.107H403.472V446.243Z" fill="#FFA412"/>
    <path d="M393.729 446.243H392.162V459.107H393.729V446.243Z" fill="#FFA412"/>
    <path d="M383.43 446.243H381.862V459.107H383.43V446.243Z" fill="#FFA412"/>
    <path d="M367.631 446.243H366.063V459.107H367.631V446.243Z" fill="#FFA412"/>
    <path d="M352.845 446.243H351.277V459.107H352.845V446.243Z" fill="#FFA412"/>
    <path d="M339.639 446.243H338.071V459.107H339.639V446.243Z" fill="#FFA412"/>
    <path d="M420.175 446.243H418.607V459.107H420.175V446.243Z" fill="#FFA412"/>
    <path d="M423.51 446.243H421.942V459.107H423.51V446.243Z" fill="#FFA412"/>
    <path d="M426.728 446.243H425.16V459.107H426.728V446.243Z" fill="#FFA412"/>
    <path d="M429.149 446.297V459.069C428.955 459.1 428.758 459.115 428.562 459.115H427.582V446.243H428.562C428.759 446.246 428.955 446.264 429.149 446.297Z" fill="#FFA412"/>
    <path d="M431.321 447.481V457.869C430.911 458.36 430.368 458.724 429.758 458.915V446.41C430.372 446.608 430.915 446.98 431.321 447.481Z" fill="#FFA412"/>
    <path d="M439.012 436.497V443.758C439.008 444.33 438.807 444.882 438.441 445.322C438.031 445.813 437.489 446.176 436.878 446.368C436.68 446.433 436.476 446.48 436.27 446.51C436.075 446.54 435.879 446.555 435.682 446.555H297.904C297.375 446.558 296.851 446.45 296.366 446.239C295.902 446.057 295.494 445.757 295.182 445.368C294.869 444.98 294.664 444.517 294.586 444.025C294.586 443.983 294.586 443.942 294.586 443.9C294.586 443.858 294.586 443.804 294.586 443.758V436.497C294.586 436.447 294.586 436.401 294.586 436.351C294.678 434.879 296.141 433.696 297.921 433.696H435.678C435.874 433.696 436.071 433.711 436.265 433.741C436.472 433.772 436.676 433.821 436.874 433.887C437.485 434.079 438.027 434.442 438.437 434.934C438.804 435.373 439.007 435.925 439.012 436.497Z" fill="#FFB93E"/>
    <path d="M371.357 446.56H297.546C297.07 446.561 296.601 446.452 296.174 446.243C295.743 446.039 295.372 445.729 295.094 445.341C294.816 444.954 294.641 444.503 294.586 444.029C294.586 443.988 294.586 443.946 294.586 443.904C294.586 443.863 294.586 443.808 294.586 443.763V436.497C294.586 436.447 294.586 436.401 294.586 436.351C294.652 435.614 294.996 434.93 295.548 434.437C296.1 433.944 296.818 433.679 297.558 433.696H362.291C363.624 438.747 366.667 443.18 370.903 446.239L371.357 446.56Z" fill="#FFA412"/>
    <path d="M422.251 433.7H420.684V446.564H422.251V433.7Z" fill="#FFA412"/>
    <path d="M416.948 433.7H415.381V446.564H416.948V433.7Z" fill="#FFA412"/>
    <path d="M410.592 433.7H409.024V446.564H410.592V433.7Z" fill="#FFA412"/>
    <path d="M400.85 433.7H399.282V446.564H400.85V433.7Z" fill="#FFA412"/>
    <path d="M390.545 433.7H388.978V446.564H390.545V433.7Z" fill="#FFA412"/>
    <path d="M374.75 433.7H373.183V446.564H374.75V433.7Z" fill="#FFA412"/>
    <path d="M359.965 433.7H358.397V446.564H359.965V433.7Z" fill="#FFA412"/>
    <path d="M346.759 433.7H345.191V446.564H346.759V433.7Z" fill="#FFA412"/>
    <path d="M427.295 433.7H425.728V446.564H427.295V433.7Z" fill="#FFA412"/>
    <path d="M430.629 433.7H429.062V446.564H430.629V433.7Z" fill="#FFA412"/>
    <path d="M433.848 433.7H432.28V446.564H433.848V433.7Z" fill="#FFA412"/>
    <path d="M436.266 433.746V446.518C436.071 446.548 435.875 446.563 435.678 446.564H434.698V433.7H435.678C435.875 433.7 436.071 433.715 436.266 433.746Z" fill="#FFA412"/>
    <path d="M438.441 434.934V445.322C438.031 445.813 437.489 446.176 436.878 446.368V433.862C437.492 434.061 438.035 434.433 438.441 434.934Z" fill="#FFA412"/>
    <path d="M442.313 423.637V430.903C442.31 431.474 442.108 432.026 441.742 432.466C441.333 432.957 440.79 433.321 440.179 433.512C439.981 433.578 439.777 433.627 439.571 433.658C439.376 433.688 439.18 433.704 438.983 433.704H301.201C299.425 433.704 297.962 432.52 297.866 431.049C297.866 430.998 297.866 430.953 297.866 430.907V423.637C297.866 423.587 297.866 423.541 297.866 423.495C297.95 422.132 299.208 421.019 300.784 420.861C300.916 420.854 301.048 420.854 301.18 420.861H438.979C439.175 420.861 439.372 420.876 439.566 420.907C439.773 420.937 439.977 420.986 440.175 421.052C440.786 421.244 441.328 421.607 441.738 422.099C442.1 422.531 442.303 423.074 442.313 423.637Z" fill="#FFB93E"/>
    <path d="M374.659 433.7H300.847C300.107 433.717 299.389 433.452 298.837 432.959C298.285 432.466 297.941 431.781 297.875 431.044C297.875 430.994 297.875 430.949 297.875 430.903V423.637C297.875 423.587 297.875 423.541 297.875 423.495C297.934 422.82 298.228 422.187 298.707 421.707C299.186 421.227 299.818 420.93 300.493 420.869C300.609 420.862 300.726 420.862 300.843 420.869H365.592C366.979 426.094 370.197 430.648 374.659 433.7Z" fill="#FFA412"/>
    <path d="M425.548 420.836H423.98V433.696H425.548V420.836Z" fill="#FFA412"/>
    <path d="M420.25 420.836H418.683V433.696H420.25V420.836Z" fill="#FFA412"/>
    <path d="M413.893 420.836H412.325V433.696H413.893V420.836Z" fill="#FFA412"/>
    <path d="M404.151 420.836H402.584V433.696H404.151V420.836Z" fill="#FFA412"/>
    <path d="M393.847 420.836H392.279V433.696H393.847V420.836Z" fill="#FFA412"/>
    <path d="M378.052 420.836H376.484V433.696H378.052V420.836Z" fill="#FFA412"/>
    <path d="M363.262 420.836H361.694V433.696H363.262V420.836Z" fill="#FFA412"/>
    <path d="M350.056 420.836H348.488V433.696H350.056V420.836Z" fill="#FFA412"/>
    <path d="M430.596 420.836H429.028V433.696H430.596V420.836Z" fill="#FFA412"/>
    <path d="M433.927 420.836H432.359V433.696H433.927V420.836Z" fill="#FFA412"/>
    <path d="M437.144 420.836H435.577V433.696H437.144V420.836Z" fill="#FFA412"/>
    <path d="M439.566 420.869V433.641C439.372 433.672 439.175 433.687 438.979 433.687H437.999V420.836H438.979C439.175 420.832 439.372 420.843 439.566 420.869Z" fill="#FFA412"/>
    <path d="M441.743 422.074V432.462C441.333 432.953 440.79 433.316 440.18 433.508V421.002C440.793 421.201 441.336 421.573 441.743 422.074Z" fill="#FFA412"/>
    <path d="M432.185 410.794V418.055C432.181 418.627 431.979 419.179 431.614 419.619C431.204 420.11 430.661 420.473 430.05 420.665C429.852 420.73 429.648 420.777 429.442 420.807L429.25 420.832H429.142H291.072C289.296 420.832 287.833 419.648 287.737 418.176C287.737 418.126 287.737 418.08 287.737 418.035V410.794C287.737 410.744 287.737 410.698 287.737 410.648C287.737 410.598 287.737 410.569 287.737 410.531C287.871 409.347 288.896 408.368 290.238 408.08H290.338C290.576 408.036 290.818 408.013 291.06 408.013H428.85H429.15H429.283L429.438 408.034C429.644 408.065 429.848 408.114 430.046 408.18C430.657 408.372 431.199 408.735 431.609 409.226C431.977 409.666 432.18 410.22 432.185 410.794Z" fill="#FFB93E"/>
    <path d="M364.529 420.869H290.717C289.978 420.886 289.259 420.621 288.707 420.128C288.155 419.635 287.812 418.951 287.745 418.214C287.745 418.164 287.745 418.118 287.745 418.072V410.794C287.745 410.744 287.745 410.698 287.745 410.648C287.745 410.598 287.745 410.569 287.745 410.531C287.82 409.929 288.084 409.366 288.499 408.922C288.914 408.479 289.458 408.178 290.054 408.063L290.184 408.038C290.357 408.008 290.533 407.993 290.709 407.993H355.463C355.463 408.022 355.463 408.051 355.488 408.084C356.875 413.272 360.07 417.794 364.496 420.836L364.529 420.869Z" fill="#FFA412"/>
    <path d="M415.418 407.997H413.851V420.861H415.418V407.997Z" fill="#FFA412"/>
    <path d="M410.12 407.997H408.553V420.861H410.12V407.997Z" fill="#FFA412"/>
    <path d="M403.764 407.997H402.196V420.861H403.764V407.997Z" fill="#FFA412"/>
    <path d="M394.021 407.997H392.454V420.861H394.021V407.997Z" fill="#FFA412"/>
    <path d="M383.717 407.997H382.149V420.861H383.717V407.997Z" fill="#FFA412"/>
    <path d="M367.923 407.997H366.355V420.861H367.923V407.997Z" fill="#FFA412"/>
    <path d="M353.133 407.997H351.565V420.861H353.133V407.997Z" fill="#FFA412"/>
    <path d="M339.927 407.997H338.359V420.861H339.927V407.997Z" fill="#FFA412"/>
    <path d="M420.467 407.997H418.899V420.861H420.467V407.997Z" fill="#FFA412"/>
    <path d="M423.797 407.997H422.229V420.861H423.797V407.997Z" fill="#FFA412"/>
    <path d="M427.016 407.997H425.448V420.861H427.016V407.997Z" fill="#FFA412"/>
    <path d="M429.437 408.038V420.811C429.243 420.841 429.047 420.856 428.85 420.857H427.87V407.997H428.85C429.046 407.996 429.243 408.01 429.437 408.038Z" fill="#FFA412"/>
    <path d="M431.613 409.231V419.618C431.203 420.11 430.66 420.473 430.05 420.665V408.184C430.66 408.376 431.203 408.739 431.613 409.231Z" fill="#FFA412"/>
    <path d="M431.892 398.026V405.287C431.888 405.859 431.686 406.411 431.321 406.85C430.911 407.342 430.368 407.705 429.758 407.897C429.602 407.948 429.444 407.989 429.283 408.018L429.153 408.043C428.959 408.073 428.762 408.088 428.566 408.088H290.784C290.644 408.088 290.505 408.08 290.367 408.063L290.204 408.043C288.708 407.817 287.549 406.738 287.47 405.433C287.47 405.383 287.47 405.337 287.47 405.291V398.026C287.47 397.976 287.47 397.93 287.47 397.88C287.561 396.408 289.025 395.224 290.805 395.224H428.561H428.824H428.937H429.062H429.149C429.356 395.255 429.56 395.304 429.758 395.37L429.845 395.399L429.929 395.429L430.095 395.495L430.275 395.583L430.433 395.666L430.604 395.775L430.733 395.862L430.817 395.925C430.901 395.99 430.982 396.059 431.058 396.133L431.125 396.2L431.188 396.266L431.313 396.408C431.693 396.861 431.898 397.435 431.892 398.026Z" fill="#FFB93E"/>
    <path d="M364.237 408.084H290.25H290.058C289.384 408.021 288.753 407.722 288.277 407.241C287.801 406.759 287.509 406.125 287.453 405.45C287.453 405.4 287.453 405.354 287.453 405.308V398.026C287.453 397.976 287.453 397.93 287.453 397.88C287.52 397.143 287.863 396.458 288.415 395.966C288.967 395.473 289.686 395.208 290.425 395.224H355.171L355.262 395.541C356.668 400.597 359.795 405.002 364.104 407.997L364.237 408.084Z" fill="#FFA412"/>
    <path d="M415.131 395.224H413.563V408.084H415.131V395.224Z" fill="#FFA412"/>
    <path d="M409.828 395.224H408.261V408.084H409.828V395.224Z" fill="#FFA412"/>
    <path d="M403.472 395.224H401.904V408.084H403.472V395.224Z" fill="#FFA412"/>
    <path d="M393.729 395.224H392.162V408.084H393.729V395.224Z" fill="#FFA412"/>
    <path d="M383.43 395.224H381.862V408.084H383.43V395.224Z" fill="#FFA412"/>
    <path d="M367.631 395.224H366.063V408.084H367.631V395.224Z" fill="#FFA412"/>
    <path d="M352.845 395.224H351.277V408.084H352.845V395.224Z" fill="#FFA412"/>
    <path d="M339.639 395.224H338.071V408.084H339.639V395.224Z" fill="#FFA412"/>
    <path d="M420.175 395.224H418.607V408.084H420.175V395.224Z" fill="#FFA412"/>
    <path d="M423.51 395.224H421.942V408.084H423.51V395.224Z" fill="#FFA412"/>
    <path d="M426.728 395.224H425.16V408.084H426.728V395.224Z" fill="#FFA412"/>
    <path d="M429.149 395.27V408.043C428.955 408.073 428.758 408.088 428.562 408.088H427.582V395.224H428.562C428.758 395.225 428.955 395.24 429.149 395.27Z" fill="#FFA412"/>
    <path d="M431.321 396.462V406.85C430.911 407.342 430.368 407.705 429.758 407.897V395.391C430.372 395.59 430.915 395.962 431.321 396.462Z" fill="#FFA412"/>
    <path d="M439.012 385.478V392.74C439.008 393.311 438.807 393.864 438.441 394.303C438.031 394.795 437.489 395.158 436.878 395.349C436.68 395.414 436.476 395.462 436.27 395.491C436.075 395.521 435.879 395.537 435.682 395.537H297.904C297.375 395.539 296.851 395.431 296.366 395.22C295.902 395.038 295.494 394.738 295.182 394.35C294.869 393.962 294.664 393.499 294.586 393.007C294.586 392.965 294.586 392.923 294.586 392.882C294.586 392.84 294.586 392.786 294.586 392.74V385.478C294.586 385.428 294.586 385.383 294.586 385.333C294.678 383.861 296.141 382.677 297.921 382.677H435.678C435.874 382.677 436.071 382.693 436.265 382.723C436.472 382.754 436.676 382.803 436.874 382.869C437.485 383.06 438.027 383.424 438.437 383.915C438.804 384.354 439.007 384.907 439.012 385.478Z" fill="#FFB93E"/>
    <path d="M371.357 395.541H297.546C297.07 395.542 296.601 395.434 296.174 395.224C295.744 395.02 295.372 394.71 295.094 394.323C294.817 393.935 294.642 393.484 294.586 393.011C294.586 392.969 294.586 392.928 294.586 392.886C294.586 392.844 294.586 392.79 294.586 392.744V385.478C294.586 385.428 294.586 385.383 294.586 385.333C294.652 384.596 294.996 383.911 295.548 383.418C296.1 382.925 296.818 382.66 297.558 382.677H362.291C363.624 387.729 366.667 392.162 370.903 395.22L371.357 395.541Z" fill="#FFA412"/>
    <path d="M422.251 382.681H420.684V395.545H422.251V382.681Z" fill="#FFA412"/>
    <path d="M416.948 382.681H415.381V395.545H416.948V382.681Z" fill="#FFA412"/>
    <path d="M410.592 382.681H409.024V395.545H410.592V382.681Z" fill="#FFA412"/>
    <path d="M400.85 382.681H399.282V395.545H400.85V382.681Z" fill="#FFA412"/>
    <path d="M390.545 382.681H388.978V395.545H390.545V382.681Z" fill="#FFA412"/>
    <path d="M374.75 382.681H373.183V395.545H374.75V382.681Z" fill="#FFA412"/>
    <path d="M359.965 382.681H358.397V395.545H359.965V382.681Z" fill="#FFA412"/>
    <path d="M346.759 382.681H345.191V395.545H346.759V382.681Z" fill="#FFA412"/>
    <path d="M427.295 382.681H425.728V395.545H427.295V382.681Z" fill="#FFA412"/>
    <path d="M430.629 382.681H429.062V395.545H430.629V382.681Z" fill="#FFA412"/>
    <path d="M433.848 382.681H432.28V395.545H433.848V382.681Z" fill="#FFA412"/>
    <path d="M436.266 382.723V395.495C436.071 395.526 435.875 395.541 435.678 395.541H434.698V382.681H435.678C435.875 382.68 436.071 382.694 436.266 382.723Z" fill="#FFA412"/>
    <path d="M438.441 383.915V394.303C438.031 394.795 437.489 395.158 436.878 395.349V382.844C437.492 383.042 438.035 383.414 438.441 383.915Z" fill="#FFA412"/>
    <path d="M450.271 372.614V379.88C450.267 380.451 450.065 381.004 449.7 381.443C449.29 381.935 448.748 382.298 448.137 382.489C447.939 382.556 447.735 382.605 447.528 382.635C447.334 382.666 447.137 382.681 446.941 382.681H309.163C307.387 382.681 305.924 381.497 305.828 380.026C305.828 379.976 305.828 379.93 305.828 379.884V372.614C305.828 372.568 305.828 372.518 305.828 372.473C305.911 371.11 307.17 369.997 308.746 369.838C308.878 369.831 309.01 369.831 309.142 369.838H446.936C447.133 369.838 447.33 369.854 447.524 369.884C447.731 369.915 447.935 369.964 448.133 370.03C448.743 370.221 449.286 370.585 449.696 371.076C450.057 371.508 450.26 372.051 450.271 372.614Z" fill="#FFB93E"/>
    <path d="M382.616 382.677H308.804C308.064 382.694 307.346 382.429 306.794 381.936C306.242 381.443 305.898 380.759 305.832 380.022C305.832 379.972 305.832 379.926 305.832 379.88V372.614C305.832 372.569 305.832 372.518 305.832 372.473C305.889 371.795 306.183 371.16 306.663 370.678C307.143 370.196 307.777 369.899 308.454 369.838C308.571 369.831 308.688 369.831 308.804 369.838H373.55C374.934 375.067 378.152 379.623 382.616 382.677Z" fill="#FFA412"/>
    <path d="M433.51 369.817H431.942V382.681H433.51V369.817Z" fill="#FFA412"/>
    <path d="M428.207 369.817H426.64V382.681H428.207V369.817Z" fill="#FFA412"/>
    <path d="M421.851 369.817H420.283V382.681H421.851V369.817Z" fill="#FFA412"/>
    <path d="M412.108 369.817H410.541V382.681H412.108V369.817Z" fill="#FFA412"/>
    <path d="M401.804 369.817H400.236V382.681H401.804V369.817Z" fill="#FFA412"/>
    <path d="M386.01 369.817H384.442V382.681H386.01V369.817Z" fill="#FFA412"/>
    <path d="M371.224 369.817H369.656V382.681H371.224V369.817Z" fill="#FFA412"/>
    <path d="M358.018 369.817H356.45V382.681H358.018V369.817Z" fill="#FFA412"/>
    <path d="M438.554 369.817H436.986V382.681H438.554V369.817Z" fill="#FFA412"/>
    <path d="M441.889 369.817H440.321V382.681H441.889V369.817Z" fill="#FFA412"/>
    <path d="M445.106 369.817H443.539V382.681H445.106V369.817Z" fill="#FFA412"/>
    <path d="M447.524 369.863V382.635C447.33 382.666 447.133 382.681 446.937 382.681H445.957V369.817H446.937C447.133 369.818 447.33 369.833 447.524 369.863Z" fill="#FFA412"/>
    <path d="M449.7 371.051V381.439C449.29 381.931 448.747 382.294 448.137 382.485V370.013C448.746 370.202 449.289 370.563 449.7 371.051Z" fill="#FFA412"/>
    <path d="M432.185 359.775V367.037C432.181 367.608 431.979 368.161 431.614 368.6C431.204 369.092 430.661 369.455 430.05 369.646C429.852 369.711 429.648 369.758 429.442 369.788L429.25 369.813H429.096H291.072C289.296 369.813 287.833 368.629 287.737 367.158C287.737 367.108 287.737 367.062 287.737 367.016V359.775C287.737 359.725 287.737 359.679 287.737 359.629C287.829 358.158 289.292 356.974 291.072 356.974H429.025C429.164 356.981 429.304 356.995 429.442 357.016C429.648 357.047 429.852 357.095 430.05 357.162C430.15 357.195 430.246 357.228 430.338 357.27L430.509 357.349L430.676 357.437H430.705L430.855 357.528C430.972 357.601 431.083 357.682 431.188 357.77L431.284 357.854L431.343 357.908L431.438 358.012L431.572 358.162C431.963 358.609 432.181 359.181 432.185 359.775Z" fill="#FFB93E"/>
    <path d="M364.529 369.838H290.717C289.978 369.855 289.259 369.59 288.707 369.097C288.155 368.604 287.812 367.92 287.745 367.183C287.745 367.133 287.745 367.087 287.745 367.041V359.775C287.745 359.725 287.745 359.679 287.745 359.629C287.812 358.892 288.155 358.208 288.707 357.715C289.259 357.222 289.978 356.957 290.717 356.974H355.463C355.488 357.07 355.517 357.17 355.546 357.27C356.961 362.374 360.13 366.815 364.496 369.813L364.529 369.838Z" fill="#FFA412"/>
    <path d="M415.418 356.974H413.851V369.838H415.418V356.974Z" fill="#FFA412"/>
    <path d="M410.12 356.974H408.553V369.838H410.12V356.974Z" fill="#FFA412"/>
    <path d="M403.764 356.974H402.196V369.838H403.764V356.974Z" fill="#FFA412"/>
    <path d="M394.021 356.974H392.454V369.838H394.021V356.974Z" fill="#FFA412"/>
    <path d="M383.717 356.974H382.149V369.838H383.717V356.974Z" fill="#FFA412"/>
    <path d="M367.923 356.974H366.355V369.838H367.923V356.974Z" fill="#FFA412"/>
    <path d="M353.133 356.974H351.565V369.838H353.133V356.974Z" fill="#FFA412"/>
    <path d="M339.927 356.974H338.359V369.838H339.927V356.974Z" fill="#FFA412"/>
    <path d="M420.467 356.974H418.899V369.838H420.467V356.974Z" fill="#FFA412"/>
    <path d="M423.797 356.974H422.229V369.838H423.797V356.974Z" fill="#FFA412"/>
    <path d="M427.016 356.974H425.448V369.838H427.016V356.974Z" fill="#FFA412"/>
    <path d="M429.437 357.02V369.792C429.243 369.822 429.047 369.838 428.85 369.838H427.87V356.974H428.85C429.047 356.974 429.243 356.99 429.437 357.02Z" fill="#FFA412"/>
    <path d="M431.613 358.212V368.6C431.203 369.092 430.66 369.455 430.05 369.646V357.141C430.664 357.339 431.206 357.711 431.613 358.212Z" fill="#FFA412"/>
    <path d="M442.313 347.207V354.473C442.309 355.044 442.108 355.597 441.742 356.036C441.334 356.531 440.791 356.897 440.179 357.091C439.981 357.157 439.777 357.206 439.571 357.237C439.376 357.267 439.18 357.282 438.983 357.283H301.201C300.69 357.285 300.184 357.184 299.713 356.987C299.236 356.809 298.815 356.508 298.494 356.114C298.172 355.719 297.961 355.247 297.883 354.744C297.883 354.706 297.883 354.669 297.883 354.631C297.883 354.594 297.883 354.536 297.883 354.49V347.207C297.883 347.157 297.883 347.111 297.883 347.066C297.966 345.702 299.225 344.589 300.801 344.431C300.933 344.424 301.065 344.424 301.197 344.431H438.979C439.175 344.431 439.372 344.447 439.566 344.477C439.773 344.495 439.978 344.532 440.179 344.585C440.79 344.777 441.332 345.14 441.742 345.632C442.11 346.074 442.312 346.632 442.313 347.207Z" fill="#FFB93E"/>
    <path d="M374.658 357.27H300.847C300.387 357.272 299.932 357.171 299.517 356.974C299.074 356.773 298.691 356.461 298.405 356.068C298.118 355.675 297.939 355.214 297.883 354.731C297.883 354.694 297.883 354.656 297.883 354.619C297.883 354.581 297.883 354.523 297.883 354.477V347.207C297.883 347.157 297.883 347.111 297.883 347.066C297.94 346.388 298.234 345.752 298.714 345.271C299.193 344.789 299.828 344.491 300.505 344.431C300.621 344.424 300.738 344.424 300.855 344.431H365.592C366.929 349.486 369.981 353.92 374.225 356.974L374.658 357.27Z" fill="#FFA412"/>
    <path d="M425.548 344.41H423.98V357.274H425.548V344.41Z" fill="#FFA412"/>
    <path d="M420.25 344.41H418.683V357.274H420.25V344.41Z" fill="#FFA412"/>
    <path d="M413.893 344.41H412.325V357.274H413.893V344.41Z" fill="#FFA412"/>
    <path d="M404.151 344.41H402.584V357.274H404.151V344.41Z" fill="#FFA412"/>
    <path d="M393.847 344.41H392.279V357.274H393.847V344.41Z" fill="#FFA412"/>
    <path d="M378.052 344.41H376.484V357.274H378.052V344.41Z" fill="#FFA412"/>
    <path d="M363.262 344.41H361.694V357.274H363.262V344.41Z" fill="#FFA412"/>
    <path d="M350.056 344.41H348.488V357.274H350.056V344.41Z" fill="#FFA412"/>
    <path d="M430.596 344.41H429.028V357.274H430.596V344.41Z" fill="#FFA412"/>
    <path d="M433.927 344.41H432.359V357.274H433.927V344.41Z" fill="#FFA412"/>
    <path d="M437.144 344.41H435.577V357.274H437.144V344.41Z" fill="#FFA412"/>
    <path d="M439.566 344.456V357.228C439.372 357.259 439.175 357.274 438.979 357.274H437.999V344.41H438.979C439.175 344.411 439.372 344.426 439.566 344.456Z" fill="#FFA412"/>
    <path d="M441.743 345.644V356.032C441.335 356.528 440.792 356.896 440.18 357.091V344.585C440.792 344.78 441.335 345.148 441.743 345.644Z" fill="#FFA412"/>
    <path d="M446.624 334.368V341.634C446.62 342.205 446.418 342.758 446.053 343.197C445.643 343.689 445.1 344.052 444.49 344.243C444.291 344.31 444.088 344.359 443.881 344.389L443.689 344.414H443.581H305.515C303.74 344.414 302.277 343.231 302.181 341.759C302.181 341.709 302.181 341.663 302.181 341.617V334.368C302.181 334.318 302.181 334.272 302.181 334.227C302.272 332.755 303.736 331.571 305.515 331.571H443.289C443.486 331.572 443.682 331.587 443.877 331.617C444.083 331.648 444.287 331.697 444.485 331.763C445.096 331.954 445.639 332.318 446.049 332.809C446.414 333.247 446.618 333.798 446.624 334.368Z" fill="#FFB93E"/>
    <path d="M378.969 344.431H305.157C304.417 344.448 303.698 344.183 303.147 343.69C302.595 343.197 302.251 342.513 302.185 341.776C302.185 341.726 302.185 341.68 302.185 341.634V334.368C302.185 334.318 302.185 334.272 302.185 334.227C302.251 333.49 302.595 332.805 303.147 332.312C303.698 331.819 304.417 331.554 305.157 331.571H369.906C371.281 336.796 374.487 341.354 378.939 344.414L378.969 344.431Z" fill="#FFA412"/>
    <path d="M429.862 331.567H428.295V344.431H429.862V331.567Z" fill="#FFA412"/>
    <path d="M424.56 331.567H422.992V344.431H424.56V331.567Z" fill="#FFA412"/>
    <path d="M418.203 331.567H416.636V344.431H418.203V331.567Z" fill="#FFA412"/>
    <path d="M408.461 331.567H406.894V344.431H408.461V331.567Z" fill="#FFA412"/>
    <path d="M398.156 331.567H396.589V344.431H398.156V331.567Z" fill="#FFA412"/>
    <path d="M382.362 331.567H380.795V344.431H382.362V331.567Z" fill="#FFA412"/>
    <path d="M367.576 331.567H366.009V344.431H367.576V331.567Z" fill="#FFA412"/>
    <path d="M354.37 331.567H352.803V344.431H354.37V331.567Z" fill="#FFA412"/>
    <path d="M434.906 331.567H433.339V344.431H434.906V331.567Z" fill="#FFA412"/>
    <path d="M438.241 331.567H436.674V344.431H438.241V331.567Z" fill="#FFA412"/>
    <path d="M441.459 331.567H439.892V344.431H441.459V331.567Z" fill="#FFA412"/>
    <path d="M443.877 331.613V344.385C443.683 344.416 443.486 344.431 443.289 344.431H442.31V331.567H443.289C443.486 331.567 443.683 331.583 443.877 331.613Z" fill="#FFA412"/>
    <path d="M446.052 332.805V343.193C445.643 343.685 445.1 344.048 444.489 344.239V331.734C445.103 331.932 445.646 332.304 446.052 332.805Z" fill="#FFA412"/>
    <rect x="148" y="323" width="240" height="149" rx="4" fill="#B9DCF8"/>
    <rect x="148" y="323" width="71" height="149" rx="4" fill="#A1D1F8"/>
    <path d="M304.886 308.615C298.183 320.286 289.75 324.788 289.75 324.788H254.664C254.664 324.788 310.001 299.711 304.886 308.615Z" fill="#233862"/>
    <path d="M277.942 398.9C277.522 399.362 277.244 399.934 277.141 400.549C277.038 401.164 277.115 401.796 277.362 402.369C278.196 404.682 280.964 407.262 284.173 409.843C284.59 410.172 284.949 410.476 285.266 410.756L285.512 410.977L285.699 411.147C288.692 413.907 287.567 414.541 292.948 420.143C299.08 426.538 303.315 426.654 304.499 426.071C305.516 425.575 303.449 422.19 301.164 418.384L300.831 417.83C300.527 417.325 300.222 416.808 299.922 416.304C297.242 411.718 297.93 409.576 298.563 402.76C299.08 397.225 307.179 371.826 307.179 371.826L295.424 369.8C295.424 369.8 291.885 390.026 287.984 393.144C285.899 394.811 283.565 394.811 281.56 396.062L281.143 396.324C279.966 397.036 278.889 397.903 277.942 398.9Z" fill="#C68863"/>
    <path d="M277.942 398.9C277.522 399.362 277.244 399.934 277.141 400.549C277.038 401.164 277.115 401.796 277.362 402.369C278.388 404.678 281.08 407.246 284.186 409.818C284.603 410.155 284.949 410.464 285.27 410.751L285.516 410.972L285.703 411.143C288.696 413.903 287.571 414.536 292.952 420.139C299.08 426.538 303.315 426.654 304.499 426.071C305.516 425.575 303.449 422.19 301.164 418.384C296.873 416.708 293.101 413.929 290.23 410.326C286.554 405.616 284.073 399.559 281.572 396.053L281.155 396.316C279.973 397.03 278.892 397.899 277.942 398.9Z" fill="#233862"/>
    <path d="M372.726 391.56C373.451 395.186 378.624 394.582 384.398 393.173C390.171 391.764 388.804 393.44 397.028 392.531C405.253 391.622 407.745 388.554 407.996 387.333C408.221 386.249 404.156 385.865 399.796 385.361L398.129 385.165C393.206 384.548 389.604 381.372 385.135 378.762C380.9 376.29 374.581 366.228 374.581 366.228L363.301 367.12C363.301 367.12 369.22 376.111 371.509 380.017C372.855 382.314 372.784 385.357 372.617 387.704C372.481 388.988 372.518 390.285 372.726 391.56Z" fill="#C68863"/>
    <path d="M384.402 393.169C390.179 391.755 388.808 393.435 397.032 392.527C405.257 391.618 407.749 388.55 408 387.329C408.225 386.245 404.16 385.861 399.8 385.357L399.758 385.44C396.236 387.704 392.167 388.971 387.982 389.108C382.405 389.246 376.494 387.645 372.638 387.708C372.495 388.993 372.524 390.291 372.726 391.568C373.451 395.186 378.624 394.582 384.402 393.169Z" fill="#233862"/>
    <path d="M329.511 319.44L318.969 347.811L305.212 384.857C302.755 385.04 300.285 384.814 297.901 384.19C294.608 383.239 290.814 380.746 290.814 380.746C290.814 380.746 294.637 340.971 301.765 318.14C303.591 312.279 305.642 307.539 307.901 304.871C318.952 291.807 329.511 319.44 329.511 319.44Z" fill="#233862"/>
    <path d="M318.784 274.383C311.877 272.157 292.177 275.016 272.385 278.889L268.233 272.057L225.489 268.722C225.489 268.722 211.383 299.986 219.107 319.932C220.241 322.85 223.05 324.101 226.894 324.159C226.894 324.159 252.401 324.78 260.659 324.78C281.918 324.78 305.649 310.165 308.717 309.552C313.74 308.548 315.754 318.598 320.777 329.144C325.8 339.691 368.027 375.886 368.027 375.886C368.027 375.886 374.513 375.949 377.198 374.944C380.078 373.864 381.133 372.443 381.133 372.443C381.133 372.443 334.362 279.41 318.784 274.383Z" fill="#2B478B"/>
    <path d="M263.702 146.534C261.22 145.06 258.368 144.326 255.482 144.417C254.117 144.441 252.752 144.352 251.401 144.15C235.202 141.966 225.452 165.501 224.881 172.633C223.822 185.781 216.869 187.782 207.082 196.015C187.456 212.522 193.209 231.705 203.943 238.829C210.383 243.106 221.734 238.829 221.734 238.829C221.892 238.942 252.773 221.568 268.004 213.185C290.81 209.946 272.869 151.991 263.702 146.534Z" fill="#051A52"/>
    <path d="M255.232 206.057C255.298 206.098 247.728 208.679 242.689 206.44C237.937 204.356 234.352 196.519 234.406 196.515C236.569 196.415 238.941 194.527 240.771 182.121L241.692 182.45L257.174 188.019C257.174 188.019 256.053 192.988 255.328 197.715C254.715 201.688 254.39 205.494 255.232 206.057Z" fill="#C68863"/>
    <path d="M257.174 188.024C257.174 188.024 256.053 192.992 255.328 197.72C246.436 196.119 242.947 187.261 241.692 182.45L257.174 188.024Z" fill="#B06C49"/>
    <path d="M259.479 192.951C259.479 192.951 238.299 190.108 240.27 176.944C242.242 163.78 240.837 154.546 254.443 155.805C268.049 157.064 269.917 162.475 270.329 167.035C270.742 171.596 265.04 193.351 259.479 192.951Z" fill="#C68863"/>
    <path d="M269.563 159.84C269.563 159.84 256.003 171.512 246.782 172.467C237.562 173.421 234.052 170.433 234.052 170.433C237.733 167.139 240.697 163.123 242.76 158.636C243.229 157.58 243.973 156.668 244.913 155.996C245.854 155.324 246.957 154.916 248.108 154.813C254.703 154.242 267.187 153.984 269.563 159.84Z" fill="#051A52"/>
    <path d="M261.084 158.777C261.084 158.777 265.619 163.175 267.049 166.414C268.429 169.553 268.842 177.06 267.745 180.045C267.745 180.045 274.832 166.289 270.813 159.619C266.566 152.545 261.084 158.777 261.084 158.777Z" fill="#051A52"/>
    <path d="M273.069 237.258C272.427 280.135 272.894 271.173 268.204 275.241C267.074 276.221 263.898 276.805 259.717 277.08C246.569 277.951 223.451 275.787 222.58 273.357C219.549 264.949 221.688 262.34 219.157 249.467C218.895 248.1 218.565 246.616 218.186 245.003C216.077 236.162 214.017 233.156 221.521 218.954C228.307 206.09 235.36 195.648 235.994 196.027C253.918 206.657 255.344 197.724 255.344 197.724C255.344 197.724 273.381 216.344 273.069 237.258Z" fill="#FCFCFC"/>
    <path d="M318.96 347.811L305.204 384.857C302.746 385.04 300.276 384.814 297.892 384.19C294.599 383.239 290.806 380.746 290.806 380.746C290.806 380.746 294.628 340.97 301.756 318.14L318.96 347.811Z" fill="#2B478B"/>
    <path d="M255.311 197.72C255.311 197.72 273.735 200.842 275.899 208.779C278.062 216.716 290.81 263.344 290.81 263.344C290.81 263.344 281.443 265.012 277.116 260.702C272.789 256.391 262.059 220.555 262.059 220.555L255.311 197.72Z" fill="#FCFCFC"/>
    <path d="M259.713 277.08C246.565 277.951 223.447 275.788 222.576 273.357C219.545 264.949 221.684 262.34 219.153 249.468L225.494 231.61C225.494 231.61 224.464 251.498 230.3 256.725C236.136 261.952 254.311 266.821 257.158 271.19C258.546 273.291 259.45 275.392 259.713 277.08Z" fill="#EBF3FF"/>
    <path d="M244.652 268.718C244.652 268.718 257.625 265.829 259.717 266.979C261.81 268.13 266.925 272.323 267.567 273.782C268.209 275.241 262.906 272.674 261.185 272.211C260.068 271.911 257.129 272.932 255.674 273.416C249.55 275.454 245.803 274.408 245.803 274.408L244.652 268.718Z" fill="#C68863"/>
    <path d="M235.965 196.015C235.965 196.015 218.549 197.086 212.521 205.498C204.034 217.337 187.197 267.78 196.847 274.508C207.715 282.086 246.303 276.488 246.303 276.488C246.303 276.488 247.586 270.485 245.798 267.584C244.01 264.683 214.976 259.993 213.626 258.634C212.275 257.275 230.375 232.502 230.375 228.483C230.375 224.465 235.965 196.015 235.965 196.015Z" fill="#FCFCFC"/>
    <path d="M260.342 270.594C260.342 270.594 263.06 274.099 263.952 274.516C264.401 274.759 264.91 274.867 265.419 274.829L263.589 271.531L260.342 270.594Z" fill="#C68863"/>
    <path d="M259.713 266.971L267.633 270.81C267.478 271.001 267.275 271.148 267.046 271.237C266.816 271.325 266.567 271.352 266.324 271.315C265.353 271.269 262.898 270.373 262.898 270.373L259.713 266.971Z" fill="#C68863"/>
    <path d="M263.952 279.885H326.455L345.255 233.131H282.848L263.952 279.885Z" fill="#FEB850"/>
    <path d="M327.189 278.063H244.652V279.885H326.455L327.189 278.063Z" fill="#FEA82A"/>
    <path d="M310.677 256.508C311.148 254.866 310.197 253.532 308.555 253.532C307.69 253.58 306.859 253.89 306.175 254.422C305.491 254.954 304.985 255.682 304.724 256.508C304.253 258.151 305.204 259.484 306.846 259.484C307.711 259.436 308.541 259.126 309.226 258.594C309.91 258.062 310.416 257.335 310.677 256.508Z" fill="white"/>
    <path d="M134.194 472C170.357 472 199.673 442.684 199.673 406.521C199.673 370.358 170.357 341.042 134.194 341.042C98.0308 341.042 68.7148 370.358 68.7148 406.521C68.7148 442.684 98.0308 472 134.194 472Z" fill="#E2810E"/>
    <path d="M128.479 472C164.642 472 193.958 442.684 193.958 406.521C193.958 370.358 164.642 341.042 128.479 341.042C92.3159 341.042 63 370.358 63 406.521C63 442.684 92.3159 472 128.479 472Z" fill="#FFB93E"/>
    <path d="M128.479 466.956C114.677 466.956 101.301 462.171 90.6312 453.415C79.9615 444.658 72.6579 432.474 69.965 418.936C67.272 405.399 69.3563 391.347 75.8626 379.174C82.3689 367.001 92.8948 357.46 105.647 352.178C118.399 346.896 132.587 346.199 145.796 350.205C159.004 354.212 170.415 362.674 178.083 374.151C185.752 385.627 189.203 399.407 187.851 413.143C186.498 426.88 180.424 439.722 170.664 449.482C165.138 455.039 158.563 459.445 151.322 462.445C144.081 465.444 136.317 466.977 128.479 466.956ZM128.479 351.197C97.5446 351.197 72.3793 376.362 72.3793 407.296C72.3793 438.231 97.5446 463.388 128.479 463.388C159.414 463.388 184.579 438.223 184.579 407.288C184.579 376.353 159.414 351.197 128.479 351.197Z" fill="#FFA412"/>
    <path d="M98.2673 437.544C97.6055 437.463 97.0712 437.163 96.6643 436.643C96.2574 436.124 96.0943 435.533 96.1748 434.871L104.041 370.248C104.129 369.52 104.401 368.949 104.854 368.534C105.374 368.127 105.997 367.968 106.725 368.057L114.468 368.999C115.726 369.152 116.617 369.831 117.142 371.037L140.167 418.567L145.435 375.287C145.524 374.559 145.795 373.988 146.248 373.573C146.768 373.166 147.392 373.007 148.12 373.095L156.657 374.134C157.385 374.223 157.952 374.527 158.359 375.047C158.766 375.566 158.925 376.19 158.836 376.918L150.982 441.442C150.894 442.17 150.59 442.737 150.07 443.144C149.55 443.551 148.96 443.714 148.298 443.634L140.456 442.679C139.132 442.518 138.208 441.835 137.683 440.629L114.636 394.103L109.477 436.49C109.396 437.152 109.096 437.687 108.576 438.093C108.123 438.508 107.532 438.671 106.804 438.583L98.2673 437.544Z" fill="#FFA412"/>
    <path d="M262.854 83.9094C284.652 81.1634 300.097 61.2667 297.351 39.4687C294.605 17.6708 274.708 2.22611 252.91 4.9721C231.112 7.71809 215.667 27.6149 218.413 49.4128C221.159 71.2108 241.056 86.6554 262.854 83.9094Z" fill="#E2810E"/>
    <path d="M259.413 84.3402C281.211 81.5942 296.655 61.6974 293.909 39.8994C291.163 18.1015 271.267 2.65683 249.469 5.40282C227.671 8.14881 212.226 28.0456 214.972 49.8436C217.718 71.6415 237.615 87.0862 259.413 84.3402Z" fill="#FFB93E"/>
    <path d="M259.022 81.2999C251.91 82.1963 244.691 80.9631 238.279 77.7562C231.867 74.5494 226.551 69.513 223.002 63.2841C219.453 57.0552 217.831 49.9136 218.341 42.7628C218.851 35.612 221.471 28.7731 225.868 23.1113C228.787 19.3512 232.418 16.2028 236.554 13.846C240.69 11.4891 245.249 9.96992 249.972 9.37514C254.695 8.78036 259.489 9.12163 264.08 10.3795C268.671 11.6373 272.969 13.7871 276.729 16.7061C280.489 19.6251 283.638 23.2561 285.994 27.3918C288.351 31.5275 289.871 36.087 290.465 40.8098C291.06 45.5327 290.719 50.3264 289.461 54.9174C288.203 59.5084 286.053 63.8066 283.134 67.5667C280.223 71.3368 276.593 74.4919 272.454 76.8492C268.315 79.2066 263.75 80.7193 259.022 81.2999ZM250.239 11.527C243.551 12.3689 237.263 15.175 232.17 19.5905C227.077 24.006 223.408 29.8325 221.627 36.3334C219.845 42.8342 220.031 49.7173 222.162 56.1122C224.292 62.5072 228.271 68.1269 233.595 72.2605C238.92 76.3942 245.35 78.8562 252.073 79.3353C258.797 79.8143 265.511 78.2889 271.368 74.9519C277.224 71.6148 281.96 66.6161 284.975 60.5878C287.991 54.5595 289.151 47.7723 288.309 41.0846C287.17 32.1212 282.522 23.9755 275.385 18.4344C268.248 12.8933 259.205 10.4092 250.239 11.527Z" fill="#FFA412"/>
    <path d="M238.181 66.6583C237.755 66.6583 237.392 66.509 237.093 66.2103C236.795 65.9117 236.645 65.549 236.645 65.1223V23.4583C236.645 22.989 236.773 22.605 237.029 22.3063C237.328 22.0077 237.712 21.8583 238.181 21.8583H243.173C243.984 21.8583 244.603 22.221 245.029 22.9463L263.333 51.3623V23.4583C263.333 22.989 263.461 22.605 263.717 22.3063C264.016 22.0077 264.4 21.8583 264.869 21.8583H270.373C270.843 21.8583 271.227 22.0077 271.525 22.3063C271.824 22.605 271.973 22.989 271.973 23.4583V65.0583C271.973 65.5277 271.824 65.9117 271.525 66.2103C271.227 66.509 270.864 66.6583 270.437 66.6583H265.381C264.528 66.6583 263.888 66.2957 263.461 65.5703L245.221 37.7943V65.1223C245.221 65.549 245.072 65.9117 244.773 66.2103C244.517 66.509 244.155 66.6583 243.685 66.6583H238.181Z" fill="#FFA412"/>
    </svg>
  </div>

  )
}
