/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ icon, title, containerVariant, type, description, iconStyles, button, cardBody }) => {
  return (
    <>
    <div className={`${containerVariant || 'px-24 pb-32 w-[304px] border border-light-smoke rounded flex-col bg-white flex items-center text-center'} `}>
    { type !== 'custom'  ?
        (<>
        <div className={`${iconStyles}`}>{icon}</div>
        <div className='mt-5 mb-8 text-black-transparent'>
            <div className='text-16 font-dmsans_b leading-6'>{title}</div>
            <div className='text-14 leading-5 mt-2'>{description}</div>
        </div>
        {button}
        </>) : cardBody }
    </div> 
    </>
  )
}

export default Card

Card.propTypes = {
    title: PropTypes.string,
    containerVariant: PropTypes.string,
    description: PropTypes.any,
    icon: PropTypes.any,
    type: PropTypes.string,
    iconStyles: PropTypes.any,
    button: PropTypes.any,
    cardBody: PropTypes.any
}