/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
export default function TransferIcon({color}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M4.47004 8.59369C4.12597 8.59369 3.84743 8.87637 3.84743 9.22557L3.67578 12.2781C3.67578 12.7231 4.03156 13.0833 4.47004 13.0833C4.90853 13.0833 5.26352 12.7231 5.26352 12.2781L5.09266 9.22557C5.09266 8.87637 4.81412 8.59369 4.47004 8.59369Z"
        fill={color}
      />
      <path
        d="M5.31992 2.44896C5.31992 2.44896 5.14125 2.26526 5.03046 2.18529C4.86973 2.06176 4.67156 2 4.47416 2C4.25258 2 4.0466 2.06968 3.87886 2.20112C3.84843 2.232 3.71891 2.3484 3.61202 2.45688C2.94104 3.09113 1.84327 4.74684 1.50778 5.61389C1.45472 5.74533 1.34003 6.0779 1.33301 6.25606C1.33301 6.42551 1.37046 6.58863 1.44692 6.74303C1.55381 6.93624 1.72156 7.09143 1.91973 7.17616C2.05705 7.2308 2.46822 7.31552 2.47603 7.31552C2.92621 7.40104 3.65806 7.44696 4.46636 7.44696C5.23644 7.44696 5.93785 7.40104 6.39506 7.33136C6.40286 7.32344 6.91312 7.23871 7.08867 7.14528C7.40856 6.97504 7.60752 6.64247 7.60752 6.28694V6.25606C7.59972 6.02406 7.40076 5.53629 7.39374 5.53629C7.05824 4.71596 6.01353 3.09905 5.31992 2.44896Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M11.5296 7.40643C11.8737 7.40643 12.1522 7.12374 12.1522 6.77454L12.3231 3.72199C12.3231 3.27698 11.9681 2.91669 11.5296 2.91669C11.0911 2.91669 10.7354 3.27698 10.7354 3.72199L10.907 6.77454C10.907 7.12374 11.1855 7.40643 11.5296 7.40643Z"
        fill={color}
      />
      <path
        d="M14.5522 9.25687C14.4453 9.06366 14.2776 8.90925 14.0794 8.82373C13.9421 8.76909 13.5301 8.68436 13.5231 8.68436C13.0729 8.59884 12.3411 8.55292 11.5328 8.55292C10.7627 8.55292 10.0613 8.59884 9.60406 8.66853C9.59626 8.67644 9.086 8.76196 8.91045 8.85461C8.58978 9.02486 8.3916 9.35743 8.3916 9.71376V9.74464C8.3994 9.97665 8.59758 10.4636 8.60538 10.4636C8.94087 11.284 9.98481 12.9017 10.6792 13.551C10.6792 13.551 10.8579 13.7347 10.9687 13.8139C11.1286 13.9382 11.3268 14 11.5257 14C11.7465 14 11.9517 13.9303 12.1203 13.7989C12.1507 13.768 12.2802 13.6516 12.3871 13.5439C13.0573 12.9088 14.1558 11.2531 14.4906 10.3868C14.5444 10.2554 14.6591 9.92201 14.6661 9.74464C14.6661 9.57439 14.6287 9.41127 14.5522 9.25687Z"
        fill={color}
      />
    </svg>
  );
}
TransferIcon.propTypes = {
  color: PropTypes.string
}
