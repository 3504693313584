import React from "react";

// Components
import AuthLayout from "../../components/Layouts/AuthLayout";
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm";


export default function ResetPassword() {
    return(
        <AuthLayout 
            center={<ResetPasswordForm />}
        />
    )
}