import useAuth from 'hooks/fetch/useAuth';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useHandleError from 'hooks/useHandleError';
import useMakeRequest from 'hooks/useMakeRequest';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { editAdmin } from 'store/modules/auth/actions';
import { notify } from 'store/modules/global';
import CustomButton from '../../../components/Button/CustomButton';
import Title from '../../../components/Header/Title';
import CustomInput from '../../../components/Input/CustomInput';
import { useAuthState } from '../../../store/modules/auth';
import BackButton from '../../../components/Button/BackButton';

const AdminProfile = () => {
  const authState = useAuthState();
  const { makeRequest } = useMakeRequest();
  const dispatch = useAppDispatch();
  const { handleError } = useHandleError();
  const navigate = useNavigate();

  const { loading, loggedUser } = authState;
  const [userInfo, setUserInfo] = useState({
    first_name: loggedUser?.first_name ?? '',
    last_name: loggedUser?.last_name ?? '',
    email: loggedUser?.email ?? '',
  });
  const { email, ...restObject } = userInfo;

  const [password, setPassword] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  });
  //Hooks
  const { change_password } = useAuth();

  //Handler Function
  const handleChange = useCallback(
    (e) => {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    },
    [userInfo],
  );
  const handlePasswordChange = useCallback(
    (e) => {
      setPassword({ ...password, [e.target.name]: e.target.value });
    },
    [password],
  );
  const handleChangePassword = (e) => {
    e.preventDefault();

    change_password(password);
  };
  const updateDetails = async (e) => {
    e.preventDefault();
    const payload = {
      id: loggedUser.id,
      formData: restObject,
    };
    try {
      makeRequest({
        action: editAdmin(payload),
      });
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };
  const handleClearForm = () => {
    setUserInfo({
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
    });
  };
  const handlePasswordClearForm = () => {
    setPassword({
      old_password: '',
      new_password: '',
      confirm_new_password: '',
    });
  };
  return (
    <div>
      <div className='flex space-x-6 my-4'>
        <BackButton onClick={() => navigate(-1)} />
        <Title title="User Information"  />
      </div>
      <div className="p-32 bg-white rounded-2xl">
        <div className="flex justify-between items-center">
          <p className="font-dmsans_m text-18">User Information</p>
        </div>
        <form className="mt-10" onSubmit={(e) => updateDetails(e)}>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-8">
            <div>
              <CustomInput
                label="First Name"
                type="text"
                name="first_name"
                value={userInfo?.first_name}
                id="first_name"
                placeholder="Muhammad"
                readOnly={loading}
                onChange={handleChange}
              />
            </div>
            <div>
              <CustomInput
                label="Last Name"
                type="text"
                name="last_name"
                value={userInfo?.last_name}
                id="last_name"
                placeholder="Oni"
                readOnly={loading}
                onChange={handleChange}
              />
            </div>
            <div>
              <CustomInput
                label="Email Address"
                type="email"
                name="email"
                value={userInfo?.email}
                id="email"
                placeholder="muhandis@pouchers.com"
                readOnly={loading}
                onChange={handleChange}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="flex justify-end items-center space-x-6 mt-10">
            <p className="text-purple font-dmsans_r cursor-pointer" onClick={handleClearForm}>
              Cancel
            </p>
            <CustomButton
              name="Save Changes"
              // loading={loading}
              disabled={
                !userInfo?.email || !userInfo?.last_name || !userInfo?.first_name || userInfo?.phone_number || loading
              }
              variant="border border-purple bg-purple text-white rounded font-dmsans_r"
            />
          </div>
        </form>
      </div>
      <div className="p-32 mt-6 bg-white rounded-2xl">
        <div className="flex justify-between items-center">
          <p className="font-dmsans_m text-18">Change Password</p>
        </div>
        <form className="mt-10" onSubmit={(e) => handleChangePassword(e)}>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-8">
            <div>
              <CustomInput
                label="Current Password"
                type="password"
                name="old_password"
                id="old_password"
                value={password.old_password}
                placeholder="Enter Current Password"
                readOnly={loading}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <CustomInput
                label="New Password"
                type="password"
                name="new_password"
                id="new_password"
                value={password.new_password}
                placeholder="Enter New Password"
                readOnly={loading}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <CustomInput
                label="Confirm New Password"
                type="password"
                name="confirm_new_password"
                id="confirm_new_password"
                value={password.confirm_new_password}
                placeholder="Enter New Password"
                readOnly={loading}
                onChange={handlePasswordChange}
              />
            </div>
          </div>
          <div className="flex justify-end items-center space-x-6 mt-10">
            <p className="text-purple font-dmsans_r cursor-pointer" onClick={handlePasswordClearForm}>
              Cancel
            </p>
            <CustomButton
              name="Save Changes"
              disabled={
                !password?.new_password || !password?.old_password || !password?.confirm_new_password || loading
              }
              // loading={loading && password?.confirm_new_password}
              variant="border border-purple bg-purple text-white rounded font-dmsans_r"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminProfile;
