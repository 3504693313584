import { createDiscount, getDiscountByCategory,deletediscount, getBanners, getExchangeRate, getNotifications } from './actions';
import { getEnvs, getProductStatus } from "./actions";

const extraReducers = {
  //Get Discount By Category
  [getDiscountByCategory.pending]: (state) => {
    state.loading = true;
  },
  [getDiscountByCategory.fulfilled]: (state, action) => {
    state.discountList = action.payload.data;
    state.loading = false;
  },
  [getDiscountByCategory.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  // Create Discount
  [createDiscount.pending]: (state) => {
    state.loading = true;
  },
  [createDiscount.fulfilled]: (state) => {
    state.loading = false;
  },
  [createDiscount.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  // Get Banners
  [getBanners.pending]: (state) => {
    state.loading = true;
  },
  [getBanners.fulfilled]: (state, action) => {
    state.loading = false;
    state.banners = action.payload.data
  },
  [getBanners.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  // Get Exchange rates
  [getExchangeRate.pending]: (state) => {
    state.loading = true;
  },
  [getExchangeRate.fulfilled]: (state, action) => {
    state.loading = false;
    state.exchangeRate = action.payload.data
  },
  [getExchangeRate.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
   // Create Discount
  [deletediscount.fulfilled]: (state) => {
    state.loading = false;
  },
  [deletediscount.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  //GET SCHEDULED PAYMENT
  [getEnvs.pending]: (state) => {
    state.loading = true
  },
  [getEnvs.fulfilled]: (state, action) => {
    state.loading = false
    state.envs = action.payload.data
  },
  [getEnvs.rejected]: (state) => {
    state.loading = false
  },
  // get product status
  [getProductStatus.pending]: (state) => {
    state.loading = true
  },
  [getProductStatus.fulfilled]: (state, action) => {
    state.loading = false
    state.productsStatus = action.payload.data
  },
  [getProductStatus.rejected]: (state) => {
    state.loading = false
  },
  // get notifications
  [getNotifications.pending]: (state) => {
    state.loading = true
  },
  [getNotifications.fulfilled]: (state, action) => {
    state.loading = false
    state.notifications = action?.payload?.data
  },
  [getNotifications.rejected]: (state) => {
    state.loading = false
  },
}

export { extraReducers };
