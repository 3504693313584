import React, { useEffect } from 'react';
import Title from 'components/Header/Title';
import TierLimit from './TierLimit/TierLimit';
import ReferralBonus from './ReferralBonus/ReferralBonus';
import VirtualCard from './VirtualCard/VirtualCard';
import CommissionFee from './CommissionFee/CommissionFee';
import Conversion from './Conversion/Conversion';
import APIAvailability from './APIAvailability/APIAvailability';
import ProductAvailability from './ProductAvailability/ProductAvailability';
import BannerSettings from './BannerSettings/BannerSettings';
import useSettings from 'hooks/fetch/useSettings';
import { useAppSelector } from 'hooks/useReduxHook';

const SiteSettings = () => {
  const { envs, exchangeRate } = useAppSelector(state => state.settings)
  const { fetchEnvs, fetchExchangeRate, loading } = useSettings([])

  useEffect(() => {
    Promise.all([fetchEnvs(), fetchExchangeRate()])
  },[])

  useEffect(() => {
    document.title = 'Site Settings - Pouchers Admin';
  }, []);

  return (
    <div>
      <Title title="Site Settings" variant="mb-3" />
      <TierLimit loading={loading} />
      <ReferralBonus
        loading={loading}
        amount={envs?.find(env => env.name === 'referral_bonus_amount')?.value}
      />
      <VirtualCard
        loading={loading}
        nairaFee={envs?.find(env => env.name === 'naira_card_creation_fee')?.value}
        dollarFee={envs?.find(env => env.name === 'dollar_card_creation_fee')?.value}
      />
      <CommissionFee
        p2p='0'
        external={envs?.find(env => env.name === 'external_transfer_fee')?.value}
        wallet={envs?.find(env => env.name === 'wallet_funding_fee')?.value}
        nairaCard={envs?.find(env => env.name === 'naira_card_funding_fee')?.value}
        dollarCard={envs?.find(env => env.name === 'dollar_card_funding_fee')?.value}
        electricityAmount={envs?.find(env => env.name === 'electricity_fee')?.value}
        vouchersAmount={envs?.find(env => env.name === 'vouchers_fee')?.value}
        internetAmount={envs?.find(env => env.name === 'internet_fee')?.value}
        educationAmount={envs?.find(env => env.name === 'education_fee')?.value}
        dataAmount={envs?.find(env => env.name === 'data_fee')?.value}
        cableAmount={envs?.find(env => env.name === 'cable_tv_fee')?.value}
        airtimeAmount={envs?.find(env => env.name === 'airtime_fee')?.value}
        betAmount={envs?.find(env => env.name === 'bet_wallet_funding_fee')?.value}
        loading={loading}
      />
      <Conversion
        loading={loading}
        amount={envs?.find(env => env.name === 'current_dollar_rate')?.value}
        exchangeRate={exchangeRate?.rate}
      />
      <APIAvailability />
      <ProductAvailability />
      <BannerSettings />
    </div>
  );
};

export default SiteSettings;
