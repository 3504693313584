import Card from 'components/Card/Card';
import Chart from 'components/Chart/Chart';
import DefaultDatePicker from 'components/Input/DefaultDatePicker';
import useCustomer from 'hooks/fetch/useCustomer';
import useFormatDate from 'hooks/useFormatDate';
import React, { useEffect, useMemo, useState } from 'react';
import { useCustomerState } from 'store/modules/customer';
import CategoryCard from '../CategoryCard';

const ProductHistory = () => {
  //Redux
  const { productHistory, selectedCustomer } = useCustomerState();
  const { getAllProductHistory } = useCustomer();

  //Hooks
  const { getFirstDayOfMonth, getLastDayOfMonth } = useFormatDate();

  //Local State
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  //Effect
  useEffect(() => {
    getAllProductHistory(selectedCustomer?.user_id, { fromDate: getFirstDayOfMonth(), toDate: getLastDayOfMonth() });
  }, []);

  //Handler Functions
  const analytics = productHistory
    ? Object.keys(productHistory)
        .filter((key) => key.includes('purchased'))
        .reduce((obj, key) => {
          return Object.assign(obj, { [key]: productHistory[key] });
        }, {})
    : [];

  const labels = useMemo(() => {
    const keysArray = analytics ? Object.keys(analytics) : [];
    const filteredLabel =  productHistory ? 
    keysArray?.map(item =>  {
      const label = item?.replace('_purchased', '').replace('total_', '')
      return item && label?.charAt(0).toUpperCase() + label?.slice(1) }) : []
    return filteredLabel;
  }, [productHistory]);

  const findHighest = useMemo(() => {
    const totalTransfers = productHistory.total_transfers;
    const maxCategoryKey = Object.keys(analytics)?.reduce((a, b) => (analytics[a] > analytics[b] ? a : b), '');
    const maxCategoryAmount = Math.max(...Object.values(analytics));
    const formatKey = maxCategoryKey?.replace('_purchased', '')?.replace('total_', '');
    return totalTransfers > maxCategoryAmount ? 'Transfers' : formatKey;
  }, [productHistory, analytics]);

  const analyticsData = useMemo(() => {
    let valueArray = productHistory ? Object.values(analytics) : [];
    return valueArray;
  }, [productHistory]);

  //Variable
  const data = {
    labels: labels,
    datasets: [
      {
        data: analyticsData,
        backgroundColor: 'rgba(80, 52, 196, 1)',
        barThickness: 48,
        borderRadius: 8,
      },
    ],
  };
  //Effect
  useEffect(() => {
    if (fromDate && toDate) {
      getAllProductHistory(selectedCustomer?.user_id, { fromDate, toDate });
    }
  }, [toDate, fromDate]);

  return (
    <div className="font-dmsans_m py-32 px-24">
      <div className="flex justify-between mb-12 items-center">
        <p className="text-black-transparent text-16 ">Product History Analytics</p>
        <div className="flex items-center space-x-2">
          <p className="font-dmsans_r ">Filter:</p>
          <div className="flex space-x-2 items-center">
            <DefaultDatePicker
              onHandleDateChange={(date) => {
                setFromDate(date);
              }}
              defaultValue={getFirstDayOfMonth()}
              selectedDate={new Date(fromDate)}
            />
            <p className="text-grey-700">-</p>
            <DefaultDatePicker
              onHandleDateChange={(date) => {
                setToDate(date);
              }}
              defaultValue={getLastDayOfMonth()}
              selectedDate={new Date(toDate)}
            />
          </div>
        </div>
      </div>
      <Card
        type="custom"
        containerVariant="col-span-4 flex flex-col p-6 bg-white border border-lighter-gray rounded-lg"
        cardBody={<Chart loading={false} data={data} />}
      />
      {/* <p className="text-center text-gray-100 text-12 font-dmsans_r mt-4">
        Your Daily average spend this week is <span className="font-inter_semiBold">N42,000</span>
      </p> */}
      <div className="">
        <p className="text-black-transparent font-dmsans_r text-16 mt-12 mb-4">Top Categories For The Month</p>
        <CategoryCard maxValue={findHighest} />
      </div>
    </div>
  );
};

export default ProductHistory;
