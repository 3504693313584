import React from 'react'
import { usePaymentState } from 'store/modules/payment'
import dayjs from 'dayjs';

const ViewPayment = () => {
  const {selectedPayment} = usePaymentState()
  return (
    <section className="mt-6">
    <div className="grid gap-8 grid-cols-12">
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">Transaction ID</p>
        <p className="text-dark_blue text-14 mt-1">{selectedPayment?.transaction_id}</p>
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">Email Address</p>
        <p className="text-blue_black text-14 mt-1">
          {selectedPayment?.email || 'N/A'}
          </p>
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">Count</p>
        <p className="text-dark_blue text-14 mt-1">{selectedPayment?.total}</p>
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">
        Biller
        </p>
        
          <p className="text-dark_blue text-14 mt-1">
          {selectedPayment?.extra_details?.subCategory ||  'N/A'}
          </p>
  
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">Date</p>
        <p className="text-dark_blue text-14 mt-1">
        {dayjs(selectedPayment?.created_at).format('MMM DD, YYYY')}
        <span className="text-grey"> | {dayjs(selectedPayment?.created_at).format('h:mm a')} </span>
        </p>
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">
        Categories
        </p>
          <p className="text-dark_blue text-14 mt-1 capitalize">
          {selectedPayment?.extra_details?.category?.replace('-purchase', '') || 'N/A'}
           <span className="text-grey"> | {selectedPayment?.extra_details?.subCategory || 'N/A'} </span>
          </p>
  
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">
        Phone number
        </p>
          <p className="text-dark_blue text-14 mt-1">
          {selectedPayment?.extra_details?.phoneNumber || 'N/A'}
          </p>
  
      </div>
      <div className="sm:col-span-6 col-span-12 lg:col-span-4">
        <p className="text-grey font-dmsans_r text-12">
        Amount
        </p>
          <p className="text-dark_blue text-14 mt-1">
          {selectedPayment?.extra_details?.amount || 'N/A'}
          </p>
  
      </div>
    </div>
  </section>
  )
}

export default ViewPayment