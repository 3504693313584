/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({onChange, variant, id, value, ...props }) => {
  return (
    <div className={`${variant || 'ml-6'} relative flex`}>
      <input
        role="checkbox"
        type="checkbox"
        style={{ borderColor: '#D0D5DD' }}
        onChange={onChange}
        data-testid={id}
        value={value}
        className="cursor-pointer opacity-0 absolute w-4 h-4 border rounded bg-white outline-none"
        checked={props.checked}
      />
      <div className="bg-white border rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center text-grey-300 focus:border-puple_light">
        <svg
          className="fill-current hidden w-3 h-3 text-purple pointer-events-none"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#5034C4" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default CheckBox;

CheckBox.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    variant: PropTypes.string,
    checked: PropTypes.bool,
    id: PropTypes.string
  };