/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Header from '../Header/Header';
import useAuth from "../../hooks/fetch/useAuth"
import PropTypes from 'prop-types';

export default function LoginForm({ invited }) {
  const { signIn, loading } = useAuth()
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const makeRequest = (e) => {
    e.preventDefault()
    signIn(formData)
  }
  return (
    <section className="w-full flex justify-center h-screen my-auto transition_left">
      <form className={`${invited === 'true' ? 'sm:w-[460px]' : 'sm:w-[400px]'} w-full m-auto lg:mx-0`} onSubmit={(e) => makeRequest(e)}>
        {invited === 'true' ? (
          <Header title="Join Pouchers" description="You have been invited to join the Pouchers Admin dashboard." />
        ) : (
          <Header title="Welcome Back" description="Login to manage your account" />
        )}
        <div className="mb-4">
          <Input
            label="Email Address "
            type="text"
            placeholder="Enter Email Address"
            id="email"
            name="email"
            readOnly={loading}
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
        <div className="mb-3.5">
          <Input
            label="Password"
            type="password"
            placeholder={invited === 'true' ? 'Password' : 'Enter Password'}
            id="password"
            name="password"
            readOnly={loading}
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
        </div>
        {invited === 'true' && (
          <div className="mb-3.5">
            <Input
              label="Password"
              type="password"
              placeholder="Confirm Password"
              id="confirm_password"
              name="confirm_password"
              readOnly={loading}
              value={formData.confirm_password}
              onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
            />
          </div>
        )}
        {invited !== 'true' && (
          <div className="w-max ml-auto">
            <Link to="/forgot-password">
              <p className="font-dmsans_b mb-8 text-14 text-blue_deep font-normal">Forgot Password?</p>
            </Link>
          </div>
        )}

        <Button name={invited === 'true' ? 'Continue' : 'Log In'} theme="dark" disabled={loading} loading={loading} styles="mt-10 w-full" />
      </form>
    </section>
  );
}

LoginForm.propTypes = {
  invited: PropTypes.string
}
