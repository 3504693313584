function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }
export const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const suffix = getDaySuffix(day);
    const monthName = months[month - 1];
    
    return `${day}${suffix} ${monthName}, ${year}`;
  }