/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../Pagination';
import TableHeader from './TableHeader';
import TableEmpty from './TableEmpty';
import LoadingSkeleton from '../Skeleton/Skeleton';

const CTable = ({
  header,
  headerVariant,
  onHandleAllCheckbox,
  data,
  loading,
  totalPayload,
  currentPage,
  children,
  perPage,
  hasPagination = true,
  hasHeader = true,
  showCheckBox = true,
  changePage,
}) => {

  const tableData = data ?? [];

  const changeCurrentPage = (data) => {
    changePage(data.selected + 1);
  };

  return (
    <div className="bg-white rounded">
      {loading ?
        <LoadingSkeleton count={5} />
        :
        <>
          <div className="min-h-fit overflow-x-scroll scrollbar-hide">
            <table
              role="table"
              className="table-auto overflow-x-scroll w-full "
            >
              {
                hasHeader && <TableHeader
                  showCheckBox={showCheckBox}
                  headerVariant={headerVariant}
                  onHandleAllCheckbox={onHandleAllCheckbox}
                  header={header}
                />}
              <tbody className="px-2 text-14" data-testid="table-body">
                {tableData.map((item, index) => (
                  <tr
                    key={item.id || item.card_sudo_id || item.schedule_id || item.transaction_id || item.user_id || index}
                    className="h-[64px] border-b border-purple_light text-14 text-dark_blue"
                  >
                    {children(item)}
                  </tr>
                ))}
              </tbody>
            </table>
            {!tableData?.length &&
              <TableEmpty />}
          </div>
          <div>
            {(hasPagination && tableData.length) ? (
              <Pagination changePage={changeCurrentPage} totalPayload={totalPayload} perPage={perPage} currentPage={currentPage || 0} />
            ) : null}
          </div>
        </>
      }
    </div>
  );
};

export default CTable;

CTable.propTypes = {
  tableBody: PropTypes.array,
  data: PropTypes.array,
  tableData: PropTypes.element,
  checked: PropTypes.any,
  handleSelect: PropTypes.func,
  onHandleAllCheckbox: PropTypes.func,
  onHandleDelete: PropTypes.func,
  changePage: PropTypes.func,
  showCheckBox: PropTypes.bool,
  headerVariant: PropTypes.string,
  titleVariant: PropTypes.string,
  hasPagination: PropTypes.bool,
  hasHeader: PropTypes.bool,
  totalPayload: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  header: PropTypes.any,
  children: PropTypes.func
};
