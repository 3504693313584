import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types"
import Input from 'components/Input/Input';
import CustomButton from 'components/Button/CustomButton';
import useSettings from 'hooks/fetch/useSettings';

export default function EditTierLimit({ limit, cancel, tier }) {
  const { loading, update_env } = useSettings()
  const [formData, setFormData] = useState({
    limit: limit || ''
  })

  const handleChange = useCallback((e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }, [formData])

  const submit = useCallback((e) => {
    e.preventDefault()
    let newFormData
    if (tier === 1) newFormData = { tier1_daily_transaction_limit: formData.limit }
    if (tier === 2) newFormData = { tier2_daily_transaction_limit: formData.limit }
    if (tier === 3) newFormData = { tier3_daily_transaction_limit: formData.limit }

    update_env({ formData: newFormData, callback: cancel })
  }, [formData])

  return (
    <form onSubmit={submit}>
      <p className='text-purple font-dmsans_b text-16 mt-[24px] mb-[16px]'>Tier {tier}</p>
      <div className='mb-[32px]'>
        <Input
          label="Transaction Limit"
          type="text"
          id="limit"
          name="limit"
          readOnly={loading}
          value={formData.limit}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-end items-center space-x-6">
        <p className="text-purple font-dmsans_r cursor-pointer" onClick={cancel}>
          Cancel
        </p>
        <CustomButton
          name="Update"
          disabled={loading}
          loading={loading}
          variant="border border-purple bg-purple text-white rounded font-dmsans_r"
        />
      </div>
    </form>
  );
}

EditTierLimit.propTypes = {
  maximum: PropTypes.string,
  limit: PropTypes.string,
  tier: PropTypes.number,
  cancel: PropTypes.func
}