import React, { useState, useCallback, useEffect } from 'react';
import Status from '../../../components/Status';
import CTable from '../../../components/Table/Table';
import { VirtualCardsHeader, EntriesfilterList } from '../../../constants/mocks/customers';
import { IoIosArrowDown } from 'react-icons/io';
import Dropdown from '../../../components/DropDown/DropDown';
import SearchInput from '../../../components/Input/SearchInput';
import { UserInitals } from 'utils/ImagePlaceholder';
import useAdmin from 'hooks/fetch/useAdmin';
import { useVirtualCard } from 'store/modules/virtualCards';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDounce';
import useCustomer from 'hooks/fetch/useCustomer';
import DateFilter from 'components/DropDown/DateFilter';

const VirtualCardList = ({ tab }) => {
  //Local State
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  //Redux Hooks
  const { virtualCardList, loading } = useVirtualCard();
  //Variable
  const per_page = perPage.value;
  let status;
  if (tab !== 'all cards') status = tab;
  //Hooks
  const { handleVirtualCards, getSingleVirtualCard } = useAdmin();
  const debouncedSearch = useDebounce(search);
  const { handleViewCustomer } = useCustomer();

  //Effect
  useEffect(() => {
    if (tab === 'all cards' && !search) {
      handleVirtualCards({ per_page, page, status, fromDate, toDate });
      return;
    }
    handleVirtualCards({ per_page, page, status });
  }, [ fromDate, toDate, page, debouncedSearch, per_page, status]);

  useEffect(() => {
    if (debouncedSearch) {
      handleVirtualCards({ search: debouncedSearch, per_page, page, status, fromDate, toDate, });
    }
  }, [per_page, page, tab, debouncedSearch, fromDate, toDate, ]);
  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );
  return (
    <div className="mt-7">
      <div className="flex justify-between items-center mb-6 flex-wrap">
        <p className="font-dmsans_b text-18 xxs:mb-4 sm:mb-0">All Virtual Cards</p>
        <div className='flex md:space-x-4 flex-wrap items-center'>
          <SearchInput placeholder="Search name, tag" value={search} handleChange={handleChange} />
          <div className="mt-4 md:mt-0">
            <DateFilter
              endDate={toDate}
              setEndDate={setToDate}
              setStartDate={setFromDate}
              startDate={fromDate}
            />
          </div>
          <div className="flex items-center mt-4 lg:mt-0">
            <span className="text-14 font-dmsans_r mr-2">Showing:</span>
            <Dropdown
              variant="border border-light-smoke w-[142px]"
              placeholder="10 Entries"
              innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
              icon={<IoIosArrowDown />}
              value={perPage}
              type="filter"
              options={EntriesfilterList}
              onChange={handlePerPage}
            />
          </div>
       
        </div>
      </div>
      <CTable
        header={VirtualCardsHeader}
        totalPayload={virtualCardList?.total}
        showCheckBox={false}
        perPage={per_page}
        changePage={setPage}
        loading={loading}
        currentPage={virtualCardList?.page}
        data={virtualCardList?.virtual_cards}
      >
        {(item) => (
          <>
            <td className="pl-6">
              <p className="text-grey-500 font-dmsans_m min-w-max whitespace-no-wrap capitalize">
                {item.tag ? '@' + item.tag : 'N/A'}
              </p>
            </td>
            <td
              data-testid="test-customer"
              className="cursor-pointer"
              // onClick={() => handleViewCustomer(item.user_id)}
              onClick={() => {
                window.open(`/manage-customer/${item.user_id}`,'_blank')
              }}
            >
              <div className="flex items-center space-x-3 min-w-max mx-4 whitespace-no-wrap">
                <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                  {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                  {!item.profile_picture && (
                    <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                      {UserInitals(item.first_name, item.last_name)}
                    </p>
                  )}
                </div>
                <div className="font-dmsans_m">
                  <p className="text-gray-900 capitalize">
                    {item.first_name} {item.last_name}
                  </p>
                  <p className="text-grey-500">{item.email}</p>
                </div>
              </div>
            </td>
            <td className="">
              <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                {item.dollar_card_id ? 'Dollar' : 'Naira'} Card
              </p>
            </td>
            <td>
              <div className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                <Status showIcon status={item.card_status} />
              </div>
            </td>
            <td>
              <p className="min-w-max mx-4 whitespace-no-wrap">
                {dayjs(item.created_at).format('MMM DD, YYYY')}
                <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
              </p>
            </td>
            <td
              data-testid="view"
              onClick={() => getSingleVirtualCard(item.card_sudo_id, item.dollar_card_id ? 'USD' : 'NGN')}
            >
              <p
                data-testid="test-view"
                className="text-purple-300 font-inter_medium cursor-pointer min-w-max mx-4 whitespace-no-wrap"
              >
                View Details
              </p>
            </td>
          </>
        )}
      </CTable>
    </div>
  );
};

export default VirtualCardList;
VirtualCardList.propTypes = {
  tab: PropTypes.string,
};
