/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function TransactionHistoryIcon({ color }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M4.47004 8.59375C4.12597 8.59375 3.84743 8.87643 3.84743 9.22563L3.67578 12.2781C3.67578 12.7231 4.03156 13.0834 4.47004 13.0834C4.90853 13.0834 5.26352 12.7231 5.26352 12.2781L5.09266 9.22563C5.09266 8.87643 4.81412 8.59375 4.47004 8.59375Z"
        fill={color}
      />
      <path
        d="M5.31992 2.44896C5.31992 2.44896 5.14125 2.26526 5.03046 2.18529C4.86973 2.06176 4.67156 2 4.47416 2C4.25258 2 4.0466 2.06968 3.87886 2.20112C3.84843 2.232 3.71891 2.3484 3.61202 2.45688C2.94104 3.09113 1.84327 4.74684 1.50778 5.61389C1.45472 5.74533 1.34003 6.0779 1.33301 6.25606C1.33301 6.42551 1.37046 6.58863 1.44692 6.74303C1.55381 6.93624 1.72156 7.09143 1.91973 7.17616C2.05705 7.2308 2.46822 7.31552 2.47603 7.31552C2.92621 7.40104 3.65806 7.44696 4.46636 7.44696C5.23644 7.44696 5.93785 7.40104 6.39506 7.33136C6.40286 7.32344 6.91312 7.23871 7.08867 7.14528C7.40856 6.97504 7.60752 6.64247 7.60752 6.28694V6.25606C7.59972 6.02406 7.40076 5.53629 7.39374 5.53629C7.05824 4.71596 6.01353 3.09905 5.31992 2.44896Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M11.5296 7.40649C11.8737 7.40649 12.1522 7.1238 12.1522 6.7746L12.3231 3.72205C12.3231 3.27704 11.9681 2.91675 11.5296 2.91675C11.0911 2.91675 10.7354 3.27704 10.7354 3.72205L10.907 6.7746C10.907 7.1238 11.1855 7.40649 11.5296 7.40649Z"
        fill={color}
      />
      <path
        d="M14.5522 9.25693C14.4453 9.06372 14.2776 8.90931 14.0794 8.82379C13.9421 8.76915 13.5301 8.68442 13.5231 8.68442C13.0729 8.59891 12.3411 8.55298 11.5328 8.55298C10.7627 8.55298 10.0613 8.59891 9.60406 8.66859C9.59626 8.67651 9.086 8.76202 8.91045 8.85467C8.58978 9.02492 8.3916 9.35749 8.3916 9.71382V9.7447C8.3994 9.97671 8.59758 10.4637 8.60538 10.4637C8.94087 11.284 9.98481 12.9018 10.6792 13.5511C10.6792 13.5511 10.8579 13.7348 10.9687 13.814C11.1286 13.9383 11.3268 14.0001 11.5257 14.0001C11.7465 14.0001 11.9517 13.9304 12.1203 13.7989C12.1507 13.7681 12.2802 13.6516 12.3871 13.544C13.0573 12.9089 14.1558 11.2532 14.4906 10.3869C14.5444 10.2554 14.6591 9.92207 14.6661 9.7447C14.6661 9.57445 14.6287 9.41134 14.5522 9.25693Z"
        fill={color}
      />
    </svg>
  );
}
TransactionHistoryIcon.propTypes = {
  color: PropTypes.string,
};
