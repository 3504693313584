import React, { useEffect } from 'react';
import Card from 'components/Card/Card';
import Title from 'components/Header/Title';
import PaymentHistory from './PaymentHistory';
import { usePaymentState } from 'store/modules/payment';
import { formatCurrency } from 'utils/formatCurrency';
import { GuestPaymentHeader } from 'constants/mocks/payment';

export default function GuestPayment() {
  const { guestPaymentHistory, loading } = usePaymentState();

  useEffect(() => {
    document.title = 'Guest Payment - Pouchers Admin';
  }, []);

  return (
    <div className="my-1">
      <Title title="Guest Payment" variant="mb-3" />
      <div className="flex">
        <Card
          type="custom"
          containerVariant="p-6 border border-t-purple border-t-4 border-light-smoke rounded-b-lg bg-white items-center w-[252px]"
          cardBody={
            <div>
              <p className="text-12 font-dmsans_m text-grey">TOTAL REVENUE/PAYMENT</p>
              <p className="text-24 text-dark_blue font-dmsans_m mt-[16px]">
                {loading ? '₦....' : formatCurrency(guestPaymentHistory?.total_revenue)}
              </p>
            </div>
          }
        />
      </div>
      <PaymentHistory
        labels={GuestPaymentHeader}
        guest={true}
      />
    </div>
  );
}