/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function CableTv({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="48" height="48" rx="24" fill={color || "white"}/>
            <g clipPath="url(#clip0_347_6508)">
            <path d="M28.754 13.1289C28.4692 12.9153 28.0652 12.9731 27.8516 13.2579L24.4304 17.8196L21.0887 14.4779C20.837 14.2261 20.4289 14.2261 20.1772 14.4779C19.9255 14.7296 19.9255 15.1376 20.1772 15.3893L24.0443 19.2565C24.1656 19.3778 24.3297 19.4453 24.5 19.4453C24.5152 19.4453 24.5305 19.4447 24.5457 19.4437C24.7321 19.4304 24.9036 19.3369 25.0157 19.1875L28.8828 14.0313C29.0965 13.7465 29.0387 13.3425 28.754 13.1289ZM19.6321 31.2007C19.3137 31.0415 18.9266 31.1706 18.7674 31.489L17.4783 34.0671C17.3191 34.3855 17.4482 34.7726 17.7666 34.9318C17.8591 34.9781 17.9574 35 18.0543 35C18.2907 35 18.5183 34.8694 18.6313 34.6436L19.9203 32.0655C20.0796 31.7471 19.9505 31.3599 19.6321 31.2007ZM31.5648 34.0671L30.2758 31.489C30.1166 31.1706 29.7295 31.0416 29.4111 31.2008C29.0927 31.36 28.9636 31.7471 29.1228 32.0655L30.4119 34.6436C30.5248 34.8695 30.7524 35 30.9889 35C31.0857 35 31.1841 34.9781 31.2766 34.9319C31.595 34.7726 31.724 34.3855 31.5648 34.0671Z" fill="black"/>
            <path d="M33.5662 18.1562H15.4336C14.3674 18.1562 13.5 19.0237 13.5 20.0898V30.4882C13.5 31.5544 14.3674 32.4218 15.4336 32.4218H33.5662C34.6324 32.4218 35.4998 31.5544 35.4998 30.4882V20.0898C35.4998 19.0237 34.6325 18.1562 33.5662 18.1562V18.1562Z" fill="#FF6414"/>
            <path d="M22.9742 23.0247L27.008 24.637C27.1393 24.6896 27.2255 24.8168 27.2255 24.9582C27.2255 25.0996 27.1393 25.2268 27.008 25.2793L22.9742 26.8917C22.9327 26.9082 22.8892 26.9164 22.8458 26.9164C22.7777 26.9164 22.7099 26.8963 22.6519 26.8569C22.5569 26.7926 22.5 26.6853 22.5 26.5706V23.3458C22.5 23.2311 22.5569 23.1238 22.6519 23.0595C22.7469 22.9951 22.8676 22.9821 22.9742 23.0247Z" fill="#E3E9FC"/>
            </g>
            <defs>
            <clipPath id="clip0_347_6508">
            <rect width="22" height="22" fill="white" transform="translate(13.5 13)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

CableTv.propTypes = {
    color: PropTypes.string
}