/* eslint-disable max-len */
import React from "react"
import { Link } from "react-router-dom"

export default function Logo() {
    return(
        <Link to="/login">
            <svg width="167" height="40" viewBox="0 0 167 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_622_10781)">
                <path d="M47.6498 25.6028C44.6754 31.5749 37.4487 33.9696 31.4455 30.9797C25.4423 27.9897 22.9991 20.7804 25.9755 14.8082C28.9305 8.87679 36.1553 6.48211 42.1585 9.47207C48.1617 12.462 50.6049 19.6713 47.6498 25.6028Z" fill="#5034C4"/>
                <path d="M49.5602 8.24657C50.0773 7.20846 48.0612 5.15379 45.0572 3.65732C42.0531 2.16086 39.1986 1.78928 38.6815 2.82738C38.1643 3.86548 40.1804 5.92016 43.1845 7.41662C46.1885 8.91309 49.043 9.28467 49.5602 8.24657Z" fill="#5034C4"/>
                <path d="M69.9017 31.6583H66.933V27.5728C64.581 30.6985 61.7287 32.2613 58.3723 32.2613C56.6523 32.2613 55.1031 31.796 53.7264 30.8633C52.3497 29.9326 51.3743 28.7129 50.8023 27.2063C50.2284 25.7016 49.9414 23.4853 49.9414 20.5613V8.8186H52.8654V19.7004C52.8654 22.3665 52.9876 24.1737 53.2319 25.1199C53.4743 26.0662 53.8485 26.8689 54.3507 27.5282C54.8529 28.1875 55.4909 28.6897 56.2645 29.0329C57.0382 29.3761 57.9767 29.5486 59.0819 29.5486C60.1872 29.5486 61.2692 29.2772 62.3298 28.7323C63.3904 28.1875 64.294 27.4274 65.0405 26.452C65.7851 25.4767 66.2873 24.4742 66.5452 23.4407C66.8031 22.4072 66.933 20.3306 66.933 17.2029V8.8186H69.9017V31.6583Z" fill="#5034C4"/>
                <path d="M95.5377 13.5498L93.2148 15.0118C91.2079 12.3457 88.4545 11.0117 84.9565 11.0117C82.2031 11.0117 79.8957 11.8939 78.0303 13.6565C76.165 15.419 75.2343 17.6063 75.2343 20.2162C75.2343 22.8261 76.1514 25.0618 77.9877 26.9271C79.822 28.7924 82.1449 29.7232 84.9565 29.7232C88.311 29.7232 91.0644 28.3891 93.2148 25.723L95.5377 27.2723C94.3898 28.9359 92.8483 30.2118 90.9132 31.0999C88.978 31.9899 86.8916 32.4339 84.654 32.4339C81.1269 32.4339 78.1796 31.2725 75.814 28.9495C73.4484 26.6266 72.2656 23.7878 72.2656 20.4333C72.2656 17.0788 73.462 14.2033 75.8567 11.8086C78.2514 9.4139 81.3267 8.21753 85.0825 8.21753C87.2911 8.21753 89.3329 8.69065 91.2118 9.63689C93.0887 10.5831 94.5314 11.8881 95.5338 13.5518L95.5377 13.5498Z" fill="#5034C4"/>
                <path d="M97.8154 0H100.784V12.9042C103.134 9.77847 105.988 8.21562 109.345 8.21562C111.065 8.21562 112.614 8.68292 113.991 9.61365C115.367 10.5463 116.341 11.764 116.915 13.2706C117.489 14.7753 117.776 16.9916 117.776 19.9157V31.6583H114.807V20.7766C114.807 18.4265 114.735 16.8055 114.592 15.9155C114.448 15.0274 114.132 14.18 113.646 13.3773C113.157 12.5745 112.505 11.9657 111.689 11.5488C110.871 11.1338 109.882 10.9244 108.72 10.9244C107.559 10.9244 106.448 11.1978 105.387 11.7408C104.327 12.2856 103.423 13.0457 102.677 14.021C101.93 14.9964 101.43 15.9988 101.172 17.0323C100.914 18.0658 100.784 20.1445 100.784 23.2702V31.6583H97.8154V0Z" fill="#5034C4"/>
                <path d="M140.055 24.261L142.551 25.5523C140.085 30.1401 136.443 32.4339 131.625 32.4339C128.183 32.4339 125.344 31.2511 123.108 28.8855C120.871 26.5199 119.754 23.6734 119.754 20.3461C119.754 17.0187 120.879 14.1664 123.13 11.7853C125.381 9.40613 128.163 8.21558 131.475 8.21558C134.787 8.21558 137.568 9.31888 139.821 11.5274C142.072 13.736 143.241 16.7473 143.327 20.5613H122.765C122.851 23.3147 123.719 25.5291 125.367 27.2063C127.016 28.8836 129.017 29.7232 131.369 29.7232C135.239 29.7232 138.136 27.9024 140.057 24.261H140.055ZM140.055 18.0231C139.654 15.8437 138.65 14.1373 137.044 12.9041C135.439 11.6709 133.589 11.0543 131.495 11.0543C127.194 11.0543 124.382 13.3772 123.064 18.0231H140.053H140.055Z" fill="#5034C4"/>
                <path d="M144.443 8.81861H147.455V12.1305C148.257 10.7828 149.039 9.79394 149.799 9.16182C150.559 8.53164 151.404 8.21558 152.337 8.21558C153.27 8.21558 154.208 8.47347 155.154 8.98924L153.605 11.4848C153.175 11.2269 152.731 11.097 152.271 11.097C151.381 11.097 150.544 11.5565 149.754 12.4737C148.965 13.3908 148.385 14.5736 148.013 16.0221C147.641 17.4705 147.455 20.1018 147.455 23.9158V31.6583H144.443V8.81861Z" fill="#5034C4"/>
                <path d="M166.767 11.3123L164.875 13.2474C163.298 11.7272 161.762 10.9671 160.271 10.9671C159.296 10.9671 158.48 11.2754 157.82 11.892C157.161 12.5087 156.831 13.2688 156.831 14.1723C156.831 15.0759 157.182 15.8496 157.884 16.4953C158.586 17.141 159.798 17.9146 161.52 18.8182C163.24 19.7218 164.553 20.6971 165.456 21.7423C166.36 22.7893 166.812 24.073 166.812 25.5912C166.812 27.5128 166.145 29.1338 164.811 30.4523C163.476 31.7709 161.836 32.4301 159.885 32.4301C158.596 32.4301 157.355 32.1432 156.164 31.5692C154.974 30.9953 153.964 30.1925 153.132 29.1609L154.982 27.0959C156.502 28.7887 158.092 29.6341 159.756 29.6341C160.931 29.6341 161.935 29.2463 162.767 28.4726C163.599 27.6989 164.014 26.7876 164.014 25.7405C164.014 24.6934 163.647 23.8345 162.916 23.1597C162.185 22.4868 160.965 21.719 159.259 20.8581C157.553 19.9971 156.277 19.0587 155.432 18.0407C154.586 17.0227 154.163 15.7042 154.163 14.0831C154.163 12.4621 154.759 11.0796 155.949 9.9317C157.14 8.78574 158.716 8.21179 160.68 8.21179C162.645 8.21179 164.673 9.24335 166.767 11.3084V11.3123Z" fill="#5034C4"/>
                <path d="M0 8.83803H2.9221V13.0069C5.2431 9.82693 8.26603 8.23694 11.9909 8.23694C15.2 8.23694 17.965 9.41974 20.286 11.7834C22.607 14.1471 23.7685 16.9916 23.7685 20.3151C23.7685 23.6386 22.6225 26.4909 20.3306 28.8681C18.0387 31.2473 15.2872 32.4359 12.0781 32.4359C8.58209 32.4359 5.53008 30.875 2.92404 27.7512V40H0V8.83803ZM20.8464 20.3577C20.8464 17.8079 19.9661 15.6227 18.2035 13.8039C16.4409 11.9851 14.2847 11.0737 11.7349 11.0737C9.18513 11.0737 7.04251 11.9695 5.30903 13.7593C3.57555 15.5509 2.70881 17.7633 2.70881 20.4004C2.70881 23.0375 3.59688 25.2499 5.37302 27.0415C7.14916 28.8332 9.27626 29.7271 11.7563 29.7271C14.2363 29.7271 16.3692 28.8177 18.1608 26.9969C19.9506 25.1781 20.8464 22.9638 20.8464 20.3558V20.3577Z" fill="#5034C4"/>
                </g>
                <defs>
                <clipPath id="clip0_622_10781">
                <rect width="166.81" height="40" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </Link>
    )
}
