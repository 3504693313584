/* eslint-disable max-len */
import React from "react";
import PropTypes from 'prop-types';

export default function Close({ color }) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_785_2067)">
            <path d="M10.0006 8.82129L13.5364 5.28546C13.8618 4.96007 14.3894 4.96007 14.7148 5.28546V5.28546C15.0402 5.61084 15.0402 6.1384 14.7148 6.46379L11.1789 9.99962L14.7148 13.5355C15.0402 13.8608 15.0402 14.3884 14.7148 14.7138V14.7138C14.3894 15.0392 13.8618 15.0392 13.5364 14.7138L10.0006 11.178L6.46477 14.7138C6.13938 15.0392 5.61182 15.0392 5.28643 14.7138V14.7138C4.96104 14.3884 4.96104 13.8608 5.28643 13.5355L8.82227 9.99962L5.28643 6.46379C4.96104 6.1384 4.96104 5.61084 5.28643 5.28546V5.28546C5.61182 4.96007 6.13938 4.96007 6.46477 5.28546L10.0006 8.82129Z" fill={color} />
            <path d="M10.0006 8.82129L13.5364 5.28546C13.8618 4.96007 14.3894 4.96007 14.7148 5.28546V5.28546C15.0402 5.61084 15.0402 6.1384 14.7148 6.46379L11.1789 9.99962L14.7148 13.5355C15.0402 13.8608 15.0402 14.3884 14.7148 14.7138V14.7138C14.3894 15.0392 13.8618 15.0392 13.5364 14.7138L10.0006 11.178L6.46477 14.7138C6.13938 15.0392 5.61182 15.0392 5.28643 14.7138V14.7138C4.96104 14.3884 4.96104 13.8608 5.28643 13.5355L8.82227 9.99962L5.28643 6.46379C4.96104 6.1384 4.96104 5.61084 5.28643 5.28546V5.28546C5.61182 4.96007 6.13938 4.96007 6.46477 5.28546L10.0006 8.82129Z" fill="black" fillOpacity="0.2"/>
            </g>
            <defs>
            <clipPath id="clip0_785_2067">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

Close.propTypes = {
    color: PropTypes.string
}