import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  discountList: {},
  banners: [],
  exchangeRate: {},
  selectedDiscount: {},
  envs: [],
  notifications: {},
  productsStatus: [],
  loading: false,
  error: null
}

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSelectedDiscount: (state = initialState, action) => {
      state.selectedDiscount = action.payload;
    },
  },
  extraReducers
})
//Selectors
const selectSettings = (state) => state.settings;

//Reducers and actions
export const {
  setSelectedDiscount} = settings.actions

//Auth Redux State
export const useSettingsState = () => useAppSelector(selectSettings);

export default settings.reducer