import dayjs from 'dayjs';

export default function useFormatDate() {

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth()
  const currentDay = new Date().getDay()
  const currentDate = new Date().getDate();

  const getFirstDayOfYear = () => {
    const firstDay = new Date(currentYear, 0, 1);
    return dayjs(firstDay).format('YYYY-MM-DD')
  }

  const getLastDayOfYear = () => {
    const lastDay = new Date(currentYear, 11, 31);
    return dayjs(lastDay).format('YYYY-MM-DD')
  }

  const getMonth = () => {
  return dayjs(new Date()).format('MM')
  }
  const getToday = () => {
    const today = new Date();
    return dayjs(today).format('YYYY-MM-DD')
  }
  const getYear = () => {
    return dayjs(new Date()).format('YYYY')
  }
  const getFirstDayOfMonth = () => {
    const firstDay = new Date(currentYear, currentMonth, 1);
    return dayjs(firstDay).format('YYYY-MM-DD')
  }

  const getLastDayOfMonth = () => {
    const lastDay = new Date(currentYear, currentMonth + 1, 0);
    return dayjs(lastDay).format('YYYY-MM-DD')
  }

  const getFirstDayOfWeek = () => {
    const diff = currentDate - currentDay + (currentDay === 0 ? -6 : 1)
    const firstDay = new Date(new Date().setDate(diff));
    return dayjs(firstDay).format('YYYY-MM-DD')
  }

  const getLastDayOfWeek = () => {
    const lastDay = new Date(currentDate - (currentDay - 1) + 6);
    return dayjs(new Date(new Date().setDate(lastDay))).format('YYYY-MM-DD')
  }

  return {
    getFirstDayOfYear,
    getLastDayOfYear,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getFirstDayOfWeek,
    getLastDayOfWeek,
    getMonth,
    getYear,
    getToday
  }
}
