/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function DocumentIcon ({color}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M10.794 1.33337H5.20667C3.18 1.33337 2 2.52004 2 4.55337V11.44C2 13.5067 3.18 14.6667 5.20667 14.6667H10.794C12.8533 14.6667 14 13.5067 14 11.44V4.55337C14 2.52004 12.8533 1.33337 10.794 1.33337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.38664 4.43335V4.44002C5.09931 4.44002 4.86664 4.67335 4.86664 4.96002C4.86664 5.24668 5.09931 5.48002 5.38664 5.48002H7.37931C7.66664 5.48002 7.89997 5.24668 7.89997 4.95268C7.89997 4.66668 7.66664 4.43335 7.37931 4.43335H5.38664ZM10.6133 8.49335H5.38664C5.09931 8.49335 4.86664 8.26002 4.86664 7.97335C4.86664 7.68668 5.09931 7.45268 5.38664 7.45268H10.6133C10.9 7.45268 11.1333 7.68668 11.1333 7.97335C11.1333 8.26002 10.9 8.49335 10.6133 8.49335ZM10.6133 11.54H5.38664C5.18664 11.5667 4.99331 11.4667 4.88664 11.3C4.77997 11.1267 4.77997 10.9067 4.88664 10.74C4.99331 10.5667 5.18664 10.4734 5.38664 10.4934H10.6133C10.8793 10.52 11.08 10.7467 11.08 11.02C11.08 11.286 10.8793 11.5134 10.6133 11.54Z"
        fill={color}
      />
    </svg>
  );
}
DocumentIcon.propTypes = {
  color: PropTypes.string
}
