import React, { useState, useCallback, useEffect } from 'react';
import CTable from 'components/Table/Table';
import { UserInitals } from 'utils/ImagePlaceholder';
import { scheduleAndAutomatedHeader, scheduleFilterList } from 'constants/mocks/scheduleAndAutomated';
import Status from 'components/Status';
import { useScheduleState } from 'store/modules/scheduleAndAutomated';
import useCustomer from 'hooks/fetch/useCustomer';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDebounce } from 'hooks/useDounce';
import DateFilter from 'components/DropDown/DateFilter';
import DashboardCard from 'components/Card/DashboardCard';
import SearchInput from 'components/Input/SearchInput';
import Dropdown from 'components/DropDown/DropDown';
import { IoIosArrowDown } from 'react-icons/io';
import { EntriesfilterList } from 'constants/mocks/customers';
import { formatCurrency } from 'utils/formatCurrency';
import { providerFilterList } from 'constants/mocks/payment';

const ScheduleAndAutomated = ({ tab }) => {
  //Redux Hooks
  const scheduleState = useScheduleState();
  //Local State
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({ id: 0, value: '', label: 'Filter by Category' });
  const [providerFilter, setProviderFilter] = useState({ id: 0, value: '', label: 'Filter by Provider', key: '' });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  //Variable
  const per_page = perPage.value;
  let category;
  let status;
  let provider;
  if (tab !== 'all') status = tab;
  if (filter.label !== 'Filter by Category') category = filter.value;
  if (providerFilter?.value !== '') provider = providerFilter?.value;

  //Hooks

  const { handleSchedulePayment, handleViewCustomer } = useCustomer();
  const debouncedSearch = useDebounce(search);
  //Effect
  useEffect(() => {
    if (debouncedSearch) {
      handleSchedulePayment({
        search: debouncedSearch,
        per_page,
        page,
        fromDate: toDate && fromDate,
        toDate: fromDate && toDate,
        status,
        category,
        provider,
      });
    } else {
      handleSchedulePayment({
        per_page,
        page,
        fromDate: toDate && fromDate,
        toDate: fromDate && toDate,
        status,
        category,
        provider,
      });
    }
  }, [per_page, page, fromDate, toDate, tab, debouncedSearch, filter.value, providerFilter?.value]);

  //Handler Functions
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );
  const handleFilter = useCallback(
    (data) => {
      if (data?.key === 'api') {
        setProviderFilter(data);
        setFilter('');
      } else {
        setFilter(data);
        setProviderFilter('');
      }
    },
    [filter],
  );

  return (
    <DashboardCard variant="border-0">
      <div className="flex justify-between items-center mt-8 flex-wrap">
        <p className="font-dmsans_m text-18 xxs:text-16 xxs:mb-4 3xl:mb-0">Scheduled & Automated Bills</p>
        <div className="flex md:space-x-4 flex-wrap items-center">
          <SearchInput placeholder="Search names or tag" value={search} handleChange={handleChange} />
          <div className="mt-4 md:mt-0">
            <DateFilter endDate={toDate} setEndDate={setToDate} setStartDate={setFromDate} startDate={fromDate} />
          </div>
          <Dropdown
            variant="border border-light-smoke w-fit mt-4 md:mt-0"
            placeholder="Filter"
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            icon={<IoIosArrowDown />}
            optionContainerVariant="max-h-136 scroll-styled min-w-[160px]"
            value={providerFilter.key === 'api' ? providerFilter : filter}
            showIcon={true}
            type="filter"
            options={scheduleFilterList}
            providers={providerFilterList}
            onChange={handleFilter}
          />
          <div className="flex items-center xl:mt-0 mt-4">
            <span className="text-14 font-dmsans_r mr-2">Showing:</span>
            <div data-testid="pagefilter">
              <Dropdown
                variant="border border-light-smoke w-[142px]"
                placeholder=""
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                icon={<IoIosArrowDown />}
                value={perPage}
                type="filter"
                options={EntriesfilterList}
                onChange={handlePerPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-7">
        <CTable
          header={scheduleAndAutomatedHeader}
          totalPayload={scheduleState?.scheduledPayment?.total}
          perPage={per_page}
          loading={scheduleState?.loading}
          changePage={setPage}
          currentPage={scheduleState?.scheduledPayment?.page}
          showCheckBox={false}
          data={scheduleState?.scheduledPayment?.scheduled_payments}
        >
          {(item) => (
            <>
              <td>
                <div className="flex min-w-max whitespace-no-wrap items-center space-x-3 pl-6">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                      </p>
                    )}
                  </div>
                  <div
                    className="cursor-pointer min-w-max mx-4 whitespace-no-wrap text-14"
                    // onClick={() => handleViewCustomer(item.user_id)}
                    onClick={() => {
                      window.open(`/manage-customer/${item.user_id}`,'_blank')
                    }}
                  >
                    <p className="text-grey-900 font-dmsans_m capitalize">
                      {item.first_name} {item.last_name}
                    </p>
                    <p className="text-grey-500 font-dmsans_r ">@{item.tag}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="text-grey-500 font-dmsans_r capitalize min-w-max mx-4 whitespace-no-wrap">
                  {item.category.replace('-', ' ')}
                </p>
              </td>
              <td>
                <p className="text-grey-500 font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  {formatCurrency(item.amount) || '-'}
                </p>
              </td>
              <td className="">
                <p className="text-dark_blue font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  {item.frequency.includes('th') ? 'Monthly' : 'Weekly'}
                </p>
              </td>
              <td className="">
                <p className="text-dark_blue font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  {item.current_provider || '-'}
                </p>
              </td>
              <td role="cell">
                <div className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap">
                  <Status showIcon status={item.status} />
                </div>
              </td>
              <td>
                <p className="min-w-max mx-4 whitespace-no-wrap">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
            </>
          )}
        </CTable>
      </div>
    </DashboardCard>
  );
};
export default ScheduleAndAutomated;
ScheduleAndAutomated.propTypes = {
  tab: PropTypes.string,
};
