import { getAllTransfers, getCustomerTransactionHistory, getSingleTransfer, getUserTransactionHistory } from './actions';

const extraReducers = {
  //GET ALL TRANSFERS
  [getAllTransfers.pending]: (state) => {
    state.loading = true;
  },
  [getAllTransfers.fulfilled]: (state, action) => {
    state.transferList = action.payload.data;
    state.loading = false;
  },
  [getAllTransfers.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
  //GET SINGLE TRANSFERS
  [getSingleTransfer.pending]: (state) => {
    state.loading = true;
  },
  [getSingleTransfer.fulfilled]: (state, action) => {
    state.selectedTransfer = action.payload.data;
    state.loading = false;
  },
  [getSingleTransfer.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  // GET USER TRANSACTION HISTORY
  [getUserTransactionHistory.pending]: (state) => {
    state.loading = true;
  },
  [getUserTransactionHistory.fulfilled]: (state, action) => {
    state.transactionHistory = action.payload.data;
    state.loading = false;
  },
  [getUserTransactionHistory.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },

  // GET CUSTOMER TRANSACTION HISTORY
  [getCustomerTransactionHistory.pending]: (state) => {
    state.loading = true;
  },
  [getCustomerTransactionHistory.fulfilled]: (state, action) => {
    state.customersTransactionHistory = action.payload.data;
    state.loading = false;
  },
  [getCustomerTransactionHistory.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload.data.message;
  },
};

export { extraReducers };
