/* eslint-disable max-len */
import React from 'react'

const TransactionHistory = () => {
  return (
<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M4.70555 9.8905C4.18944 9.8905 3.77163 10.3145 3.77163 10.8383L3.51416 15.417C3.51416 16.0846 4.04783 16.625 4.70555 16.625C5.36328 16.625 5.89577 16.0846 5.89577 15.417L5.63947 10.8383C5.63947 10.3145 5.22167 9.8905 4.70555 9.8905Z" fill="#3A434B"/>
<path d="M5.98037 0.673447C5.98037 0.673447 5.71236 0.397892 5.54618 0.277931C5.30509 0.0926435 5.00783 0 4.71173 0C4.37936 0 4.07039 0.104521 3.81877 0.301685C3.77313 0.348007 3.57886 0.522605 3.41852 0.685325C2.41204 1.6367 0.765393 4.12026 0.262153 5.42083C0.182571 5.618 0.0105329 6.11685 0 6.38409C0 6.63827 0.0561757 6.88294 0.170868 7.11455C0.331202 7.40436 0.582823 7.63715 0.880085 7.76424C1.08606 7.84619 1.70282 7.97328 1.71453 7.97328C2.38981 8.10156 3.48757 8.17045 4.70003 8.17045C5.85514 8.17045 6.90727 8.10156 7.59308 7.99704C7.60478 7.98516 8.37017 7.85807 8.6335 7.71792C9.11333 7.46255 9.41177 6.96371 9.41177 6.43041V6.38409C9.40006 6.03608 9.10163 5.30444 9.09109 5.30444C8.58785 4.07394 7.02079 1.64858 5.98037 0.673447Z" fill="#3A434B"/>
<path opacity="0.4" d="M15.2949 8.10962C15.811 8.10962 16.2288 7.68559 16.2288 7.16178L16.4851 2.58296C16.4851 1.91543 15.9526 1.375 15.2949 1.375C14.6372 1.375 14.1035 1.91543 14.1035 2.58296L14.361 7.16178C14.361 7.68559 14.7788 8.10962 15.2949 8.10962Z" fill="#3A434B"/>
<path d="M19.8293 10.8853C19.6689 10.5955 19.4173 10.3638 19.1201 10.2356C18.9141 10.1536 18.2961 10.0265 18.2856 10.0265C17.6103 9.89824 16.5126 9.82935 15.3001 9.82935C14.145 9.82935 13.0929 9.89824 12.4071 10.0028C12.3954 10.0146 11.63 10.1429 11.3666 10.2819C10.8856 10.5373 10.5884 11.0361 10.5884 11.5706V11.6169C10.6001 11.9649 10.8973 12.6954 10.909 12.6954C11.4123 13.9259 12.9782 16.3525 14.0198 17.3265C14.0198 17.3265 14.2878 17.6021 14.454 17.7208C14.6939 17.9073 14.9911 18 15.2896 18C15.6208 18 15.9286 17.8954 16.1814 17.6983C16.227 17.652 16.4213 17.4774 16.5816 17.3158C17.5869 16.3632 19.2347 13.8796 19.7368 12.5802C19.8176 12.383 19.9896 11.883 20.0001 11.6169C20.0001 11.3616 19.944 11.1169 19.8293 10.8853Z" fill="#3A434B"/>
</svg>
  )
}

export default TransactionHistory