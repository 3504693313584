/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Airtime({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="48" height="48" rx="24" fill={color || 'white'}/>
            <g clipPath="url(#clip0_347_6530)">
            <path d="M17.9551 29.4545V32.8636C17.9551 33.4061 18.1706 33.9264 18.5542 34.31C18.9378 34.6936 19.458 34.9091 20.0005 34.9091H29.546C30.0885 34.9091 30.6087 34.6936 30.9923 34.31C31.3759 33.9264 31.5914 33.4061 31.5914 32.8636V29.4545H17.9551ZM24.7733 33.5455C24.5036 33.5455 24.2399 33.4655 24.0157 33.3156C23.7914 33.1658 23.6166 32.9528 23.5134 32.7037C23.4102 32.4545 23.3832 32.1803 23.4358 31.9158C23.4884 31.6513 23.6183 31.4083 23.809 31.2176C23.9997 31.0269 24.2427 30.897 24.5072 30.8444C24.7717 30.7918 25.0459 30.8188 25.2951 30.922C25.5443 31.0252 25.7572 31.2 25.9071 31.4242C26.0569 31.6485 26.1369 31.9121 26.1369 32.1818C26.1369 32.5435 25.9932 32.8903 25.7375 33.1461C25.4818 33.4018 25.1349 33.5455 24.7733 33.5455Z" fill="#6463FD"/>
            <path d="M17.9551 29.4545V15.1364C17.9551 14.5939 18.1706 14.0736 18.5542 13.69C18.9378 13.3064 19.458 13.0909 20.0005 13.0909H29.546C30.0885 13.0909 30.6087 13.3064 30.9923 13.69C31.3759 14.0736 31.5914 14.5939 31.5914 15.1364V29.4545H17.9551Z" fill="#6463FD"/>
            </g>
            <defs>
            <clipPath id="clip0_347_6530">
            <rect width="24" height="24" fill="white" transform="translate(12.5 12)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

Airtime.propTypes = {
    color: PropTypes.string
}