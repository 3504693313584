import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  customerList: {},
  transactionHistory: {},
  transactionReceipt: {},
  walletDetails: {},
  productHistory: {},
  customerReferralTrial: [],
  selectedCustomer: {},
  loading: false,
  error: null
}

export const customer = createSlice({
  name: 'customer',
  initialState,
  extraReducers
})

// Selectors,
const selectCustomer = (state) => state.customer;


//Auth Redux State
export const useCustomerState = () => useAppSelector(selectCustomer);

export default customer.reducer