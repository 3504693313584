/* eslint-disable max-len */
import React from 'react'

const Kyc = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1724_35615)">
<path opacity="0.4" d="M23.4924 20.9451L18.4089 15.8616C17.707 16.8461 16.8461 17.7071 15.8613 18.4089L20.9451 23.4927C21.6597 24.1848 22.8003 24.1667 23.4924 23.4518C24.1692 22.7533 24.1692 21.6437 23.4924 20.9451Z" fill="#3A434B"/>
<path d="M19.2162 9.60826C19.2163 4.30187 14.9148 0.000183111 9.60845 5.84556e-09C4.30206 -0.000183099 0.000183111 4.30133 5.84556e-09 9.60771C-0.000183099 14.9141 4.30133 19.216 9.60771 19.2162H9.60808C14.9119 19.2101 19.2101 14.9121 19.2162 9.60826ZM13.211 13.8116H6.00512C5.6737 13.8118 5.40472 13.5434 5.40453 13.2118C5.40453 13.2116 5.40453 13.2114 5.40453 13.211C5.41021 11.6427 6.29076 10.2087 7.68712 9.49437C6.41271 8.43328 6.23968 6.54015 7.30059 5.26574C8.3615 3.99133 10.2548 3.81829 11.5292 4.8792C12.8036 5.94012 12.9767 7.83342 11.9156 9.10784C11.7988 9.2481 11.6695 9.37755 11.5292 9.49437C12.9254 10.2087 13.8061 11.6429 13.8116 13.2114C13.8118 13.5428 13.5432 13.8116 13.2118 13.8116C13.2116 13.8116 13.2112 13.8116 13.211 13.8116Z" fill="#3A434B"/>
</g>
<defs>
<clipPath id="clip0_1724_35615">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default Kyc