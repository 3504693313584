/* eslint-disable max-len */
import React from 'react'

const DoubleCheck = () => {
  return (
<svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.83966 5.23473L8.75247 6.14754L14.2254 0.67457L15.1395 1.58867L8.75247 7.97574L4.63837 3.86164L5.55247 2.94754L6.9262 4.32128L7.83966 5.23409V5.23473ZM7.84095 3.40653L11.0422 0.20459L11.9538 1.11611L8.75247 4.31805L7.84095 3.40653ZM6.0134 7.06229L5.09994 7.97574L0.98584 3.86164L1.89994 2.94754L2.8134 3.861L2.81275 3.86164L6.0134 7.06229Z" fill="#5034C4"/>
</svg>

  )
}

export default DoubleCheck