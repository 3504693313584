import React from 'react';
import PropTypes from "prop-types"

const CopyIcon = ({onClick}) => {
  return (
    <button type="button" onClick={onClick}>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.0964 8H9.59635C8.67588 8 7.92969 8.74619 7.92969 9.66667V17.1667C7.92969 18.0871 8.67588 18.8333 9.59635 18.8333H17.0964C18.0168 18.8333 18.763 18.0871 18.763 17.1667V9.66667C18.763 8.74619 18.0168 8 17.0964 8Z"
          stroke="#8F8E9B"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.59766 12.9993H3.76432C3.3223 12.9993 2.89837 12.8238 2.58581 12.5112C2.27325 12.1986 2.09766 11.7747 2.09766 11.3327V3.83268C2.09766 3.39065 2.27325 2.96673 2.58581 2.65417C2.89837 2.34161 3.3223 2.16602 3.76432 2.16602H11.2643C11.7064 2.16602 12.1303 2.34161 12.4428 2.65417C12.7554 2.96673 12.931 3.39065 12.931 3.83268V4.66602"
          stroke="#8F8E9B"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default CopyIcon;

CopyIcon.propTypes = {
    onClick: PropTypes.func
}
