import React from "react";
import VerifyAccountForm from "../../components/Forms/VerifyAccountForm";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { LeftAuth } from "../../components/Onboarding/LeftAuth";

const VerifyAccount = () => {
    return (
      <AuthLayout 
        left={<LeftAuth/>}
        right={<VerifyAccountForm/>}
      />
    );
  }
  
  export default VerifyAccount;
  