import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../services/axios-instance'
import { admin } from '../../../services/endpoints/admin'

const getPaymentHistory = createAsyncThunk('payment/getPaymentHistory', async(params) => {
    try {
        const response = await api.get(admin.get_payment_history, {params})
        if (response.data) {
            return response.data
        }
        return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});
const getGuestPaymentHistory = createAsyncThunk('payment/getGuestPaymentHistory', async(params) => {
    try {
        const response = await api.get(admin.get_guest_payment_history, {params})
        if (response.data) {
            return response.data
        }
        return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});
const getSinglePayment = createAsyncThunk('payment/getSinglePayment', async(id) => {
    try {
        const response = await api.get(`/admin-overview/payments/${id}`)
        if (response.data) {
            return response.data
        }
        return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});
const getCategoryAnalytics = createAsyncThunk('payment/getCategoryAnalytics', async(params) => {
    try {
        const response = await api.get(admin.get_category_analytics, {params})
        if (response.data) {
            return response.data
        }
        return {code: response?.response?.data?.code, message: response?.response?.data?.message, status: response?.response?.data?.message};
    } catch (error) {
        return Promise.reject(error)
    }
});

export {
  getPaymentHistory,
  getCategoryAnalytics,
  getSinglePayment,
  getGuestPaymentHistory
}