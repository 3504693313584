export const AuditTrailHeader = [
  {
      name: 'Action By',
      variant: 'pl-6'
  },
  {
      name: 'Description',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Action on',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Module',
      variant: 'pl-4 min-w-max',
  },
  {
      name: 'Date',
      variant: 'xxs:pl-4 md:pl-0 min-w-max',
  },
]
export const auditFilter = [
  { id: 1, value: 'admin_management', label: 'Admin' },
  { id: 2, value: 'customer_management', label: 'Customer' },
  { id: 3, value: 'virtual_cards_management', label: 'Virtual Card' },
  { id: 4, value: 'dashboard_management', label: 'Dashboard' },
  { id: 5, value: 'transfers_management', label: 'Transfers' },
  { id: 6, value: 'vouchers_management', label: 'Vouchers' },
  { id: 7, value: 'discount_management', label: 'Discount' },
  { id: 8, value: 'site_settings_management', label: 'Site Settings' },
  { id: 9, value: 'notifications_management', label: 'Notifications' },
  { id: 10, value: 'scheduled_payments_management', label: 'Schedule Payments' },
  { id: 11, value: 'audit_trail_management', label: 'Audit Trail' },
  { id: 12, value: 'payments_management', label: 'Payment' },
]
export const AuditTrailData = [
  {
      customer_tagname: "@Muhandis",
      customer: "Olivia Rhye",
      action_on: "User Management",
      user: "Olivia Rhye",
      first_name: "Olivia", 
      last_name: "Rhye", 
      description: "Updated account password",
      date_created: "May 27, 2020",
      time: "12:38pm"
  },
  {
    customer_tagname: "@Muhandis",
    customer: "Phoenix Baker",
    user: "Phoenix Baker",
    action_on: "Upgrade Account Tier",
    first_name: "Phoenix", 
    last_name: "Baker", 
    description: "Accepted redemption request",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Muhandis",
    customer: "Lana Steiner",
    user: "Lana Steiner",
    action_on: "Manage Admin",
    first_name: "Lana", 
    last_name: "Steiner", 
    description: "Declined redemption request",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Muhandis",
    customer: "Demi Wilkinson",
    action_on: "Reports",
    user: "Olivia Rhye",
    first_name: "Olivia", 
    last_name: "Rhye", 
    description: "Deactivate user profile",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Isla",
    customer: "Candice Wu",
    action_on: "Virtual Cards",
    user: "Demi Wilkinson",
    first_name: "Olivia", 
    last_name: "Rhye", 
    description: "Deactivate user profile",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Isla",
    customer: "Natali Craig",
    action_on: "Manage Admin",
    user: "Candice Wu",
    first_name: "Candice", 
    last_name: "Wu", 
    description: "Deactivate user profile",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Isla",
    customer: "Natali Craig",
    action_on: "Manage Admin",
    user: "Candice Wu",
    first_name: "Candice", 
    last_name: "Wu", 
    description: "Deactivate user profile",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Isla",
    customer: "Drew Cano",
    action_on: "Manage Admin",
    user: "Drew Cano",
    first_name: "Drew", 
    last_name: "Cano", 
    description: "Accepted redemption request",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Muhandis",
    customer: "Drew Cano",
    action_on: "Manage Admin",
    user: "Drew Cano",
    first_name: "Drew", 
    last_name: "Cano", 
    description: "Accepted redemption request",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
  {
    customer_tagname: "@Muhandis",
    customer: "Drew Cano",
    action_on: "Manage Admin",
    user: "Olivia Rhye",
    first_name: "Candice", 
    last_name: "Wu", 
    description: "Accepted redemption request",
    date_created: "May 27, 2020",
    time: "12:38pm"
  },
]
