import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import PropTypes from 'prop-types';
import { SideBarLinks } from 'constants/sideBarLinks';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { top_nav_links, middle_nav_links } = SideBarLinks();

  const topNavLink = ({ url, icon, title }, i) => {
    return (
      <li key={i}>
        {pathname.includes('/manage-customer') ? (
          <NavLink
            onClick={() => (window.location.href = url)}
            style={{
              borderLeft: pathname.includes(url) && '4px solid #5034C4',
              backgroundColor: pathname.includes(url) && '#F4F4FB',
              color: pathname.includes(url) ? '#5034C4' : '#3A434B',
            }}
            className={`${pathname === url ? '#5034C4' : '#3A434B'} flex items-center py-12 px-5`}
          >
            <div className="fill-current stroke-current">{icon}</div>
            <p className="ml-[10px] font-dmsans_r font-bold text-14">{title}</p>
          </NavLink>
        ) : (
          <NavLink
            to={url}
            style={{
              borderLeft: pathname.includes(url) && '4px solid #5034C4',
              backgroundColor: pathname.includes(url) && '#F4F4FB',
              color: pathname.includes(url) ? '#5034C4' : '#3A434B',
            }}
            className={`${pathname === url ? '#5034C4' : '#3A434B'} flex items-center py-12 px-5`}
          >
            <div className="fill-current stroke-current">{icon}</div>
            <p className="ml-[10px] font-dmsans_r font-bold text-14">{title}</p>
          </NavLink>
        )}
      </li>
    );
  };

  return (
    <aside className="h-screen bg-white text-black-transparent min-w-[250px] xl:block hidden relative scrollbar-hide py-32 overflow-y-auto">
      <ul className="pb-44">{top_nav_links.filter(({ display }) => display).map((link, i) => topNavLink(link, i))}</ul>
      <hr className="px-10 h-px border-0 bg-deep-grey" />
      <ul className="pb-80">
        {middle_nav_links
          .filter(({ display }) => display)
          .map((link) => (
            <React.Fragment key={link.id}>
              <SidebarNavLink link={link} pathname={pathname} />
            </React.Fragment>
          ))}
      </ul>
    </aside>
  );
};

export const SidebarNavLink = ({ link, pathname }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <li data-testid="sidebar-item" onClick={() => setShowDropDown(!showDropDown)}>
      <div
        style={{
          borderLeft: pathname === link.url && '4px solid #5034C4',
          backgroundColor: pathname === link.url && '#F4F4FB',
          color: pathname === link.url ? '#5034C4' : '#3A434B',
        }}
        className="flex items-center justify-between py-12 px-5 cursor-pointer"
      >
        <div className="flex">
          <div className="text-purple">{link.icon}</div>
          <p className="ml-[10px] font-dmsans_r font-bold text-14">{link.title}</p>
        </div>
        {link.subMenuItems?.length && <IoIosArrowDown />}
      </div>
      {link.subMenuItems?.length && showDropDown && (
        <ul>
          {link.subMenuItems
            ?.filter(({ display }) => display)
            .map((item) => (
              <li key={item.id}>
                <NavLink
                  to={item.route}
                  style={{
                    borderLeft: pathname.includes(item.route) && '4px solid #5034C4',
                    backgroundColor: pathname.includes(item.route) && '#F4F4FB',
                    color: pathname.includes(link.route) ? '#5034C4' : '#3A434B',
                  }}
                  className={`${pathname === link.url ? '#5034C4' : '#3A434B'} flex items-center py-12 px-5`}
                >
                  <p className="ml-48 font-dmsans_r font-bold text-14">{item.name}</p>
                </NavLink>
              </li>
            ))}
        </ul>
      )}
    </li>
  );
};

SidebarNavLink.propTypes = {
  link: PropTypes.object,
  pathname: PropTypes.string,
};

export default Sidebar;
