import React, { useCallback, useState } from 'react';
import { TierLimit as tier_limit } from 'constants/mocks/settings';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import EditTierLimit from './EditTierLimit';
import LoadingSkeleton from 'components/Skeleton/Skeleton';
import SiteSettingsEditIcon from 'assets/icons/SiteSettingsEditIcon';
import DashboardCard from 'components/Card/DashboardCard';
import usePermissions from 'hooks/usePermissions';
import { formatCurrency } from 'utils/formatCurrency';
import { useAppSelector } from 'hooks/useReduxHook';
import EditTierMaximumBalance from './EditTierMaximumBalance';

export default function TierLimit({ loading }) {
  const { envs } = useAppSelector(state => state.settings)
  const { userIsPermittedTo } = usePermissions();
  const [showModal, setShowModal] = useState(false)
  const [itemToEdit, setItemToEdit] = useState({ level: 0, purpose: '', value: '' })
  const tier1_max = envs?.find(env => env.name === 'tier1_maximum_balance')?.value
  const tier1_limit = envs?.find(env => env.name === 'tier1_daily_transaction_limit')?.value
  const tier2_limit = envs?.find(env => env.name === 'tier2_daily_transaction_limit')?.value
  const tier2_max = envs?.find(env => env.name === 'tier2_maximum_balance')?.value
  const tier3_limit = envs?.find(env => env.name === 'tier3_daily_transaction_limit')?.value
  const tier3_max = envs?.find(env => env.name === 'tier3_maximum_balance')?.value

  const closeModal = useCallback(() => {
    setItemToEdit({ level: '', purpose: '' })
    setShowModal(false)
  }, [])

  const setShowModalProxy = useCallback((level, purpose, value) => {
    setItemToEdit({ level, purpose, value })
    setShowModal(true)
  }, [])

  const checkItemIsNumber = (item) => {
    return !isNaN(item) ? formatCurrency(item) : item
  }

  return (
    <>
      <div className="mt-6">
        <DashboardCard>
          <p className="text-18 font-dmsans_m mb-10">Tier Limit & Maximum Balance</p>
          <div className="ml-6">
            <div className="grid grid-cols-4">
              {tier_limit.map((item) => (
                <p key={item.name} className="text-purple text-16 font-dmsans_b">
                  {item.name}
                </p>
              ))}
            </div>
            {loading ? <LoadingSkeleton count={3} /> :
              <div className="mt-[50px] flex flex-col space-y-[50px]">
                <section className="grid grid-cols-4">
                  <p className="text-black-transparent text-16 font-dmsans_r">Tier 1</p>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier1_limit)}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(1, 'limit', tier1_limit)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier1_max)} </p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(1, 'maximum', tier1_max)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                </section>
                <section className="grid grid-cols-4">
                  <p className="text-black-transparent text-16 font-dmsans_r">Tier 2</p>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier2_limit)}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(2, 'limit', tier2_limit)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier2_max)}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(2, 'maximum', tier2_max)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                </section>
                <section className="grid grid-cols-4">
                  <p className="text-black-transparent text-16 font-dmsans_r">Tier 3</p>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier3_limit)}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(3, 'limit', tier3_limit)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                  <div className='flex justify-between w-[80%]'>
                    <p className="text-black-transparent text-16 font-dmsans_r">{checkItemIsNumber(tier3_max)}</p>
                    {userIsPermittedTo('Update', 'site_settings_management') && (
                      <div
                        className="text-black-transparent text-16 font-dmsans_r"
                        data-testid="edit"
                        onClick={() => setShowModalProxy(3, 'maximum', tier3_max)}
                      >
                        <SiteSettingsEditIcon />
                      </div>
                    )}
                  </div>
                </section>
              </div>
            }
          </div>
        </DashboardCard>
      </div>
      {showModal &&
        <Modal
          closeModal={closeModal}
          title={itemToEdit?.purpose === 'limit' ? "Edit Tier Limit" : "Edit Tier Maximum Balance"}
          containerVariant="min-w-[559px]"
        >
          <>
            {itemToEdit?.purpose === 'limit' ?
              <EditTierLimit
                tier={itemToEdit?.level}
                cancel={closeModal}
                limit={itemToEdit?.value}
              /> : ''
            }
            {itemToEdit?.purpose === 'maximum' ?
              <EditTierMaximumBalance
                tier={itemToEdit?.level}
                cancel={closeModal}
                maximum={itemToEdit?.value}
              /> : ''
            }
          </>
        </Modal>
      }
    </>
  );
}

TierLimit.propTypes = {
  tier1_max: PropTypes.string,
  tier1_limit: PropTypes.string,
  tier2_max: PropTypes.string,
  tier2_limit: PropTypes.string,
  tier3_max: PropTypes.string,
  tier3_limit: PropTypes.string,
  loading: PropTypes.bool,
};
