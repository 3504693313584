import React, { useState, useCallback, useEffect } from 'react';
import CTable from 'components/Table/Table';
import { DiscountHeader, DiscountType, frequncyOptions } from 'constants/mocks/settings';
import Status from 'components/Status';
import { useNavigate, useParams } from 'react-router-dom';
import { setSelectedDiscount, useSettingsState } from 'store/modules/settings';
import { formatCurrency } from 'utils/formatCurrency';
import dayjs from 'dayjs';
import useSettings from 'hooks/fetch/useSettings';
import Dropdown from 'components/DropDown/DropDown';
import { HiDotsVertical } from 'react-icons/hi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PropTypes from 'prop-types';
import { IoIosArrowDown } from 'react-icons/io';
import usePermissions from 'hooks/usePermissions';
import DashboardCard from 'components/Card/DashboardCard';
import Filter from 'assets/icons/Filter';
import { EntriesfilterList } from 'constants/mocks/customers';

const Service = ({ tab }) => {
  //local State
  const [filter, setFilter] = useState(null);
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState('1');
  const [type, setType] = useState(null);
  //Redux
  const { discountList, loading } = useSettingsState();
  const dispatch = useAppDispatch();
  const { userIsPermittedTo } = usePermissions();
  //Variable
  const per_page = perPage.value;
  let status;
  if (tab !== 'all') status = tab;
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleDiscountByCategory, deleteDiscount } = useSettings();
  //Effect
  useEffect(() => {
    if (tab === 'all') {
      handleDiscountByCategory(id, {
        per_page,
        page,
        discount_type: type && type.value,
        frequency: filter && filter.value,
      });
    } else {
      handleDiscountByCategory(id, {
        per_page,
        discount_type: type && type.value,
        frequency: filter && filter.value,
        page,
        status,
      });
    }
  }, [per_page, page, tab, type, filter]);

  //Handler Function
  const handleFilter = useCallback(
    (data) => {
      setFilter(data);
    },
    [filter],
  );
  const handleType = useCallback(
    (data) => {
      setType(data);
    },
    [type],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );
  const getSingleDiscount = async (discountId) => {
    const discount = discountList?.discounts.filter((item) => item.discount_id === discountId);
    dispatch(setSelectedDiscount(discount[0]));
    navigate(`/settings/discount-management/${id}/edit-discount`);
  };
  return (
    <DashboardCard variant="border-0">
      <div className="flex justify-between items-center flex-wrap">
        <div className="flex xxs:space-x-4 space-y-4 md:space-y-0 flex-wrap mt-4 items-center">
          <Dropdown
            variant="border border-light-smoke w-[175px]"
            placeholder="Filter by Frequency"
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            icon={<Filter />}
            type="filter"
            value={filter}
            options={frequncyOptions}
            onChange={handleFilter}
          />
          <Dropdown
            variant="border border-light-smoke w-[150px]"
            placeholder="Filter by Type"
            innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
            icon={<IoIosArrowDown />}
            optionContainerVariant="max-h-36 w-full scroll-styled"
            value={type}
            type="filter"
            options={DiscountType}
            onChange={handleType}
          />
          <div className='flex items-center mt-4 lg:mt-0'>
            <span className="text-14 font-dmsans_r mr-2">Showing:</span>
            <div data-testid="pagefilter">
              <Dropdown
                variant="border border-light-smoke w-[142px]"
                placeholder=""
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                icon={<IoIosArrowDown />}
                value={perPage}
                type="filter"
                options={EntriesfilterList}
                onChange={handlePerPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-7">
        <CTable
          totalPayload={discountList?.total}
          header={DiscountHeader}
          perPage={per_page}
          loading={loading}
          changePage={setPage}
          currentPage={discountList?.page}
          showCheckBox={false}
          data={discountList?.discounts}
        >
          {(item) => (
            <>
              <td className="pl-3">
                <p className="font-dmsans_r text-dark_blue min-w-max mx-4 whitespace-no-wrap capitalize">
                  {item.discount_type === 'flat' ? 'Flat Rate' : item.discount_type}
                </p>
              </td>
              <td>
                <p className="font-dmsans_r text-dark_blue min-w-max mx-4 whitespace-no-wrap">{item.discount_value}</p>
              </td>
              <td>
                <p className="font-dmsans_r text-dark_blue min-w-max mx-4 whitespace-no-wrap">
                  {formatCurrency(item.threshold)}
                </p>
              </td>
              <td>
                <p className="font-dmsans_r text-dark_blue min-w-max mx-4 whitespace-no-wrap capitalize">
                  {item.frequency.replace('_', '-')}
                </p>
              </td>
              <td>
                <Status showIcon status={item.status} />
              </td>
              <td>
                <p className="font-dmsans_m text-blue_black min-w-max mx-4 whitespace-no-wrap">
                  {dayjs(item.start_date).format('MMM DD, YYYY')}
                  <span className="text-grey font-dmsans_r"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
              {(userIsPermittedTo('Update', 'discount_management') ||
                userIsPermittedTo('Delete', 'discount_management')) && (
                <td>
                  <div className="min-w-max mx-4 whitespace-no-wrap">
                    <Dropdown
                      type="filterIcon"
                      filterIcon={<HiDotsVertical className="text-16 text-dark_blue" />}
                      optionContainerVariant="w-[115px]"
                    >
                      <div>
                        {userIsPermittedTo('Update', 'discount_management') && (
                          <div
                            key={1}
                            className="h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                            onClick={() => getSingleDiscount(item.discount_id)}
                          >
                            Edit
                          </div>
                        )}
                        {userIsPermittedTo('Delete', 'discount_management') && (
                          <div
                            key={2}
                            className="text-red-500 h-10 hover:bg-smoke cursor-pointer py-3 px-3.5 font-dmsans_r"
                            onClick={() =>
                              deleteDiscount(item.discount_id, () => handleDiscountByCategory(id, { per_page, page }))
                            }
                          >
                            Delete
                          </div>
                        )}
                      </div>
                    </Dropdown>
                  </div>
                </td>
              )}
            </>
          )}
        </CTable>
      </div>
    </DashboardCard>
  );
};

export default Service;
Service.propTypes = {
  tab: PropTypes.string,
};
