/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CustomerIcon = ({color}) => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99893 7.69336C5.69894 7.69336 3.7583 8.06919 3.7583 9.51969C3.7583 10.9708 5.71147 11.3334 7.99893 11.3334C10.2989 11.3334 12.2396 10.9576 12.2396 9.50708C12.2396 8.05598 10.2864 7.69336 7.99893 7.69336Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M7.99809 6.31119C9.55549 6.31119 10.8043 5.05521 10.8043 3.48885C10.8043 1.92188 9.55549 0.666504 7.99809 0.666504C6.44068 0.666504 5.19189 1.92188 5.19189 3.48885C5.19189 5.05521 6.44068 6.31119 7.99809 6.31119Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M14.0556 4.14615C14.4585 2.56117 13.2772 1.1377 11.7729 1.1377C11.6094 1.1377 11.453 1.15571 11.3002 1.18633C11.2799 1.19113 11.2572 1.20133 11.2452 1.21935C11.2315 1.24216 11.2417 1.27278 11.2566 1.29259C11.7085 1.93018 11.9681 2.70646 11.9681 3.53978C11.9681 4.33827 11.73 5.08273 11.3121 5.70051C11.2691 5.76415 11.3073 5.85 11.3831 5.86321C11.4882 5.88182 11.5956 5.89143 11.7055 5.89443C12.8009 5.92325 13.784 5.21421 14.0556 4.14615Z"
        fill={color}
      />
      <path
        d="M15.2048 7.87781C15.0042 7.44794 14.5201 7.15316 13.7841 7.00847C13.4367 6.92322 12.4965 6.80315 11.622 6.81936C11.6089 6.82116 11.6017 6.83016 11.6005 6.83617C11.5987 6.84457 11.6023 6.85898 11.6196 6.86799C12.0237 7.06911 13.5859 7.94385 13.3895 9.78879C13.3812 9.86864 13.445 9.93768 13.5244 9.92567C13.9089 9.87044 14.898 9.65671 15.2048 8.9909C15.3743 8.63908 15.3743 8.23023 15.2048 7.87781Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M4.69655 1.18633C4.54434 1.15511 4.38734 1.1377 4.22378 1.1377C2.71951 1.1377 1.53817 2.56117 1.9417 4.14615C2.21271 5.21421 3.19586 5.92325 4.29124 5.89443C4.40107 5.89143 4.50912 5.88122 4.61358 5.86321C4.68939 5.85 4.7276 5.76415 4.68462 5.70051C4.26676 5.08213 4.02858 4.33827 4.02858 3.53978C4.02858 2.70586 4.28885 1.92958 4.74073 1.29259C4.75505 1.27278 4.7658 1.24216 4.75147 1.21935C4.73953 1.20073 4.71745 1.19113 4.69655 1.18633Z"
        fill={color}
      />
      <path
        d="M2.21421 7.00847C1.47819 7.15316 0.994669 7.44794 0.794099 7.8778C0.623972 8.23022 0.623972 8.63907 0.794099 8.99149C1.10092 9.6567 2.09005 9.87103 2.47447 9.92567C2.55386 9.93768 2.61714 9.86923 2.60878 9.78878C2.41239 7.94444 3.97457 7.0697 4.37929 6.86858C4.39601 6.85897 4.39959 6.84517 4.3978 6.83616C4.3966 6.83016 4.39004 6.82115 4.3769 6.81995C3.5018 6.80314 2.56222 6.92321 2.21421 7.00847Z"
        fill={color}
      />
    </svg>
  );
};

export default CustomerIcon;
CustomerIcon.propTypes = {
  color: PropTypes.string
}
