
export const ManageAdminHeader = [
    {
        name: 'Admin',
        variant: 'pl-6'
    },
    {
        name: 'Role',
        variant: 'xxs:pl-4 min-w-max',
    },
    {
        name: 'Status',
        variant: 'xxs:pl-4 min-w-max',
    },
    {
        name: 'Date Created',
        variant: 'xxs:pl-4 min-w-max',
    },
    {
        name: 'No of Permission',
        variant: 'xxs:pl-4 min-w-max',
    },
    {
        name: ''
    }
]
export const ManageAdminData = [
    {
        id: 1, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: 'Super Admin',
        status: 'active',
        permisssion: 'All',
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 2, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: 'Auditor',
        status: 'active',
        permisssion: 3,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 3, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: 'Customer Service',
        status: 'active',
        permisssion: 7,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 4, 
        name: 'Demi Wilkinson',
        email: 'demi@untitledui.com',
        role_title: 'Social Media & Digital Marketer',
        status: 'active',
        permisssion: 3,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 5, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: 'Support Engineer',
        status: 'deactivated',
        permisssion: 3,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 6, 
        name: 'Natali Craig',
        email: 'natali@untitledui.com',
        role_title: '@Livia24',
        status: 'active',
        permisssion: 1,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 7, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: '@Livia24',
        status: 'suspended',
        permisssion: 3,
        date_created: 'May 27, 2020 | 12:38pm',
    },
    {
        id: 8, 
        name: 'Lana Steiner',
        email: 'lana@untitledui.com',
        role_title: '@Livia24',
        status: 'suspended',
        permisssion: 3,
        date_created: 'May 27, 2020 | 12:38pm',
    }
]
export const AdminListDropDown = [
    {
        id: 1,
        value: 'View',
        url: '',
        styles: ""
    },
    {
        id: 2,
        value: 'Deactivate',
        url: '',
        styles: ""

    }
]