import CustomButton from 'components/Button/CustomButton';
import DashboardCard from 'components/Card/DashboardCard';
import Dropdown from 'components/DropDown/DropDown';
import SearchInput from 'components/Input/SearchInput';
import CTable from 'components/Table/Table';
import { EntriesfilterList } from 'constants/mocks/customers';
import { paymentFilterList, PaymentHeader, providerFilterList } from 'constants/mocks/payment';
import dayjs from 'dayjs';
import useAdmin from 'hooks/fetch/useAdmin';
import useCustomer from 'hooks/fetch/useCustomer';
import { useDebounce } from 'hooks/useDounce';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { usePaymentState } from 'store/modules/payment';
import ExportExcel from 'components/ExportExcel';
import { formatCurrency } from 'utils/formatCurrency';
import { UserInitals } from 'utils/ImagePlaceholder';
import ViewPayment from './ViewPayment';
import Modal from 'components/Modal/Modal';
import Status from 'components/Status';
import DateFilter from 'components/DropDown/DateFilter';

const PaymentHistory = () => {
  const [search, setSearch] = useState('');
  const [showViewModal, setShowViewModal] = useState(false);
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({ id: 0, value: '', label: 'Filter by Category' });
  const [providerFilter, setProviderFilter] = useState({ id: 0, value: '', label: 'Filter by Provider', key: '' });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const start_date = toDate && fromDate
  const end_date = fromDate && toDate
  const { selectedPayment, paymentHistory, loading } = usePaymentState();
  const { handlePaymentHistory, handleViewPayment } = useAdmin();
  const { handleViewCustomer } = useCustomer();
  const debouncedSearch = useDebounce(search);

  const per_page = perPage.value;
  let category;
  if (filter?.value !== '') category = filter?.value;

  let provider;
  if (providerFilter?.value !== '') provider = providerFilter?.value;

  useEffect(() => {
    if (debouncedSearch) {
      handlePaymentHistory({ search: debouncedSearch, per_page, page, category, provider, fromDate, toDate });
    } else {
      handlePaymentHistory({ per_page, page, category, provider, fromDate, toDate });
    }
  }, [per_page, page, debouncedSearch, start_date, end_date, filter?.value, providerFilter?.value]);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handleFilter = useCallback(
    (data) => {
      if (data?.key === 'api') {
        setProviderFilter(data);
        setFilter('');
      } else {
        setFilter(data);
        setProviderFilter('');
      }
    },
    [filter],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  const handleViewModal = useCallback(() => {
    setShowViewModal(!showViewModal);
  }, [showViewModal]);

  return (
      <DashboardCard variant="mt-6 p-32">
        <div className="flex justify-between items-center mb-6">
          <p className="font-dmsans_b text-18">Payments Logs</p>
          <ExportExcel excelData={paymentHistory?.payments} fileName={`file-paymentList-${new Date().toISOString()}`}>
            <CustomButton name="Export" variant="border border-purple bg-purple text-white rounded font-dmsans_r" />
          </ExportExcel>
        </div>
        <div className="flex justify-between mb-6 flex-wrap items-center">
          <div className="flex md:space-x-4 flex-wrap items-center">
            <SearchInput placeholder="Search name, tag" value={search} handleChange={handleChange} />
            <Dropdown
              variant="border border-light-smoke w-[160px] mt-4 md:mt-0"
              placeholder="Filter"
              innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
              icon={<IoIosArrowDown />}
              optionContainerVariant="max-h-136 scroll-styled min-w-[160px]"
              value={providerFilter.key === 'api' ? providerFilter : filter}
              showIcon={true}
              type="filter"
              options={paymentFilterList}
              providers={providerFilterList}
              onChange={handleFilter}
            />
          </div>
          <div className="flex items-center space-x-4 flex-wrap mt-4 lg:mt-0">
            <DateFilter endDate={toDate} setEndDate={setToDate} setStartDate={setFromDate} startDate={fromDate} />
            <div className="flex items-center mt-4 md:mt-0">
              <span className="text-14 font-dmsans_r mr-2">Showing:</span>
              <Dropdown
                variant="border border-light-smoke w-[142px]"
                placeholder="10 Entries"
                innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
                icon={<IoIosArrowDown />}
                value={perPage}
                type="filter"
                options={EntriesfilterList}
                onChange={handlePerPage}
              />
            </div>
          </div>
        </div>
        <CTable
          header={PaymentHeader}
          totalPayload={paymentHistory?.total}
          changePage={setPage}
          perPage={per_page}
          showCheckBox={false}
          currentPage={page}
          loading={loading}
          data={paymentHistory?.payments}
        >
          {(item) => (
            <>
              <td
                data-testid="test-customer"
                className="cursor-pointer"
                // onClick={() => handleViewCustomer(item.user_id)}
                onClick={() => {
                  window.open(`/manage-customer/${item.user_id}`,'_blank')
                }}
                role="cell"
              >
                <div className="flex items-center space-x-3 pl-5 min-w-max whitespace-no-wrap">
                  <div className="rounded-full product__img bg-grey-400 flex items-center justify-center">
                    {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                    {!item.profile_picture && (
                      <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                        {UserInitals(item.first_name, item.last_name)}
                      </p>
                    )}
                  </div>
                  <div className="text-grey-500">
                    <p className="text-gray-900 capitalize font-dmsans_m">
                      {item.first_name} {item.last_name}
                    </p>
                    <p className="">@{item.tag}</p>
                  </div>
                </div>
              </td>
              <td role="cell">
                <p className="font-dmsans_r text-grey-500 min-w-max mx-4 whitespace-no-wrap capitalize">
                  {item.transaction_category.replace('-', ' ')}
                </p>
              </td>
              <td role="cell">
                <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                  {formatCurrency(item.amount)}
                </p>
              </td>
              <td>
                <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                  Wallet | <span className="text-grey">{item.service_provider || 'Anchor'}</span>
                </p>
              </td>
              <td>
                <p className="font-dmsans_r min-w-max mx-4 whitespace-no-wrap text-blue_black">
                  {dayjs(item.created_at).format('MMM DD, YYYY')}
                  <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
                </p>
              </td>
              <td>
                <p
                  className=" text-purple-300 font-inter_medium cursor-pointer min-w-max mx-4 whitespace-no-wrap"
                  data-testid="view-dropDown"
                  onClick={() => handleViewPayment(item.transaction_id, () => setShowViewModal(true))}
                >
                  View Details
                </p>
              </td>
            </>
          )}
        </CTable>
        {showViewModal && (
          <Modal
            closeModal={handleViewModal}
            title="Payment Details"
            containerVariant="md:w-[700px] w-[320px]"
            dividerVariant="mb-8 mt-5"
            status={
              <Status
                showIcon
                status={selectedPayment?.status === 'success' ? 'successful' : selectedPayment?.status}
              />
            }
          >
            <ViewPayment />
          </Modal>
        )}
      </DashboardCard>
  );
};

export default PaymentHistory;
