import React, { forwardRef } from 'react';
import dayjs from 'dayjs';
import DatePickerInner from 'components/Input/DatePickerInner/DatePickerInner';
import CalenderIcon from 'assets/icons/CalenderIcon';
import PropTypes from 'prop-types';

//Custom date interface component
export const CustomDateInput = forwardRef(({ value, onClick, variant, name, placeHolder }, ref) => {
  return (
    <div
      onClick={onClick}
      ref={ref}
      id={`date${name}`}
      className={` ${variant} text-14 border-0 w-full py-16 outline-0 font-dmsans_r font-normal rounded-lg focus:border bg-green_light flex`}
    >
      <div className=" border-r border-[#E9E9E9] h-full flex justify-center items-center pl-2 ">
        <CalenderIcon />
      </div>
      {value ? (
        <p className="text-sm px-3">{dayjs(value).format('MMM D, YYYY')}</p>
      ) : (
        <p className="text-sm px-3 text-gray-250 ">{placeHolder}</p>
      )}
    </div>
  );
});
CustomDateInput.displayName = 'CustomDateInput';

CustomDateInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
  placeHolder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
};
//This is a wrapper for the app to hold the Datepicker library and the custom interface
// onChange(selectedDate);
const DefaultDatePicker = ({
  name,
  placeHolder = 'DD/MM/YYYY',
  selectedDate,
  defaultValue,
  onHandleDateChange,
  variant,
  showLabel = false,
  label,
  dateVariant,
  isDisabled = false,
  maxDate = '2100-01-01',
  minDate = '1950-01-01',
}) => {
  return (
    <div className="relative">
      {showLabel && <label className="text-grey mb-2 block text-14">{label}</label>}
      {isDisabled ? (
        <CustomDateInput
          variant={variant}
          name={name}
          placeHolder={placeHolder}
          value={String(selectedDate).substring(0, 15)}
        />
      ) : (
        <DatePickerInner
          onChange={(data) => onHandleDateChange(data)}
          defaultValue={defaultValue}
          showLabel={showLabel}
          label={label}
          selectedDate={selectedDate}
          maxDate={maxDate}
          minDate={minDate}
          variant={dateVariant}
        />
      )}
    </div>
  );
};

export default DefaultDatePicker;
DefaultDatePicker.propTypes = {
  onHandleDateChange: PropTypes.func,
  name: PropTypes.string,
  variant: PropTypes.string,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  dateVariant: PropTypes.string,
  selectedDate: PropTypes.any,
  defaultValue: PropTypes.any,
  maxDate: PropTypes.any,
  isDisabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  minDate: PropTypes.any,
};
