import axios from 'axios';
import NProgress from 'nprogress';
import { getUser, updateUser } from './token.service';

let isRefreshing = false;
const requestArray = [];

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// timeout: 60000 * 5, // 5 minutes
	timeout: 60000 * 120, // 2 hours
	crossdomain: true
});

instance.interceptors.request.use((config) => {
	config.headers["Authorization"] = `Bearer ${getUser()?.loggedUser?.token}`;
	NProgress.start();
	return config;
});

instance.interceptors.response.use(
	(response) => {
		if (requestArray.length !== 0) {
			requestArray.forEach(function (x, i) {
				if (response.config.url === x.url) {
					requestArray.splice(i, 1);
				}
			});
		}
		NProgress.done();
		return response;
	},
	async (error) => {
		const originalConfig = error.config;
		requestArray.push(originalConfig);
		
		if ((originalConfig.url !== "/login" || originalConfig.url === "/login") && ['Session expired', 'jwt malformed', 'refresh_token is required', 'invalid signature',''].includes(error?.response?.data?.message)) {
			// Access Token is expired
			if (!originalConfig._retry && !isRefreshing) {
				originalConfig._retry = true;
				isRefreshing = true;

				return updateUser()
					.then((res) => {
						if (requestArray.length !== 0) {
							requestArray.forEach(x => {
								x.headers.Authorization = `Bearer ${res?.data?.token}`;
								instance.defaults.headers.common["Authorization"] = `Bearer ${res?.data?.token}`
								instance(x)
							});
						}
						instance.defaults.headers.common["Authorization"] = `Bearer ${res?.data?.token}`
						return instance(originalConfig)
					})
					.finally(() => isRefreshing = false)
			}
		}
		
		NProgress.done();
		return error;
	}
);

export default instance;
