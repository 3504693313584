import React from 'react';

const ArrowUp = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6"
      stroke="#12B76A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export default ArrowUp
