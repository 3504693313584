/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types"

export default function Tickets({color}) {
    return(
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="48" height="48" rx="24" fill={color || "white"}/>
        <path d="M35.8762 17.9385L35.6401 16.5994C35.4494 15.5206 34.2947 14.8222 33.0603 15.0397L17 17.8718H35.1924C35.4271 17.8718 35.6556 17.8955 35.8762 17.9385Z" fill="black"/>
        <path d="M34.4193 19.9334H13.581C12.7092 19.9334 12 20.5063 12 21.2102V31.3341C12 32.038 12.7092 32.6109 13.581 32.6109H34.4193C35.2908 32.6109 36 32.038 36 31.3341V21.2102C35.9999 20.506 35.2908 19.9334 34.4193 19.9334ZM19.4328 31.3107H17.7602V29.8711H19.4328V31.3107ZM19.4328 28.4314H17.7602V26.9919H19.4328V28.4314ZM19.4328 25.5523H17.7602V24.1126H19.4328V25.5523ZM19.4328 22.673H17.7602V21.2333H19.4328V22.673ZM31.7226 24.4061L30.4355 25.6102L31.5952 29.4839L30.9456 30.1334C30.8743 30.2045 30.7588 30.2045 30.6875 30.1334L28.9588 26.9916L27.3689 28.5598L27.6221 29.7956L27.2175 30.2005C27.1638 30.2542 27.0765 30.2542 27.0233 30.2007L24.7331 27.9105C24.6793 27.8568 24.6793 27.7698 24.7331 27.7163L25.1376 27.3112L26.3508 27.5587L27.92 25.9627L24.8002 24.2454C24.7289 24.1746 24.7289 24.0591 24.7997 23.9878L25.4499 23.3382L29.2979 24.4901L30.5106 23.1938C30.8649 22.8395 31.4235 22.8239 31.7582 23.1587C32.0923 23.4932 32.0764 24.0519 31.7226 24.4061Z" fill="#00BB64"/>
        <path d="M34.4193 19.9334H13.581C12.7092 19.9334 12 20.5063 12 21.2102V31.3341C12 32.038 12.7092 32.6109 13.581 32.6109H34.4193C35.2908 32.6109 36 32.038 36 31.3341V21.2102C35.9999 20.506 35.2908 19.9334 34.4193 19.9334ZM19.4328 31.3107H17.7602V29.8711H19.4328V31.3107ZM19.4328 28.4314H17.7602V26.9919H19.4328V28.4314ZM19.4328 25.5523H17.7602V24.1126H19.4328V25.5523ZM19.4328 22.673H17.7602V21.2333H19.4328V22.673ZM31.7226 24.4061L30.4355 25.6102L31.5952 29.4839L30.9456 30.1334C30.8743 30.2045 30.7588 30.2045 30.6875 30.1334L28.9588 26.9916L27.3689 28.5598L27.6221 29.7956L27.2175 30.2005C27.1638 30.2542 27.0765 30.2542 27.0233 30.2007L24.7331 27.9105C24.6793 27.8568 24.6793 27.7698 24.7331 27.7163L25.1376 27.3112L26.3508 27.5587L27.92 25.9627L24.8002 24.2454C24.7289 24.1746 24.7289 24.0591 24.7997 23.9878L25.4499 23.3382L29.2979 24.4901L30.5106 23.1938C30.8649 22.8395 31.4235 22.8239 31.7582 23.1587C32.0923 23.4932 32.0764 24.0519 31.7226 24.4061Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

Tickets.propTypes = {
    color: PropTypes.string
}