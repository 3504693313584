import React, { useState, useCallback, useEffect } from 'react';
import Status from '../../../components/Status';
import CTable from '../../../components/Table/Table';
import { ManageCustomerHeader, EntriesfilterList } from '../../../constants/mocks/customers';
import Dropdown from '../../../components/DropDown/DropDown';
import SearchInput from '../../../components/Input/SearchInput';
import { IoIosArrowDown } from 'react-icons/io';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { UserInitals } from 'utils/ImagePlaceholder';
import { formatCurrency } from 'utils/formatCurrency';
import useCustomer from 'hooks/fetch/useCustomer';
import { useCustomerState } from 'store/modules/customer';
import { useDebounce } from 'hooks/useDounce';
import { Link } from 'react-router-dom';

const CustomerList = ({ tab }) => {
  //Hook
  const {customerList, loading} = useCustomerState();
  //local state
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState({ label: '10 Entries', value: 10 });
  const [page, setPage] = useState(1);
  //Variable
  const per_page = perPage.value;
  let status
  if (tab !== 'all') status = tab; 
  //Hooks
  const { handleGetAllCustomer, handleViewCustomer } = useCustomer();
  const debouncedSearch = useDebounce(search)
  //Effect
  useEffect(() => {
    if (debouncedSearch ) {
      handleGetAllCustomer({ search:debouncedSearch , per_page, page,  status  });
    } else {
      handleGetAllCustomer({ per_page, page, status });
    }
  }, [per_page, page, tab , debouncedSearch]);
  //Handler function
  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search],
  );
  const handlePerPage = useCallback(
    (data) => {
      setPerPage(data);
    },
    [perPage],
  );

  return (
    <div className="mt-7">
      <div className="flex justify-between items-center mb-6">
        <p className="font-dmsans_b text-18">Customer’s List</p>
        <div className="flex space-x-4">
          <SearchInput
            placeholder="Search names, tag"
            value={search}
            handleChange={handleChange}
          />
          <div className="flex items-center">
            <span className="text-14 font-dmsans_r mr-2">Showing:</span>
            <Dropdown
              variant="border border-light-smoke w-[142px]"
              placeholder="10 Entries"
              innerVariant="text-black-transparent text-14 justify-between font-dmsans_r flex  items-center"
              icon={<IoIosArrowDown />}
              value={perPage}
              type="filter"
              options={EntriesfilterList}
              onChange={handlePerPage}
            />
          </div>
        </div>
      </div>
      <CTable
        header={ManageCustomerHeader}
        totalPayload={customerList?.total}
        changePage={setPage}
        perPage={per_page}
        showCheckBox={false}
        currentPage={customerList?.page}
        loading={loading}
        data={customerList?.customers}
      >
        {(item) => (
          <>
            <td
              role="cell"
              data-testid="test-picture"
            >
              <Link to={`/manage-customer/${item.user_id}`} target="_blank" className="flex items-center pl-6 space-x-3">
                <div className="rounded-full product__img flex items-center justify-center">
                  {item.profile_picture && <img src={`${item.profile_picture}`} alt="item" />}
                  {!item.profile_picture && (
                    <p className="uppercase text-gray_dark_100 font-dmsans_m text-14">
                      {UserInitals(item.first_name, item.last_name)}
                    </p>
                  )}
                </div>
                <div className="">
                  <p className="text-dark_blue font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap capitalize">
                    {item.first_name} {item.last_name}
                  </p>
                  <p className="text-grey font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">@{item.tag ? item.tag : 'N/A'}</p>
                </div>
              </Link>
            </td>
            <td role="cell">
              <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {dayjs(item.created_at).format('MMM DD, YYYY')}
                <span className="text-grey"> | {dayjs(item.created_at).format('h:mm a')}</span>
              </p>
            </td>
            <td role="cell">
              <p className="text-grey-500 font-dmsans_m min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                Tier {item.tier_levels}
              </p>
            </td>
            <td role="cell">
              <p className="font-dmsans_r min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {formatCurrency(item.wallet_balance || '00.00')}{' '}
              </p>
            </td>
            <td role="cell">
              <p className="text-grey-500 font-dmsans_b min-w-max mx-4 md:mx-0 whitespace-no-wrap">
                {item.card_count === '0' ? 'No' : 'Yes'}
              </p>
            </td>
            <td>
              <Status showIcon status={item.status} />
            </td>
            <td role="cell" data-testid={`table-dropdown-${item.user_id}`}>
            <p 
              className=" text-purple-300 font-inter_medium cursor-pointer min-w-max mx-4 whitespace-no-wrap"
              data-testid='test-view'
              // onClick={() => handleViewCustomer(item.user_id)}
              onClick={() => {
                window.open(`/manage-customer/${item.user_id}`,'_blank')
              }}
            >
              View
            </p>
            {/* onClick={() => handleViewCustomer(item.user_id)}>View</p> */}
            </td>
          </>
        )}
      </CTable>
    </div>
  );
};
export default CustomerList;
CustomerList.propTypes = {
  tab: PropTypes.string,
};
