export const NotificationData = [
  {
    title: 'Happy New Month',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Happy New Month',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'Push Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'lana@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'It is the beginiing of a new month...',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Demi Wilkinson',
    description: 'demi@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Demi',
    last_name: 'Wilkinson',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'lana@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Natali Craig',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Natali',
    last_name: 'Craig',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
  {
    title: 'Lana Steiner',
    description: 'natali@untitledui.com',
    sent_by: 'Adedoyin Abiola',
    first_name: 'Lana',
    last_name: 'Steiner',
    type: 'In App Notification',
    date_created: 'May 27, 2020',
    time: '12:38pm',
  },
];
export const MessageType = [
  {
    id: 1,
    value: 'In App Notifcation',
    label: 'In App Notifcation'
  },
  {
    id: 2,
    value: 'Push Notification',
    label: 'Push Notification'
  },
  {
    id: 3,
    value: 'In App & Push Notification',
    label: 'In App & Push Notification',
  },
];
