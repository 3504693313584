import Card from 'components/Card/Card';
import React, { useMemo } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import SendIcon from 'assets/icons/SendIcon';
import Status from 'components/Status';
import { useVirtualCard } from 'store/modules/virtualCards';
import { groupDataByDate } from 'utils/groupDataByDate';
import Spinner from 'components/Spinner';
import EmptyState from 'components/EmptyState';
import dayjs from 'dayjs';

const CardTransactionHistory = () => {
  //Redux Hooks
  const { cardHistory, loading } = useVirtualCard();
  //Hooks
  const filterTransactions = useMemo(() => groupDataByDate(cardHistory), [cardHistory]);
  const todayDate = dayjs(new Date()).format('YYYY-MM-DD');

  return (
    <div>
      <p className=" text-purple font-dmsans_m text-24">Transaction History</p>
      {loading && (
        <div className="mt-10">
          <Spinner />
        </div>
      )}

      {!loading &&
        (filterTransactions?.length > 0 ? (
          <div className="border border-light-smoke rounded-lg p-8 mt-10">
            <div className="flex justify-center items-center space-x-6 mb-8">
              <Card
                type="custom"
                containerVariant="bg-purple-50 p-6 border border-purple rounded-xl"
                cardBody={
                  <>
                    <p className="font-dmsans_r text-grey">Balance</p>
                    <p className="text-dark_blue text-24 font-dmsans_m">₦ 14,209,000.00</p>
                  </>
                }
              />
              <Card
                type="custom"
                containerVariant="bg-green-50 p-6 border border-green rounded-xl"
                cardBody={
                  <>
                    <p className="font-dmsans_r text-grey">Inflow</p>
                    <p className="text-dark_blue text-24 font-dmsans_m">₦ 14,209,000.00</p>
                  </>
                }
              />
              <Card
                type="custom"
                containerVariant="bg-orange_light p-6 border border-red rounded-xl"
                cardBody={
                  <>
                    <p className="font-dmsans_r text-grey">Outflow</p>
                    <p className="text-dark_blue text-24 font-dmsans_m">₦ 14,209,000.00</p>
                  </>
                }
              />
            </div>
            <div className="w-3/4 mx-auto">
              {filterTransactions.map((item) => (
                <div key={item.date}>
                  <p className="border-b pb-1 mt-2 border-smoke text-grey font-dmsans_r">
                    {' '}
                    {item.date === todayDate ? 'Today' : dayjs(item.date).format(' MMM D, YYYY')}
                  </p>
                  {item.items.map((data) => (
                    <div key={data.transaction_id}>
                      <Card
                        containerVariant="mt-[18px] flex justify-between items-center p-4 
                        hover:bg-green-100 border-b border-light-smoke cursor-pointer
                         hover:border hover:border-green rounded"
                        type="custom"
                        cardBody={
                          <>
                            <div className="flex items-center">
                              <div className="bg-purple_transparent hover:bg-white w-10 h-10 flex items-center justify-center rounded-full mr-3">
                                {data.transaction_type === 'credit' ? (
                                  <BsPlusLg className="text-12 text-green-200" />
                                ) : (
                                  <SendIcon />
                                )}
                              </div>
                              <div className="">
                                <p className="text-grey font-dmsans_m">
                                  To: <span className="text-dark_blue">Kingsley Omin</span>
                                </p>
                                <p className="text-grey text-12 font-dmsans_r mt-0.5">Deposit</p>
                              </div>
                            </div>
                            <Status showIcon status="completed" />
                            <p className="text-purple font-dmsans_m text-14">+ 20,000 USD</p>
                          </>
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <EmptyState />
        ))}
    </div>
  );
};

export default CardTransactionHistory;
